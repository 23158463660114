var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('page-header',{attrs:{"title":"Autoposting","breadcrumbs":[
          { text: 'Home', to: '/' },
          { text: 'Autoposting', disabled: true },
          { text: 'Dashboard', disabled: true },
        ]}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"flat":"","outlined":"","rounded":"lg"}},[_c('v-card-title',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(" Amount of donors "),_c('v-spacer'),_c('autoposting-data-toggler',{attrs:{"variants":[
                  { title: '7 days', key: '7_days' },
                  { title: '24 hours', key: '24_hours' },
                ]},model:{value:(_vm.postfix.number_donors_added),callback:function ($$v) {_vm.$set(_vm.postfix, "number_donors_added", $$v)},expression:"postfix.number_donors_added"}})],1),_c('v-divider'),_c('v-card-text',[_c('autoposting-chart',{attrs:{"data":_vm.dashboard?.[
                    `number_donors_added_${this.postfix.number_donors_added}`
                  ]}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"flat":"","outlined":"","rounded":"lg"}},[_c('v-card-title',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(" Number access checked "),_c('v-spacer'),_c('autoposting-data-toggler',{attrs:{"variants":[
                  { title: '7 days', key: '7_days' },
                  { title: '24 hours', key: '24_hours' },
                ]},model:{value:(_vm.postfix.number_running_access_checks),callback:function ($$v) {_vm.$set(_vm.postfix, "number_running_access_checks", $$v)},expression:"postfix.number_running_access_checks"}})],1),_c('v-divider'),_c('v-card-text',[_c('autoposting-chart',{attrs:{"data":_vm.dashboard?.[
                    `number_running_access_checks_${this.postfix.number_running_access_checks}`
                  ]}})],1)],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{attrs:{"flat":"","outlined":"","rounded":"lg"}},[_c('v-card-title',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(" Successful authorizations "),_c('v-spacer'),_c('autoposting-data-toggler',{attrs:{"variants":[
                  { title: '7 days', key: '7_days' },
                  { title: '24 hours', key: '24_hours' },
                ]},model:{value:(_vm.postfix.number_success_authorized),callback:function ($$v) {_vm.$set(_vm.postfix, "number_success_authorized", $$v)},expression:"postfix.number_success_authorized"}})],1),_c('v-divider'),_c('v-card-text',[_c('autoposting-chart',{attrs:{"data":_vm.dashboard?.[
                    `number_success_authorized_${this.postfix.number_success_authorized}`
                  ]}})],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{attrs:{"flat":"","outlined":"","rounded":"lg"}},[_c('v-card-title',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(" Posts "),_c('v-spacer'),_c('autoposting-data-toggler',{attrs:{"variants":[
                  { title: '7 days', key: '7_days' },
                  { title: '24 hours', key: '24_hours' },
                ]},model:{value:(_vm.postfix.posts),callback:function ($$v) {_vm.$set(_vm.postfix, "posts", $$v)},expression:"postfix.posts"}})],1),_c('v-divider'),_c('v-card-text',[_c('autoposting-chart',{attrs:{"data":_vm.dashboard?.[`${this.postfix.posts}`]}})],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{attrs:{"flat":"","outlined":"","rounded":"lg"}},[_c('v-card-title',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(" Not created texts "),_c('v-spacer'),_c('autoposting-data-toggler',{attrs:{"variants":[
                  { title: '7 days', key: '7_days' },
                  { title: '24 hours', key: '24_hours' },
                ]},model:{value:(_vm.postfix.text_not_created_7_days),callback:function ($$v) {_vm.$set(_vm.postfix, "text_not_created_7_days", $$v)},expression:"postfix.text_not_created_7_days"}})],1),_c('v-divider'),_c('v-card-text',[_c('autoposting-chart',{attrs:{"data":_vm.dashboard?.[
                    `text_not_created_${this.postfix.text_not_created_7_days}`
                  ]}})],1)],1)],1)],1),_c('v-row',{attrs:{"dense":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":"","outlined":"","rounded":"lg"}},[_c('v-card-title',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(" Queue URL's ")]),_c('v-card-title',[_c('v-text-field',{attrs:{"hide-details":"","dense":"","outlined":"","placeholder":"Type to search","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider'),_c('v-card-text',{staticClass:"px-0"},[_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.queueUrlsHeaders,"items":_vm.dashboard?.queue_urls || []}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }