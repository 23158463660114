<template>
  <apexchart
    :options="computedOptions"
    :series="computedSeries"
    height="30"
    width="240"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  props: ["series"],

  components: { apexchart: VueApexCharts },

  computed: {
    computedSeries() {
      return [
        {
          name: "Done",
          data: [this.series[0]],
        },
        {
          name: "New",
          data: [this.series[1]],
        },
        {
          name: "Error",
          data: [this.series[2]],
        },
        {
          name: "No donors",
          data: [this.series[3]],
        },
      ];
    },
    computedOptions() {
      return {
        chart: {
          type: "bar",
          stacked: true,
          stackType: "100%",
          toolbar: {
            show: false,
          },
        },
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
        },
        colors: ["#4e8cd3", "#31cc14", "#ef4242", "#e69e0a"],
        grid: {
          padding: {
            bottom: -20,
            top: -40,
          },
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          labels: {
            show: false,
          },
          // categories: [2008, 2009, 2010, 2011, 2012, 2013, 2014],
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
