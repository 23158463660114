<template>
  <v-card class="styled-card--light" rounded="lg" :loading="loading">
    <v-card-title style="gap: 0.5rem">
      <v-spacer />
      <v-chip :outlined="!dataLabels" label @click="dataLabels = !dataLabels">
        <v-icon v-if="!dataLabels" small left
          >mdi-checkbox-blank-outline
        </v-icon>
        <v-icon v-else small left>mdi-checkbox-marked</v-icon>
        Data labels
      </v-chip>
      <v-chip :outlined="monochrome" label @click="monochrome = !monochrome">
        <v-icon v-if="monochrome" small left>mdi-checkbox-blank-outline</v-icon>
        <v-icon v-else small left>mdi-checkbox-marked</v-icon>
        Gradient
      </v-chip>
      <v-menu bottom offset-y content-class="my-shadow--e2">
        <template #activator="{ on }">
          <v-chip label outlined v-on="on">
            Type
            <v-badge :content="type" inline color="info" />
            <v-icon small>mdi-chevron-down</v-icon>
          </v-chip>
        </template>
        <v-list dense>
          <v-list-item
            v-for="item in ['area', 'bar']"
            :key="item"
            :class="{ 'v-list-item--active': type === item }"
            @click="type = item"
          >
            {{ item }}
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer />
    </v-card-title>
    <v-card-text>
      <vue-apex-charts
        :type="type"
        :series="positions.series"
        :options="getOptions"
        height="390"
        width="100%"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: { VueApexCharts },
  props: {
    loading: Boolean,
    positions: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    type: "area",
    dataLabels: true,
    monochrome: false,
    gradientColors: [
      "#20cb59",
      "#72d741",
      "#abe026",
      "#e3e50f",
      "#ffe710",
      "#ffe073",
      "#fad1a5",
      "#f8b7b7",
    ],
  }),
  computed: {
    getOptions() {
      return {
        colors: this.gradientColors,
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light",
          monochrome: {
            enabled: this.monochrome,
            color: "#2b81d6",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
        chart: {
          animations: {
            enabled: false,
          },
          stacked: true,
          // toolbar: {
          //   show: false,
          // },
        },
        plotOptions: {
          bar: {
            columnWidth: "95%",
            borderRadius: 16,
          },
        },
        xaxis: {
          categories: this.positions.categories || [],
          // type: "datetime",
        },
        dataLabels: {
          enabled: this.dataLabels,
          style: {
            fontSize: "14px",
            colors: ["black"],
          },
        },
        grid: {
          borderColor: "transparent",
          padding: {
            left: 24,
            right: 0,
            bottom: 12,
            top: 0,
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          offsetY: 8,
        },
        // yaxis: [
        //   {
        //     y: 0,
        //     offsetX: 0,
        //     offsetY: 0,
        //     padding: {
        //       left: 0,
        //       right: 0,
        //     },
        //   },
        // ],
        tooltip: {
          shared: true,
          intersect: false,
        },
        fill: {
          opacity: 1,
        },
      };
    },
  },
};
</script>
