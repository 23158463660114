<template>
  <div v-if="historyItem" class="text-body-2 py-2 px-4">
    <div class="d-flex justify-space-between">
      <div>
        <b>
          {{ historyItem?.status?.name }}
          <status-icon small color="primary" />
        </b>
      </div>
      <div>
        <v-tooltip bottom open-delay="30">
          <template #activator="{ on }">
            <div v-on="on">
              <v-icon small>mdi-calendar</v-icon>
              {{ $moment(historyItem.created_at).format("DD.MM.YY") }}
            </div>
          </template>
          {{ $moment(historyItem.created_at).format("lll") }}
        </v-tooltip>
      </div>
    </div>
    <div class="d-flex mt-2" style="gap: 0.25rem">
      <v-avatar size="38">
        <v-img :src="historyItem?.user?.photo" />
      </v-avatar>
      <div>
        <div>
          <b>{{ historyItem?.user?.name }}</b>
        </div>
        <div>{{ historyItem?.user?.email }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusIcon from "@/components/SiteWorkplace/SiteWorksheet/components/StatusIcon.vue";

export default {
  components: { StatusIcon },
  props: ["historyItem"],
};
</script>
