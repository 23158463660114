<template>
  <div>
    <template v-if="item?._content?.type === types.POSITIONS">
      <v-chip label>
        {{ item?._content?.data?.collected_positions_percent }}% of collected
        positions
      </v-chip>
    </template>
  </div>
</template>

<script>
import { Tribe4DxApiService } from "../service/Tribe4DxApiService";
import { RELATED_DASHBOARD_DATA } from "../defaults";

export default {
  props: ["item"],

  data() {
    const types = Tribe4DxApiService().TRIBE_TYPES;

    return {
      types,
      relatedDashboardsData: RELATED_DASHBOARD_DATA(types, this.$moment),
    };
  },
};
</script>
