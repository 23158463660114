<template>
  <v-dialog
    :close-on-content-click="false"
    content-class="my-shadow--e2"
    width="420"
    scrollable
  >
    <template #activator="{ on }">
      <v-card
        v-on="on"
        class="text-center pa-4"
        outlined
        rounded="lg"
        :disabled="loadings.statusesHistory"
        :style="{ opacity: loadings.statusesHistory ? '0.5' : 1 }"
        @click="handleShowStatusesHistory"
      >
        Show statuses history
        <v-progress-circular
          v-if="loadings.statusesHistory"
          size="12"
          width="2"
          indeterminate
        />
        <v-icon v-else small right>mdi-history</v-icon>
      </v-card>
    </template>
    <v-card flat outlined rounded="lg" width="380">
      <v-card-title class="text-body-2 pb-1 pt-2">
        Statuses history
        <v-spacer />
        <v-btn
          icon
          :loading="loadings.statusesHistory"
          @click="handleShowStatusesHistory(true)"
        >
          <v-icon small> mdi-refresh </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-title class="pt-0 pb-3">
        <v-text-field
          v-model="statusesHistorySearch"
          hide-details
          dense
          outlined
          placeholder="Type to search"
          append-icon="mdi-magnify"
        />
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-0">
        <template v-if="loadings.statusesHistory">
          <div class="px-4 pb-4 pt-2">
            <div class="skeleton" style="height: 120px; width: 100%" />
          </div>
        </template>
        <template v-else-if="statusesHistory?.[form?._ctx?.url_id]?.length">
          <div class="d-flex flex-column">
            <template
              v-for="(historyItem, idx) in searchByJson(
                statusesHistorySearch,
                statusesHistory?.[form?._ctx?.url_id]
              )"
            >
              <status-history-item
                v-if="historyItem"
                :history-item="historyItem"
                :key="`history-item-${idx}`"
              />
              <v-divider
                v-if="
                  historyItem &&
                  idx !== statusesHistory?.[form?._ctx?.url_id]?.length - 1
                "
                :key="idx"
              />
            </template>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import StatusHistoryItem from "./StatusHistoryItem.vue";
import { SiteWorkSheetApiService } from "../services/SiteWorkSheetApiService";

export default {
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },

  components: { StatusHistoryItem },

  data: () => ({
    loadings: {
      statusesHistory: false,
    },
    statusesHistory: {},
    statusesHistorySearch: null,
  }),

  methods: {
    searchByJson(searchQuery, items) {
      if (!searchQuery) return items;

      return (
        items?.filter((item) => {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(String(searchQuery).toLowerCase());
        }) || []
      );
    },
    async handleShowStatusesHistory(forceUpload = false) {
      if (forceUpload !== true) {
        if (this.statusesHistory?.[this.form?._ctx?.url_id]?.length) return;
      }

      try {
        this.loadings.statusesHistory = true;

        const { data } = await SiteWorkSheetApiService().getStatusesHistory(
          this.form._ctx.url_id
        );

        this.statusesHistory = {};

        await this.$nextTick();

        // По ключу "айди урла" сохраняю полученные данные
        // Нужно для кейса, когда пользователь нажимает "Show history" в разных модалках "Edit status"
        // Был баг, что во всех модалках одинаковые данные подгружались из-за первого условия в этом методе
        this.$set(this.statusesHistory, this.form?._ctx?.url_id, data);
      } catch (e) {
        console.error("Error while loading statuses history.", e);
      } finally {
        this.loadings.statusesHistory = false;
      }
    },
  },
};
</script>
