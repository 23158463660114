<template>
  <v-tooltip bottom open-delay="150">
    <template #activator="{ on }">
      <v-img v-on="on" :src="computedImage" v-bind="$attrs" />
    </template>
    {{ iso }}
  </v-tooltip>
</template>

<script>
const icons = require("rendered-country-flags");

export default {
  props: ["iso"],

  computed: {
    computedImage() {
      return icons[this.iso];
    },
  },
};
</script>
