<template>
  <v-data-table :items="data.items" :headers="data.headers" dense>
    <template v-for="h in data.headers" #[`item.${h.value}`]="{ item, value }">
      <template v-if="['visibility', 'traffic', 'keys'].includes(h.value)">
        <traffic-value :traffic="item['_' + h.value]" :key="h.value" />
      </template>
      <template v-else-if="h.value === 'geo'">
        <flag-image :iso="value" :key="h.value" width="24" />
      </template>
      <template v-else-if="h.value === 'change'">
        <div
          :key="h.value"
          :class="{
            'error--text': value < 0,
            'success--text': value > 0,
          }"
        >
          {{ value }}
        </div>
      </template>
      <template v-else>
        {{ value }}
      </template>
    </template>
  </v-data-table>
</template>

<script>
import TrafficValue from "../TablesGroup/TrafficValue/index.vue";
import FlagImage from "@/components/UI/FlagImage.vue";

export default {
  components: { FlagImage, TrafficValue },
  props: {
    data: {},
  },
};
</script>
