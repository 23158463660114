var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"anchor-distribution__table",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.items,"dense":"","custom-sort":_vm.customSort,"sort-by":['vol'],"sort-desc":[true],"footer-props":{ itemsPerPageOptions: [50, 100, 150, 200, -1] },"items-per-page":50},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:`header.${h.value}`,fn:function({ header }){return [_c('v-tooltip',{key:h.value,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({style:({
            background:
              _vm.getSelectedUrl === header.text ? 'rgba(28,90,250,0.35)' : '',
          })},on),[_vm._v(" "+_vm._s(_vm.fixHeader(header.text))+" ")])]}}],null,true)},[_c('div',[_vm._v(_vm._s(header.tooltip ? header.tooltip : header.text))])])]}}}),{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(row,index){return _c('tr',{key:index},_vm._l((Object.keys(row)),function(key,i){return _c('td',{key:key + i,style:(`background-color: ${_vm.getRgb(
            _vm.getValue(row[key]),
            _vm.getMax(items, key),
            50,
            233,
            153
          )}`)},[(_vm.hasHover(row[key]))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.getValue(row[key])))])]}}],null,true)},[_c('div',[_vm._v(_vm._s(row[key].hover))])]):_c('span',[_vm._v(_vm._s(_vm.getValue(row[key])))])],1)}),0)}),0)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }