<template>
  <v-dialog
    v-model="dialog"
    content-class="my-shadow--e2"
    width="740"
    scrollable
  >
    <v-card flat rounded="lg" elevation="0">
      <v-card-title class="text-body-2 pa-4">
        Add new project
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-3">
        <v-row>
          <v-col cols="12">
            <div class="text-body-2">Name</div>
            <v-text-field
              v-model="form.name"
              hide-details="auto"
              dense
              outlined
              :error-messages="validationResult.name"
              @change="resetErrors('name')"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <div class="text-body-2">Project category</div>
            <v-autocomplete
              v-model="form.project_category"
              :items="filtersData.project_categories"
              :loading="filtersDataPendingKeys?.project_categories"
              hide-details="auto"
              dense
              outlined
              :error-messages="validationResult.project_category"
              @change="resetErrors('project_category')"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6">
            <div class="text-body-2">Project status</div>
            <v-autocomplete
              v-model="form.project_status"
              :items="filtersData.project_statuses"
              :loading="filtersDataPendingKeys?.project_statuses"
              hide-details="auto"
              dense
              outlined
              :error-messages="validationResult.project_status"
              @change="resetErrors('project_status')"
            ></v-autocomplete>
          </v-col>
          <v-slide-y-transition>
            <v-col v-if="form.project_category === MONOBRAND_ID" cols="12">
              <div class="text-body-2">Keywords</div>
              <v-textarea
                v-model="form.keywords"
                hide-details="auto"
                :error-messages="validationResult.keywords"
                dense
                :placeholder="`keyword\nkeyword\n...`"
                outlined
              />
            </v-col>
          </v-slide-y-transition>
          <v-col cols="12">
            <div class="text-body-2">URL</div>
            <v-text-field
              v-model="form.url"
              hide-details="auto"
              dense
              outlined
              :error-messages="validationResult.url"
              @change="resetErrors('url')"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <div class="text-body-2">Country</div>
            <v-autocomplete
              v-model="form.country"
              :items="$store.state.reuse.countries"
              placeholder="Type to search"
              hide-details="auto"
              dense
              item-text="name"
              item-value="id"
              outlined
              :error-messages="validationResult.country"
              @change="resetErrors('country')"
            />
          </v-col>
          <v-col cols="4">
            <div class="text-body-2">Language</div>
            <v-autocomplete
              v-model="form.language"
              :items="$store.state.reuse.languages"
              placeholder="Type to search"
              hide-details="auto"
              dense
              item-text="name"
              item-value="id"
              outlined
              :error-messages="validationResult.language"
              @change="resetErrors('language')"
            />
          </v-col>
          <v-col cols="4">
            <div class="text-body-2">Niche</div>
            <v-autocomplete
              v-model="form.niche"
              :items="filtersData?.niches"
              :loading="filtersDataPendingKeys?.niches"
              placeholder="Type to search"
              hide-details="auto"
              dense
              outlined
              :error-messages="validationResult.niche"
              @change="resetErrors('niche')"
            />
          </v-col>
          <v-col cols="12">
            <v-card flat outlined rounded="lg">
              <v-card-title class="text-body-2">
                Responsibility
                <v-spacer />
                <v-icon small> mdi-account-group</v-icon>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <div class="text-body-2">Junior SEO</div>
                    <v-autocomplete
                      v-model="form.junior_seo"
                      :items="filtersData.users"
                      item-text="email"
                      item-value="id"
                      required
                      hide-details="auto"
                      dense
                      outlined
                      placeholder="Type to search"
                      clearable
                      multiple
                      :error-messages="validationResult.junior_seo"
                      @change="resetErrors('junior_seo')"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <div class="text-body-2">Chief SEO</div>
                    <v-autocomplete
                      v-model="form.chief_seo"
                      :items="filtersData.users"
                      item-text="email"
                      item-value="id"
                      required
                      outlined
                      hide-details="auto"
                      dense
                      placeholder="Type to search"
                      clearable
                      @change="handleChangeChiefSeo"
                      :error-messages="validationResult.chief_seo"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <div class="text-body-2">SEO Manager</div>
                    <v-autocomplete
                      v-model="form.seo_manager"
                      :items="filtersData.users"
                      item-text="email"
                      item-value="id"
                      required
                      outlined
                      hide-details="auto"
                      placeholder="Type to search"
                      dense
                      clearable
                      multiple
                      :error-messages="validationResult.seo_manager"
                      @change="resetErrors('seo_manager')"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions class="px-6">
        <v-spacer />
        <v-btn class="px-6 text-normal" large text @click="dialog = false">
          Cancel
        </v-btn>
        <v-btn
          class="px-6 text-normal"
          large
          color="success"
          @click="handleCreateProject"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../mixins/Dialog";
import { projectApiService } from "../../../views/project/services/projectApiService";
import UploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";
import ApiErrorHandlingMixin from "../../../mixins/ApiErrorHandlingMixin";

export default {
  mixins: [Dialog, UploadFiltersDataMixin, ApiErrorHandlingMixin],

  data: () => ({
    MONOBRAND_ID: 2,
    form: {
      project_category: 1,
      project_status: 1,
      responsibility: null,
      keywords: "",
      name: "",
      url: "",
      country: "",
      language: "",
      niche: 2,
      junior_seo: [],
      chief_seo: null,
      seo_manager: [],
    },
    loadings: {
      create: false,
    },
    USERS: {
      STANISLAV: 100,
      YELYZAVETA: 1010,
      VALENTYNA: 1074,
      ANTON: 40,
    },
  }),

  mounted() {
    this.uploadFiltersData([
      "niches",
      "project_categories",
      "project_statuses",
      "users",
    ]);

    this.initializeDefaultFormValues();
  },

  methods: {
    initializeDefaultFormValues() {
      this.form.chief_seo = this.$store.getters["auth/user"]?.id;
      this.form.seo_manager = [this.USERS.ANTON];
    },
    resetForm() {
      this.form = {
        project_category: 1,
        project_status: 1,
        responsibility: null,
        name: "",
        url: "",
        country: "",
        language: "",
        niche: 2,
        junior_seo: [],
        chief_seo: null,
        seo_manager: [],
        keywords: "",
      };
    },
    afterProjectCreated(projectData) {
      this.dialog = false;
      this.resetForm();
      this.$emit("created");

      const routeData = this.$router.resolve({
        name: "keyword.control.index",
        query: { openUploadKeysDialog: 1, project_id: projectData.id },
      });

      window.open(routeData.href);
    },
    async handleCreateProject() {
      try {
        this.loadings.create = false;

        const { data } = await projectApiService().createProject(this.form);

        if (!data?.id) {
          return this.$message.notification({
            title: "Not created",
            text: "The project has not been created, please check the completed data and try again.",
            type: "error",
          });
        }

        this.$message.notification({
          title: "Successfully created",
          text: `The project ${this.form.name} has been successfully created.`,
          type: "success",
        });

        this.afterProjectCreated(data);
      } catch (e) {
        console.error("Error while creating project.", e);
        this.handleApiError(e);
      } finally {
        this.loadings.create = false;
      }
    },

    addToJuniorSeo(ids = []) {
      ids.forEach((id) => {
        if (this.form.junior_seo.includes(id)) return;

        this.form.junior_seo.push(id);
      });
    },

    addToSeoManager(ids = []) {
      ids.forEach((id) => {
        if (this.form.seo_manager.includes(id)) return;

        this.form.seo_manager.push(id);
      });
    },

    handleChangeChiefSeo() {
      this.resetErrors("chief_seo");

      if (this.form.chief_seo === this.USERS.STANISLAV)
        this.addToJuniorSeo([this.USERS.VALENTYNA, this.USERS.YELYZAVETA]);

      if (
        this.form.chief_seo === this.USERS.YELYZAVETA ||
        this.form.chief_seo === this.USERS.VALENTYNA
      )
        this.addToSeoManager([this.USERS.STANISLAV]);
    },
  },
};
</script>
