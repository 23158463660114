/*eslint-disable*/
import axios from "axios";
import eventPipe from "@/events/eventPipe";
import Service from "@/plugins/service";
import routes from "@/utils/routes";
import { checkRouteAccess } from "@/middleware/helpers";
import { deepClone, isJsonValid } from "@/utils/functions";
import service from "@/plugins/service";

export default {
  state: {
    flat_permissions:
      typeof localStorage.getItem("flat_permissions") === "string" &&
      localStorage.getItem("flat_permissions") !== "undefined"
        ? JSON.parse(localStorage.getItem("flat_permissions"))
        : [],
    permissions:
      typeof localStorage.getItem("permissions") === "string" &&
      localStorage.getItem("permissions") !== "undefined"
        ? JSON.parse(localStorage.getItem("permissions"))
        : [],
    menu_items_base: [],
    localStoragePermissionsKey: "permissions",
    localStorageFlatPermissionsKey: "flat_permissions",
    PERMISSIONS: {
      ADMIN: "a",
    },
  },
  actions: {
    async fetchPermission(ctx, cb) {
      try {
        const url = "/user/get-permissions";

        const response = await service.get(url);

        ctx.commit("updatePermissions", response.data);
        eventPipe.$emit("fetchedPermissions", {});
        cb();
      } catch (e) {
        console.error("Error while fetching user permissions.", e);
      }
    },
    "base/compareRoutesAndPermissions": (ctx, options) => {
      try {
        const tmprRoutes = deepClone(routes);
        const permissions = options.permissions;

        const WHITE_LIST = [971, 24];

        if (!permissions) throw new Error("Permissions not exist.");

        if (
          ctx.getters["auth/user"] &&
          WHITE_LIST.includes(ctx.getters["auth/user"].id)
        ) {
          ctx.commit("updateMenuItemsBase", tmprRoutes);
          return tmprRoutes;
        }

        const flatPermissions = permissions.map((v) => v.route);

        // Show or hide menu item
        tmprRoutes.forEach((route) => {
          let isOneOfChildrenHasAccess = false;

          // If menu item parent has child
          if (route.children !== undefined) {
            route.children.forEach((child) => {
              if (checkRouteAccess(child.name, flatPermissions)) {
                isOneOfChildrenHasAccess = true;
              } else {
                child.hidden = true;
              }
            });
          }

          if (
            checkRouteAccess(route.name, flatPermissions) ||
            isOneOfChildrenHasAccess
          ) {
            // Show route
          } else {
            route.hidden = true;
          }
        });

        ctx.commit("updateMenuItemsBase", tmprRoutes);
        return tmprRoutes;
      } catch (e) {
        console.warn("Error while initializing permissions.", e);
        // throw e;
      }
    },
    async "global/getFilters"(ctx, payload) {
      const url = "/filter/get-items";
      const resp = await Service.post(url, payload, true);
      if (resp.status === 200) {
        return resp.data;
      }
    },
    async "global/getUsers"(ctx, payload) {
      if (!payload.projectId) return [];

      const url = "/project/get-users/" + payload.projectId;
      const resp = await Service.get(url, true);
      if (resp.status === 200) {
        return resp.data;
      }
    },
  },
  mutations: {
    updatePermissions(state, data) {
      let permissions = JSON.stringify(data);

      localStorage.setItem(state.localStoragePermissionsKey, permissions);

      state.permissions = data;
    },
    updateFlatPermissions(state, data) {
      let permissions = JSON.stringify(data);
      localStorage.setItem(state.localStorageFlatPermissionsKey, permissions);

      state.flat_permissions = [...new Set(data)];
    },
    updateMenuItemsBase(state, data) {
      state.menu_items_base = data;
    },
  },
  getters: {
    "base/menu_items": (state) => state.menu_items_base,
    "base/PERMISSIONS": (state) => state.PERMISSIONS,
    "base/getUserPermissions": (state) => {
      const lsPermissions = localStorage.getItem(
        state.localStoragePermissionsKey
      );

      if (isJsonValid(lsPermissions)) {
        return JSON.parse(lsPermissions);
      }
      return state.permissions;
    },
    "base/getLocalStorageUserPermissionsKey": (state) =>
      state.localStoragePermissionsKey,
  },
};
