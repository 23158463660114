<template>
  <v-dialog
    v-model="dialog"
    content-class="my-shadow--e4"
    scrollable
    width="600"
  >
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2">
        Route info
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-form ref="formRef">
          <v-row>
            <v-col cols="12">
              <v-card flat outlined rounded="lg">
                <v-card-title class="text-body-2 font-weight-bold">
                  Route sharing
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="fill">
                      <v-autocomplete
                        v-model="roles"
                        multiple
                        :items="filtersData.roles"
                        hide-details
                        dense
                        chips
                        small-chips
                        deletable-chips
                        clearable
                        outlined
                        label="Share current page with selected role"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        height="40"
                        :color="success ? 'success' : ''"
                        class="text-normal"
                        @click="handleShare"
                        :loading="loading"
                      >
                        <template v-if="success">
                          Done <v-icon small right>mdi-check</v-icon>
                        </template>
                        <template v-else> Share </template>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card flat outlined rounded="lg">
                <v-card-title class="text-body-2 font-weight-bold">
                  Route parameters
                </v-card-title>
                <v-divider />
                <v-card-text class="pa-0">
                  <v-simple-table dense>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>fullPath</td>
                        <td>
                          {{ $route.fullPath }}
                        </td>
                      </tr>
                      <tr>
                        <td>path</td>
                        <td>
                          {{ $route.path }}
                        </td>
                      </tr>
                      <tr>
                        <td>name</td>
                        <td>
                          {{ $route.name }}
                        </td>
                      </tr>
                      <tr>
                        <td>meta</td>
                        <td>
                          <pre>
                    {{ $route.meta }}
                  </pre
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>maintainer</td>
                        <td>
                          {{ $route.maintainer }}
                        </td>
                      </tr>
                      <tr>
                        <td>title</td>
                        <td>
                          {{ $route.title }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "@/mixins/Dialog";
import UploadFiltersDataMixin from "@/mixins/UploadFiltersDataMixin";
import { UserManageApiService } from "@/components/Accounts/services/UserManageApiService";

export default {
  mixins: [Dialog, UploadFiltersDataMixin],

  data: () => ({
    filtersData: {},
    roles: [],
    success: false,
    loading: false,
  }),

  mounted() {
    this.uploadFiltersData(["roles"]);
  },

  methods: {
    async handleShare() {
      try {
        this.loading = true;
        await UserManageApiService().shareRoute({
          route: this.$route.name,
          roles: this.roles,
        });

        this.success = true;
      } catch (e) {
        console.error("Error while sharing role.", e);
      } finally {
        this.loading = false;
        this.roles = [];
        setTimeout(() => {
          this.success = false;
        }, 3000);
      }
    },
  },
};
</script>
