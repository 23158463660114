<template>
  <div>
    <div class="pa-3">
      <v-text-field
        v-model="searchQuery"
        hide-details
        dense
        outlined
        placeholder="Type to search"
        append-icon="mdi-magnify"
        clearable
      />
    </div>
    <v-data-table
      :search="searchQuery"
      :items="data?.items"
      :headers="data?.headers"
      :items-per-page="50"
      :sort-by="computedDefaultSortBy"
      :sort-desc="[true]"
      :footer-props="{ itemsPerPageOptions: [50, 150, 300, -1] }"
      :custom-sort="_$smartCustomTableSort"
      multi-sort
    >
      <template
        v-for="{ value: key } in data?.headers"
        #[`item.${key}`]="{ value, item }"
      >
        <template v-if="key === 'phrase'">
          <div style="min-width: 320px" :key="key">
            {{ value }}
          </div>
        </template>
        <template v-else-if="!['volume', 'phrase'].includes(key)">
          <positions-value
            :key="key"
            :traffic="{ diff: item['_' + key]?.diff, our: value, _ctx: item }"
            :is-monobrand="filters?.project_categories?.includes(2)"
          />
        </template>
        <template v-else>
          {{ value }}
        </template>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import PositionsValue from "../TablesGroup/PositionsValue/index.vue";
import TableSorting from "../../../mixins/TableSorting";

export default {
  mixins: [TableSorting],
  props: {
    data: Object,
    filters: Object,
  },
  components: {
    PositionsValue,
  },
  data: () => ({
    searchQuery: "",
  }),
  computed: {
    computedDefaultSortBy() {
      return ["volume"];
      // const match = this.data.headers.find(
      //   (header) => header.text === this.$moment().format("DD MMM")
      // );
      //
      // if (!match) return [];
      //
      // return [match.value];
    },
  },
};
</script>
