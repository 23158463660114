import Service from "@/plugins/service";

export const mockDashboard = {
  number_donors_added_7_days: {
    series: [
      {
        name: "Series 1",
        data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      },
    ],
    categories: ["1", "2", "3", "4", "5", "6", "7", "8", "10"],
  },
  number_donors_added_24_hours: {
    series: [
      {
        name: "Series 1",
        data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100].reverse(),
      },
    ],
    categories: ["1", "2", "3", "4", "5", "6", "7", "8", "10"],
  },
  number_running_access_checks_7_days: {
    series: [
      {
        name: "Series 1",
        data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      },
    ],
    categories: ["1", "2", "3", "4", "5", "6", "7", "8", "10"],
  },
  number_running_access_checks_24_hours: {
    series: [
      {
        name: "Series 1",
        data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100].reverse(),
      },
    ],
    categories: ["1", "2", "3", "4", "5", "6", "7", "8", "10"],
  },
  number_success_authorized_7_days: {
    series: [
      {
        name: "Series 1",
        data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      },
    ],
    categories: ["1", "2", "3", "4", "5", "6", "7", "8", "10"],
  },
  number_success_authorized_24_hours: {
    series: [
      {
        name: "Series 1",
        data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100].reverse(),
      },
    ],
    categories: ["1", "2", "3", "4", "5", "6", "7", "8", "10"],
  },
  "7_days": {
    series: [
      {
        name: "Series 1",
        data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      },
    ],
    categories: ["1", "2", "3", "4", "5", "6", "7", "8", "10"],
  },
  "24_hours": {
    series: [
      {
        name: "Series 1",
        data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100].reverse(),
      },
    ],
    categories: ["1", "2", "3", "4", "5", "6", "7", "8", "10"],
  },
  text_not_created_7_days: {
    series: [
      {
        name: "Series 1",
        data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      },
    ],
    categories: ["1", "2", "3", "4", "5", "6", "7", "8", "10"],
  },
  text_not_created_24_hours: {
    series: [
      {
        name: "Series 1",
        data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100].reverse(),
      },
    ],
    categories: ["1", "2", "3", "4", "5", "6", "7", "8", "10"],
  },
  queue_urls: {
    headers: [
      {
        text: "Id",
        value: "id",
      },
      {
        text: "Task id",
        value: "task_id",
      },
      {
        text: "URL",
        value: "url",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Post date",
        value: "post_date",
      },
    ],
    items: [
      {
        id: 1,
        task_id: 5,
        url: "example.com",
        status: "status",
        post_date: new Date(),
      },
    ],
  },
};

export const mockTaskDetails = {
  task: {
    id: 867,
    name: "21.12.2022",
    status: "done",
    created_at: "2022-12-21T13:10:56.000000Z",
    updated_at: "2023-01-25T05:34:16.000000Z",
  },
  postUrls: [
    {
      id: 31517,
      url: "https://nycresumeservices.com/",
      url_gen: "https://nycresumeservices.com/",
      link_text: "new york resume writers",
      status: "done",
      status_id: 2,
      length: 600,
      post_date: null,
      post_url: "https://salloura.com/professional-resume-writers-nyc/",
      created_at: "2022-12-21T13:10:56.000000Z",
      updated_at: "2023-01-24T18:12:18.000000Z",
      message: null,
    },
  ],
  postDomains: [
    {
      id: 1099379,
      status: "error",
      domain: "plusdede.net",
      message:
        '[38,"Failed to authorize on site - incorrect login or password"]',
      created_at: "2022-12-21T13:10:56.000000Z",
      updated_at: "2023-01-24T16:45:31.000000Z",
    },
  ],
};

export const mockTasksListResponse = {
  headers: [
    {
      text: "Name",
      value: "name",
    },
    {
      text: "Status",
      value: "status",
    },
    {
      text: "Post type",
      value: "post_type",
    },
    {
      text: "Chart",
      value: "chart",
    },
    {
      text: "Created",
      value: "created_at",
    },
    {
      text: "Updated",
      value: "updated_at",
    },
  ],
  items: [
    {
      id: 11,
      name: "Обмеження на завантаження 2",
      status: "done",
      post_type: "New post",
      created_at: "2020-03-10T15:29:43.000000Z",
      updated_at: "2020-08-17T11:11:02.000000Z",
      chart: [0, 2, 0, 3, 1, 1],
      queue: null,
    },
    {
      id: 20,
      name: "Anton Test 5",
      status: "done",
      post_type: "New post",
      created_at: "2020-03-15T07:23:37.000000Z",
      updated_at: "2020-08-17T11:11:02.000000Z",
      chart: [1, 3, 4, 1, 2, 2],
      queue: null,
    },
  ],
};

export const AutopostingAlpApiService = () => {
  const getDashboard = async () => {
    // { data: mockDashboard } ||
    return Service.get("/alp/dashboard");
  };

  const getTasksDetails = async (taskId) => {
    // { data: mockTaskDetails } ||
    return Service.get(`/alp/task-view/${taskId}`);
  };

  const restartUrl = async (urlId) => {
    return Service.get(`/task/action/restart-post-url/${urlId}`);
  };

  const getTasksList = async (payload) => {
    // { data: mockTasksListResponse } ||
    return Service.get(`/alp/task`, true, { params: payload });
  };

  const createTask = async (payload) => {
    return Service.post(`/alp/task`, payload);
  };

  const updateTask = async (payload, taskId) => {
    return Service.put(`/alp/task/${taskId}`, payload);
  };

  const deleteTask = async (taskId) => {
    return Service.delete(`/alp/task/${taskId}`);
  };

  return {
    getDashboard,
    getTasksDetails,
    restartUrl,
    getTasksList,
    createTask,
    updateTask,
    deleteTask,
  };
};
