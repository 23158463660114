<template>
  <v-dialog v-model="dialog" content-class="my-shadow--e2" width="460">
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2">
        Create task
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-form ref="formRef">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="form.name"
                label="Task name"
                hide-details
                dense
                outlined
                :error-messages="validationResult?.name"
                @blur="resetErrors('name')"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="form.project_id"
                hide-details
                dense
                outlined
                label="Project"
                :items="filtersData?.projects"
                :error-messages="validationResult?.project_id"
                @blur="resetErrors('project_id')"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="form.source"
                hide-details
                dense
                outlined
                label="Source"
                :items="filtersData?.sources"
                :error-messages="validationResult?.source"
                @blur="resetErrors('source')"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="form.post_type"
                hide-details
                dense
                outlined
                label="Post type"
                :items="filtersData?.post_types"
                :error-messages="validationResult?.post_type"
                @blur="resetErrors('post_type')"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="form.for_text"
                label="URL"
                hide-details
                dense
                outlined
                :placeholder="`Url <tab> Link text <tab> Length <tab> Date ( <tab> URL Gen ) - not required`"
                :error-messages="validationResult?.for_text"
                @blur="resetErrors('for_text')"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="form.domains"
                label="Domains"
                hide-details
                dense
                outlined
                :placeholder="`domain.com\ndomain.com\n...`"
                :error-messages="validationResult?.domains"
                @blur="resetErrors('domains')"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn large class="px-6 text-normal" @click="dialog = false"
          >Back</v-btn
        >
        <v-btn
          large
          class="px-6 text-normal"
          color="success"
          @click="handleCreate"
          :loading="loadings.create"
          >Create</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "@/mixins/Dialog";
import { AutopostingAlpApiService } from "@/views/autoposting/services/AutopostingAlpApiService";
import ApiErrorHandlingMixin from "@/mixins/ApiErrorHandlingMixin";

export default {
  props: ["filtersData"],

  mixins: [Dialog, ApiErrorHandlingMixin],

  data: () => ({
    loadings: {
      create: false,
    },
    form: {
      name: "",
      source: null,
      post_type: null,
      for_text: null,
      domains: null,
    },
  }),

  methods: {
    resetForm() {
      this.form = {
        name: "",
        source: null,
        post_type: null,
        for_text: null,
        domains: null,
      };
    },
    async handleCreate() {
      try {
        this.loadings.create = true;
        await AutopostingAlpApiService().createTask({
          ...this.form,
          domains: this.form?.domains?.split("\n") || [],
        });
        this.resetForm();
      } catch (e) {
        console.error("Errors while creating task.", e);
        this.handleApiError(e);
      } finally {
        this.loadings.create = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
