<template>
  <v-dialog
    :close-on-content-click="false"
    content-class="my-shadow--e2"
    scrollable
    width="420"
  >
    <template #activator="{ on }">
      <v-card
        v-on="on"
        class="pa-4 text-center"
        outlined
        rounded="lg"
        :disabled="loadings.fieldsHistory"
        :style="{ opacity: loadings.fieldsHistory ? '0.5' : 1 }"
        @click="handleShowFieldsHistory"
      >
        Show fields history
        <v-progress-circular
          v-if="loadings.fieldsHistory"
          size="12"
          width="2"
          indeterminate
        />
        <v-icon v-else small right>mdi-history</v-icon>
      </v-card>
    </template>

    <v-card flat outlined rounded="lg" width="380">
      <v-card-title class="text-body-2 pb-1 pt-2">
        Fields history
        <v-spacer />
        <v-btn
          icon
          :loading="loadings.fieldsHistory"
          @click="handleShowFieldsHistory(true)"
        >
          <v-icon small> mdi-refresh </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-title class="pt-0 pb-3">
        <v-text-field
          v-model="fieldsHistorySearch"
          hide-details
          dense
          outlined
          placeholder="Type to search"
          append-icon="mdi-magnify"
        />
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-0">
        <template v-if="loadings.fieldsHistory">
          <div class="px-4 pb-4 pt-2">
            <div class="skeleton" style="height: 120px; width: 100%" />
          </div>
        </template>
        <template v-else-if="fieldsHistory?.[form._ctx.url_id]?.length">
          <div class="d-flex flex-column">
            <template
              v-for="(historyItem, idx) in searchByJson(
                fieldsHistorySearch,
                fieldsHistory[form._ctx.url_id]
              )"
            >
              <field-history-item
                v-if="historyItem"
                :history-item="historyItem"
                :key="`history-item-${idx}`"
              />
              <v-divider
                v-if="
                  historyItem &&
                  idx !== fieldsHistory?.[form._ctx.url_id]?.length - 1
                "
                :key="idx"
              />
            </template>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FieldHistoryItem from "./FiedHistoryItem.vue";
import { SiteWorkSheetApiService } from "../services/SiteWorkSheetApiService";

export default {
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },

  components: { FieldHistoryItem },

  data: () => ({
    loadings: {
      fieldsHistory: false,
    },
    fieldsHistorySearch: null,
    fieldsHistory: {},
  }),

  methods: {
    async handleShowFieldsHistory(forceUpload = false) {
      if (forceUpload !== true) {
        if (this.fieldsHistory?.[this.form._ctx.url_id]?.length) return;
      }

      try {
        this.loadings.fieldsHistory = true;

        const { data } = await SiteWorkSheetApiService().getFieldsHistory(
          this.form._ctx.url_id
        );

        this.fieldsHistory = {};

        await this.$nextTick();

        // По ключу "айди урла" сохраняю полученные данные
        // Нужно для кейса, когда пользователь нажимает "Show history" в разных модалках "Edit status"
        // Был баг, что во всех модалках одинаковые данные подгружались из-за первого условия в этом методе
        this.$set(this.fieldsHistory, this.form._ctx.url_id, data);
      } catch (e) {
        console.error("Error while loading fields history.", e);
      } finally {
        this.loadings.fieldsHistory = false;
      }
    },
    searchByJson(searchQuery, items) {
      if (!searchQuery) return items;

      return (
        items?.filter((item) => {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(String(searchQuery).toLowerCase());
        }) || []
      );
    },
  },
};
</script>
