<template>
  <v-tooltip bottom open-delay="300">
    <template #activator="{ on }">
      <div v-on="on" class="d-flex align-center">
        <v-badge
          :color="{ 0: 'error', 1: 'success', 2: 'yellow' }[item._ctx.status]"
          dot
          inline
        />
      </div>
    </template>
    <div>
      {{
        {
          0: "Project is disabled",
          1: "Project is active",
          2: "Project is paused",
        }[item._ctx.status]
      }}
    </div>
  </v-tooltip>
</template>

<script>
export default {
  props: ["item"],
};
</script>
