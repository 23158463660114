<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <page-header
          title="View task"
          :breadcrumbs="[
            { text: 'Home', to: '/' },
            { text: 'Autoposting', disabled: true },
            { text: 'Tasks list', to: { name: 'autoposting.tasks' } },
            { text: 'View task', disabled: true },
          ]"
        />
      </v-col>
      <v-col cols="12" class="pb-0">
        <v-btn
          class="text-normal"
          @click="$router.push({ name: 'autoposting.tasks' })"
        >
          <v-icon small left>mdi-chevron-left</v-icon>
          Back to dashboard
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <v-card flat outlined rounded="lg">
              <v-card-title class="text-body-2 font-weight-bold">
                Post URL's
              </v-card-title>
              <v-divider />
              <v-card-text class="px-0">
                <post-urls-data-table
                  :headers="postUrlsHeaders"
                  :items="dashboard?.postUrls"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card flat outlined rounded="lg">
              <v-card-title class="text-body-2 font-weight-bold">
                Post Domains
              </v-card-title>
              <v-divider />
              <v-card-text class="px-0">
                <v-data-table
                  :headers="postDomainsHeaders"
                  :items="dashboard?.postDomains"
                >
                  <template #[`item.updated_at`]="{ value }">
                    {{ value ? $moment(value).format("ll") : "" }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import PageHeader from "@/components/UI/PageHeader.vue";
import { AutopostingAlpApiService } from "@/views/autoposting/services/AutopostingAlpApiService";
import PostUrlsDataTable from "@/views/autoposting/view-jobs/components/PostUrlsDataTable.vue";

export default {
  components: { PostUrlsDataTable, PageHeader },

  data: () => ({
    postDomainsHeaders: [
      {
        text: "ID",
        value: "id",
      },
      {
        text: "Domain",
        value: "domain",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Message",
        value: "message",
      },
      {
        text: "Updated",
        value: "updated_at",
      },
    ],
    postUrlsHeaders: [
      {
        text: "ID",
        value: "id",
      },
      {
        text: "Link Text",
        value: "link_text",
      },
      {
        text: "Message",
        value: "message",
      },
      {
        text: "URL",
        value: "url",
      },
      {
        text: "URL Gen",
        value: "url gen",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Post date",
        value: "post_date",
      },
      {
        text: "Result",
        value: "post_url",
      },
      {
        text: "Updated",
        value: "updated_at",
      },
    ],
    dashboard: {
      task: {},
      postUrls: [],
      postDomains: [],
    },
  }),

  mounted() {
    this.initializeDashboard();
  },

  methods: {
    async initializeDashboard() {
      try {
        const { data } = await AutopostingAlpApiService().getTasksDetails(
          this.$route.query.task_id
        );

        console.log("test", data);

        this.dashboard = data;
      } catch (e) {
        console.error("Error while initializing dashboard.", e);
      }
    },
  },
};
</script>
