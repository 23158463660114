<template>
  <div>
    <v-container fluid v-if="isset_active_project">
      <loader :loading="loading"></loader>

      <chat
        v-model="chat.show"
        @getCountUnreadMessage="getCountUnreadMessage"
      ></chat>

      <v-row>
        <v-col cols="fill">
          <div class="d-flex align-end" style="gap: 8px">
            <div>
              <div>
                <small> ANCHORS PLAN </small>
              </div>
              <div>
                <h2 style="line-height: 110%">
                  <template v-if="!task.name">
                    <span style="opacity: 0.5"
                      >Something wrong. This task not contain task name...</span
                    >
                  </template>
                  <template v-else>
                    {{ task.name }}
                  </template>
                </h2>
              </div>
            </div>
            <div v-if="task.user_name">
              Performer <b>{{ task.user_name }}</b>
            </div>
            <div v-if="task.daily_dynamic">
              Daily dynamic <b>{{ task.daily_dynamic }}</b>
            </div>
          </div>
        </v-col>
        <v-col cols="auto" class="d-flex justify-end align-end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                label
                class="pr-1"
                @click="chat.show = true"
                v-bind="attrs"
                v-on="on"
              >
                <v-badge inline color="primary">
                  <template #badge>
                    {{ chat.count_unread_message }}
                  </template>

                  <v-icon small left>mdi-chat</v-icon>
                  Open chat
                </v-badge>
              </v-chip>
            </template>
            <span>Chat for people to communicate!!!</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-card class="styled-card--default" v-if="isset_active_project">
            <v-card-title>
              <v-row class="align-end">
                <v-col>
                  <table-search
                    placeholder="Type to search in table"
                    :model-value="search"
                    @update:modelValue="search = $event"
                  />
                </v-col>
                <v-col class="d-flex justify-end" style="gap: 8px">
                  <v-chip
                    label
                    @click="add_new_row"
                    title="Will create a new row in the anchors plan"
                  >
                    Add new row <v-icon small right>mdi-plus</v-icon>
                  </v-chip>
                  <v-chip
                    label
                    class="flat-chip flat-chip--success"
                    @click="save_data"
                    title="Save changes"
                  >
                    Save content <v-icon small right>mdi-content-save</v-icon>
                  </v-chip>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider />
            <v-card-text class="px-0 pb-0">
              <v-data-table
                :search="search"
                :headers="table.headers"
                :items="table.items"
                dense
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr
                      v-for="(item, index) in items"
                      :key="index"
                      style="white-space: nowrap"
                    >
                      <td
                        :style="
                          item.done_url != null && item.done_url !== ''
                            ? 'background-color:' + item.color
                            : ''
                        "
                      >
                        <span
                          ><v-btn
                            :color="item.trouble ? 'red' : ''"
                            fab
                            width="25"
                            height="25"
                            @click="update_link_data(item, 'trouble')"
                          >
                            <v-icon size="20">mdi-check</v-icon>
                          </v-btn>
                        </span>
                        <span
                          v-if="
                            item.can_forced_on_page === 1 &&
                            (item.islink === 0 ||
                              item.islink === -1 ||
                              item.robotsclose === 1 ||
                              item.noindex === 1 ||
                              item.forced_on_page === 1)
                          "
                          ><v-btn
                            :color="item.color_forced_on_page"
                            fab
                            width="20"
                            height="20"
                            @click="update_link_data(item, 'forced_on_page')"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on"
                                  >mdi-check-circle-outline</v-icon
                                >
                              </template>
                              <span
                                >I think the link is good and I will be fully
                                responsible!!!</span
                              >
                            </v-tooltip>
                          </v-btn></span
                        >
                        <span v-if="item.id == null || item.manual"
                          ><v-btn
                            color=""
                            fab
                            width="30"
                            height="30"
                            @click="left_new_row(item.id)"
                          >
                            <v-icon size="20">mdi-minus</v-icon>
                          </v-btn>
                        </span>
                        <span>{{ item.id }}</span>
                      </td>
                      <td>
                        <span v-if="item.id"
                          >{{ item.url
                          }}<sup style="color: #f10707"
                            ><b>{{ item.count_moderation }}</b></sup
                          ><v-btn
                            color=""
                            fab
                            icon
                            width="30"
                            height="30"
                            @click="item.show_redirect = true"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on"
                                  >mdi-plus</v-icon
                                >
                              </template>
                              <span>Will replace the original URL!!!</span>
                            </v-tooltip> </v-btn
                          ><span v-if="item.show_redirect"
                            ><v-text-field
                              class="mx-auto"
                              v-model="item.redirect_url"
                              @change="table.change_items.push(item.id)"
                              outlined
                              :style="get_theme()"
                              full-width
                              hide-details
                              dense
                            ></v-text-field></span
                        ></span>
                        <span v-else>
                          <v-text-field
                            class="mx-auto"
                            v-model="item.url"
                            @change="table.change_items.push(item.id)"
                            solo
                            :style="get_theme()"
                            full-width
                            hide-details
                            dense
                          ></v-text-field>
                        </span>
                      </td>
                      <td>
                        <span v-if="item.id"
                          >{{ item.phrase }}
                          <v-btn
                            :color="
                              item.dofollow === 1 ? item.color_dofollow : ''
                            "
                            fab
                            width="22"
                            height="22"
                            @click="update_anchors_plan(item, 'dofollow')"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon size="13" v-bind="attrs" v-on="on"
                                  >DF</v-icon
                                >
                              </template>
                              <span>Change the value (dofollow/nofollow)</span>
                            </v-tooltip> </v-btn
                          ><span v-if="item.show_redirect"
                            ><v-text-field
                              class="mx-auto"
                              v-model="item.redirect_phrase"
                              @change="table.change_items.push(item.id)"
                              outlined
                              :style="get_theme()"
                              full-width
                              hide-details
                              dense
                            ></v-text-field></span
                        ></span>
                        <span v-else>
                          <v-text-field
                            class="mx-auto"
                            v-model="item.phrase"
                            @change="table.change_items.push(item.id)"
                            solo
                            :style="get_theme()"
                            full-width
                            hide-details
                            dense
                          ></v-text-field>
                        </span>
                        <span v-if="item.recommended_domain != null">
                          <a
                            :href="'http://' + item.recommended_domain"
                            target="_blank"
                            ><v-icon>mdi-alpha-d-box</v-icon></a
                          >
                        </span>
                      </td>
                      <td>
                        <v-row style="min-width: 300px">
                          <v-col cols="9">
                            <span
                              v-if="
                                !item.recommendation && item.done_url != null
                              "
                            >
                              Not in R
                            </span>
                            <v-text-field
                              class="mx-auto"
                              v-model="item.done_url"
                              @change="table.change_items.push(item.id)"
                              solo
                              :style="get_theme()"
                              full-width
                              hide-details
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="1">
                            <a
                              v-if="
                                item.done_url != null && item.done_url !== ''
                              "
                              :href="item.done_url"
                              target="_blank"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon small v-bind="attrs" v-on="on"
                                    >mdi-open-in-new
                                  </v-icon>
                                </template>
                                <span>open in new</span>
                              </v-tooltip>
                            </a>
                          </v-col>
                          <v-col cols="1">
                            <v-icon v-if="item.check_link === 0" color="#D3D3D3"
                              >mdi-thumb-down
                            </v-icon>
                            <v-icon v-if="item.check_link === 2" color="red"
                              >mdi-thumb-down
                            </v-icon>
                            <v-icon v-if="item.check_link === 1" color="green"
                              >mdi-thumb-up-outline
                            </v-icon>
                          </v-col>
                          <v-col cols="1">
                            <v-icon @click="to_deleted_links(item)" color="red"
                              >mdi-skull
                            </v-icon>
                          </v-col>
                        </v-row>
                      </td>
                      <td style="min-width: 200px">
                        <v-text-field
                          v-model="item.login"
                          @change="table.change_items.push(item.id)"
                          solo
                          :style="get_theme()"
                          full-width
                          hide-details
                          dense
                        ></v-text-field>
                      </td>
                      <td style="min-width: 200px">
                        <v-text-field
                          v-model="item.password"
                          @change="table.change_items.push(item.id)"
                          solo
                          full-width
                          :style="get_theme()"
                          hide-details
                          dense
                        ></v-text-field>
                      </td>
                      <td style="min-width: 200px">
                        <v-text-field
                          v-model="item.email"
                          @change="table.change_items.push(item.id)"
                          solo
                          full-width
                          :style="get_theme()"
                          hide-details
                          dense
                        ></v-text-field>
                      </td>
                      <td class="text-center">
                        {{ item.dateadd }}
                        <v-btn
                          :color="item.color_previous_month"
                          fab
                          width="25"
                          height="25"
                          @click="update_link_data(item, 'previous_month')"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon size="20" v-bind="attrs" v-on="on"
                                >mdi-alpha-l-box-outline
                              </v-icon>
                            </template>
                            <span
                              >The link refers to the following location</span
                            >
                          </v-tooltip>
                        </v-btn>
                      </td>
                      <td style="min-width: 200px">
                        <v-textarea
                          hide-details
                          @change="table.change_items.push(item.id)"
                          v-model="item.text"
                          solo
                          :style="get_theme()"
                          rows="1"
                        ></v-textarea>
                      </td>
                      <td style="min-width: 200px">
                        <v-textarea
                          hide-details
                          @change="table.change_items.push(item.id)"
                          v-model="item.notes"
                          solo
                          :style="get_theme()"
                          rows="1"
                        ></v-textarea>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="12"
          v-for="block in table_domains.blocks"
          :key="block.name"
        >
          <v-card
            class="mx-auto"
            v-if="
              isset_active_project &&
              ((table_domains[block.js] &&
                table_domains[block.js].length > 0) ||
                block.always_show)
            "
          >
            <v-card-text>
              <v-row class="align-end">
                <v-col sm="3">
                  <v-card-title>
                    <span>{{ block.name }}</span>
                    <span>
                      <v-btn @click="download_file(block.used)" icon>
                        <v-icon>mdi-arrow-down-bold-circle</v-icon>
                      </v-btn>
                    </span>
                  </v-card-title>
                </v-col>
                <v-col sm="6">
                  <v-text-field
                    v-model="search_domains"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    solo
                    class="align-end"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col>
                  <TableIndex
                    :items="table_domains[block.js]"
                    :headers="table_domains.headers"
                    :search_domains="search_domains"
                    :filters-data="filtersData"
                    :block="block"
                    :recommended_acceptors="recommended_acceptors"
                    :icon="table_domains.country_icons"
                    :thematic_threads="thematic_threads"
                    :tags="tags"
                    :task="task"
                    :fetch_data_domains="fetch_data_domains"
                  ></TableIndex>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
      dark
      v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="primary"
            dark
            @click="$store.dispatch('toggle_select_project_menu')"
            >Select project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import TableIndex from "@/components/AnchorPlan/PlacementLinks/Other/Child/TableIndex";
import Loader from "@/components/AnchorPlan/Child/LoaderSave";
import Chat from "@/components/AnchorPlan/Child/Chat";
import service from "@/plugins/service";
import Notification from "@/mixins/Notification";
import TableSearch from "@/components/UI/TableSearch.vue";
import UploadFiltersDataMixin from "@/mixins/UploadFiltersDataMixin";

export default {
  name: "PlacementLinks",
  components: { TableSearch, TableIndex, Loader, Chat },
  mixins: [Notification, UploadFiltersDataMixin],
  metaInfo: {
    title: "Placement Links - LTT - SI",
  },
  data() {
    return {
      loading: false,
      search_domains: "",
      search: "",
      scrollSync: {
        top: 0,
        left: 0,
      },
      chat: {
        show: false,
        count_unread_message: 0,
      },
      loadings: {},
      task: {},
      table: {
        headers: [
          { text: "id", title: "id", value: "id", class: "text-center" },
          {
            text: "Acceptor URL",
            title: "url",
            value: "url",
            class: "text-center",
          },
          {
            text: "Anchor",
            title: "phrase",
            value: "phrase",
            class: "text-left",
          },
          {
            text: "Done URL",
            title: "posted_link",
            value: "posted_link",
            class: "text-left",
          },
          { text: "Login", title: "login", value: "login", class: "text-left" },
          {
            text: "Pass",
            title: "password",
            value: "password",
            class: "text-left",
          },
          { text: "Email", title: "email", value: "email", class: "text-left" },
          {
            text: "Date",
            title: "comment",
            value: "comment",
            class: "text-left",
          },
          {
            text: "Text",
            title: "date_finish",
            value: "date_finish",
            class: "text-left",
          },
          {
            text: "Notes",
            title: "date_finish",
            value: "date_finish",
            class: "text-left",
          },
        ],
        items: [],
        change_items: [],
      },
      table_domains: {
        headers: [
          { text: "id", title: "id", value: "id", class: "text-center" },

          {
            text: "Donor",
            title: "domain",
            value: "domain",
            class: "text-center",
          },
          {
            text: "Recommended acceptor",
            title: "Recommended acceptor",
            value: "recommended_acceptor",
            class: "text-center",
            sortable: false,
            icon: true,
            icon_name: "mdi-file-hidden",
          },
          {
            text: "Is live",
            title: "ltt_all",
            value: "ltt_all",
            class: "text-center",
          },
          {
            text: "Last posting",
            title: "last_posting_in_days",
            value: "last_posting_in_days",
            class: "text-center",
          },
          {
            text: "DR",
            title: "domain_rating",
            value: "domain_rating",
            class: "text-center",
          },
          {
            text: "Ahrefs traffic",
            title: "traffic",
            value: "traffic",
            class: "text-center",
          },
          {
            text: "Regional Traff Ahrefs",
            title: "country_traffic",
            value: "country_traffic",
            class: "text-center",
            icon: true,
          },
          {
            text: "SW Rank",
            title: "global_rank",
            value: "global_rank",
            class: "text-center",
          },
          {
            text: "Google Index",
            title: "google_index",
            value: "google_index",
            class: "text-center",
          },
          {
            text: "Relevant pages count",
            title: "google_index_niche",
            value: "google_index_niche",
            class: "text-center",
          },
          {
            text: "Google Index Path",
            title: "google_index_path",
            value: "google_index_path",
            class: "text-center",
          },
          {
            text: "Date Add",
            title: "created_at",
            value: "created_at",
            class: "text-center",
          },
          {
            text: "Overall status",
            title: "Overall status",
            value: "overall_status_id",
            class: "text-center",
          },
          {
            text: "Spam Index",
            title: "spam_score",
            value: "spam_score",
            class: "text-center",
          },
          {
            text: "P",
            title: "priority",
            value: "priority",
            class: "text-center",
          },
          {
            text: "Thematic threads for comment and forum",
            title: "",
            value: "",
            class: "text-center",
          },
          {
            text: "Donor Tag",
            title: "activity_profile",
            value: "activity_profile",
            align: "center",
            class: "text-center",
          },
          {
            text: "Donor workflow",
            title: "workflow",
            value: "workflow",
            class: "text-center",
          },
          {
            text: "Text",
            title: "text",
            value: "text",
            class: "text-center",
          },
          {
            text: "Notes",
            title: "notes",
            value: "notes",
            class: "text-center",
          },
          {
            text: "Login",
            title: "login",
            value: "login",
            class: "text-center",
          },
          {
            text: "Pass",
            title: "pass",
            value: "pass",
            class: "text-center",
          },
          {
            text: "Email",
            title: "email",
            value: "email",
            class: "text-center",
          },
          {
            text: "Added by",
            title: "user",
            value: "user",
            class: "text-center",
          },
          {
            text: "Check",
            title: "check",
            value: "check",
            class: "text-center",
          },
        ],
        blocks: [
          {
            name: "NEW DOMAINS",
            value: "new",
            js: "items_new",
            always_show: true,
            used: 0,
          },
          {
            name: "USED DOMAINS",
            value: "used",
            js: "items_used",
            always_show: true,
            used: 1,
          },
          {
            name: "IN PROGRESS DOMAINS",
            value: "in_progress",
            js: "items_in_progress",
            always_show: false,
            used: 5,
          },
          {
            name: "MODERATION DOMAINS",
            value: "moderation",
            js: "items_moderation",
            always_show: false,
            used: 4,
          },
          {
            name: "PROBLEMATIC DONORS",
            value: "delete",
            js: "items_delete",
            always_show: false,
            used: 2,
          },
          {
            name: "DELETED LINKS",
            value: "deleted_links",
            js: "items_deleted_links",
            always_show: false,
            used: 6,
          },
          {
            name: "PENDING DOMAINS",
            value: "pending",
            js: "items_pending",
            always_show: false,
            used: 3,
          },
        ],
        items_new: [],
        items_used: [],
        items_delete: [],
        items_moderation: [],
        items_pending: [],
        items_in_progress: [],
        items_deleted_links: [],
        country_icons: "",
      },
      filter: {
        country: false,
        performer: false,
        niche_id: undefined,
        lang_id: undefined,
      },
      thematic_threads: "",
      tags: [],
      recommended_acceptors: [],
    };
  },
  methods: {
    getCountUnreadMessage(data) {
      if (data === undefined) {
        this.chat.count_unread_message = 0;
      } else {
        this.chat.count_unread_message = data.count;
      }
    },
    async to_deleted_links(item) {
      try {
        if (!item.domainid)
          return this.mWarning({
            title: "Validation error",
            text: "Domain id is required.",
          });
        if (!this.id_project)
          return this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });

        self.loading = true;
        self.update_loading("to_deleted_links", true);

        const url = "/anchor-plan/placement-links/donors-base/to-deleted-links";
        const payload = {
          item,
          type: "ap",
        };

        const response = await service.post(url, payload);

        if (response.data.status) {
          let foundIndex = this.table.items.findIndex(
            (tableItem) => tableItem.id === item.id
          );
          this.resetItemFormData(item);
          this.table.items[foundIndex] = item;
          this.fetch_data();
        } else {
          alert(response.data.message);
        }
      } catch (e) {
        console.error("Error while deleting links.", e);
      } finally {
        this.loading = false;
      }
    },
    resetItemFormData(item) {
      item.done_url = "";
      item.text = "";
      item.notes = "";
      item.login = "";
      item.password = "";
      item.email = "";
    },
    async update_anchors_plan(item, name) {
      try {
        let value = null;
        let color = null;
        if (item[name] !== 1) {
          value = 1;
          color = "green";
        }

        const url = "/anchor-plan/placement-links/update/lin";
        const payload = {
          id: item.id,
          column: name,
          value: value,
        };

        this.update_loading("update_anchors_plan", true);

        await service.post(url, payload);

        this.table.items.find((tableItem) => tableItem.id === item.id)[
          "color_" + name
        ] = color;
        this.table.items.find((tableItem) => tableItem.id === item.id)[name] =
          value;
      } catch (e) {
        console.error("Error while updating anchor plan.", e);
      } finally {
        this.update_loading("update_anchors_plan", false);
      }
    },
    async update_link_data(item, name) {
      try {
        let value = null;
        let color = null;
        if (item[name] !== 1) {
          value = 1;
          color = "green";
        }

        if (!item.link_id)
          return this.mWarning({
            title: "Vaalidation error",
            text: "Link id is required.",
          });

        this.loading = true;
        this.update_loading("update_link_data", true);

        const url = `/ltt/update_link_data/link/${item.link_id}/1`;
        const config = {
          params: { name: name, value: value },
        };

        await service.get(url, true, config);

        this.table.items.find((i) => i.id === item.id)["color_" + name] = color;
        this.table.items.find((i) => i.id === item.id).name = 1;

        this.fetch_data();
      } catch (e) {
        console.error("Error while updating link data.", e);
      } finally {
        this.update_loading("update_link_data", false);
        this.loading = false;
      }
    },
    async download_file(used) {
      try {
        const url = "/export/base/get-hash";
        const payload = {
          level: 1,
          used: used,
          id: this.$route.params.id,
          country: this.filter.country,
        };

        const response = await service.post(url, payload);

        if (response.data.success) {
          location.href = `${this.$store.state.server_url}/export/anchor-plan-donors/index/0?hash=${response.data.hash}`;
        }
      } catch (e) {
        console.error("Error while downloading file.", e);
      }
    },
    async left_new_row(id) {
      try {
        if (!id)
          return this.mWarning({
            title: "Validation error",
            text: "Id is required.",
          });

        this.update_loading("left_new_row", true);
        const url = `/anchor-plan/anchor-plan/delete/manual/${id}`;

        await service.post(url, {});

        this.table.items = self.table.items.filter((x) => x.id !== id);
      } catch (e) {
        console.error("Error while left new row.", e);
      } finally {
        this.update_loading("left_new_row", false);
      }
    },
    add_new_row() {
      let row = {
        url: null,
        phrase: null,
        color: null,
        nf: null,
        date_start: null,
        date_finish: null,
        comment_link_builder: null,
        created_at: null,
        notes: null,
        text: null,
        completed: 0,
        id: null,
        done_url: null,
        link_id: null,
        acceptorurlid: null,
        ltypeid: null,
        projectid: null,
        url_id: null,
        email: null,
        login: null,
        password: null,
        redirect_url: null,
        redirect_phrase: null,
        dateadd: null,
        task_id: this.$route.params.id,
        recommendation: null,
        task_type_id: this.task.link_type_id,
      };

      this.table.items.push(row);
    },
    async save_data() {
      if (!this.id_project)
        return this.mWarning({
          title: "Validation error",
          text: "Project id is required",
        });

      try {
        this.loading = true;
        this.update_loading("save_data", true);

        const url = "/anchor-plan/placement-links/save/1";

        let change_items = this.table.change_items.filter(
          (x, i, a) => a.indexOf(x) === i
        );
        let items = this.table.items.filter(function (params) {
          if (change_items.includes(params.id)) {
            return params;
          }
        });

        const payload = {
          items: items,
        };

        this.table.change_items = [];

        await service.post(url, payload);

        this.fetch_data();
      } catch (e) {
        console.error("Error while saving data.", e);
      } finally {
        this.update_loading("save_data", false);
        this.loading = false;
      }
    },
    async fetch_tags() {
      try {
        this.update_loading("fetch_task_info", true);

        const url = "/anchor-plan/placement-links/tags";

        const response = await service.post(url, {});

        this.tags = response.data;
      } catch (e) {
        console.error("Error while fetching tags.", e);
      } finally {
        this.update_loading("fetch_task_info", false);
      }
    },
    async fetch_task_info() {
      try {
        if (!this.id_project)
          this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });
        if (!this.$route.params.id)
          this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });

        const icons = require("rendered-country-flags");

        this.update_loading("fetch_task_info", true);

        const url = `/anchor-plan/placement-links/task/${this.$route.params.id}`;

        const response = await service.post(url, {});

        this.task = response.data;
        this.table_domains.country_icons = icons[this.task.iso];
        this.filter.country = this.task.country_id;
        this.filter.performer = this.task.performer;
        this.filter.niche_id = this.task.niche_id;
        this.filter.lang_id = this.task.lang_id;
        this.update_loading("fetch_task_info", false);
        this.fetch_thematic_threads();
        this.fetch_data_domains();
      } catch (e) {
        console.error("Error while fetching task info.", e);
      } finally {
        this.update_loading("fetch_task_info", false);
      }
    },
    async fetch_data() {
      try {
        if (!this.id_project)
          this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });
        if (!this.$route.params.id)
          this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });

        this.update_loading("fetch_data", true);

        const url = `/anchor-plan/anchor-plan/${this.$route.params.id}/1`;
        const payload = {
          filter: this.filter,
        };

        this.table.items = [];
        this.table.items_base = [];

        const response = await service.post(url, payload);

        this.table.items = response.data;
        this.table.items_base = response.data;

        response.data.forEach(function (item) {
          this.recommended_acceptors.push({
            id: item.id,
            name: item.url,
          });
        });
      } catch (e) {
        console.error("Error while loading main data.", e);
      } finally {
        this.update_loading("fetch_data", false);
      }
    },
    async fetch_data_domains() {
      try {
        if (!this.$route.params.id)
          this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });

        this.update_loading("fetch_data", true);

        const url = `/anchor-plan/donors-base/${this.$route.params.id}`;
        const payload = {
          filter: this.filter,
          task: this.task,
        };

        this.table_domains.items = [];

        const response = await service.post(url, payload);

        this.table_domains.items_new = response.data.new;
        this.table_domains.items_used = response.data.used;
        this.table_domains.items_delete = response.data.deleted;
        this.table_domains.items_pending = response.data.pending;
        this.table_domains.items_moderation = response.data.moderation;
        this.table_domains.items_in_progress = response.data.in_progress;
        this.table_domains.items_deleted_links = response.data.deleted_links;
      } catch (e) {
        console.error("Error while loading domains data.", e);
      } finally {
        this.update_loading("fetch_data", false);
      }
    },
    async fetch_thematic_threads() {
      try {
        if (!this.id_project)
          this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });
        if (!this.task.lang_id)
          this.mWarning({
            title: "Validation error",
            text: "Language is required.",
          });
        if (!this.task.niche_id)
          this.mWarning({
            title: "Validation error",
            text: "Niche is required.",
          });

        const url = `/anchor-plan/thematic-threads/${this.task.lang_id}/${this.task.niche_id}`;

        const response = await service.post(url, {});

        this.thematic_threads = response.data;
      } catch (e) {
        console.error("Error while loading thematic threads.", e);
      } finally {
        this.update_loading("fetch_thematic_threads", false);
      }
    },
    is_dark() {
      return this.$vuetify.theme.dark;
    },
    get_theme() {
      return this.is_dark()
        ? "border-color: transparent; border: 1px solid red"
        : "";
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
  mounted() {
    this.uploadFiltersData(["overall_statuses"]);
    this.fetch_tags();
    this.fetch_task_info();
    this.fetch_data();
  },
};
</script>

<style scoped></style>
