<template>
  <v-data-table
    :headers="[{ value: '_actions', width: '120px' }, ...(headers || [])]"
    :items="items"
  >
    <template #[`item._actions`]="{ item }">
      <template v-if="['error'].includes(item.status)">
        <v-btn
          class="text-normal"
          @click="handleRestart(item)"
          :loading="loadings.restart === item.id"
        >
          Restart
          <v-icon small right>mdi-refresh</v-icon>
        </v-btn>
      </template>
    </template>
    <template #[`item.updated_at`]="{ value }">
      {{ value ? $moment(value).format("ll") : "" }}
    </template>
  </v-data-table>
</template>

<script>
import { AutopostingAlpApiService } from "@/views/autoposting/services/AutopostingAlpApiService";

export default {
  props: ["headers", "items"],

  data: () => ({
    loadings: { restart: null },
  }),

  methods: {
    async handleRestart(tableItem) {
      try {
        this.loadings.restart = tableItem.id;

        await AutopostingAlpApiService().restartUrl(tableItem.id);
      } catch (e) {
        console.error("Error while restarting url.", e);
      } finally {
        this.loadings.restart = null;
      }
    },
  },
};
</script>
