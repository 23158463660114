<template>
  <v-container fluid class="contentball-analytics">
    <v-row dense>
      <v-col>
        <h2>ContentBall Analytics</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-text-field
              v-model="filters.url"
              label="URL"
              hide-details
              dense
              outlined
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.niche"
              :items="filtersData.niche"
              label="Niche"
              hide-details
              dense
              outlined
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.team"
              :items="filtersData.team"
              label="Team"
              hide-details
              dense
              outlined
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.main_seo"
              :items="filtersData.main_seo"
              label="Main SEO"
              hide-details
              dense
              outlined
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill"> </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <easy-range-input
              label="Need top"
              outlined
              :model-value="filters.need_top"
              @update:modelValue="filters.need_top = $event"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.project_categories"
              :items="filtersData.project_categories"
              hide-details
              multiple
              dense
              outlined
              label="Project categories"
              placeholder="Type to search"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.project"
              :items="filtersData.projects"
              label="Project"
              hide-details
              dense
              outlined
              clearable
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.subproject"
              :items="filtersData.subprojects"
              :loading="filtersDataPendingKeys?.subprojects"
              label="Subproject"
              hide-details
              dense
              outlined
              clearable
            />
          </v-col>
          <v-col cols="fill" class="d-flex justify-end">
            <v-btn
              class="text-normal px-6"
              color="primary"
              @click="fetchData"
              height="40"
            >
              Get data
              <v-icon v-if="!loadings.table" small right>mdi-refresh</v-icon>
              <v-progress-circular
                v-else
                size="14"
                width="2"
                class="ml-2"
                indeterminate
              />
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <div class="d-flex mb-2 align-end">
          <v-text-field
            v-model="search"
            hide-details
            dense
            outlined
            label="Type to search"
            append-icon="mdi-magnify"
            style="max-width: 320px"
          />
          <v-spacer />
          <div class="d-flex" style="gap: 0.25rem">
            <v-btn
              v-if="selectedTableItems.length > 0"
              class="text-normal"
              @click="handleSendOnRecalculate"
            >
              Recalculate Selected
              <v-icon small right>mdi-calculator-variant-outline</v-icon>
            </v-btn>

            <a
              href="https://docs.google.com/spreadsheets/d/18YdfVCMS3GgXZr47eggJwB0twUV2lLCHEO06vVebe6Q/edit#gid=872306655"
              target="_blank"
            >
              <v-btn class="text-normal" @click="() => {}"
                >View structure
                <v-icon right small>mdi-open-in-new</v-icon>
              </v-btn>
            </a>
          </div>
        </div>
        <v-card flat outlined rounded="lg">
          <v-card-text class="pa-0 pb-2 pt-1">
            <template v-if="!loadings.table">
              <template v-if="items && items.length > 0">
                <v-data-table
                  v-model="selectedTableItems"
                  show-select
                  :search="search"
                  :headers="getHeaders"
                  :items="items"
                  :sort-by="sortBy"
                  :sort-desc="sortDesc"
                  item-key="url"
                  :custom-sort="customSort"
                  multi-sort
                  :items-per-page="50"
                  :footer-props="{
                    itemsPerPageOptions: [25, 50, 100, 200, 500, -1],
                  }"
                  class="relative-tr"
                >
                  <template
                    v-for="h in getHeaders"
                    #[`header.${h.value}`]="{ header }"
                  >
                    <template v-if="h.title">
                      <v-tooltip bottom :key="h.text">
                        <template #activator="{ on }">
                          <span v-on="on">{{ header.text }} </span>
                        </template>
                        <span>{{ header.title }}</span>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      <span :key="h.value">{{ h.text }}</span>
                    </template>
                  </template>
                  <template
                    v-for="header in getHeaders"
                    #[`item.${header.value}`]="{ value, item }"
                  >
                    <template v-if="smartHoverTds.includes(header.value)">
                      <smart-hover-td
                        :key="header.value"
                        :data="value"
                        @show="handleShowUrls($event, item)"
                        :content="item"
                        :name="header.value"
                      />
                    </template>
                    <template v-else-if="smartTds.includes(header.value)">
                      <smart-td
                        :key="header.value"
                        :data="value"
                        @show="handleShowDetail"
                        :name="header.value"
                      />
                    </template>
                    <template v-else-if="deltaTds.includes(header.value)">
                      <smart-td
                        :key="header.value"
                        :data="item[getFixedDeltaName(header.value)]"
                        @show="handleShowDetail"
                        :name="header.value"
                        :force-value="value"
                      />
                    </template>
                    <template v-else-if="header.value === '_actions'">
                      <v-tooltip bottom :key="header.value">
                        <template #activator="{ on }">
                          <v-btn
                            v-on="on"
                            icon
                            @click="handleAddToRecalculate(item)"
                            :loading="loadings.recalculate.includes(item.url)"
                          >
                            <v-icon>mdi-calculator-variant-outline </v-icon>
                          </v-btn>
                        </template>
                        <div>Add to recalculate queue</div>
                      </v-tooltip>
                    </template>
                    <template v-else-if="header.value === 'content_score'">
                      <v-tooltip
                        bottom
                        :key="header.value"
                        content-class="pa-0 rounded-lg"
                      >
                        <template #activator="{ on }">
                          <div v-on="on">
                            <smart-hover-td
                              :key="header.value"
                              :data="value"
                              value-key="overall"
                              @show="handleShowUrls($event, item)"
                              :content="item"
                              :force-value="Math.ceil(value?.overall)"
                              :name="header.value"
                            />
                          </div>
                        </template>
                        <v-card
                          v-if="typeof value === 'object' && value"
                          flat
                          outlined
                          rounded="lg"
                          class="pa-4"
                        >
                          <div class="d-flex flex-column" style="gap: 0.25rem">
                            <div
                              v-for="(item, idx) in Object.entries(value)"
                              :key="`item-${idx}`"
                              class="d-flex justify-space-between"
                              style="gap: 0.5rem"
                            >
                              <div>{{ formatCamelCaseString(item[0]) }}</div>
                              <div>
                                <v-chip
                                  class="flat-chip flat-chip--auto"
                                  label
                                  small
                                  :style="{
                                    color: getPercentColor(Math.ceil(item[1])),
                                  }"
                                >
                                  {{ Math.ceil(item[1]) }}
                                </v-chip>
                              </div>
                            </div>
                          </div>
                        </v-card>
                      </v-tooltip>
                    </template>
                    <template v-else-if="header.value === 'stat_collect'">
                      <div
                        :key="header.value"
                        :class="`colored-td colored-td--inline ${
                          item.serp !== item.texts ? 'red' : ''
                        }`"
                      >
                        <span v-if="value" style="white-space: nowrap">
                          {{
                            Object.values(
                              [
                                value?.serp,
                                value?.texts,
                                value?.valid_texts,
                              ].filter((v) => !!v)
                            ).join(" / ")
                          }}
                        </span>
                      </div>
                    </template>
                    <template
                      v-else-if="['date', 'sw_deadline'].includes(header.value)"
                    >
                      <v-tooltip
                        :key="header.value"
                        content-class="pa-0 remove-dialog-shadow"
                        bottom
                      >
                        <template #activator="{ on }">
                          <div
                            v-on="on"
                            :class="`colored-td colored-td--inline ${
                              getDaysDiff(value) > 21 ? 'red' : ''
                            }`"
                          >
                            {{
                              value ? $moment(value).format("DD/MM/YYYY") : ""
                            }}
                          </div>
                        </template>
                        <v-card class="pa-2">
                          {{ $moment(value).fromNow() }}
                        </v-card>
                      </v-tooltip>
                    </template>
                    <template
                      v-else-if="
                        ['cf_pr', 'sw_linking', 'sw_status'].includes(
                          header.value
                        )
                      "
                    >
                      <v-chip
                        :key="header.value"
                        v-if="value"
                        :color="smStatuses[value]?.color"
                        small
                      >
                        {{ value }}
                      </v-chip>
                    </template>
                    <template
                      v-else-if="
                        ['m1', 'm2', 'm3', 'm4', 'm5', 'm6'].includes(
                          header.value
                        )
                      "
                    >
                      <table-select-chip
                        :key="header.value"
                        :value="value"
                        @change="handleChangeMValue($event, header.value, item)"
                        :items="[0, 1, 2]"
                        :loading="loadings.changeMValue.includes(item.id)"
                      />
                    </template>
                    <template v-else-if="header.value === 'url'">
                      <div :key="header.value">
                        <div
                          v-if="String(item.text_size_our) === '0'"
                          class="mark mark--error"
                        />
                        <span
                          style="
                            display: inline-block;
                            max-width: 240px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                          "
                          :title="value"
                        >
                          <div>
                            <small style="line-height: 100%; opacity: 0.8">
                              {{ getUrlData(value)?.host }}
                            </small>
                          </div>
                          <a
                            :href="value"
                            target="_blank"
                            style="font-weight: 500"
                          >
                            {{ getUrlData(value)?.pathname }}
                          </a>
                        </span>
                      </div>
                    </template>
                    <template v-else-if="header.value === 'calc_status'">
                      <v-tooltip
                        :key="header.value"
                        bottom
                        content-class="pa-0 my-shadow--e2"
                      >
                        <template #activator="{ on }">
                          <v-chip v-on="on" color="transparent">
                            {{ value }}
                            <v-icon small right style="opacity: 0.5"
                              >mdi-information-outline</v-icon
                            >
                          </v-chip>
                        </template>
                        <template #default>
                          <v-card flat outlined class="pa-4">
                            <div
                              class="text-body-2 d-flex flex-column"
                              style="gap: 0.5rem"
                            >
                              <div>
                                <b>Not available</b>
                                <div style="opacity: 0.7">
                                  <div><u> Requirements:</u></div>
                                  <div>1. Status code - 200</div>
                                  <div>2. Availability of keys</div>
                                  <div>3. Project status - active</div>
                                  <div>4. Project niche - casino</div>
                                </div>
                              </div>

                              <div>
                                <b>Error</b>
                                <div style="opacity: 0.7">None...</div>
                              </div>
                              <div>
                                <b>Queued</b>
                                <div style="opacity: 0.7">None...</div>
                              </div>
                              <div>
                                <b>Done</b>
                                <div style="opacity: 0.7">None...</div>
                              </div>
                            </div>
                          </v-card>
                        </template>
                      </v-tooltip>
                    </template>
                    <template v-else-if="header.value === 'cb3_live_editor'">
                      <a
                        v-if="value && value !== ''"
                        :href="value"
                        :key="header.value"
                        target="_blank"
                        style="font-weight: 500; white-space: nowrap"
                      >
                        Open Editor
                      </a>
                    </template>
                    <template v-else>
                      {{ value }}
                    </template>
                  </template>
                </v-data-table>
              </template>
              <template v-else>
                <div
                  style="height: 300px"
                  class="d-flex align-center justify-center"
                >
                  <span style="opacity: 0.7">No data...</span>
                </div>
              </template>
            </template>
            <template v-else>
              <v-skeleton-loader type="table" />
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <details-dialog
      :model-value="detailDialogModel"
      @update:modelValue="detailDialogModel = $event"
      :items="detailsData"
    />
    <activity-dialog
      :headers="activityTableHeaders"
      :items="urlItemsData"
      :selected-url="selectedUrlData?.url"
      :model-value="urlItemsDialogModel"
      @update:modelValue="urlItemsDialogModel = $event"
      @addToRecalculate="handleAddToRecalculate(selectedUrlData)"
    />
  </v-container>
</template>

<script>
import SmartTd from "./UI/SmartTd.vue";
import SmartHoverTd from "./UI/SmartHoverTd.vue";
import {
  ACTIVITY_TABLE_HEADERS,
  DEFAULT_MENU_PROPS,
} from "../../utils/defaultData";
import getRgb from "../../mixins/GetRgb";
import service from "../../plugins/service";
import ActivityDialog from "@/components/ContentballAnalytics/UI/ActivityDialog.vue";
import EasyRangeInput from "@/components/UI/EasyRangeInput.vue";
import DetailsDialog from "@/components/ContentballAnalytics/DetailsDialog.vue";
import TableSelectChip from "@/components/ContentballAnalytics/UI/TableSelectChip.vue";
import StatusesMixin from "../../mixins/StatusesMixin";
import FiltersHandling from "../../mixins/components/FiltersHandling";
import UploadFiltersDataMixin from "../../mixins/UploadFiltersDataMixin";
import { formatCamelCaseString } from "../../utils/functions";
import GlobalProjectBinderMixin from "@/mixins/utils/GlobalProjectBinderMixin";

export default {
  components: {
    TableSelectChip,
    DetailsDialog,
    EasyRangeInput,
    ActivityDialog,
    SmartTd,
    SmartHoverTd,
  },
  mixins: [
    getRgb,
    FiltersHandling,
    StatusesMixin,
    UploadFiltersDataMixin,
    GlobalProjectBinderMixin,
  ],
  data: () => ({
    filters: {
      project_categories: [],
      url: null,
      niche: null,
      team: null,
      main_seo: null,
      project: null,
      subproject: null,
      need_top: [null, null],
    },
    filtersData: {
      niche: [],
      project_categories: [],
      team: [],
      main_seo: [],
      site: [],
      projects: [],
      subprojects: [],
    },
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Contentball analytic",
        disabled: true,
      },
    ],
    smartHoverTds: [
      "total_words",
      "total_words_editor",
      "water_index_editor",
      "flesch_editor",
      "total_sentences",
      "average_sentence_length",
      "semantic_vocabulary",
      "vocabulary",
      "water_index",
      "zipfs_law",
      "classical_nausea_index",
      "dale_chall_score",
      "spache",
      "flesch",
      "automated_index",
      "cb3_overused",
      "cb3_required",
      "cb3_required_not_used",
      "cb3_required_not_in_target",
      "cb3_recommended",
      "cb3_recommended_not_used",
      "cb3_recommended_not_in_target",
      "cb3_used",
      "cb3_ai_score",
      "water_index_editor",
    ],
    smartTds: [
      "only_stop_words_1",
      "by_words_2_others",
      "only_stop_words_2",
      "main_phrases_by_vision",
      "main_words_by_vision",
      "snippet",
      "related",
      "suggest",
    ],
    deltaTds: [
      "only_stop_words_1_delta",
      "only_stop_words_2_delta",
      "by_words_2_others_delta",
      "main_words_by_vision_delta_positive",
      "main_words_by_vision_delta_negative",
    ],
    menuProps: DEFAULT_MENU_PROPS,
    selectedTableItems: [],
    headers: [],
    sortBy: ["pr_pr", "nt", "pr"],
    sortDesc: [true, false, false],
    items: [],
    detailSearch: "",
    detailDialogModel: false,
    urlItemsDialogModel: false,
    needToShowDetails: false,
    search: "",
    urlItemsData: [],
    selectedUrlData: null,
    activityTableHeaders: ACTIVITY_TABLE_HEADERS,
    modalDetailsTableData: [],
    loadings: {
      table: false,
      filters: false,
      recalculate: [],
      changeMValue: [],
      selectedRecalc: false,
    },
    detailsData: [],
    listOfDetailsCols: [
      "total_words",
      "total_words_editor",
      "total_sentences",
      "average_sentence_length",
      "semantic_vocabulary",
      "vocabulary",
      "water_index",
      "zipfs_law",
      "classical_nausea_index",
      "dale_chall_score",
      "spache",
      "flesch",
      "automated_index",
    ],
  }),
  async mounted() {
    await this.parseQuery("filters");

    if (!this.filters.project)
      this.filters.project = this.gpbmComputedGlobalProject;

    this.fetchFilters();
    this.uploadFiltersData(["project_categories", "projects", "subprojects"], {
      project: this.filters.project,
    });

    this.fetchData();

    this.$watch("filters.project", () => {
      this.filters.subproject = null;

      this.uploadFiltersData([
        "subprojects",
        { project: this.filters.project },
      ]);
    });
  },
  computed: {
    computedSelectedFilters() {
      return Object.values(this.filters).filter(
        (filter) => !this.__isEmptyValue(filter)
      );
    },
    getHeaders() {
      return [...this.headers, { text: "", value: "_actions" }];
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(value) {
        this._$handleFiltersChange(value);
      },
    },
  },
  methods: {
    gpbmHandleChangeGlobalProject(projectId) {
      this.filters.project = projectId;
    },
    formatCamelCaseString,
    getPercentColor(percent) {
      if (percent === undefined || percent === null) return "";

      if (percent <= 20) return "red";
      if (percent > 20 && percent <= 50) return "orange";
      if (percent > 50 && percent <= 80) return "yellow";
      if (percent > 80) return "green";

      return "transparent";
    },
    getFixedDeltaName(nameString) {
      if (
        nameString.includes("_delta_negative") ||
        nameString.includes("_delta_positive")
      ) {
        return nameString
          .replace("_delta_negative", "")
          .replace("_delta_positive", "");
      }

      return nameString.replace("_delta", "");
    },
    getUrlData(urlString) {
      try {
        return new URL(urlString);
      } catch {
        return "Incorrect URL";
      }
    },
    async fetchFilters() {
      try {
        const filters = ["niche", "team", "main_seo", "site"];
        const payload = {
          type: "/dashboards/cb/analytics",
          take: filters,
          filter: {
            projectID: this.filters.project,
          },
        };

        this.loadings.filters = true;

        const resp = await this.$store.dispatch("global/getFilters", payload);

        if (resp) this.filtersData = { ...this.filtersData, ...resp };
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.filters = false;
      }
    },
    handleClearFilters() {
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = null;
      });
    },
    async handleChangeMValue(value, type, { id: urlId }) {
      try {
        const url = "/dashboards/cb/analytics/update-marker";

        const payload = {
          url: urlId,
          marker: type,
          value,
        };

        this.loadings.changeMValue.push(urlId);

        await service.post(url, payload);
      } catch (e) {
        console.error("Error while changing M status.", e);
        throw e;
      } finally {
        this.loadings.changeMValue = this.loadings.changeMValue.filter(
          (v) => v !== urlId
        );
      }
    },
    getDaysDiff(date) {
      try {
        return this.$moment(date).diff(this.$moment(), "days");
      } catch {
        return 0;
      }
    },
    async handleSendOnRecalculate() {
      try {
        this.loadings.selectedRecalc = true;
        const urls = this.selectedTableItems.map((v) => v.id);

        const url = "/dashboards/cb/analytics/refresh-cache";

        const payload = {
          urls,
        };

        const resp = await service.post(url, payload);

        this.loadings.selectedRecalc = false;

        if (resp && resp.data && resp.data.status) {
          this.$message.notification({
            title: "Success",
            text: "All selected URL's Successfully sent for recalculation",
            type: "success",
          });
        }
      } catch {
        this.loadings.selectedRecalc = false;
      }
    },
    async handleAddToRecalculate(item) {
      try {
        const url = "/dashboards/cb/analytics/refresh-cache";

        const payload = {
          urls: [item.id],
        };

        this.loadings.recalculate.push(item.url);

        const resp = await service.post(url, payload);

        if (resp && resp.data && resp.data.status) {
          this.$message.notification({
            title: "Success",
            text: "URL Successfully sent for recalculation",
            type: "success",
          });
        }

        this.loadings.recalculate = this.loadings.recalculate.filter(
          (v) => v !== item.url
        );
      } catch {
        this.loadings.recalculate = this.loadings.recalculate.filter(
          (v) => v !== item.url
        );
      }
    },
    handleShowUrls(urlData, rowData) {
      this.urlItemsData = urlData.amountItems;
      this.selectedUrlData = rowData;
      this.urlItemsDialogModel = true;
    },
    customSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (a[sortBy[i]] === null && b[sortBy[i]] === null) continue;

          /*eslint-disable*/
          try {
            const hasDeviation =
              a[sortBy[i]].hasOwnProperty("deviation") &&
              b[sortBy[i]].hasOwnProperty("deviation");
            const hasPercent =
              a[sortBy[i]].hasOwnProperty("percent") &&
              b[sortBy[i]].hasOwnProperty("percent");

            if (hasDeviation) {
              let aVal, bVal;
              if (
                a[sortBy[i]].deviation === null ||
                a[sortBy[i]].deviation === ""
              )
                aVal = 0;
              if (
                b[sortBy[i]].deviation === null ||
                b[sortBy[i]].deviation === ""
              )
                bVal = 0;
              if (aVal === 0 && bVal !== 0) return 1;
              if (bVal === 0 && aVal !== 0) return -1;

              if (a[sortBy[i]].deviation > b[sortBy[i]].deviation)
                return sortDesc[i] ? -1 : 1;
              if (a[sortBy[i]].deviation < b[sortBy[i]].deviation)
                return sortDesc[i] ? 1 : -1;
              continue;
            }
            if (hasPercent) {
              let aVal, bVal;
              if (
                a[sortBy[i]]?.percent === null ||
                a[sortBy[i]]?.percent === ""
              )
                aVal = 0;
              if (
                b[sortBy[i]]?.percent === null ||
                b[sortBy[i]]?.percent === ""
              )
                bVal = 0;
              if (aVal === 0 && bVal !== 0) return 1;
              if (bVal === 0 && aVal !== 0) return -1;

              if (a[sortBy[i]]?.percent > b[sortBy[i]]?.percent)
                return sortDesc[i] ? -1 : 1;
              if (a[sortBy[i]]?.percent < b[sortBy[i]]?.percent)
                return sortDesc[i] ? 1 : -1;
              continue;
            }
          } catch {}

          const string =
            isNaN(parseInt(a[sortBy[i]])) && isNaN(parseInt(b[sortBy[i]]));

          if (string) {
            let aVal, bVal;
            if (a[sortBy[i]] === null || a[sortBy[i]] === "") aVal = 0;
            if (b[sortBy[i]] === null || b[sortBy[i]] === "") bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;

            if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
            if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
            continue;
          }

          let aVal, bVal;
          if (isNaN(parseInt(a[sortBy[i]]))) aVal = 0;
          if (isNaN(parseInt(b[sortBy[i]]))) bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
          if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
    handleShowDetail(data) {
      if (data.items && data.items.length > 0) {
        this.detailDialogModel = true;
        this.detailsData = data.items;
      }
    },
    async fetchData() {
      try {
        this.loadings.table = true;

        const payload = {
          filter: {
            project_id: this.filters.project,
            ...this.filters,
          },
        };

        const resp = await this.$store.dispatch(
          "contentball-analytics/fetchData",
          payload
        );

        if (resp) {
          this.items = resp.items;
          this.headers = resp.headers;
        }
      } catch (e) {
        console.error("Error while loading data table.", e);
      } finally {
        this.loadings.table = false;
      }
    },
  },
};
</script>
<style lang="scss">
.contentball-analytics {
  &__table {
    tr.marked {
      background-color: rgba(196, 39, 39, 0.2);

      &:hover {
        background-color: rgba(196, 39, 39, 0.26) !important;
      }
    }
  }

  .v-data-table {
    table {
      th {
        &:nth-child(2),
        &:nth-child(22),
        &:nth-child(24),
        &:nth-child(26),
        &:nth-child(28) {
          border-right: 1px solid rgba(155, 155, 155, 0.3);
        }
      }

      tbody {
        tr {
          td {
            &:nth-child(2),
            &:nth-child(22),
            &:nth-child(24),
            &:nth-child(26),
            &:nth-child(28) {
              border-right: 1px solid rgba(155, 155, 155, 0.3);
            }
          }
        }
      }
    }
  }
}

.activity-table {
  tr:nth-child(2) {
    font-weight: 600 !important;
  }
}
</style>
