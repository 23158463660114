var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"link-dynamics-report-links-by-project-table relative-tr",attrs:{"search":_vm.search,"headers":_vm.headers || [],"items":_vm.items || [],"sort-by":['priority'],"sort-desc":[true],"items-per-page":-1,"item-key":"","dense":""},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:`item.${h.value}`,fn:function({ item, value }){return [(h.value === 'project')?[_c('div',{key:`mark.${h.value}`,staticClass:"mark",style:(_vm.get_style_row(item.lag_on_page))}),_c('div',{key:`mark.2.${h.value}`,staticClass:"px-3 d-flex align-center",staticStyle:{"white-space":"nowrap"},style:({
          'border-left': `3px solid ${_vm.to_color(_vm.hash_code(value))}`,
          height: '100%',
        })},[_vm._v(" "+_vm._s(value)+" ")])]:(h.value === 'fact')?[_c('div',{key:`fact.${h.value}`,staticStyle:{"white-space":"nowrap"}},[_c('span',[_c('a',{attrs:{"href":_vm.get_link_to_ltt(item.id, item.subproject_id, item.type_id),"target":"_blank"}},[_vm._v(" "+_vm._s(item.fact.all.all))])]),(item.fact.on_page.all > 0)?_c('span',[_vm._v("("+_vm._s(item.fact.on_page.all)),(item.fact.gi.all > 0)?_c('span',[_vm._v(" / "+_vm._s(item.fact.gi.all))]):_vm._e(),_vm._v(") ")]):_vm._e()])]:(_vm.$moment(h.value, 'YYYY-MM-DD', true).isValid())?[(item.dates[h.value])?_c('LinksByProjectTableDateCellMinimized',{key:`date.${h.value}`,attrs:{"date":item.dates[h.value],"link":_vm.get_link_to_ltt(
            item.id,
            item.subproject_id,
            item.type_id,
            item.dates[h.value].date
          )}}):_vm._e()]:(h.value === 'done')?[_vm._v(" "+_vm._s(item.done_on_page ? item.done_on_page + "%" : item.done_on_page)+" ")]:(h.value === 'left')?[(item.left)?_c('div',{key:`left.${h.value}`,staticStyle:{"white-space":"nowrap"}},[(item.left.performer > 0)?_c('span',[_vm._v(" "+_vm._s(item.left.plan)+" ")]):_c('span',[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v("mdi-crop")])],1)]):_vm._e()]:(h.value === 'created_at')?_vm._l((value),function(i,j){return _c('div',{key:`date.${j}.${h.value}`},[_c('div',{staticStyle:{"white-space":"nowrap"},attrs:{"title":i.department}},[_vm._v(" "+_vm._s(i.date)+" ")])])}):(h.value === 'seo')?[_c('div',{key:`seo.${h.value}`,staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(value)+" ")])]:(h.value === 'link_task')?[_c('v-chip',{key:`link_task.${h.value}`,staticClass:"my-1",attrs:{"label":"","href":value,"target":"_blank","color":"transparent"}},[_vm._v(" Visit "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-open-in-new")])],1)]:(h.value === 'test')?void 0:[_vm._v(" "+_vm._s(value)+" ")]]}}})],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }