<template>
  <v-card
    flat
    class="text-body-2 flat-chip"
    style="border: 1px solid var(--warning-text-color)"
  >
    <v-card-title
      class="text-body-2 flex-nowrap pl-3 pr-2 pt-2 pb-2 d-flex align-start"
      style="gap: 0.25rem"
    >
      <div class="d-flex flex-column">
        <small>
          {{
            problem?.cm_status_sibling_problem?.category || "Without category"
          }}
          •
          {{
            $moment(
              problem?.cm_status_sibling_problem?.created_at ||
                problem?.created_at
            ).format("ll")
          }}
        </small>
        <b v-if="problem?.cm_status_sibling_problem">{{
          problem?.cm_status_sibling_problem?.problem
        }}</b>
      </div>
      <v-spacer />
      <v-tooltip
        bottom
        open-delay="100"
        content-class="my-shadow--e2 pa-0 rounded-lg"
      >
        <template #activator="{ on }">
          <v-avatar size="28" v-on="on">
            <v-img :src="problem?.site_workplace_history?.user?.photo" />
          </v-avatar>
        </template>
        <v-card flat outlined rounded="lg" class="pa-4 text-body-2">
          <v-row dense>
            <v-col cols="auto">
              <v-avatar size="38">
                <v-img :src="problem?.site_workplace_history?.user?.photo" />
              </v-avatar>
            </v-col>
            <v-col cols="fill" class="d-flex flex-column">
              <template v-if="problem?.site_workplace_history?.user?.name">
                <div
                  class="text-body-2"
                  style="
                    max-width: 200px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  :title="problem?.site_workplace_history?.user?.email"
                >
                  <b>{{ problem?.site_workplace_history?.user?.email }}</b>
                </div>
                <div class="text-body-2">
                  {{ problem?.site_workplace_history?.user?.name }}
                </div>
              </template>
            </v-col>
          </v-row>
        </v-card>
      </v-tooltip>
    </v-card-title>
    <v-card-text class="px-3 pb-3">
      {{ problem?.comment }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    problem: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
