<template>
  <v-dialog v-model="dialog" width="620" content-class="my-shadow--e2">
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2">
        Upload base keys
        <v-icon small right>mdi-upload</v-icon>
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4">
        <div>
          <small>Keys</small>
        </div>
        <v-textarea
          v-model="keys"
          outlined
          no-resize
          :placeholder="'phrase<tab>volume<tab>kd\nphrase<tab>volume<tab>kd\nphrase<tab>volume<tab>kd'"
          hide-details
          rows="7"
        />
        <h4 class="mt-4">.csv (phrase, volume, kd)</h4>
        <v-file-input
          v-model="importFile"
          dense
          outlined
          accept=".csv,.xls,.xlsx"
          prepend-icon="mdi-paperclip"
          label="Upload CSV/Excel"
          hide-details
        ></v-file-input>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          large
          class="text-normal px-6"
          @click="uploadKeys"
          color="primary"
          :loading="loading"
        >
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../mixins/Dialog";
import Project from "../../mixins/Project";
import axios from "axios";
import { findMostPopularDivider } from "../../utils/functions";

export default {
  mixins: [Dialog, Project],
  data: () => ({
    keys: "",
    importFile: null,
    loading: false,
  }),
  methods: {
    async uploadKeys() {
      this.loading = true;
      console.log("⏳ uploadKeys: Начало загрузки ключей...");

      try {
        const { validRows, invalidRows } = await this.processInput();
        console.log("✅ uploadKeys: Все строки обработаны");
        console.log("🟢 uploadKeys: Валидные строки:", validRows);
        console.log("🔴 uploadKeys: Не валидные строки:", invalidRows);

        if (!validRows.length) {
          throw new Error(
            "No valid rows left after validation. No data to upload."
          );
        }

        if (invalidRows.length) {
          this.showInvalidRowsDialog(invalidRows, validRows);
        }

        await this.sendRowsToAPI(validRows);
        console.log("🚀 uploadKeys: Данные успешно отправлены в API");

        this.showNotification(
          "Success",
          "Keywords imported successfully",
          "success"
        );

        this.dialog = false;
      } catch (error) {
        console.error("❌ uploadKeys: Ошибка", error);
        this.showNotification("Error", error.message, "error");
      } finally {
        this.resetState();
        console.log("🔄 uploadKeys: Завершение процесса, состояние сброшено.");
      }
    },

    async processInput() {
      console.log("📌 processInput: Обрабатываем ввод...");

      let validRows = [];
      let invalidRows = [];

      // Обрабатываем текстовые данные
      if (this.keys.trim()) {
        const parsedManualRows = this.parseTextarea();
        const { valid, invalid } = this.getValidatedRows(parsedManualRows);
        validRows = validRows.concat(valid);
        invalidRows = invalidRows.concat(invalid);
      }

      // Обрабатываем файл
      if (this.importFile) {
        const parsedFileRows = await this.parseFileSafe(this.importFile);
        const { valid, invalid } = this.getValidatedRows(parsedFileRows);
        validRows = validRows.concat(valid);
        invalidRows = invalidRows.concat(invalid);
      }

      return { validRows, invalidRows };
    },

    getValidatedRows(rows) {
      if (!Array.isArray(rows)) {
        console.warn(
          "⚠️ getValidatedRows: Полученные данные не являются массивом, возвращаю пустой список."
        );
        return { valid: [], invalid: [] };
      }

      console.log("🔍 getValidatedRows: Валидация строк...", rows);

      const validRows = [];
      const invalidRows = [];

      for (const row of rows) {
        try {
          // Проверяем, что строка имеет ровно 3 элемента (phrase, volume, kd)
          if (!Array.isArray(row) || row.length !== 3) {
            throw new Error(
              "Неверный формат: ожидается 3 колонки (phrase, volume, kd)"
            );
          }
          // Можно добавить дополнительные проверки, например, что volume и kd — числа
          // или что phrase не пустой.

          this.validateRows([row]); // Дополнительная валидация, если требуется
          validRows.push(row);
        } catch (error) {
          // console.warn(
          //   "⚠️ getValidatedRows: Ошибка в строке",
          //   row,
          //   error.message
          // );
          invalidRows.push({ row, error: error.message });
        }
      }

      console.log(
        `✅ getValidatedRows: Валидация завершена. Принято: ${validRows.length}, Ошибок: ${invalidRows.length}`
      );

      return { valid: validRows, invalid: invalidRows };
    },

    async parseFileSafe(file) {
      if (!file) {
        console.error("❌ parseFileSafe: Файл отсутствует или не был выбран.");
        this.showNotification(
          "Error",
          "Файл отсутствует или не был выбран.",
          "error"
        );
        return [];
      }

      console.log("📂 parseFileSafe: Начинаем обработку файла", file.name);

      try {
        const fileContent = await this.readFileAsText(file);

        if (!fileContent || fileContent.trim() === "") {
          throw new Error("Файл пуст или не содержит данных.");
        }

        console.log(
          "📜 parseFileSafe: Файл успешно считан, передаём в парсер..."
        );
        return await this.parseFile(fileContent);
      } catch (error) {
        console.error("❌ parseFileSafe: Ошибка при обработке файла", error);
        this.showNotification(
          "Error",
          "Ошибка при обработке файла: " + error.message,
          "error"
        );
        return [];
      }
    },

    async readFileAsText(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });
    },

    showNotification(title, text, type) {
      console.log(`📢 showNotification: ${title} - ${text} [${type}]`);
      this.$message.notification({ title, text, type });
    },

    showInvalidRowsDialog(invalidRows, validRows) {
      console.log(
        `⚠️ showInvalidRowsDialog: Присутствуют невалидные строки:`,
        invalidRows
      );

      // Формируем подробное описание ошибок
      // const errorDetails = invalidRows
      //   .map(({ row, error }) => `❌ ${JSON.stringify(row)} — ${error}`)
      //   .join("\n");

      this.$message.notification({
        title: "Some lines are invalid",
        text: `Invalid strings are present <b>${
          invalidRows.length
        }</b> invalid lines of <b>${
          invalidRows.length + validRows.length
        }</b>. <div>Only <b>${
          validRows.length
        }</b> pass the validation. </div>`,
        type: "warning",
        duration: 10000, // Дольше висит
      });
    },

    resetState() {
      console.log("♻️ resetState: Сброс состояния...");
      this.loading = false;
      this.importFile = null;
    },

    async parseFile(fileContent) {
      if (typeof fileContent !== "string") {
        console.error(
          "❌ parseFile: Передан некорректный контент файла",
          fileContent
        );
        throw new Error("Формат файла не поддерживается.");
      }

      if (!fileContent.trim()) {
        console.warn("⚠️ parseFile: Файл пуст, нечего парсить.");
        return [];
      }

      console.log("📜 parseFile: Начинаем парсинг содержимого...");

      // Пример простого парсинга CSV: разделяем содержимое по строкам.
      // Если файл в другом формате – нужно реализовать соответствующую логику.
      const popularDelimiter = findMostPopularDivider(fileContent, [
        ",",
        "\t",
      ])?.trim();

      console.log(
        "📜 parseFile: Самый популярный разделитель:",
        popularDelimiter
      );

      const rows = fileContent
        .split("\n")
        .map((line) => line.trim().split(popularDelimiter || "\t"))
        .filter((line) => line !== "");

      console.log(
        "📜 parseFile: Парсинг завершён, найдено строк:",
        rows.length
      );

      return rows;
    },

    // -----------------------

    parseTextarea() {
      return this.keys
        .trim()
        .split("\n")
        .map((line) => line.split("\t").map((col) => col.trim()));
    },
    validateRows(rows) {
      if (!Array.isArray(rows) || rows.length === 0) {
        throw new Error("The file or input is empty or invalid.");
      }

      return rows.filter(
        (row) =>
          Array.isArray(row) &&
          row.length === 3 &&
          row.every((col) => col !== "")
      );
    },
    async sendRowsToAPI(rows) {
      if (!Array.isArray(rows) || rows.length === 0) {
        console.warn("sendRowsToAPI: Нет данных для отправки.");
        return;
      }

      const taskId = this.$route.params.id;
      const url = `${this.$store.state.server_url}/semantic-tool/task/upload-keys/${taskId}`;
      const config = {
        headers: {
          Authorization: this.$store.state.auth.token,
        },
      };

      const chunkSize = 500;
      const totalChunks = Math.ceil(rows.length / chunkSize);

      for (let i = 0; i < rows.length; i += chunkSize) {
        const chunk = rows.slice(i, i + chunkSize);
        const currentChunk = Math.floor(i / chunkSize) + 1;
        console.log(
          `Отправка чанка ${currentChunk} из ${totalChunks} (строк с ${
            i + 1
          } по ${i + chunk.length})`
        );

        const data = chunk.map(([phrase, volume, kd]) => ({
          phrase,
          volume,
          kd,
        }));

        try {
          await axios.post(url, { rows: data }, config);
          console.log(`Чанк ${currentChunk} успешно отправлен.`);
        } catch (error) {
          console.error(
            `Не удалось загрузить чанк, начиная с строки ${i + 1}:`,
            error
          );
          throw new Error(
            `Error loading a chunk starting from the line ${
              i + 1
            }. Check the validity of the data and try uploading the file again.`
          );
        }
      }
    },
  },
};
</script>
