<template>
  <div v-if="filters && filtersData">
    <div class="d-flex" style="gap: 0.25rem">
      <v-btn
        class="text-normal px-6"
        large
        color="secondary"
        @click="dialogValue = !dialogValue"
      >
        Filters
        <my-badge
          v-if="computedSelectedFilters?.length"
          class="success white--text ml-2"
        >
          {{ computedSelectedFilters?.length }}
        </my-badge>
        <v-icon right small>mdi-filter</v-icon>
      </v-btn>
      <v-btn
        class="text-normal px-6"
        color="primary"
        large
        @click="$emit('getData')"
        :loading="loadings.allSections"
        >Get Data
      </v-btn>
    </div>
    <v-dialog v-model="dialogValue" content-class="my-shadow--e3" width="940">
      <v-card flat outlined rounded="lg">
        <v-card-title class="pa-5 text-body-2">
          Filters
          <v-spacer />
          <v-icon @click="dialogValue = false"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-row>
            <v-col ref="metricsFilterRef">
              <smart-autocomplete
                :model-value="filters.sections"
                @update:modelValue="filters.sections = $event"
                :items="filtersData.tribe_sections"
                :handling="false"
                label="Metrics"
                :rules="[(v) => !!v?.length || 'Metrics is required']"
              />
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="filters.view_dimension"
                label="View Dimension"
                :items="filtersData.tribe_view_dimensions"
                multiple
                hide-details
                dense
                clearable
                outlined
              />
            </v-col>
            <v-col></v-col>
            <v-col></v-col>
          </v-row>

          <v-row>
            <v-col ref="nicheFilterRef">
              <v-tooltip bottom open-delay="300">
                <template #activator="{ on }">
                  <v-autocomplete
                    v-on="on"
                    v-model="filters.niche"
                    :items="filtersData.niches"
                    label="Niche"
                    hide-details
                    clearable
                    dense
                    outlined
                    :loading="loadings?.niches"
                    @mouseenter="handleMouseOnNiches"
                  />
                </template>
                This filter affects the display of "Projects" and "Subprojects".
              </v-tooltip>
            </v-col>
            <v-col ref="teamsFilterRef">
              <v-tooltip bottom open-delay="300">
                <template #activator="{ on, props }">
                  <v-autocomplete
                    v-on="on"
                    v-bind="props"
                    v-model="filters.teams"
                    :items="filtersData.teams"
                    label="Team"
                    multiple
                    hide-details="auto"
                    clearable
                    dense
                    outlined
                    :loading="loadings?.teams"
                    :rules="computedRulesAtLeastOneFilter"
                    @mouseenter="handleMouseOnTeam"
                  />
                </template>
                This filter affects the display of "Projects" and "Subprojects".
              </v-tooltip>
            </v-col>
            <v-col></v-col>
            <v-col></v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-start" ref="projectStatusFilterRef">
              <v-tooltip bottom open-delay="300">
                <template #activator="{ on }">
                  <v-autocomplete
                    v-on="on"
                    v-model="filters.project_status"
                    :items="filtersData.project_statuses"
                    label="Project Status"
                    hide-details
                    dense
                    clearable
                    multiple
                    outlined
                    :loading="loadings?.project_statuses"
                    @mouseenter="
                      animateDom($refs.projectFilterRef, 'highlight', {
                        id: '1',
                      })
                    "
                  />
                </template>
                This filter affects the display of "Projects".
              </v-tooltip>
            </v-col>
            <v-col class="d-flex align-start" ref="projectFilterRef">
              <v-tooltip bottom open-delay="300">
                <template #activator="{ on }">
                  <v-autocomplete
                    v-on="on"
                    v-model="filters.project"
                    :items="filtersData.projects"
                    label="Project"
                    multiple
                    hide-details="auto"
                    clearable
                    dense
                    outlined
                    :loading="loadings?.projects"
                    :rules="computedRulesAtLeastOneFilter"
                    @mouseenter="
                      animateDom($refs.subprojectFilterRef, 'highlight', {
                        id: '1',
                      })
                    "
                  />
                </template>
                This filter affects the display of "Subprojects".
              </v-tooltip>
            </v-col>

            <v-col class="d-flex align-start" ref="subprojectStatusFilterRef">
              <v-tooltip bottom open-delay="300">
                <template #activator="{ on }">
                  <v-autocomplete
                    v-on="on"
                    v-model="filters.subproject_status"
                    :items="filtersData.project_statuses"
                    label="Subproject Status"
                    hide-details
                    dense
                    clearable
                    multiple
                    outlined
                    :loading="loadings?.project_statuses"
                    @mouseenter="
                      animateDom($refs.subprojectFilterRef, 'highlight', {
                        id: '1',
                      })
                    "
                  />
                </template>
                This filter affects the display of "Subprojects".
              </v-tooltip>
            </v-col>
            <v-col class="d-flex align-start" ref="subprojectFilterRef">
              <smart-autocomplete
                :model-value="filters.subprojects"
                @update:modelValue="filters.subprojects = $event"
                :items="filtersData.subprojects"
                label="Subproject"
                :loading="loadings?.subprojects"
                :rules="computedRulesAtLeastOneFilter"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col ref="pageTypesFilterRef">
              <v-autocomplete
                v-model="filters.page_types"
                :items="filtersData.page_types"
                label="Page Type"
                multiple
                hide-details
                clearable
                dense
                outlined
                :loading="loadings?.page_types"
              />
            </v-col>
            <v-col>
              <easy-range-input
                :model-value="filters.need_top"
                @update:modelValue="filters.need_top = $event"
                label="Need Top"
              />
            </v-col>
            <v-col ref="mainSeoFilterRef">
              <v-autocomplete
                v-model="filters.main_seo"
                :items="filtersData.seo_users"
                label="Main SEO"
                multiple
                clearable
                hide-details
                dense
                outlined
                :loading="loadings?.seo_users"
              />
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="filters.project_category"
                :items="filtersData.project_categories"
                label="Project category"
                clearable
                hide-details="auto"
                dense
                outlined
                :loading="loadings?.project_categories"
                :rules="computedRulesAtLeastOneFilter"
                @change="handleChangeProjectCategory(filters.project_category)"
                @mouseenter="
                  animateDom($refs.metricsFilterRef, 'highlight', {
                    id: '10',
                  })
                "
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="text-normal px-6"
            color="primary"
            large
            @click="$emit('getData')"
            :loading="loadings.allSections"
            >Get Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EasyRangeInput from "../../UI/EasyRangeInput.vue";
import SmartAutocomplete from "../../UI/SmartAutocomplete.vue";
import DomAnimatorMixin from "../../../mixins/DomAnimatorMixin";
import MyBadge from "../../UI/MyBadge.vue";
import FiltersHandling from "../../../mixins/components/FiltersHandling";

export default {
  components: { MyBadge, SmartAutocomplete, EasyRangeInput },

  emits: ["getData", "update:dialog"],

  props: ["value", "filtersData", "loadings", "dialog"],

  mixins: [DomAnimatorMixin, FiltersHandling],

  computed: {
    dialogValue: {
      get() {
        return this.dialog;
      },
      set(newValue) {
        this.$emit("update:dialog", newValue);
      },
    },
    computedSelectedFilters() {
      return Object.values(this.filters).filter(
        (filter) => !this.__isEmptyValue(filter)
      );
    },
    computedRulesAtLeastOneFilter() {
      const { teams, project, subprojects, project_category } = this.filters;

      return [
        () =>
          !!teams?.length ||
          !!project?.length ||
          !!subprojects?.length ||
          !!project_category ||
          "Pick at least one: Team, Subproject, Project, Project category",
      ];
    },
    filters: {
      set(newData) {
        this.$emit("input", newData);
      },
      get() {
        return this.value;
      },
    },
  },

  methods: {
    handleChangeProjectCategory(selectedProjectCategoryId) {
      const presets = {
        1: [
          "links",
          "p200",
          "content",
          "sw",
          "pages-info-table",
          "need-top-table",
          "positions",
          "conversion",
        ],
        2: ["positions", "conversion", "revenue_ftd", "links"],
      };

      this.filters.sections = presets[selectedProjectCategoryId];
    },
    async handleMouseOnNiches() {
      this.animateDom(this.$refs.projectFilterRef, "highlight", {
        id: "1",
      });
      this.animateDom(this.$refs.subprojectFilterRef, "highlight", {
        id: "2",
      });
    },
    async handleMouseOnTeam() {
      this.animateDom(this.$refs.projectFilterRef, "highlight", {
        id: "3",
      });
      this.animateDom(this.$refs.subprojectFilterRef, "highlight", {
        id: "4",
      });
    },
  },
};
</script>
