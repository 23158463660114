import Service from "../../../plugins/service";

export const UrlControlApiService = () => {
  const showPartners = (id) => {
    return Service.post(`/url-control/${id}/partners`);
  };

  return {
    showPartners,
  };
};
