<template>
  <div v-if="traffic?.our" style="white-space: nowrap">
    {{ Number(traffic?.our) }}
    <sup
      v-if="traffic?.diff"
      :class="{
        'error--text': traffic?.diff < 0,
        'success--text': traffic?.diff > 0,
      }"
    >
      {{ String(Number(traffic?.diff)).replace("-", "") }}
    </sup>
  </div>
</template>
<script>
export default {
  props: {
    traffic: Object,
  },
};
</script>
