<template>
  <div class="site-overview">
    <v-row dense>
      <v-col cols="12">
        <page-header
          title="4DX Site Overview"
          :breadcrumbs="[
            {
              text: 'Home',
              to: '/',
            },
            {
              text: '4DX Site Overview',
              disabled: true,
            },
          ]"
        />
      </v-col>
      <v-col cols="12" class="pt-6">
        <v-row dense>
          <v-col>
            <v-autocomplete
              v-model="filters.project"
              :items="filtersData.projects"
              hide-details
              label="Project"
              clearable
              dense
              outlined
              :disabled="loadings.projects"
              :loading="filtersDataPendingKeys?.project"
              placeholder="Type to search"
              append-icon="mdi-chevron-down"
              @change="handleChangeProject(true)"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.subproject"
              :items="filtersData.subprojects"
              hide-details="auto"
              clearable
              label="Subproject"
              dense
              placeholder="Type to search"
              :disabled="
                loadings.projects ||
                (!filters.project && !filtersData.subprojects?.length)
              "
              outlined
              :loading="filtersDataPendingKeys?.subprojects"
              append-icon="mdi-chevron-down"
            />
            <small style="line-height: 110%" class="error--text">
              {{ !filters.project ? "To see the list, select a project." : "" }}
            </small>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.team"
              :items="filtersData.teams"
              hide-details
              label="Team"
              clearable
              dense
              outlined
              :loading="filtersDataPendingKeys?.teams"
              placeholder="Type to search"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              hide-details
              dense
              outlined
              label="SEO"
              placeholder="Type to search"
              v-model="filters.seo"
              :items="filtersData.seo || []"
              :loading="loadings.filters"
            >
            </v-autocomplete>
          </v-col>
          <v-col>
            <easy-range-input
              title="Need Top"
              :model-value="filters.need_top"
              @update:modelValue="filters.need_top = $event"
              :presets="needTopPresets"
              :max="255"
            />
          </v-col>
          <v-col>
            <smart-date-filter
              :model-value="filters.date"
              @update:modelValue="filters.date = $event"
            />
          </v-col>
          <v-col>
            <v-btn
              block
              @click="handleGetData"
              class="text-normal"
              color="primary"
              height="40"
              :loading="loadings.table"
            >
              Get Data
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card flat outlined rounded="lg" class="pt-0 pb-2">
          <v-card-text class="pa-0">
            <v-data-table
              :search="search.needTop"
              :items="needTopTableData.items"
              :headers="needTopTableData.headers"
              :items-per-page="-1"
              hide-default-footer
            >
              <template
                v-for="header in headers"
                #[`item.${header.value}`]="{ item, value }"
              >
                <template v-if="header.value === 'url'">
                  <uo-td-url :value="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'status'">
                  <uo-td-status
                    :value="value"
                    :data="item"
                    :key="header.value"
                  />
                </template>
                <template v-else-if="header.value === 'l_sc'">
                  <uo-td-l-sc :value="value" :data="item" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'sc_sc'">
                  <uo-td-sc-sc
                    :value="value"
                    :data="item"
                    :key="header.value"
                  />
                </template>
                <template v-else-if="header.value === 'sw_sc'">
                  <uo-td-sw-sc
                    :value="value"
                    :data="item"
                    :key="header.value"
                  />
                </template>
                <template v-else-if="header.value === 't_sc'">
                  <uo-td-t-sc :value="value" :data="item" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'status_code'">
                  <status-code-cell
                    :value="value"
                    :data="item"
                    :key="header.value"
                  />
                </template>
                <template v-else-if="header.value === 'nt_percent'">
                  <uo-td-nt-percent :value="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'pos'">
                  <uo-td-pos :data="item" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'dynamic'">
                  <uo-td-dynamic :value="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'kd'">
                  <div
                    class="main-value d-flex align-center justify-center"
                    :key="header.value"
                  >
                    <uo-kd :value="value" :text="true" :opacity="1" />
                  </div>
                </template>
                <template v-else-if="header.value === '1'">
                  <uo-td-delta :data="item['1']" :key="header.value" />
                </template>
                <template v-else-if="header.value === '3'">
                  <uo-td-delta :data="item['3']" :key="header.value" />
                </template>
                <template v-else-if="header.value === '10'">
                  <uo-td-delta :data="item['10']" :key="header.value" />
                </template>
                <template v-else-if="header.value === '20'">
                  <uo-td-delta :data="item['20']" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'trends_imp'">
                  <uo-td-trends-imp :data="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'trends_cl'">
                  <uo-td-trends-cl :data="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'trends_k'">
                  <uo-td-trends-cl :data="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'trends_kr'">
                  <uo-td-trends-cl :data="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'trends_kd'">
                  <uo-td-trends-cl :data="value" :key="header.value" />
                </template>
                <template v-else>
                  {{ value }}
                </template>
              </template>
              <template
                v-for="header in baseTableData.headers"
                #[`item.${header.value}`]="{ item, value }"
              >
                <template v-if="header.value === 'url'">
                  <uo-td-url :value="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'status'">
                  <uo-td-status
                    :value="value"
                    :data="item"
                    :key="header.value"
                  />
                </template>
                <template v-else-if="header.value === 'l_sc'">
                  <uo-td-l-sc :value="value" :data="item" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'sc_sc'">
                  <uo-td-sc-sc
                    :value="value"
                    :data="item"
                    :key="header.value"
                  />
                </template>
                <template v-else-if="header.value === 'sw_sc'">
                  <uo-td-sw-sc
                    :value="value"
                    :data="item"
                    :key="header.value"
                  />
                </template>
                <template v-else-if="header.value === 't_sc'">
                  <uo-td-t-sc :value="value" :data="item" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'status_code'">
                  <status-code-cell
                    :value="value"
                    :data="item"
                    :key="header.value"
                  />
                </template>
                <template v-else-if="header.value === 'nt_percent'">
                  <uo-td-nt-percent :value="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'pos'">
                  <uo-td-pos :data="item" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'dynamic'">
                  <uo-td-dynamic :value="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'kd'">
                  <div
                    class="main-value d-flex align-center justify-center"
                    :key="header.value"
                  >
                    <uo-kd :value="value" :text="true" :opacity="1" />
                  </div>
                </template>
                <template v-else-if="header.value === '1'">
                  <uo-td-delta :data="item['1']" :key="header.value" />
                </template>
                <template v-else-if="header.value === '3'">
                  <uo-td-delta :data="item['3']" :key="header.value" />
                </template>
                <template v-else-if="header.value === '10'">
                  <uo-td-delta :data="item['10']" :key="header.value" />
                </template>
                <template v-else-if="header.value === '20'">
                  <uo-td-delta :data="item['20']" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'trends_imp'">
                  <uo-td-trends-imp :data="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'trends_cl'">
                  <uo-td-trends-cl :data="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'trends_k'">
                  <uo-td-trends-cl :data="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'trends_kr'">
                  <uo-td-trends-cl :data="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'trends_kd'">
                  <uo-td-trends-cl :data="value" :key="header.value" />
                </template>
                <template v-else>
                  {{ value }}
                </template>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title>
            <v-text-field
              v-model="search.base"
              hide-details
              dense
              outlined
              placeholder="Type to search"
              append-icon="mdi-magnify"
            />
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0">
            <v-data-table
              :headers="baseTableData.headers"
              :items="baseTableData.items"
              :loading="loadings.table"
              :items-per-page="-1"
              hide-default-footer
            >
              <template
                v-for="header in headers"
                #[`item.${header.value}`]="{ item, value }"
              >
                <template v-if="header.value === 'url'">
                  <uo-td-url :value="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'status'">
                  <uo-td-status
                    :value="value"
                    :data="item"
                    :key="header.value"
                  />
                </template>
                <template v-else-if="header.value === 'l_sc'">
                  <uo-td-l-sc :value="value" :data="item" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'sc_sc'">
                  <uo-td-sc-sc
                    :value="value"
                    :data="item"
                    :key="header.value"
                  />
                </template>
                <template v-else-if="header.value === 'sw_sc'">
                  <uo-td-sw-sc
                    :value="value"
                    :data="item"
                    :key="header.value"
                  />
                </template>
                <template v-else-if="header.value === 't_sc'">
                  <uo-td-t-sc :value="value" :data="item" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'status_code'">
                  <status-code-cell
                    :value="value"
                    :data="item"
                    :key="header.value"
                  />
                </template>
                <template v-else-if="header.value === 'nt_percent'">
                  <uo-td-nt-percent :value="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'pos'">
                  <uo-td-pos :data="item" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'dynamic'">
                  <uo-td-dynamic :value="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'kd'">
                  <div
                    class="main-value d-flex align-center justify-center"
                    :key="header.value"
                  >
                    <uo-kd :value="value" :text="true" :opacity="1" />
                  </div>
                </template>
                <template v-else-if="header.value === '1'">
                  <uo-td-delta :data="item['1']" :key="header.value" />
                </template>
                <template v-else-if="header.value === '3'">
                  <uo-td-delta :data="item['3']" :key="header.value" />
                </template>
                <template v-else-if="header.value === '10'">
                  <uo-td-delta :data="item['10']" :key="header.value" />
                </template>
                <template v-else-if="header.value === '20'">
                  <uo-td-delta :data="item['20']" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'trends_imp'">
                  <uo-td-trends-imp :data="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'trends_cl'">
                  <uo-td-trends-cl :data="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'trends_k'">
                  <uo-td-trends-cl :data="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'trends_kr'">
                  <uo-td-trends-cl :data="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'trends_kd'">
                  <uo-td-trends-cl :data="value" :key="header.value" />
                </template>
                <template v-else>
                  {{ value }}
                </template>
              </template>
              <template
                v-for="header in baseTableData.headers"
                #[`item.${header.value}`]="{ item, value }"
              >
                <template v-if="header.value === 'url'">
                  <uo-td-url :value="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'status'">
                  <uo-td-status
                    :value="value"
                    :data="item"
                    :key="header.value"
                  />
                </template>
                <template v-else-if="header.value === 'l_sc'">
                  <uo-td-l-sc :value="value" :data="item" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'sc_sc'">
                  <uo-td-sc-sc
                    :value="value"
                    :data="item"
                    :key="header.value"
                  />
                </template>
                <template v-else-if="header.value === 'sw_sc'">
                  <uo-td-sw-sc
                    :value="value"
                    :data="item"
                    :key="header.value"
                  />
                </template>
                <template v-else-if="header.value === 't_sc'">
                  <uo-td-t-sc :value="value" :data="item" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'status_code'">
                  <status-code-cell
                    :value="value"
                    :data="item"
                    :key="header.value"
                  />
                </template>
                <template v-else-if="header.value === 'nt_percent'">
                  <uo-td-nt-percent :value="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'pos'">
                  <uo-td-pos :data="item" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'dynamic'">
                  <uo-td-dynamic :value="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'kd'">
                  <div
                    class="main-value d-flex align-center justify-center"
                    :key="header.value"
                  >
                    <uo-kd :value="value" :text="true" :opacity="1" />
                  </div>
                </template>
                <template v-else-if="header.value === '1'">
                  <uo-td-delta :data="item['1']" :key="header.value" />
                </template>
                <template v-else-if="header.value === '3'">
                  <uo-td-delta :data="item['3']" :key="header.value" />
                </template>
                <template v-else-if="header.value === '10'">
                  <uo-td-delta :data="item['10']" :key="header.value" />
                </template>
                <template v-else-if="header.value === '20'">
                  <uo-td-delta :data="item['20']" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'trends_imp'">
                  <uo-td-trends-imp :data="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'trends_cl'">
                  <uo-td-trends-cl :data="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'trends_k'">
                  <uo-td-trends-cl :data="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'trends_kr'">
                  <uo-td-trends-cl :data="value" :key="header.value" />
                </template>
                <template v-else-if="header.value === 'trends_kd'">
                  <uo-td-trends-cl :data="value" :key="header.value" />
                </template>
                <template v-else>
                  {{ value }}
                </template>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UoTdDelta from "@/components/Dashboards/UrlsOverview/Td/UoTdDelta";
import UoTdTrendsCl from "@/components/Dashboards/UrlsOverview/Td/UoTdTrendsCl";
import UoTdTrendsImp from "@/components/Dashboards/UrlsOverview/Td/UoTdTrendsImp";
import UoTdNtPercent from "@/components/Dashboards/UrlsOverview/Td/UoTdNtPercent";
import UoTdTSc from "@/components/Dashboards/UrlsOverview/Td/UoTdTSc";
import UoTdSwSc from "@/components/Dashboards/UrlsOverview/Td/UoTdSwSc";
import UoTdScSc from "@/components/Dashboards/UrlsOverview/Td/UoTdScSc";
import UoTdLSc from "@/components/Dashboards/UrlsOverview/Td/UoTdLSc";
import UoTdStatus from "@/components/Dashboards/UrlsOverview/Td/UoTdStatus";
import Location from "../../../mixins/Location";
import Project from "../../../mixins/Project";
import SmartDateFilter from "../../UI/SmartDateFilter.vue";
import EasyRangeInput from "../../UI/EasyRangeInput.vue";
import UploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";
import PageHeader from "../../UI/PageHeader.vue";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import UoTdDynamic from "../UrlsOverview/Td/UoTdDynamic.vue";
import UoTdUrl from "../UrlsOverview/Td/UoTdUrl.vue";
import UoTdPos from "../UrlsOverview/Td/UoTdPos.vue";
import UoKd from "../UrlsOverview/Td/UoKd.vue";
import StatusCodeCell from "../UrlsOverview/Td/StatusCodeCell.vue";
import Service from "../../../plugins/service";
import GlobalProjectBinderMixin from "../../../mixins/utils/GlobalProjectBinderMixin";

export default {
  components: {
    StatusCodeCell,
    UoKd,
    UoTdPos,
    UoTdUrl,
    UoTdDynamic,
    PageHeader,
    EasyRangeInput,
    SmartDateFilter,
    UoTdDelta,
    UoTdTrendsCl,
    UoTdTrendsImp,
    UoTdNtPercent,
    UoTdTSc,
    UoTdSwSc,
    UoTdScSc,
    UoTdLSc,
    UoTdStatus,
  },
  mixins: [
    FiltersHandling,
    Location,
    UploadFiltersDataMixin,
    Project,
    GlobalProjectBinderMixin,
  ],
  data: () => ({
    loadings: {
      table: false,
      filters: false,
    },
    needTopPresets: [
      ["1-3", [1, 3]],
      ["1-5", [1, 5]],
      ["4-10", [4, 10]],
      ["4-20", [4, 20]],
      ["1-50", [1, 50]],
      ["11-50", [11, 50]],
    ],
    firstEnter: true,
    baseTableData: {
      headers: [],
      items: [],
    },
    search: {
      base: null,
      needTop: null,
    },
    needTopTableData: {
      headers: [],
      items: [],
    },
    // filters
    filters: {
      seo: null,
      date: [],
      need_top: [0, 0],
      project: null,
      subproject: null,
    },
    // end filters
    filtersData: {
      seo: [],
      teams: [],
      projects: [],
      subprojects: [],
    },
    isWatchBlocked: true,
  }),

  watch: {
    "filters.project"(value) {
      if (this.isWatchBlocked) return;

      if (!value) {
        this.filters.subproject = null;
        this.filtersData.subprojects = [];
      }
    },
  },

  computed: {
    getFilters() {
      const { date, seo, need_top } = this.filters;
      const output = {};

      if (date.length !== 0) {
        output.date = date;
      }

      output.seo = seo;

      if (JSON.stringify(need_top) !== "[0,0]") {
        output.need_top = need_top;
      }

      return output;
    },
    getDefaultDate() {
      const date = new Date();
      const start = this.$moment(date)
        .subtract(1, "day")
        .startOf("day")
        .format("YYYY-MM-DD");
      const end = this.$moment(date).format("YYYY-MM-DD");
      return [start, end];
    },
  },

  created() {
    this.isWatchBlocked = true;
  },

  async mounted() {
    await this.parseQuery("filters");
    await this.uploadFiltersData(["projects", "teams"]);

    this.fetchFilters();

    if (!this.filters.project)
      this.filters.project = this.gpbmComputedGlobalProject;

    if (!this.$route.query.date) {
      this.filters.date = this.getDefaultDate;
    }

    if (Object.keys(this.$route.query).length) this.fetchData();

    this.$nextTick(() => {
      this.isWatchBlocked = false;
      if (this.filters.project) this.handleChangeProject(false);
    });
  },

  methods: {
    gpbmHandleChangeGlobalProject(projectId) {
      this.filters.project = projectId;
    },
    async handleChangeProject(replaceSelectedSubproject = false) {
      await this.uploadFiltersData(["subprojects"], {
        project: this.filters.project,
      });

      // Pre-select first subproject
      if (this.filtersData?.subprojects?.length && replaceSelectedSubproject) {
        this.filters.subproject = this.filtersData.subprojects[0]?.value;
      }
    },
    goToUrlOverview(projectId) {
      const url = "/dashboards/urls-overview";
      const bannedParams = ["seo"];
      const params = {
        ...this.getFilters,
        project: projectId,
      };
      this.routerPushWithQueries(params, url, bannedParams);
    },
    routerPushWithQueries(params, url, bannedKeys = []) {
      const query = {};
      let queryString = "";

      Object.keys(params).forEach((key) => {
        if (bannedKeys.includes(key)) return;
        query[key] = params[key];
      });

      Object.keys(query).forEach((key, index) => {
        const prefix = index === 0 ? "?" : "&";
        queryString += `${prefix}${key}=${query[key]}`;
      });

      const path = url + queryString;

      window.open(path, "_blank");
    },
    handleGetData() {
      this.fetchData();
    },
    async fetchFilters() {
      this.loadings.filters = true;
      this.firstEnter = false;

      const payload = {
        type: "/dashboards/summary/site-overview",
        take: ["seo"],
      };
      const filters = await this.$store.dispatch("global/getFilters", payload);

      this.filtersData = {
        ...this.filtersData,
        seo: filters.seo,
      };

      this.loadings.filters = false;
    },
    async fetchData() {
      try {
        this.loadings.table = true;

        const payload = {
          filter: {
            date_from: this.filters.date[0],
            date_to: this.filters.date[1],
            ...this.filters,
          },
        };

        const { data } = await Service.post(
          `/dashboards/summary/site-overview`,
          payload
        );

        if (data) {
          this.baseTableData = data.base;
          this.needTopTableData = data.need_top;
        }
      } catch (e) {
        console.error("Error while loading data-table data.", e);
      } finally {
        this.loadings.table = false;
      }
    },
  },
};
</script>
