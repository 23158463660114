import { loremIpsum } from "lorem-ipsum";
import moment from "moment";
import { uid } from "@/utils/functions";

export const PROJECT_ID_IDTF = "project_id";
export const SUBPROJECT_IDTF = "subproject";

export const DEFAULT_MOMENT_DATE_FORMAT = "YYYY-MM-DD";

export const APARSER_MOCK_CARDS = [
  {
    id: 0,
    error: true,
    amount: 0,
    text: "Wait Aparser",
  },
  {
    id: 1,
    error: true,
    amount: 0,
    text: "Wait Insert",
  },
  {
    id: 2,
    error: true,
    amount: 0,
    text: "Start Insert",
  },
  {
    id: 3,
    error: true,
    amount: 5,
    text: "Errors",
  },
  {
    id: 4,
    error: false,
    amount: 0,
    text: "Long Aparser",
  },
  {
    id: 5,
    error: false,
    amount: 0,
    text: "Wait Insert",
  },
];

export const DEV_DASHBOARD_APARSER_DETAILS_TABLE_HEADERS = [
  { text: "id", value: "id" },
  { text: "parser", value: "parser" },
  { text: "queries ", value: "queries_all " },
  { text: "aparser ", value: "status_aparser " },
  { text: "laravel ", value: "status_laravel " },
  { text: "error ", value: "error", align: "center" },
  { text: "created ", value: "created_at" },
  { text: "server ", value: "server", align: "center" },
  { text: "", value: "_", align: "center" },
];

export const TOP100_TABLE_DATA = {
  headers: [
    {
      text: "Country + Country Lang",
      align: "start",
      sortable: false,
      value: "country",
    },
    { text: "Phrases Need", value: "phrasesNeed" },
    { text: "Phrases Collected", value: "phrasesCollected" },
    { text: "Delta", value: "delta", sortable: false },
  ],
  items: [
    {
      country: "US-en",
      phrasesNeed: 17854,
      phrasesCollected: 17854,
      delta: 0,
    },
    {
      country: "CA-en",
      phrasesNeed: 12143,
      phrasesCollected: 12143,
      delta: 0,
    },
    {
      country: "CA-fr",
      phrasesNeed: 8033,
      phrasesCollected: 8033,
      delta: 0,
    },
    {
      country: "DE-de",
      phrasesNeed: 7736,
      phrasesCollected: 7736,
      delta: 3,
    },
    {
      country: "JP-ja",
      phrasesNeed: 6069,
      phrasesCollected: 6066,
      delta: 3,
    },
    {
      country: "AT-de",
      phrasesNeed: 5949,
      phrasesCollected: 5449,
      delta: 500,
    },
  ],
};

export const FAILED_PROXY_CHART_DATA = {
  series: [],
  options: {
    stroke: { show: false },
    chart: {
      type: "pie",
    },
    animations: {
      enabled: false,
    },
    labels: [],
  },
};

export const PRESETS_TABLE_DATA = {
  headers: [
    {
      text: "Name",
      align: "start",
      sortable: false,
      value: "name",
    },
    {
      text: "Failed Queries",
      align: "start",
      sortable: false,
      value: "failed_queries",
    },
    {
      text: "Rows",
      align: "start",
      sortable: false,
      value: "value",
    },
    {
      text: "2022-04-05 09:39",
      align: "end",
      sortable: false,
      value: "chart",
      width: "175px",
    },
  ],
  signatureHeaders: [
    {
      text: "Signature",
      align: "start",
      sortable: true,
      value: "signature",
    },
    { text: "Project", value: "project" },
    { text: "Idle", value: "idle", align: "start" },
    {
      text: "Last Success Launched At",
      value: "last_launch_success",
      sortable: false,
      align: "end",
    },
    { text: "Status", value: "status", sortable: false },
  ],
  presetsItems: [
    {
      name: {
        text: "BackLinks",
        links: "https://www.google.com",
      },
      rows: 2,
      parsingInfo: {
        graphId: 0,
        error: true,
        graph: [{ name: "rows", data: [4, 3, 3, 3, 3, 4, 3, 2, 0] }],
      },
    },
    {
      name: {
        text: "LinkExtractor",
        links: "https://www.google.com",
      },
      rows: 2,
      parsingInfo: {
        graphId: 1,
        graph: [{ name: "rows", data: [0, 0, 40, 32, 6, 4, 0, 0, 0] }],
      },
    },
    {
      name: {
        text: "SimilarWeb",
        links: "https://www.google.com",
      },
      rows: 0,
      parsingInfo: {
        graphId: 2,
        graph: [{ name: "rows", data: [0, 0, 0, 0, 0, 0, 0, 0, 0] }],
      },
    },
    {
      name: {
        text: "WebArchive",
        links: "https://www.google.com",
      },
      rows: 0,
      parsingInfo: {
        graphId: 3,
        graph: [{ name: "rows", data: [0, 0, 0, 0, 0, 0, 0, 0, 0] }],
      },
    },
    {
      name: {
        text: "GoogleTranslate",
        links: "https://www.google.com",
      },
      rows: 25477,
      parsingInfo: {
        graphId: 4,
        error: true,
        graph: [
          {
            name: "rows",
            data: [
              45700, 43400, 41900, 39851, 36133, 35125, 30255, 15332, 14257,
            ],
          },
        ],
      },
    },
    {
      name: {
        text: "BackLinks",
        links: "https://www.google.com",
      },
      rows: 2,
      parsingInfo: {
        graphId: 0,
        error: true,
        graph: [{ name: "rows", data: [4, 3, 3, 3, 3, 4, 3, 2, 0] }],
      },
    },
    {
      name: {
        text: "LinkExtractor",
        links: "https://www.google.com",
      },
      rows: 2,
      parsingInfo: {
        graphId: 1,
        graph: [{ name: "rows", data: [0, 0, 40, 32, 6, 4, 0, 0, 0] }],
      },
    },
    {
      name: {
        text: "SimilarWeb",
        links: "https://www.google.com",
      },
      rows: 0,
      parsingInfo: {
        graphId: 2,
        graph: [{ name: "rows", data: [0, 0, 0, 0, 0, 0, 0, 0, 0] }],
      },
    },
    {
      name: {
        text: "WebArchive",
        links: "https://www.google.com",
      },
      rows: 0,
      parsingInfo: {
        graphId: 3,
        graph: [{ name: "rows", data: [0, 0, 0, 0, 0, 0, 0, 0, 0] }],
      },
    },
    {
      name: {
        text: "GoogleTranslate",
        links: "https://www.google.com",
      },
      rows: 25477,
      parsingInfo: {
        graphId: 4,
        error: true,
        graph: [
          {
            name: "rows",
            data: [
              45700, 43400, 41900, 39851, 36133, 35125, 30255, 15332, 14257,
            ],
          },
        ],
      },
    },
  ],
  signatureItems: [
    {
      signature: "aggregation:content_dashboard",
      project: "serpinsider",
      delay: "",
      lastLaunched: "2022-04-05 10:03:45",
      lastSuccessLaunched: {
        date: "2022-04-05 10:03:45",
        idle: "0",
      },
    },
    {
      signature: "aparser:history",
      project: "serpinsider",
      lastSuccessLaunched: {
        date: "2022-03-31 16:57:50",
        idle: "113",
      },
    },
    {
      signature: "aparser:pack",
      project: "serpinsider",
      lastSuccessLaunched: {
        date: "2022-04-05 10:04:53",
        idle: "0",
      },
    },
    {
      signature: "aparser:result_preset ActualUrl",
      project: "serpinsider",
      lastSuccessLaunched: {
        date: "2022-04-05 10:04:53",
        idle: "0",
      },
    },
    {
      signature: "aparser:result_preset ActualUrl 15951232",
      project: "serpinsider",
      lastSuccessLaunched: {
        date: "2022-04-01 13:10:00",
        idle: "92",
      },
    },
    {
      signature: "aggregation:content_dashboard",
      project: "serpinsider",
      lastSuccessLaunched: {
        date: "2022-04-05 10:03:45",
        idle: "0",
      },
    },
    {
      signature: "aparser:history",
      project: "serpinsider",
      lastSuccessLaunched: {
        date: "2022-03-31 16:57:50",
        idle: "113",
      },
    },
    {
      signature: "aparser:pack",
      project: "serpinsider",
      lastSuccessLaunched: {
        date: "2022-04-05 10:04:53",
        idle: "0",
      },
    },
    {
      signature: "aparser:result_preset ActualUrl",
      project: "serpinsider",
      lastSuccessLaunched: {
        date: "2022-04-05 10:04:53",
        idle: "0",
      },
    },
    {
      signature: "aparser:result_preset ActualUrl 15951232",
      project: "serpinsider",
      lastSuccessLaunched: {
        date: "2022-04-01 13:10:00",
        idle: "92",
      },
    },
  ],
};

export const PRESETS_TABLE_CHART_OPTIONS = {
  chart: {
    width: "100%",
    toolbar: { show: false },
    tools: { zoom: false },
    type: "line",
    zoom: {
      enabled: false,
    },
  },
  animations: {
    enabled: true,
  },
  tooltip: {
    custom: function ({ series, seriesIndex, dataPointIndex }) {
      return `<div class="custom-tooltip">${series[seriesIndex][dataPointIndex]} rows</div>`;
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
    width: 1,
  },
  grid: {
    xaxis: {
      lines: { show: false },
    },
    yaxis: { lines: { show: false } },
  },
  yaxis: { show: false },
  xaxis: {
    tooltip: {
      enabled: false,
    },
  },
};

export const PRESETS_TABLE_ERROR_CHART_OPTIONS = {
  colors: ["#cb0f14"],
  chart: {
    width: "100%",
    toolbar: { show: false },
    tools: { zoom: false },
    type: "line",
    zoom: {
      enabled: false,
    },
  },
  animations: {
    enabled: false,
  },
  tooltip: {
    custom: function ({ series, seriesIndex, dataPointIndex }) {
      return `<div class="custom-tooltip">${series[seriesIndex][dataPointIndex]} rows</div>`;
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
    width: 1,
  },
  grid: {
    xaxis: {
      lines: { show: false },
    },
    yaxis: { lines: { show: false } },
  },
  yaxis: { show: false },
  xaxis: {
    tooltip: {
      enabled: false,
    },
  },
};

export const TIME_CHART_DATA = [
  {
    name: "Desktops",
    data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
  },
  {
    name: "Mobile",
    data: [10, 22, 1, 13, 52, 2, 2, 123, 148],
  },
];

export const INFO_CARDS_DATA = [
  {
    id: 0,
    label: "All",
    value: 106693,
  },
  {
    id: 1,
    label: "Last 7 days",
    value: 303,
  },
  {
    id: 2,
    label: "Last 30 days",
    value: 991,
  },
  {
    id: 3,
    label: "Last 90 days",
    value: 4315,
  },
];

// hour: 0
// name: "Export Errors"
// queue: 0
// responsible: "vladyslav.talasimov@boosta.co"
// service: "Export"
export const DEV_MONITOR_ACTIONS_DATA = {
  headers: [
    { text: "Status", value: "_status" },
    { text: "WS", value: "worksection", width: "45px" },
    { text: "Hour", value: "hour" },
    { text: "Day", value: "day" },
    { text: "Service", value: "service" },
    { text: "Name", value: "name" },
    { text: "Queue", value: "queue" },
    { text: "Responsible", value: "responsible" },
    { text: "Prior", value: "priority" },
    { text: "Service Prior", value: "service_priority" },
    { text: "Type", value: "type" },
    { text: "Updated", value: "updated_at" },
    { text: "Snooze Until", value: "snooze_until" },
  ],
};

export const DOMAIN_TABLE_DATA = {
  headers: [
    { text: "Parameter", value: "name" },
    { text: "Count domains", value: "v" },
    { text: "Percent", value: "p" },
  ],
  items: [
    {
      parameter: "Ahrefs Domain Rating",
      countDomains: "5468",
      percent: "11",
    },
    {
      parameter: "Ahrefs Metrics Extended",
      countDomains: "5933",
      percent: "12",
    },
    {
      parameter: "Ahrefs Positions Metrics",
      countDomains: "5555",
      percent: "11",
    },
    {
      parameter: "Alexa",
      countDomains: "16241",
      percent: "33",
    },
    {
      parameter: "Similar Web",
      countDomains: "37392",
      percent: "77",
    },
    {
      parameter: "Whois",
      countDomains: "6848",
      percent: "8",
    },
    {
      parameter: "Google Trust",
      countDomains: "9371",
      percent: "19",
    },
  ],
};

export const ONE_YEAR_DATA_TABLE_HEADERS = [
  {
    text: "Phrase",
    sortable: true,
    value: "phrase",
  },
  {
    text: "Pr",
    sortable: true,
    value: "priority",
    width: "65px",
  },
  {
    text: "Volume",
    align: "center",
    sortable: true,
    value: "volume",
    width: "65px",
  },
  {
    text: "Cl",
    align: "center",
    sortable: true,
    value: "clicks",
    width: "65px",
  },
  {
    text: "Imp",
    align: "center",
    sortable: true,
    value: "impressions",
    width: "65px",
  },
  {
    text: "KD",
    align: "center",
    sortable: true,
    value: "kd",
    width: "65px",
  },
  {
    text: "Comp",
    align: "center",
    sortable: true,
    value: "comp",
    width: "65px",
  },
  {
    text: "t",
    align: "center",
    sortable: true,
    value: "t",
    width: "65px",
  },
  {
    text: "y",
    align: "center",
    sortable: true,
    value: "y",
    width: "65px",
  },
  {
    text: "1w",
    align: "center",
    sortable: true,
    value: "_1w",
    width: "65px",
  },
  {
    text: "2w",
    align: "center",
    sortable: true,
    value: "_2w",
    width: "65px",
  },
  {
    text: "3w",
    align: "center",
    sortable: true,
    value: "_3w",
    width: "65px",
  },
  {
    text: "1m",
    align: "center",
    sortable: true,
    value: "_1m",
    width: "65px",
  },
  {
    text: "2m",
    align: "center",
    sortable: true,
    value: "_2m",
    width: "65px",
  },
  {
    text: "3m",
    align: "center",
    sortable: true,
    value: "_3m",
    width: "65px",
  },
  {
    text: "6m",
    align: "center",
    sortable: true,
    value: "_6m",
    width: "65px",
  },
  {
    text: "9m",
    align: "center",
    sortable: true,
    value: "_9m",
    width: "65px",
  },
  {
    text: "12m",
    align: "center",
    sortable: true,
    value: "_12m",
    width: "65px",
  },
  {
    text: "18m",
    align: "center",
    sortable: true,
    value: "_18m",
    width: "65px",
  },
  {
    text: "24m",
    align: "center",
    sortable: true,
    value: "_24m",
    width: "65px",
  },
];
export const ONE_YEAR_LINKS_TABLE_HEADERS = [
  {
    text: "Type",
    sortable: true,
    value: "name",
  },
  {
    text: "PC",
    sortable: true,
    value: "pc",
    align: "center",
    width: "65px",
  },
  {
    text: "PN",
    sortable: true,
    value: "pn",
    align: "center",
    width: "65px",
  },
  {
    text: "AP",
    sortable: true,
    value: "ap",
    align: "center",
    width: "65px",
  },
  {
    text: "C",
    sortable: true,
    value: "ca",
    align: "center",
    title: "Commercial",
    width: "65px",
  },
  {
    text: "B",
    align: "center",
    sortable: true,
    value: "b",
    title: "Brand",
    width: "65px",
  },
  {
    text: "G",
    align: "center",
    sortable: true,
    value: "ga",
    title: "Generic",
    width: "65px",
  },
  {
    text: "U/D",
    align: "center",
    sortable: true,
    value: "du",
    title: "Url-Domain",
    width: "65px",
  },
  {
    text: "O",
    align: "center",
    sortable: true,
    value: "o",
    title: "Other",
    width: "65px",
  },
  {
    text: "All",
    align: "center",
    sortable: true,
    value: "a",
    width: "65px",
  },
  {
    text: "t",
    align: "center",
    sortable: true,
    value: "dt",
    width: "65px",
  },
  {
    text: "1w",
    align: "center",
    sortable: true,
    value: "d1w",
    width: "65px",
  },
  {
    text: "2w",
    align: "center",
    sortable: true,
    value: "d2w",
    width: "65px",
  },
  {
    text: "3w",
    align: "center",
    sortable: true,
    value: "d3w",
    width: "65px",
  },
  {
    text: "1m",
    align: "center",
    sortable: true,
    value: "d1m",
    width: "65px",
  },
  {
    text: "2m",
    align: "center",
    sortable: true,
    value: "d2m",
    width: "65px",
  },
  {
    text: "3m",
    align: "center",
    sortable: true,
    value: "d3m",
    width: "65px",
  },
  {
    text: "6m",
    align: "center",
    sortable: true,
    value: "d6m",
    width: "65px",
  },
  {
    text: "9m",
    align: "center",
    sortable: true,
    value: "d9m",
    width: "65px",
  },
  {
    text: "12m",
    align: "center",
    sortable: true,
    value: "d12m",
    width: "65px",
  },
  {
    text: "18m+",
    align: "center",
    sortable: true,
    value: "d18m",
    width: "65px",
  },
];

export const MOCK_SELECT_ITEMS = {
  clusters: [
    "no deposit 20 eur",
    "catalog 30 usd",
    "casino free search",
    "find easy way to cash",
  ],
  urls: [
    "casinotest.com/no-deposit",
    "free-roll.com/faq",
    "money777.com/deposit",
  ],
  tags: ["free", "money", "deposit", "dollar", "euro", "casino"],
  priority: ["First", "Second", "Third"],
};

export const POSITIONS_TABLE_DATA = {
  headers: [
    {
      id: 0,
      text: "Cluster",
      value: "cluster",
      active: true,
    },
    {
      id: 1,
      text: "Kws",
      value: "kws",
      active: true,
    },
    {
      id: 2,
      text: "URL",
      value: "url",
      active: true,
    },
    {
      id: 3,
      text: "Priority",
      value: "priority",
      active: true,
    },
    {
      id: 4,
      text: "Volume",
      value: "volume",
      active: true,
    },
    {
      id: 5,
      text: "Tags",
      value: "tags",
      active: true,
    },
    {
      id: 6,
      text: "Target top",
      value: "target_top",
      active: true,
    },
    {
      id: 7,
      text: "Trend",
      value: "trend",
      active: true,
    },
    {
      id: 8,
      text: "Date1",
      value: "date1",
    },
    {
      id: 9,
      text: "Date2",
      value: "date2",
    },
  ],
  items: [
    {
      cluster: "Ahrefs Domain Rating",
      kws: "5468",
      url: "11",
      priority: "11",
      volume: "11",
      tags: "11",
      target_top: "11",
      trend: "11",
      date1: "11",
      date2: "11",
    },
  ],
};

export const WEEK_REPORT_TABLE_HEADERS = [
  {
    text: "Project",
    title: "Project name",
    value: "project_name",
    class: "text-center",
    width: "125px",
  },
  {
    text: "Task",
    title: "Task name",
    value: "name",
    class: "text-center",
    width: "400px",
  },
  {
    text: "Prior",
    title: "Priority",
    value: "priority",
    class: "text-center",
    align: "center",
  },
  {
    text: "D",
    title: "Deadline",
    value: "finish",
    class: "text-center",
    align: "center",
  },
  {
    text: "Today",
    title: "Today",
    value: "today",
    class: "text-center",
    align: "center",
  },
  {
    text: "Last 14d",
    title: "Last 14 days activity",
    value: "d_arr",
    class: "text-center",
    align: "center",
  },
  {
    text: "Time",
    title: "Week",
    value: "week",
    class: "text-center",
    align: "center",
  },
  {
    text: "E",
    title: "Estimate",
    value: "max_time",
    class: "text-center",
    align: "center",
  },
  {
    text: "Exec",
    title: "Executive",
    value: "executive",
    class: "text-center",
    align: "center",
  },
  {
    text: "M",
    title: "Monthly",
    value: "monthly",
    class: "text-center",
    align: "center",
    width: "75px",
  },
  {
    text: "Prior Proj",
    title: "Priority project",
    value: "priority_project",
    class: "text-center",
    align: "center",
  },
  {
    text: "Done",
    title: "Is Done",
    value: "status",
    class: "text-center",
    width: "50px",
  },
];

export const DEFAULT_MENU_PROPS = {
  maxHeight: "400",
  rounded: "lg",
  transition: "slide-y-transition",
  contentClass: "shadow-e2-bordered remove-scrollbar",
};

export const CURRENT_ITEMS_INTERFACE = {
  type: "",
  subproj: "",
  page: "",
  amount: {
    pages: null,
    pagesWithElems: null,
  },
  todo: {
    pages: null,
    pagesWithElems: null,
  },
  in_progress: {
    pages: null,
    pagesWithElems: null,
  },
  ctb_ta_cw: {
    pages: null,
    pagesWithElems: null,
  },
  wfi: {
    pages: null,
    pagesWithElems: null,
  },
  done: {
    pages: null,
    pagesWithElems: null,
  },
  "1w": {
    pages: null,
    pagesWithElems: null,
  },
  "2w": {
    pages: null,
    pagesWithElems: null,
  },
  "3w": {
    pages: null,
    pagesWithElems: null,
  },
  "4w": {
    pages: null,
    pagesWithElems: null,
  },
};

// anchors
// clicks
// code
// crawled_at
// depth
// desc_length
// first_seen
// id
// impressions
// is_ignored
// kc_keys
// links
// need_top
// ni
// page_type
// priority
// title_length
// uniq_anchors
// uniq_link
// url
// url_length

export const ANCHOR_INFO_BY_PAGES_TABLE_HEADERS = [
  { text: "Url", value: "url" },
  { text: "Anchor", value: "phrase" },
  { text: "SA", value: "sa", align: "center" },
  { text: "A", value: "a", align: "center" },
  { text: "NA", value: "na", align: "center" },
  { text: "PA", value: "pa", align: "center" },
  { text: "D", value: "depth", align: "center" },
  { text: "Uniq Link", value: "uniq_link", align: "center" },
  { text: "L", value: "links", align: "center" },
  { text: "URL L", value: "url_length", align: "center" },
  { text: "Pr", value: "priority", align: "center" },
  { text: "Cl", value: "clicks", align: "center" },
  { text: "Imp", value: "impressions", align: "center" },
  { text: "KC Keys", value: "kc_keys", align: "center" },
  { text: "Page Type", value: "page_type" },
  { text: "Code", value: "code", align: "center" },
  { text: "Anchors", value: "anchors", align: "center" },
  { text: "NI", value: "ni", align: "center" },
  { text: "Desc Length", value: "desc_length", align: "center" },
  { text: "Is Ignored", value: "is_ignored", align: "center" },
  { text: "Title Length", value: "title_length", align: "center" },
  { text: "Crawled At", value: "crawled_at", align: "center" },
  { text: "First Seen", value: "first_seen", align: "center" },
];

export const DASHBOARDS_CONTENT_TABLE_HEADERS = [
  { text: "Url", value: "url", align: "left" },
  { text: "SW P deadline", value: "workplace_deadline", align: "left" },
  { text: "Url L", value: "url_length", align: "center" },
  { text: "T L", value: "title_l", align: "center" },
  { text: "T", value: "title", align: "center" },
  { text: "T N", value: "title_normalize", align: "center" },
  { text: "D L", value: "desc_l", align: "center" },
  { text: "D N", value: "desc_normalize", align: "center" },
  { text: "H1 N", value: "h1_normalize", align: "center" },
  { text: "Top Key", value: "top_key", align: "center" },
  { text: "Int L", value: "internal_links", align: "center" },
  { text: "Un A", value: "unique_anchors", align: "center" },
  { text: "#keys", value: "keys", align: "center" },
  { text: "NT", value: "need_top", align: "center" },
  { text: "Pr", value: "priority", align: "center" },
];

export const TECHNICAL_TABLE_HEADERS = [
  { text: "Url", tooltip: "Url", value: "url", align: "left" },
  { text: "C", tooltip: "Code", value: "code", align: "center", width: "50px" },
  {
    tooltip: "first_contentful_paint",
    text: "FCP",
    value: "first_contentful_paint",
    align: "center",
  },
  {
    tooltip: "time_to_interactive",
    text: "TTI",
    value: "time_to_interactive",
    align: "center",
  },
  { tooltip: "speed_index", text: "SI", value: "speed_index", align: "center" },
  {
    tooltip: "total_blocking_time",
    text: "TBT",
    value: "total_blocking_time",
    align: "center",
  },
  {
    tooltip: "largest_contentful_paint",
    text: "LCP",
    value: "largest_contentful_paint",
    align: "center",
  },
  {
    tooltip: "cumulative_layout_shift",
    text: "CLS",
    value: "cumulative_layout_shift",
    align: "center",
  },
  {
    tooltip: "first_contentful_paint_28",
    text: "FCP_28",
    value: "first_contentful_paint_28",
    align: "center",
  },
  {
    tooltip: "first_input_delay_28",
    text: "FIP_28",
    value: "first_input_delay_28",
    align: "center",
  },
  {
    tooltip: "largest_contentful_paint_28",
    text: "LCP_28",
    value: "largest_contentful_paint_28",
    align: "center",
  },
  {
    tooltip: "cumulative_layout_shift_28",
    text: "CLS_28",
    value: "cumulative_layout_shift_28",
    align: "center",
  },
  //
  {
    text: "performance",
    tooltip: "performance",
    value: "performance",
    align: "center",
  },
  {
    text: "accessibility",
    tooltip: "accessibility",
    value: "accessibility",
    align: "center",
  },
  {
    text: "best_practices",
    tooltip: "best_practices",
    value: "best_practices",
    align: "center",
  },
  { text: "seo", tooltip: "seo", value: "seo", align: "center" },
  { text: "pwa", tooltip: "pwa", value: "pwa", align: "center" },
  { text: "NT", tooltip: "Need Top", value: "need_top", align: "center" },
  { text: "Pr", tooltip: "Priority", value: "priority", align: "center" },
];

export const TOP_5_TABLE_HEADERS = [
  { text: "Url", value: "url", align: "left" },
  { text: "Cl", value: "cl", align: "center" },
  { text: "Imp", value: "imp", align: "center" },
  { text: "#top1", value: "phrases_top_1", align: "center" },
  { text: "#top3", value: "phrases_top_3", align: "center" },
  { text: "#top5", value: "phrases_top_5", align: "center" },
  { text: "Title", value: "title", align: "center" },
  { text: "Desc", value: "description", align: "center" },
  { text: "T of C", value: "table_of_contents", align: "center" },
  { text: "FAQ", value: "faq", align: "center" },
  { text: "Intro", value: "introduction", align: "center" },
  { text: "Table", value: "table", align: "center" },
  { text: "T Cas", value: "table_casinos", align: "center" },
  { text: "Page Type", value: "page_type", align: "center" },
  { text: "Pr", value: "prio", align: "center" },
];

export const MANAGERS_GPM_TABLE_HEADERS = [
  {
    text: "URL",
    value: "url",
    tooltip: "URL",
    width: "220px",
  },
  {
    text: "Anchor ",
    value: "anchor",
    tooltip: "In degree: how many times used this ANCHOR for the current URL",
  },
  {
    text: "Link Type ",
    value: "link_type",
  },
  {
    text: "PA ",
    value: "pos_by_anchor",
    tooltip: "Anchor Position",
    align: "center",
  },
  {
    text: "PTK ",
    value: "pos_by_key",
    tooltip: "Top Key Position",
    align: "center",
  },
  {
    text: "Cost ",
    value: "price",
    tooltip: "In degree: price lower than current in previously publication",
    align: "center",
  },
  {
    text: "Cost per link",
    value: "price_link",
    align: "center",
  },
  {
    text: "Donor Domain",
    value: "donor",
    align: "center",
    tooltip:
      "<u>In degree:</u> how many times used this DONOR DOMAIN for the current site. <br/> <u>Orange:</u> Have friendly link. <br/> <u>Blue:</u> Amount of used links. <br/> <br/> <u>In chip:</u> Donors on step 'Approving placement by SEO'",
  },
  {
    text: "WR",
    value: "wm_response_at",
    tooltip: "Webmaster's reply",
    align: "center",
  },
  {
    text: "TPA",
    value: "tech_task_appear",
    tooltip: "Topic Approval",
    align: "center",
  },
  {
    text: "TA",
    value: "theme_approve_at",
    tooltip: "TA are created",
    align: "center",
  },
  {
    text: "MK",
    value: "main_keywords",
    tooltip: "Num of main keywords",
    align: "center",
  },
  {
    text: "CR",
    value: "content_ready_at",
    tooltip: "Content is ready",
    align: "center",
  },
  {
    text: "CNT",
    value: "content_appear_at",
    tooltip: "Content are created",
    align: "center",
  },
  {
    text: "PBL",
    value: "on_site_appear",
    tooltip: "Published on Site",
    align: "center",
  },
  {
    text: "MNG",
    value: "outreach_manager_approve",
    tooltip: "Manager Approve",
    align: "center",
  },
  {
    text: "LST",
    value: "last_activity_at",
    tooltip: "Last activity",
    align: "center",
  },
  {
    text: "1st",
    value: "first_msg_at",
    tooltip: "Send 1st message",
    align: "center",
  },
  {
    text: "AHDR",
    value: "ah_dr",
    tooltip: "AHDR",
    align: "center",
  },
  {
    text: "AHTraffic",
    value: "ah_traffic",
    tooltip: "AHTraffic",
    align: "center",
  },
  {
    text: "Current Step",
    value: "step_name",
    tooltip: "Current Step",
  },
  {
    text: "TC",
    value: "task_closed",
    tooltip: "Task Closed",
  },
  {
    text: "CD ",
    value: "created_at",
    tooltip: "Create anchor+url Date",
  },
  {
    text: "M",
    value: "month",
    tooltip: "Expected Month",
  },
  {
    text: "NT",
    value: "need_top",
    tooltip: "Need Top by SiteWorkplace",
    align: "center",
  },
  {
    text: "UP",
    value: "priority_url",
    tooltip: "URL priority by SiteWorkplace",
    align: "center",
  },
  {
    text: "K",
    value: "keitaro_click",
    tooltip: "Keitaro Click",
    align: "center",
  },
  {
    text: "L",
    value: "keitaro_lead",
    tooltip: "Keitaro Lead",
    align: "center",
  },
  {
    text: "DP",
    value: "priority_domain",
    tooltip: "DOMAIN Priority by Global System Priority",
    align: "center",
  },
  {
    text: "Responsible",
    value: "responsible",
    tooltip: "Responsible",
  },
  {
    text: "Performer",
    value: "performer",
    tooltip: "Performer",
  },
  {
    text: "Task Id",
    value: "gpm_task_id",
    tooltip: "Gpm Task Id",
    align: "center",
  },
  {
    text: "Current Status",
    value: "current_status",
    align: "end",
  },
];

export const DEFAULT_DBC_TABLE_HEADERS = [
  {
    text: "Name",
    value: "user",
  },
  {
    text: "1 Step",
    value: "1_step",
  },
  {
    text: "Checked",
    value: "checked",
  },
  {
    text: "2 Check",
    value: "2_check",
  },
  {
    text: "Added",
    value: "added",
  },
  {
    text: "In Work",
    value: "in_work",
  },
  {
    text: "%",
    value: "percent",
  },
  {
    text: "Good",
    value: "good",
  },
];

export const GP_TRACKER_TABLE_DATA = {
  headers: [
    { text: "Url", value: "url" },
    { text: "Acceptor", value: "acceptor" },
    { text: "Traffic", value: "traffic" },
    { text: "Keywords", value: "kw" },
    { text: "1-3", value: "kw_1_3" },
    { text: "3-10", value: "kw_3_10" },
    { text: "10-100", value: "kw_10_100" },
    { text: "Main KW", value: "main_kw" },
    { text: "Main KW Pos", value: "main_kw_pos" },
    { text: "Web Internal Pos", value: "web_internal_pos" },
    { text: "Anchor Pos", value: "anchor_pos" },
    { text: "Highest traffic keyword", value: "highest_traffic_kw" },
    { text: "Reffered Domains", value: "reffered_domains" },
    { text: "UR", value: "ur" },
    { text: "2nd lvl links", value: "second_lvl_links" },
    { text: "Price", value: "price" },
    { text: "Date Added", value: "created_at" },
    { text: "Date Updated", value: "updated_at" },
    { text: "Author", value: "author" },
  ],
};

export const DEFAULT_MAN_OUTREACH_PROBLEMS_TABLE_DATA = {
  headers: [
    {
      text: "",
      value: "name",
      sortable: false,
    },
    {
      text: "",
      value: "category",
      sortable: false,
    },
    {
      text: "8-14.05",
      value: "1w",
      sortable: false,
      align: "center",
    },
    {
      text: "1-7.05",
      value: "2w",
      sortable: false,
      align: "center",
    },
    {
      text: "21-28.04",
      value: "3w",
      sortable: false,
      align: "center",
    },
  ],
  items: [
    {
      name: "Theme",
      "1w": {
        errors: 10,
        donors: 2,
      },
      "2w": {
        errors: 15,
        donors: 6,
      },
      "3w": {
        errors: 40,
        donors: 12,
      },
    },
    {
      name: "",
      "1w": {
        errorsPercent: 10,
      },
      "2w": {
        errorsPercent: 60,
      },
      "3w": {
        errorsPercent: 50,
      },
    },
    {
      name: "",
      "1w": {
        donorsCurWeek: 20,
      },
      "2w": {
        donorsCurWeek: 10,
      },
      "3w": {
        donorsCurWeek: 24,
      },
    },
    {
      name: "Problem 1",
      "1w": {
        errors: 6,
        donors: 1,
      },
      "2w": {},
      "3w": {},
    },
    {
      name: "Problem 2",
      "1w": {
        errors: 3,
        donors: 2,
      },
      "2w": {},
      "3w": {},
    },
    {
      name: "Problem 3",
      "1w": {
        errors: 1,
        donors: 1,
      },
      "2w": {},
      "3w": {},
    },
  ],
};

const linksDynamicData = {
  table: {
    headers: [
      { text: "Url", value: "url" },
      { text: "Vision", value: "vision" },
      { text: "Total All", value: "total_all" },
      { text: "Total Period", value: "total_period" },
      { text: "Jan`21", value: "1m" },
      { text: "Feb`21", value: "2m" },
      { text: "Mar`21", value: "3m" },
      { text: "Apr`21", value: "4m" },
      { text: "May`21", value: "5m" },
      { text: "Jun`21", value: "6m" },
      { text: "Jul`21", value: "7m" },
      { text: "Aug`21", value: "8m" },
      { text: "Sep`21", value: "9m" },
      { text: "Oct`21", value: "10m" },
      { text: "Nov`21", value: "11m" },
      { text: "Dec`21", value: "12m" },
    ],
    items: [
      {
        url: "https://apexcharts.com/",
        ...getFields(),
      },
      {
        url: "http://localhost:8080/",
        ...getFields(),
      },
      {
        url: "https://www.google.com/",
        ...getFields(),
      },
    ],
  },
  chart: {
    animations: {
      enabled: false,
    },
    series: [
      {
        name: "https://apexcharts.com/",
        data: [123, 230, 511, 540, 570, 610, 673, 790, 800, 823, 871],
      },
      {
        name: "http://localhost:8080/",
        data: [12, 130, 145, 178, 199, 220, 229, 247, 281, 290, 310],
      },
      {
        name: "https://www.google.com/",
        data: [300, 310, 312, 319, 420, 444, 410, 490, 499, 510, 955],
      },
    ],
  },
};

export const LINKS_DYNAMIC_DATA_MOCK = [
  {
    ...linksDynamicData,
    sectionName: "All links",
  },
  {
    ...linksDynamicData,
    sectionName: "GP Links",
  },
  {
    ...linksDynamicData,
    sectionName: "MainPage",
  },
];

export const LINK_TYPES_CHART_OPTIONS = {
  chart: {
    type: "line",
    stacked: true,
  },
  yaxis: {
    show: true,
    axisBorder: {
      show: true,
    },
    crosshairs: {
      show: true,
    },
    labels: {
      show: true,
    },
  },
  grid: {
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
};

export const COMPETITOR_URL_CHART_OPTIONS = {
  chart: {
    height: 250,
    type: "line",
    zoom: {
      enabled: false,
    },
    animations: {
      enabled: false,
    },
  },
  markers: {
    size: 5,
  },
  tooltip: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
  },
  xaxis: {},
};

export const LINKS_DYNAMIC_CHART_OPTIONS = {
  chart: {
    height: 250,
    type: "line",
    zoom: {
      enabled: false,
    },
    animations: {
      enabled: false,
    },
  },
  markers: {
    size: 5,
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
  },
  legend: {
    position: "left",
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
  },
  xaxis: {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
  },
};

export const SERP_DYNAMIC_CHART_OPTIONS = {
  chart: {
    height: 350,
    type: "line",
    zoom: {
      enabled: false,
    },
    animations: {
      enabled: false,
    },
  },
  animations: {
    enabled: false,
  },
  markers: {
    size: 5,
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
  legend: {
    position: "left",
  },
  stroke: {
    curve: "straight",
  },
  title: {
    text: "Dynamic",
    align: "left",
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
  },
  xaxis: {
    categories: ["365d", "132d", "68d", "44d", "21d", "14d", "7d", "1d"],
  },
};

export const SERP_DYNAMIC_MOCK = {
  chart: {
    animations: {
      enabled: false,
    },
    series: [
      {
        name: "URL 1",
        data: randomArray(8, 1000),
      },
      {
        name: "URL 2",
        data: randomArray(8, 1000),
      },
      {
        name: "URL 3",
        data: randomArray(8, 1000),
      },
      {
        name: "URL 4",
        data: randomArray(8, 1000),
      },
      {
        name: "URL 5",
        data: randomArray(8, 1000),
      },
    ],
  },
  domains: {
    headers: [
      { text: "url", value: "url" },
      { text: "traffic", value: "traffic" },
    ],
    items: [...getDomainItem(100)],
  },
  second_domains: {
    headers: [
      { text: "url", value: "url" },
      { text: "pos", value: "pos" },
    ],
    items: [...getDomainItemWithCount(100)],
  },
  domainsChart: {
    series: [
      {
        name: "imdb.com",
        data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      },
      {
        name: "wikipedia.org",
        data: [2, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      },
      {
        name: "[ People Also Ask ]",
        data: [3, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      },
      {
        name: "instagram.com",
        data: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
      },
      {
        name: "instagram.com",
        data: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
      },
      {
        name: "fandom.com",
        data: [6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
      },
      {
        name: "facebook.com",
        data: [7, 7, 7, 7, 7, 7, 8, 7, 7, 7],
      },
      {
        name: "tvtropes.org",
        data: [8, 8, 8, 8, 8, 8, 7, 8, 8, 8],
      },
      {
        name: "pinterest.com",
        data: [9, 13, 13, 14, 17, 20, 17, 13, 13, 13],
      },
      {
        name: "tvguide.com",
        data: [10, 9, 9, 9, 9, 9, 15, 10, 9, 9],
      },
      {
        name: "rottentomatoes.com",
        data: [12, 12, 12, 12, 13, 12, 10, 12, 12, 12],
      },
      {
        name: "usmagazine.com",
        data: [17, null, null, null, 18, 18, 17, 13, 13, 13],
      },
    ],
  },
};

export const SERP_DYNAMIC_DOMAINS_CHART_OPTIONS = {
  chart: {
    height: 600,
    type: "line",
    zoom: {
      enabled: false,
    },
  },
  animations: {
    enabled: false,
  },
  markers: {
    size: 5,
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
  },
  title: {
    text: "Dynamic",
    align: "left",
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
  },
  legend: {
    position: "left",
  },
  xaxis: {
    categories: [
      "May 05 2022",
      "May 06 2022",
      "May 07 2022",
      "May 08 2022",
      "May 09 2022",
      "May 10 2022",
      "May 11 2022",
      "May 12 2022",
      "May 13 2022",
      "May 14 2022",
    ],
  },
};

export const SIMPLE_TABLE_MOCK = {
  headers: [
    { text: "GP", value: "gp" },
    { text: "Main PAge", value: "main_page" },
  ],
  subHeaders: [
    { text: "Commercial", value: "commercial" },
    { text: "Domain/url", value: "domain_url" },
    { text: "Brand", value: "brand" },
    { text: "Generic", value: "generic" },
    { text: "Other", value: "Other" },
  ],
  items: [
    [
      "https://ra.worksection.com",
      "1",
      "2",
      "3",
      "4",
      "5",
      "1.2",
      "2.2",
      "3.2",
      "4.2",
      "5.2",
    ],
    [
      "https://vuetifyjs.com",
      "1",
      "2",
      "3",
      "4",
      "5",
      "1.2",
      "2.2",
      "3.2",
      "4.2",
      "5.2",
    ],
  ],
};

function getDomainItem(count) {
  const output = [];

  for (let i = 0; i <= count; i++) {
    output.push({
      domain: loremIpsum({ units: "word", count: 3 }),
      vision: rand(0, 1000),
    });
  }
  return output;
}

function getDomainItemWithCount(count) {
  const output = [];

  for (let i = 0; i <= count; i++) {
    output.push({
      domain: loremIpsum({ units: "word", count: 3 }),
      pos: i + 1,
    });
  }
  return output;
}

function getFields() {
  return {
    vision: rand(0, 300),
    total_all: rand(0, 300),
    total_period: rand(0, 300),
    "1m": rand(0, 300),
    "2m": rand(0, 300),
    "3m": rand(0, 300),
    "4m": rand(0, 300),
    "5m": rand(0, 300),
    "6m": rand(0, 300),
    "7m": rand(0, 300),
    "8m": rand(0, 300),
    "9m": rand(0, 300),
    "10m": rand(0, 300),
    "11m": rand(0, 300),
    "12m": rand(0, 300),
  };
}

function randomArray(length, max) {
  return Array.apply(null, Array(length)).map(function () {
    return Math.round(Math.random() * max);
  });
}

function rand(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

export const DEFAULT_LINKS_MONITOR_HEADERS = [
  { text: "Date", value: "dateadd" },
  { text: "Donor", value: "donor" },
  { text: "Acceptor", value: "acceptor" },
  { text: "Url", value: "url" },
  { text: "Anchor", value: "anchor" },
];

export const DELETED_LINKS_STATUS_COLORS = {
  SENT_TO_WM: {
    classes: "mark mark--warning",
    code: 1,
  },
  IGNORE: {
    classes: "mark mark--info",
    code: 2,
  },
  CANT_RESTORE: {
    classes: "mark mark--error",
    code: 3,
  },
  REFUNDED: {
    classes: "mark mark--success",
    code: 4,
  },
  RESTORE: {
    classes: "mark mark--success",
    code: 5,
  },
  TROUBLE: {
    classes: "mark mark--warning",
    code: 6,
  },
};

export const DEFAULT_LTT_DELETED_LINKS_TABLE_DATA = {
  headers: [
    { text: "...", value: "_actions", width: "35px", sortable: false },
    { text: "Donor", value: "donor" },
    { text: "Acceptor", value: "acceptor" },
    { text: "Phrase", value: "phrase" },
    { text: "Code", value: "server_response" },
    { text: "OP", value: "on_page" },
    { text: "NI", value: "noindex" },
    { text: "NF", value: "nofollow" },
    { text: "C", value: "canonical" },
    { text: "Date Add", value: "dateadd" },
    { text: "Performer", value: "performer" },
    { text: "Status", value: "status_id" },
    { text: "Email", value: "email" },
    { text: "S Date", value: "status_date" },
    { text: "$", value: "price", width: 150 },
  ],
  items: [
    {
      donor: "1",
      acceptor: "",
      code: "",
      op: "",
      ni: "",
      nf: "",
      c: "",
      created_at: "",
      performer: "",
      other: "",
      ls_link: "",
      checked: false,
    },
    {
      donor: "2",
      acceptor: "",
      code: "",
      op: "",
      ni: "",
      nf: "",
      c: "",
      created_at: "",
      performer: "",
      other: "",
      ls_link: "",
      checked: false,
    },
  ],
};

export const NEW_LINKS_DYNAMIC_DASHBOARD_PRESETS = {
  ahDrPresets: [
    ["20-100", [20, 100]],
    ["40-100", [40, 100]],
    ["60-100", [60, 100]],
    ["80-100", [80, 100]],
    ["0-20", [0, 20]],
    ["0-40", [0, 40]],
  ],
  ahUrlTrafficPresets: [
    ["1-20", [1, 20]],
    ["1-100", [1, 100]],
    ["100-1000", [100, 1000]],
    ["1000+", [1000, -1]],
  ],
  ah2ndLevelPresets: [
    ["1-10", [1, 10]],
    ["1-50", [1, 50]],
    ["50-200", [50, 200]],
    ["100-1000", [100, 1000]],
  ],
  swRankPresets: [
    ["1-10000", [1, 10000]],
    ["1-500000", [1, 500000]],
    ["1-1000000", [1, 1000000]],
    ["1000000+", [1000000, -1]],
  ],
  numOfPagesPresets: [
    ["1-1000", [1, 1000]],
    ["1-5000", [1, 5000]],
    ["1-10000", [1, 10000]],
  ],
  customDatePresets: [
    {
      id: uid(),
      name: "Last 200 days",
      range: [
        moment()
          .subtract(200, "day")
          .startOf("day")
          .format(DEFAULT_MOMENT_DATE_FORMAT),
        moment().format(DEFAULT_MOMENT_DATE_FORMAT),
      ],
    },
    {
      id: uid(),
      name: "Last 390 days",
      range: [
        moment()
          .subtract(390, "day")
          .startOf("day")
          .format(DEFAULT_MOMENT_DATE_FORMAT),
        moment().format(DEFAULT_MOMENT_DATE_FORMAT),
      ],
    },
  ],
};

export const LINKS_DYNAMIC_DASHBOARD_PRESETS = {
  ahDrPresets: [
    ["20-100", [20, 100]],
    ["40-100", [40, 100]],
    ["60-100", [60, 100]],
    ["80-100", [80, 100]],
    ["0-20", [0, 20]],
    ["0-40", [0, 40]],
  ],
  ahUrlTrafficPresets: [
    ["1-20", [1, 20]],
    ["1-100", [1, 100]],
    ["100-1000", [100, 1000]],
    ["1000-10000", [1000, 10000]],
  ],
  ah2ndLevelPresets: [
    ["1-10", [1, 10]],
    ["1-50", [1, 50]],
    ["50-200", [50, 200]],
    ["100-1000", [100, 1000]],
  ],
  swRankPresets: [
    ["1-10000", [1, 10000]],
    ["1-500000", [1, 500000]],
    ["1-1000000", [1, 1000000]],
    ["1000000-3000000", [1000000, 3000000]],
  ],
  numOfPagesPresets: [
    ["1-1000", [1, 1000]],
    ["1-5000", [1, 5000]],
    ["1-10000", [1, 10000]],
  ],
  customDatePresets: [
    {
      id: uid(),
      name: "Last 200 days",
      range: [
        moment()
          .subtract(200, "day")
          .startOf("day")
          .format(DEFAULT_MOMENT_DATE_FORMAT),
        moment().format(DEFAULT_MOMENT_DATE_FORMAT),
      ],
    },
    {
      id: uid(),
      name: "Last 390 days",
      range: [
        moment()
          .subtract(390, "day")
          .startOf("day")
          .format(DEFAULT_MOMENT_DATE_FORMAT),
        moment().format(DEFAULT_MOMENT_DATE_FORMAT),
      ],
    },
  ],
};

export const URLS_OVERVIEW_4DX_TABLE_HEADERS = [
  {
    text: "Url's",
    value: "url",
    align: "start",
  },
  {
    text: "Status",
    value: "status",
    align: "center",
  },
  {
    text: "L",
    value: "l_sc",
    align: "center",
  },
  {
    text: "C",
    value: "sc_sc",
    align: "center",
  },
  {
    text: "SW",
    value: "sw_sc",
    align: "center",
  },
  {
    text: "T",
    value: "t_sc",
    align: "center",
  },
  {
    text: "NT, %",
    value: "nt_percent",
    align: "center",
  },
  {
    text: "NT",
    value: "nt",
    align: "center",
  },
  {
    text: "Pos",
    value: "pos",
    align: "center",
  },
  {
    text: "Dynamic",
    value: "dynamic",
    align: "center",
  },
  {
    text: "Vol",
    value: "volume",
    align: "center",
  },
  {
    text: "KD",
    value: "kd",
    align: "center",
  },
  {
    text: "1",
    value: "1",
    align: "center",
  },
  {
    text: "3",
    value: "3",
    align: "center",
  },
  {
    text: "10",
    value: "10",
    align: "center",
  },
  {
    text: "20",
    value: "20",
    align: "center",
  },
  {
    text: "R",
    value: "r",
    align: "center",
  },
  {
    text: "F",
    value: "f",
    align: "center",
  },
  {
    text: "Trends Imp",
    value: "trends_imp",
    width: 125,
    align: "center",
  },
  {
    text: "Trends CL",
    value: "trends_cl",
    width: 125,
    align: "center",
  },
  {
    text: "Trends KCI",
    value: "trends_k",
    width: 125,
    align: "center",
  },
  {
    text: "Trends KR",
    value: "trends_kr",
    width: 125,
    align: "center",
  },
  {
    text: "Trends KD",
    value: "trends_kd",
    width: 125,
    align: "center",
  },
  {
    text: "Pr",
    value: "pr",
    align: "center",
  },
  // {
  //     text: 'D Pr',
  //     value: 'd_pr',
  //     align: 'center',
  // },
];

export const CONVERSION_2_TABLE_HEADERS = [
  { text: "Page", value: "url", class: "text-left" },
  { text: "Clicks GSC", value: "clicks_gsc", class: "text-right" },
  { text: "Clicks", value: "clicks", class: "text-right" },
  { text: "Cl/GSC", value: "c_c", class: "text-right" },
  { text: "UCl", value: "clicks_unique", class: "text-right" },
  { text: "UCl/GSC", value: "c_unique_c_gsc", class: "text-right" },
  { text: "Reg", value: "lead", class: "text-right" },
  { text: "Ndc", value: "sale", class: "text-right" },
  { text: "Recommend", value: "recommend", class: "text-right" },
  { text: "main_table", value: "main_table", class: "text-right" },
  { text: "main_table,%", value: "main_table_clicks", class: "text-right" },
  { text: "casino_review", value: "casino_review", class: "text-right" },
  {
    text: "casino_review,%",
    value: "casino_review_clicks",
    class: "text-right",
  },
  { text: "top3_table", value: "top3_table", class: "text-right" },
  { text: "top3_table,%", value: "top3_table_clicks", class: "text-right" },
  { text: "bonus_table", value: "bonus_table", class: "text-right" },
  { text: "bonus_table,%", value: "bonus_table_clicks", class: "text-right" },
  { text: "sidebar", value: "sidebar", class: "text-right" },
  { text: "sidebar,%", value: "sidebar_clicks", class: "text-right" },
  {
    text: "bonus_sidebar_logo",
    value: "bonus_sidebar_logo",
    class: "text-right",
  },
  {
    text: "bonus_sidebar_logo,%",
    value: "bonus_sidebar_logo_clicks",
    class: "text-right",
  },
  {
    text: "related_casino_play",
    value: "related_casino_play",
    class: "text-right",
  },
  {
    text: "related_casino_play,%",
    value: "related_casino_play_clicks",
    class: "text-right",
  },
  { text: "bonus_poster", value: "bonus_poster", class: "text-right" },
  { text: "bonus_poster,%", value: "bonus_poster_clicks", class: "text-right" },
  { text: "bonus_header", value: "bonus_header", class: "text-right" },
  { text: "alt_casino_table", value: "alt_casino_table", class: "text-right" },
  {
    text: "alt_casino_table_clicks",
    value: "alt_casino_table_clicks",
    class: "text-right",
  },
  {
    text: "bonus_header_clicks",
    value: "bonus_header_clicks",
    class: "text-right",
  },
];
export const CONVERSION_2_TABLE_COMPARISON_HEADERS = [
  { text: "Page", value: "url1", class: "text-left" },
  { text: "Clicks GSC 1", value: "clicks_gsc1", class: "text-right" },
  { text: "Clicks GSC 2", value: "clicks_gsc2", class: "text-right" },
  { text: "Clicks 1", value: "clicks1", class: "text-right" },
  { text: "Clicks 2", value: "clicks2", class: "text-right" },
  { text: "Cl/GSC 1", value: "c_c1", class: "text-right" },
  { text: "Cl/GSC 2", value: "c_c2", class: "text-right" },
  { text: "UCl 1", value: "clicks_unique1", class: "text-right" },
  { text: "UCl 2", value: "clicks_unique2", class: "text-right" },
  { text: "UCl/GSC 1", value: "c_unique_c_gsc1", class: "text-right" },
  { text: "UCl/GSC 2", value: "c_unique_c_gsc2", class: "text-right" },
  { text: "Reg 1", value: "lead1", class: "text-right" },
  { text: "Reg 2", value: "lead2", class: "text-right" },
  { text: "Ndc 1", value: "sale1", class: "text-right" },
  { text: "Ndc 2", value: "sale2", class: "text-right" },
];
export const CONVERSION_4_CHART_OPTIONS = {
  chart: {
    id: "chart-timeline-positions",
    type: "area",
    height: 300,
    zoom: {
      enabled: true,
      autoScaleYaxis: true,
    },
  },
  colors: [
    "#545bde",
    "#e91e63",
    "#ff9800",
    "#1976d2",
    "#42a5f5",
    "#90caf9",
    "#ccc",
    "#673ab7",
    "#8bc34a",
  ],
  annotations: {
    xaxis: [],
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    style: "hollow",
  },
  xaxis: {
    type: "datetime",
    min: new Date().setMonth(new Date().getMonth() - 12),
    tickAmount: 6,
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      datetimeUTC: false,
    },
  },
  yaxis: [
    {
      seriesName: "clicks_keitaro",
      show: false,
    },
    {
      seriesName: "visits",
      show: false,
    },
    {
      seriesName: "registrations",
      show: false,
    },
    {
      seriesName: "ndc",
      show: false,
    },
    {
      seriesName: "pub_revenue",
      show: false,
    },
    {
      seriesName: "r_v_percent",
      show: false,
    },
    {
      seriesName: "ndc_v_percent",
      show: false,
    },
    {
      seriesName: "rev_ndc",
      show: false,
    },
  ],
  stroke: {
    width: [3, 3, 3, 3, 3, 3, 3, 2, 2],
    dashArray: [
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      [7, 5],
      [7, 5],
    ],
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0,
      opacityTo: 0,
      stops: [0, 100],
    },
  },
};

export const URL_OVERVIEW_COLORS = {
  green: "rgb(44,134,34)",
  yellow: "rgb(232,184,2)",
  red: "rgb(204,29,29)",
};

export const CONVERSION_6_TABLE_HEADERS = [
  {
    text: "www.leafcasino.com",
    value: "site",
  },
  {
    text: "21.06.22 - 14.06.22",
    value: "21.06.22 - 14.06.22",
  },
  {
    text: "07.06.22 - 14.06.22",
    value: "07.06.22 - 14.06.22",
  },
  {
    text: "01.06.22 - 07.06.22",
    value: "01.06.22 - 07.06.22",
  },
];

export const CONVERSION_6_TABLE_ITEMS = [
  {
    site: "Clicks Keitaro",
    "21.06.22 - 14.06.22": {
      value: rand(1000, 1500),
      diff: rand(0, 50) + "%",
      direction: rand(0, 2),
    },
    "07.06.22 - 14.06.22": {
      value: rand(1000, 1500),
      diff: rand(0, 50) + "%",
      direction: rand(0, 2),
    },
    "01.06.22 - 07.06.22": {
      value: rand(1000, 1500),
      diff: rand(0, 50) + "%",
      direction: rand(0, 2),
    },
  },
  {
    site: "Clicks GSC",
    "21.06.22 - 14.06.22": {
      value: rand(1000, 1500),
      diff: rand(0, 50) + "%",
      direction: rand(0, 2),
    },
    "07.06.22 - 14.06.22": {
      value: rand(1000, 1500),
      diff: rand(0, 50) + "%",
      direction: rand(0, 2),
    },
    "01.06.22 - 07.06.22": {
      value: rand(1000, 1500),
      diff: rand(0, 50) + "%",
      direction: rand(0, 2),
    },
  },
  {
    site: "Clicks",
    "21.06.22 - 14.06.22": {
      value: rand(1000, 1500),
      diff: rand(0, 50) + "%",
      direction: rand(0, 2),
    },
    "07.06.22 - 14.06.22": {
      value: rand(1000, 1500),
      diff: rand(0, 50) + "%",
      direction: rand(0, 2),
    },
    "01.06.22 - 07.06.22": {
      value: rand(1000, 1500),
      diff: rand(0, 50) + "%",
      direction: rand(0, 2),
    },
  },
  {
    site: "Reg",
    "21.06.22 - 14.06.22": {
      value: rand(0, 500),
    },
    "07.06.22 - 14.06.22": {
      value: rand(0, 500),
    },
    "01.06.22 - 07.06.22": {
      value: rand(0, 500),
    },
  },
  {
    site: "NDC",
    "21.06.22 - 14.06.22": {
      value: rand(0, 500),
    },
    "07.06.22 - 14.06.22": {
      value: rand(0, 500),
    },
    "01.06.22 - 07.06.22": {
      value: rand(0, 500),
    },
  },
  {
    site: "c/n %",
    "21.06.22 - 14.06.22": {
      value: rand(0, 101) + "%",
    },
    "07.06.22 - 14.06.22": {
      value: rand(0, 101) + "%",
    },
    "01.06.22 - 07.06.22": {
      value: rand(0, 101) + "%",
    },
  },
  {
    site: "r/n %",
    "21.06.22 - 14.06.22": {
      value: rand(0, 10),
      diff: rand(0, 5),
      direction: rand(0, 2),
    },
    "07.06.22 - 14.06.22": {
      value: rand(0, 10),
      diff: rand(0, 5),
      direction: rand(0, 2),
    },
    "01.06.22 - 07.06.22": {
      value: rand(0, 10),
      diff: rand(0, 5),
      direction: rand(0, 2),
    },
  },
  {
    site: "Pub Rev",
    "21.06.22 - 14.06.22": {
      value: rand(0, 10001),
    },
    "07.06.22 - 14.06.22": {
      value: rand(0, 10001),
    },
    "01.06.22 - 07.06.22": {
      value: rand(0, 10001),
    },
  },
];

export const PAGE_PRIORITY = [
  {
    name: "gpm.view-entity",
    priority: "8,623",
    uniqueEvents: "1,535",
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "view.info.by.site.show",
    priority: "3,625",
    uniqueEvents: 941,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "gpm.guest-post",
    priority: "2,396",
    uniqueEvents: 822,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "anchors-plan.placement-links.x",
    priority: "1,492",
    uniqueEvents: 582,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "gpm.alex-dashboard",
    priority: "1,277",
    uniqueEvents: 487,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "links-dynamic-data",
    priority: "1,075",
    uniqueEvents: 532,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "site-workplace.dashboard",
    priority: 706,
    uniqueEvents: 394,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "anchors-plan.linkbuilder.tasks",
    priority: 675,
    uniqueEvents: 440,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "anchors-plan.placement-links",
    priority: 552,
    uniqueEvents: 246,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "ltt.index",
    priority: 463,
    uniqueEvents: 244,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "gpm.manager-dashboard",
    priority: 459,
    uniqueEvents: 361,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "semantic.tool.index",
    priority: 407,
    uniqueEvents: 269,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "anchors-plan.tasks",
    priority: 322,
    uniqueEvents: 217,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "positions.index",
    priority: 317,
    uniqueEvents: 267,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "keyword.control.index",
    priority: 238,
    uniqueEvents: 158,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "serp-dynamics",
    priority: 215,
    uniqueEvents: 114,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "donor-base.clean.index",
    priority: 209,
    uniqueEvents: 179,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "content.checker.index",
    priority: 206,
    uniqueEvents: 143,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "ltt.link-url-control-phrase",
    priority: 189,
    uniqueEvents: 44,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "worksection.week.report",
    priority: 171,
    uniqueEvents: 80,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "url.control.index",
    priority: 162,
    uniqueEvents: 90,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "one-page-analyzer-show",
    priority: 147,
    uniqueEvents: 106,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "ltt.ltt-deleted-links",
    priority: 125,
    uniqueEvents: 46,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "aparser.task.index",
    priority: 97,
    uniqueEvents: 20,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "analytics.conversion2.report",
    priority: 94,
    uniqueEvents: 45,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "worksection.daily.report",
    priority: 93,
    uniqueEvents: 44,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "one-year-report",
    priority: 92,
    uniqueEvents: 67,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "niche.analyzer.index",
    priority: 88,
    uniqueEvents: 55,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "analytics.conversion4.report",
    priority: 83,
    uniqueEvents: 33,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "ltt.link-dynamics-report",
    priority: 82,
    uniqueEvents: 70,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "view.info.by.site.index",
    priority: 77,
    uniqueEvents: 62,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "niche.analyzer.project-dashboard",
    priority: 70,
    uniqueEvents: 46,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "dbc-dashboard",
    priority: 69,
    uniqueEvents: 23,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "niche.analyzer.detailed-static",
    priority: 68,
    uniqueEvents: 48,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "gpm.research-hub",
    priority: 63,
    uniqueEvents: 41,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "donor-base.x.research.index",
    priority: 61,
    uniqueEvents: 56,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "gpm.managers-gpm",
    priority: 57,
    uniqueEvents: 34,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "analytics.conversion6.report",
    priority: 56,
    uniqueEvents: 10,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "ltt.report",
    priority: 54,
    uniqueEvents: 38,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "analytics.conversion3.report",
    priority: 53,
    uniqueEvents: 27,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "ltt.monitor",
    priority: 51,
    uniqueEvents: 21,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "ltt.link-url-control",
    priority: 49,
    uniqueEvents: 45,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "gpm.reserved-domains",
    priority: 43,
    uniqueEvents: 35,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "gpm.admin.status",
    priority: 42,
    uniqueEvents: 31,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "gpm.gp-tracker",
    priority: 38,
    uniqueEvents: 28,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "ltt.dynamics.domain",
    priority: 38,
    uniqueEvents: 17,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "anchors-plan.task.edit",
    priority: 37,
    uniqueEvents: 30,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "gpm.3rd-blogs",
    priority: 36,
    uniqueEvents: 28,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "search-console.index",
    priority: 32,
    uniqueEvents: 31,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "search-console.url",
    priority: 29,
    uniqueEvents: 22,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "donor-base.x.index",
    priority: 27,
    uniqueEvents: 25,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "uct.index",
    priority: 26,
    uniqueEvents: 15,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "analytics.conversion.report",
    priority: 23,
    uniqueEvents: 15,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "links.plan.index",
    priority: 23,
    uniqueEvents: 20,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "semantic.tool.keyword",
    priority: 23,
    uniqueEvents: 17,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "site-workplace.admin.fields",
    priority: 20,
    uniqueEvents: 17,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "ltt.deleted-links",
    priority: 19,
    uniqueEvents: 13,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "anchors-plan.tier2.linkbuilder.tasks",
    priority: 17,
    uniqueEvents: 16,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "gpm.outreach-problems",
    priority: 17,
    uniqueEvents: 14,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "gpm.admin.gp-total",
    priority: 14,
    uniqueEvents: 12,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "semantic.tool.dashboard",
    priority: 13,
    uniqueEvents: 9,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "anchors-plan.dashboard",
    priority: 12,
    uniqueEvents: 9,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "anchors-plan.tier2.tasks",
    priority: 12,
    uniqueEvents: 8,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "mailer.index",
    priority: 12,
    uniqueEvents: 11,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "semantic.tool.keyword-detailed",
    priority: 11,
    uniqueEvents: 9,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "analytics.conversion5.report",
    priority: 10,
    uniqueEvents: 9,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "content.checker.changes",
    priority: 10,
    uniqueEvents: 6,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "gpm.time-spent-dashboard",
    priority: 9,
    uniqueEvents: 9,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "ltt.index-all",
    priority: 9,
    uniqueEvents: 7,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "gtt.index",
    priority: 8,
    uniqueEvents: 8,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "new-positions-dashboard",
    priority: 8,
    uniqueEvents: 6,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "ltt.2Level.index",
    priority: 7,
    uniqueEvents: 6,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "analytics.conversion.report1",
    priority: 6,
    uniqueEvents: 5,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "dashboard-dev",
    priority: 6,
    uniqueEvents: 5,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "ltt.progress-links-dashboard",
    priority: 4,
    uniqueEvents: 4,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "textrazor.index",
    priority: 4,
    uniqueEvents: 4,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "site-workplace",
    priority: 3,
    uniqueEvents: 2,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "essay.hub.index",
    priority: 2,
    uniqueEvents: 2,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "gpm.admin.fields",
    priority: 2,
    uniqueEvents: 2,
    eventValue: 0,
    avgValue: 0,
  },
  {
    name: "analytics.all-site",
    priority: 1,
    uniqueEvents: 1,
    eventValue: 0,
    avgValue: 0,
  },
];

export const MOCK_DEV_DASHBOARD_DATA = [
  {
    id: 0,
    sectionName: "Ltt: Auto Types",
    cards: [],
    graphs: [
      {
        id: 0,
        name: "Last 14 Days",
        legend: "Days",
        categories: [
          "Jun 25",
          "Jun 26",
          "Jun 27",
          "Jun 28",
          "Jun 29",
          "Jun 30",
          "Jul 1",
          "Jul 2",
          "Jul 3",
          "Jul 4",
          "Jul 5",
          "Jul 6",
          "Jul 7",
        ],
        series: [
          {
            name: "Links",
            data: [
              35000, 37700, 33000, 35000, 22500, 16500, 11500, 13400, 13700,
              10900, 10800, 13600, 7690,
            ],
          },
        ],
      },
      {
        id: 1,
        name: "Last 24 Hours",
        legend: "Hour",
        categories: [
          "00",
          "02",
          "04",
          "06",
          "08",
          "10",
          "12",
          "14",
          "16",
          "18",
          "20",
          "22",
          "24",
        ],
        series: [
          {
            name: "Today",
            data: [720, 1200, 900, 560, 663, 600, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: "Yesterday",
            data: [0, 0, 0, 0, 0, 0, 15, 973, 556, 484, 269, 302, 650],
          },
        ],
      },
    ],
  },
  {
    id: 1,
    sectionName: "Ltt: Added Links",
    cards: [
      {
        id: 0,
        value: 113348,
        text: "All",
      },
      {
        id: 1,
        value: 335,
        text: "Last 7 Days",
      },
      {
        id: 2,
        value: 1962,
        text: "Last 30 Days",
      },
      {
        id: 3,
        value: 5954,
        text: "Last 90 Days",
      },
    ],
    graphs: [
      {
        id: 0,
        name: "Last 14 Days",
        legend: "Days",
        categories: [
          "Jun 25",
          "Jun 26",
          "Jun 27",
          "Jun 28",
          "Jun 29",
          "Jun 30",
          "Jul 1",
          "Jul 2",
          "Jul 3",
          "Jul 4",
          "Jul 5",
          "Jul 6",
          "Jul 7",
        ],
        series: [
          {
            name: "Links",
            data: [9, 17, 153, 111, 147, 78, 31, 12, 36, 98, 43, 62, 67],
          },
        ],
      },
      {
        id: 1,
        name: "Last 24 Hours",
        legend: "Hour",
        categories: [
          "00",
          "02",
          "04",
          "06",
          "08",
          "10",
          "12",
          "14",
          "16",
          "18",
          "20",
          "22",
          "24",
        ],
        series: [
          {
            name: "Today",
            data: [10, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0],
          },
          {
            name: "Yesterday",
            data: [0, 0, 0, 0, 0, 0, 1, 1, 0, 5, 3, 4, 1],
          },
        ],
      },
    ],
  },
];

export const DEFAULT_HEADERS_SITE_OVERVIEW_TABLE = [
  {
    text: "Name",
    value: "name",
    align: "start",
  },
  {
    text: "Status",
    value: "status",
    align: "center",
  },
  {
    text: "L",
    value: "l_sc",
    align: "center",
  },
  {
    text: "C",
    value: "sc_sc",
    align: "center",
  },
  {
    text: "SW",
    value: "sw_sc",
    align: "center",
  },
  {
    text: "T",
    value: "t_sc",
    align: "center",
  },
  {
    text: "NT, %",
    value: "nt_percent",
    align: "center",
  },
  {
    text: "1",
    value: "1",
    align: "center",
  },
  {
    text: "3",
    value: "3",
    align: "center",
  },
  {
    text: "10",
    value: "10",
    align: "center",
  },
  {
    text: "20",
    value: "20",
    align: "center",
  },
  {
    text: "R",
    value: "r",
    align: "center",
  },
  {
    text: "F",
    value: "f",
    align: "center",
  },
  {
    text: "Trends Imp",
    value: "trends_imp",
    width: 125,
    align: "center",
  },
  {
    text: "Trends CL",
    value: "trends_cl",
    width: 125,
    align: "center",
  },
];

export const MOCK_1_TEST = [
  {
    domain: "blogster.com",
    type: "guest post",
    check_on_page: 0,
    ltt_live_percent: 100,
    ah_traffic_country: "471",
    ah_traffic: "321",
    ah_dr: 66,
    ah_ref_domains: "29.5K",
    noindex: 0,
    nofollow: -1,
    linked_root_domains: "5.2K",
    ltt_last_posting: "2017-10-17 00:00:00",
    spam_score: 100,
    sw_category:
      "Computers_Electronics_and_Technology/Computers_Electronics_and_Technology",
    google_index: "2.6K",
    gi_ahp: "0",
    sw_percent: 36.74,
    organic_search: "7.68",
    sw_visits: "19K",
    sw_search: "1.9K",
    google_trust: 0,
    monthly_visits: "6.3K",
    created_at: "09/11",
    sw_rank_country: "455.2K",
    blacklist_count: null,
    favorite_count: null,
    google_index_path: "0",
    google_index_niche: 0,
    whois_creation: "2002-08",
    sw_global_rank: "3.8M",
    last_for_project: null,
    last_try_post_for_project: null,
    link_placement: "image",
    google_index_query: null,
    social: "5.74",
    anchor: null,
    referrals: "3.69",
    paid_search: "0.00",
    email: "0.00",
    paid_referrals: "0.00",
    cosine: null,
    lang: "none",
    comment: null,
    url: "http://example.com/",
    id: 6942846,
    is_checked: 0,
    donor_domain_id: 1878998,
    domain_status: 0,
    url_id: 7335903,
    type_id: 6,
    added_x_days_ago: 723,
    score: -1,
    trafficbycountries: "",
    last_try_post_for_project_in_days: null,
    desktop_device_distribution: 40,
    value: null,
    value2: null,
    tag: [],
    count_comment: null,
    ss_traffic: 22705,
    tag_count: null,
    mj_trust_flow: 0,
    mj_citation_flow: 0,
    count_link_all: null,
    count_link_on_page: null,
    count_link_gi: null,
    google_trust_region: 0,
    count_link_dateadd: null,
    last_for_project_in_days: null,
    ltt_all: 1,
    ltt_on_page: 1,
    ltt_gi: 1,
    ltt_cache: 0,
    ltt_follow: 0,
    is_index: 0,
    check_is_index: 0,
    check_gi: 0,
    last_posting_in_days: 1735,
    traffic_share: -1,
    new_type: "guest post",
  },
  {
    domain: "forreadingaddicts.co.uk",
    type: "guest post",
    check_on_page: 3,
    ltt_live_percent: 75,
    ah_traffic_country: "9.3K",
    ah_traffic: "51K",
    ah_dr: 37,
    ah_ref_domains: "1.3K",
    noindex: 0,
    nofollow: 1,
    linked_root_domains: "1.2K",
    ltt_last_posting: "2018-03-06 00:00:00",
    spam_score: 70,
    sw_category: "Arts_and_Entertainment/Books_and_Literature",
    google_index: "12.6K",
    gi_ahp: "23",
    sw_percent: 41.89,
    organic_search: "51.49",
    sw_visits: "421.1K",
    sw_search: "111.7K",
    google_trust: 2,
    monthly_visits: "140.4K",
    created_at: "09/11",
    sw_rank_country: "153.9K",
    blacklist_count: null,
    favorite_count: null,
    google_index_path: "0",
    google_index_niche: 0,
    whois_creation: "2015-02",
    sw_global_rank: "456.4K",
    last_for_project: null,
    last_try_post_for_project: null,
    link_placement: "text",
    google_index_query: null,
    social: "29.24",
    anchor: 1,
    referrals: "0.13",
    paid_search: "0.09",
    email: "0.00",
    paid_referrals: "0.00",
    cosine: null,
    lang: "ENGLISH",
    comment: null,
    url: "http://forreadingaddicts.co.uk/guest-blogs/easy-write-literature-review-heres/24360",
    id: 6942843,
    is_checked: 0,
    donor_domain_id: 1878992,
    domain_status: 0,
    url_id: 92408264,
    type_id: 6,
    added_x_days_ago: 898,
    score: -1,
    trafficbycountries: "",
    last_try_post_for_project_in_days: null,
    desktop_device_distribution: 30,
    value: null,
    value2: null,
    tag: [],
    count_comment: null,
    ss_traffic: 804200,
    tag_count: null,
    mj_trust_flow: 0,
    mj_citation_flow: 0,
    count_link_all: null,
    count_link_on_page: null,
    count_link_gi: null,
    google_trust_region: null,
    count_link_dateadd: null,
    last_for_project_in_days: null,
    ltt_all: 4,
    ltt_on_page: 3,
    ltt_gi: 3,
    ltt_cache: 0,
    ltt_follow: 0,
    is_index: 0,
    check_is_index: 3,
    check_gi: 0,
    last_posting_in_days: 1595,
    traffic_share: -1,
    new_type: "guest post",
  },
  {
    domain: "letsreachsuccess.com",
    type: "guest post",
    check_on_page: 5,
    ltt_live_percent: 26,
    ah_traffic_country: "2.9K",
    ah_traffic: "6.5K",
    ah_dr: 56,
    ah_ref_domains: "1.8K",
    noindex: 0,
    nofollow: -1,
    linked_root_domains: "2.5K",
    ltt_last_posting: "2022-04-04 00:00:00",
    spam_score: 45,
    sw_category: "Health/Mental_Health",
    google_index: "1.4K",
    gi_ahp: "19",
    sw_percent: 31.98,
    organic_search: "73.89",
    sw_visits: "160.9K",
    sw_search: "141.4K",
    google_trust: 2,
    monthly_visits: "53.6K",
    created_at: "09/11",
    sw_rank_country: "230.2K",
    blacklist_count: null,
    favorite_count: null,
    google_index_path: "0",
    google_index_niche: 160,
    whois_creation: "2013-05",
    sw_global_rank: "833.5K",
    last_for_project: null,
    last_try_post_for_project: null,
    link_placement: "text",
    google_index_query: null,
    social: "6.88",
    anchor: null,
    referrals: "2.82",
    paid_search: "0.00",
    email: "0.33",
    paid_referrals: "0.00",
    cosine: null,
    lang: "ENGLISH",
    comment: "none",
    url: "https://letsreachsuccess.com",
    id: 6942842,
    is_checked: 0,
    donor_domain_id: 1878991,
    domain_status: 0,
    url_id: 575986347,
    type_id: 6,
    added_x_days_ago: 709,
    score: -1,
    trafficbycountries: "",
    last_try_post_for_project_in_days: null,
    desktop_device_distribution: 26,
    value: null,
    value2: null,
    tag: [],
    count_comment: null,
    ss_traffic: 1121755,
    tag_count: null,
    mj_trust_flow: 0,
    mj_citation_flow: 0,
    count_link_all: 7,
    count_link_on_page: 1,
    count_link_gi: 6,
    google_trust_region: 0,
    count_link_dateadd: "2018-08-10 17:14:10",
    last_for_project_in_days: null,
    ltt_all: 19,
    ltt_on_page: 5,
    ltt_gi: 5,
    ltt_cache: 1,
    ltt_follow: 4,
    is_index: 5,
    check_is_index: 5,
    check_gi: 4,
    last_posting_in_days: 105,
    traffic_share: -1,
    new_type: "guest post",
  },
  {
    domain: "grownuptravelguide.com",
    type: "guest post",
    check_on_page: 3,
    ltt_live_percent: 75,
    ah_traffic_country: "2.3K",
    ah_traffic: "1.5K",
    ah_dr: 28,
    ah_ref_domains: "534",
    noindex: 0,
    nofollow: -1,
    linked_root_domains: "3K",
    ltt_last_posting: "2018-10-16 00:00:00",
    spam_score: 12,
    sw_category: "Travel_and_Tourism/Travel_and_Tourism",
    google_index: "2.8K",
    gi_ahp: "36",
    sw_percent: 33.72,
    organic_search: "83.63",
    sw_visits: "31.5K",
    sw_search: "25K",
    google_trust: 0,
    monthly_visits: "10.5K",
    created_at: "09/11",
    sw_rank_country: "1.5M",
    blacklist_count: null,
    favorite_count: null,
    google_index_path: "0",
    google_index_niche: 24,
    whois_creation: "2011-09",
    sw_global_rank: "2.7M",
    last_for_project: null,
    last_try_post_for_project: null,
    link_placement: "text",
    google_index_query: null,
    social: "1.68",
    anchor: null,
    referrals: "1.36",
    paid_search: "0.00",
    email: "1.84",
    paid_referrals: "0.00",
    cosine: null,
    lang: "ENGLISH",
    comment: null,
    url: "http://grownuptravelguide.com/without-example",
    id: 6942838,
    is_checked: 0,
    donor_domain_id: 1878981,
    domain_status: 0,
    url_id: 494797423,
    type_id: 6,
    added_x_days_ago: 1147,
    score: -1,
    trafficbycountries: "",
    last_try_post_for_project_in_days: null,
    desktop_device_distribution: 30,
    value: null,
    value2: null,
    tag: [],
    count_comment: null,
    ss_traffic: 54052,
    tag_count: null,
    mj_trust_flow: 0,
    mj_citation_flow: 0,
    count_link_all: 1,
    count_link_on_page: 1,
    count_link_gi: 1,
    google_trust_region: 0,
    count_link_dateadd: "2018-10-16 15:44:11",
    last_for_project_in_days: null,
    ltt_all: 4,
    ltt_on_page: 3,
    ltt_gi: 3,
    ltt_cache: 3,
    ltt_follow: 3,
    is_index: 3,
    check_is_index: 3,
    check_gi: 3,
    last_posting_in_days: 1371,
    traffic_share: -1,
    new_type: "guest post",
  },
  {
    domain: "girlsmagpk.com",
    type: "guest post",
    check_on_page: 0,
    ltt_live_percent: 100,
    ah_traffic_country: "88",
    ah_traffic: "144",
    ah_dr: 19,
    ah_ref_domains: "625",
    noindex: 0,
    nofollow: -1,
    linked_root_domains: "1.4K",
    ltt_last_posting: "2017-07-18 00:00:00",
    spam_score: 94,
    sw_category: "Hobbies_and_Leisure/Photography",
    google_index: "1.8K",
    gi_ahp: "11",
    sw_percent: 28.25,
    organic_search: "82.40",
    sw_visits: "45K",
    sw_search: "7.2K",
    google_trust: 2,
    monthly_visits: "15K",
    created_at: "09/11",
    sw_rank_country: "824.2K",
    blacklist_count: null,
    favorite_count: null,
    google_index_path: "0",
    google_index_niche: 9,
    whois_creation: "2012-12",
    sw_global_rank: "3.2M",
    last_for_project: null,
    last_try_post_for_project: null,
    link_placement: "text",
    google_index_query: null,
    social: "0.00",
    anchor: null,
    referrals: "0.00",
    paid_search: "0.00",
    email: "0.00",
    paid_referrals: "0.00",
    cosine: null,
    lang: "ENGLISH",
    comment: "none",
    url: "https://girlsmagpk.com",
    id: 6942837,
    is_checked: 0,
    donor_domain_id: 1878980,
    domain_status: 0,
    url_id: 575986347,
    type_id: 6,
    added_x_days_ago: 709,
    score: -1,
    trafficbycountries: "",
    last_try_post_for_project_in_days: null,
    desktop_device_distribution: 10,
    value: null,
    value2: null,
    tag: [],
    count_comment: null,
    ss_traffic: 186525,
    tag_count: null,
    mj_trust_flow: 0,
    mj_citation_flow: 0,
    count_link_all: null,
    count_link_on_page: null,
    count_link_gi: null,
    google_trust_region: null,
    count_link_dateadd: null,
    last_for_project_in_days: null,
    ltt_all: 3,
    ltt_on_page: 3,
    ltt_gi: 3,
    ltt_cache: 0,
    ltt_follow: 3,
    is_index: 2,
    check_is_index: 0,
    check_gi: 0,
    last_posting_in_days: 1826,
    traffic_share: -1,
    new_type: "guest post",
  },
  {
    domain: "girlsmagpk.com",
    type: "guest post",
    check_on_page: 0,
    ltt_live_percent: 100,
    ah_traffic_country: "88",
    ah_traffic: "144",
    ah_dr: 19,
    ah_ref_domains: "625",
    noindex: 0,
    nofollow: -1,
    linked_root_domains: "1.4K",
    ltt_last_posting: "2017-07-18 00:00:00",
    spam_score: 94,
    sw_category: "Hobbies_and_Leisure/Photography",
    google_index: "1.8K",
    gi_ahp: "11",
    sw_percent: 28.25,
    organic_search: "82.40",
    sw_visits: "45K",
    sw_search: "7.2K",
    google_trust: 2,
    monthly_visits: "15K",
    created_at: "09/11",
    sw_rank_country: "824.2K",
    blacklist_count: null,
    favorite_count: null,
    google_index_path: "0",
    google_index_niche: 9,
    whois_creation: "2012-12",
    sw_global_rank: "3.2M",
    last_for_project: null,
    last_try_post_for_project: null,
    link_placement: "text",
    google_index_query: null,
    social: "0.00",
    anchor: null,
    referrals: "0.00",
    paid_search: "0.00",
    email: "0.00",
    paid_referrals: "0.00",
    cosine: null,
    lang: "ENGLISH",
    comment: "none",
    url: "https://girlsmagpk.com",
    id: 6942837,
    is_checked: 0,
    donor_domain_id: 1878980,
    domain_status: 0,
    url_id: 575986347,
    type_id: 6,
    added_x_days_ago: 709,
    score: -1,
    trafficbycountries: "",
    last_try_post_for_project_in_days: null,
    desktop_device_distribution: 10,
    value: null,
    value2: null,
    tag: [],
    count_comment: null,
    ss_traffic: 186525,
    tag_count: null,
    mj_trust_flow: 0,
    mj_citation_flow: 0,
    count_link_all: null,
    count_link_on_page: null,
    count_link_gi: null,
    google_trust_region: null,
    count_link_dateadd: null,
    last_for_project_in_days: null,
    ltt_all: 3,
    ltt_on_page: 3,
    ltt_gi: 3,
    ltt_cache: 0,
    ltt_follow: 3,
    is_index: 2,
    check_is_index: 0,
    check_gi: 0,
    last_posting_in_days: 1826,
    traffic_share: -1,
    new_type: "guest post",
  },
  {
    domain: "moneygossips.com",
    type: "guest post",
    check_on_page: 0,
    ltt_live_percent: 60,
    ah_traffic_country: "113",
    ah_traffic: "269",
    ah_dr: 22,
    ah_ref_domains: "548",
    noindex: 0,
    nofollow: -1,
    linked_root_domains: "3.3K",
    ltt_last_posting: "2019-03-27 00:00:00",
    spam_score: 26,
    sw_category: "Business_and_Consumer_Services/Online_Marketing",
    google_index: "895",
    gi_ahp: "62",
    sw_percent: 17,
    organic_search: "26.74",
    sw_visits: "3.1K",
    sw_search: "4.7K",
    google_trust: 2,
    monthly_visits: "1K",
    created_at: "09/11",
    sw_rank_country: "0",
    blacklist_count: null,
    favorite_count: null,
    google_index_path: "0",
    google_index_niche: 42,
    whois_creation: "2014-08",
    sw_global_rank: "10.4M",
    last_for_project: null,
    last_try_post_for_project: null,
    link_placement: "text",
    google_index_query: null,
    social: "4.84",
    anchor: null,
    referrals: "14.53",
    paid_search: "0.00",
    email: "0.00",
    paid_referrals: "0.00",
    cosine: null,
    lang: "ENGLISH",
    comment: null,
    url: "http://moneygossips.com/without-example",
    id: 6942835,
    is_checked: 0,
    donor_domain_id: 1878978,
    domain_status: 0,
    url_id: 494800168,
    type_id: 6,
    added_x_days_ago: 1147,
    score: -1,
    trafficbycountries: "",
    last_try_post_for_project_in_days: null,
    desktop_device_distribution: 100,
    value: null,
    value2: null,
    tag: [],
    count_comment: null,
    ss_traffic: 202875,
    tag_count: null,
    mj_trust_flow: 0,
    mj_citation_flow: 0,
    count_link_all: null,
    count_link_on_page: null,
    count_link_gi: null,
    google_trust_region: null,
    count_link_dateadd: null,
    last_for_project_in_days: null,
    ltt_all: 10,
    ltt_on_page: 6,
    ltt_gi: 6,
    ltt_cache: 1,
    ltt_follow: 6,
    is_index: 1,
    check_is_index: 0,
    check_gi: 0,
    last_posting_in_days: 1209,
    traffic_share: -1,
    new_type: "guest post",
  },
  {
    domain: "whitedust.net",
    type: "guest post",
    check_on_page: 5,
    ltt_live_percent: 25,
    ah_traffic_country: "24.8K",
    ah_traffic: "18.7K",
    ah_dr: 35,
    ah_ref_domains: "1.3K",
    noindex: 0,
    nofollow: -1,
    linked_root_domains: "840",
    ltt_last_posting: "2021-04-30 00:00:00",
    spam_score: 100,
    sw_category:
      "Computers_Electronics_and_Technology/Social_Networks_and_Online_Communities",
    google_index: "836",
    gi_ahp: "4",
    sw_percent: 18.57,
    organic_search: "72.32",
    sw_visits: "167.8K",
    sw_search: "134.2K",
    google_trust: 1,
    monthly_visits: "55.9K",
    created_at: "09/11",
    sw_rank_country: "306.3K",
    blacklist_count: null,
    favorite_count: null,
    google_index_path: "0",
    google_index_niche: 20,
    whois_creation: "2015-06",
    sw_global_rank: "685.8K",
    last_for_project: "2020-11-27 08:56:38",
    last_try_post_for_project: null,
    link_placement: "text",
    google_index_query: null,
    social: "7.83",
    anchor: null,
    referrals: "1.18",
    paid_search: "0.00",
    email: "0.00",
    paid_referrals: "0.00",
    cosine: null,
    lang: "ENGLISH",
    comment: "none",
    url: "https://whitedust.net",
    id: 6942834,
    is_checked: 0,
    donor_domain_id: 1878976,
    domain_status: 0,
    url_id: 575986347,
    type_id: 6,
    added_x_days_ago: 709,
    score: -1,
    trafficbycountries: "",
    last_try_post_for_project_in_days: null,
    desktop_device_distribution: 17,
    value: null,
    value2: null,
    tag: [],
    count_comment: null,
    ss_traffic: 1909151,
    tag_count: null,
    mj_trust_flow: 0,
    mj_citation_flow: 0,
    count_link_all: 9,
    count_link_on_page: 2,
    count_link_gi: 5,
    google_trust_region: 1,
    count_link_dateadd: "2020-11-27 08:56:38",
    last_for_project_in_days: 598,
    ltt_all: 24,
    ltt_on_page: 6,
    ltt_gi: 4,
    ltt_cache: 2,
    ltt_follow: 6,
    is_index: 2,
    check_is_index: 3,
    check_gi: 2,
    last_posting_in_days: 444,
    traffic_share: -1,
    new_type: "guest post",
  },
  {
    domain: "scallywagandvagabond.com",
    type: "guest post",
    check_on_page: 33,
    ltt_live_percent: 97,
    ah_traffic_country: "34.3K",
    ah_traffic: "49.6K",
    ah_dr: 55,
    ah_ref_domains: "5.2K",
    noindex: 0,
    nofollow: 0,
    linked_root_domains: "10.2K",
    ltt_last_posting: "2022-06-24 00:00:00",
    spam_score: 66,
    sw_category: "News_and_Media",
    google_index: "15.3K",
    gi_ahp: "33",
    sw_percent: 67.33,
    organic_search: "58.79",
    sw_visits: "403K",
    sw_search: "225.4K",
    google_trust: 2,
    monthly_visits: "134.3K",
    created_at: "09/11",
    sw_rank_country: "77.7K",
    blacklist_count: null,
    favorite_count: null,
    google_index_path: "0",
    google_index_niche: 392,
    whois_creation: "2008-10",
    sw_global_rank: "314.1K",
    last_for_project: null,
    last_try_post_for_project: null,
    link_placement: "text",
    google_index_query: null,
    social: "6.64",
    anchor: null,
    referrals: "1.70",
    paid_search: "0.00",
    email: "0.33",
    paid_referrals: "0.00",
    cosine: null,
    lang: "ENGLISH",
    comment: "none",
    url: "https://scallywagandvagabond.com/2017/09/writing-college-essay-application-paper-appealing/",
    id: 6942833,
    is_checked: 0,
    donor_domain_id: 1878974,
    domain_status: 0,
    url_id: 72787714,
    type_id: 6,
    added_x_days_ago: 978,
    score: -1,
    trafficbycountries: "",
    last_try_post_for_project_in_days: null,
    desktop_device_distribution: 35,
    value: "200$",
    value2: null,
    tag: [],
    count_comment: null,
    ss_traffic: 148628,
    tag_count: null,
    mj_trust_flow: 0,
    mj_citation_flow: 0,
    count_link_all: 3,
    count_link_on_page: 3,
    count_link_gi: 2,
    google_trust_region: 2,
    count_link_dateadd: "2022-06-24 15:53:15",
    last_for_project_in_days: null,
    ltt_all: 34,
    ltt_on_page: 33,
    ltt_gi: 33,
    ltt_cache: 18,
    ltt_follow: 33,
    is_index: 25,
    check_is_index: 33,
    check_gi: 28,
    last_posting_in_days: 24,
    traffic_share: -1,
    new_type: "guest post",
  },
];

export const ANCHOR_DISTRIBUTION_TABLE_ITEMS = (count) => {
  const output = [];

  for (let i = 0; i < count; i++) {
    output.push({
      pos: rand(0, 255),
      cl: rand(0, 255),
      i: rand(0, 255),
      vol: rand(0, 255),
      t: rand(0, 255),
      ut: rand(0, 255),
      "http://test.domain1": rand(0, 255),
      "http://test.domain2": rand(0, 255),
      "http://test.domain3": rand(0, 255),
      "http://test.domain4": rand(0, 255),
      "http://test.domain5": rand(0, 255),
      "http://test.domain6": rand(0, 255),
      "http://test.domain7": rand(0, 255),
      "http://test.domain8": rand(0, 255),
      "http://test.domain9": rand(0, 255),
    });
  }

  return output;
};

export const ANCHOR_DISTRIBUTION_TABLE_HEADERS = [
  {
    text: "P",
    value: "pos",
  },
  {
    text: "CL",
    value: "cl",
  },
  {
    text: "I",
    value: "i",
  },
  {
    text: "V",
    value: "vol",
  },
  {
    text: "T",
    value: "t",
  },
  {
    text: "uT",
    value: "ut",
  },
  {
    text: "http://test.domain1",
    value: "http://test.domain1",
  },
  {
    text: "http://test.domain2",
    value: "http://test.domain2",
  },
  {
    text: "http://test.domain3",
    value: "http://test.domain3",
  },
  {
    text: "http://test.domain4",
    value: "http://test.domain4",
  },
  {
    text: "http://test.domain5",
    value: "http://test.domain5",
  },
  {
    text: "http://test.domain6",
    value: "http://test.domain6",
  },
  {
    text: "http://test.domain7",
    value: "http://test.domain7",
  },
  {
    text: "http://test.domain8",
    value: "http://test.domain8",
  },
  {
    text: "http://test.domain9",
    value: "http://test.domain9",
  },
];

// "id": 1988644606,
//     "domain": "proininews.gr",
//     "price": "$28.99",
//     "cms": "WP",
//     "seller": "cole",
//     "shell_type": "WSO",
//     "access_type": "WEB",
//     "date_added": "2022-09-02",
//     "gt": 2,
//     "ah_tr": 81357,
//     "ah_bl": 53547,
//     "ah_rd": 2739,
//     "ah_dr": 37

export const SHELL_LINKS_TABLE_HEADERS = [
  { text: "Domain", value: "domain" },
  { text: "Price", value: "price" },
  { text: "CMS", value: "cms" },
  { text: "Seller", value: "seller" },
  { text: "Shell Type", value: "shell_type" },
  { text: "Access Type", value: "access_type" },
  { text: "Added", value: "date_added" },
  { text: "GT", value: "gt" },
  { text: "Ah Tr", value: "ah_tr" },
  { text: "Ah Bl", value: "ah_bl" },
  { text: "Ah Rd", value: "ah_rd" },
  { text: "Ah Dr", value: "ah_dr" },
];

export const CONV_7_ROW_NAMES = [
  "Clicks GSC",
  "Clicks Keitaro",
  "Clicks/GSC,%",
  "uCl",
  "uCl/GSC,%",
  "Reg",
  "Ndc",
  "main_table",
  "main_table,%",
  "top3_table",
  "top3_table,%",
  "bonus_table",
  "bonus_table,%",
  "sidebar",
  "casino_review",
  "casino_review,%",
];

export const LINKS_DYNAMIC_DASHBOARD_SECTION_NAMES = [
  "DomainsAhTrafficRefDomainsSection",
  "DomainsCommonTrustRefDomainsSection",
  "DomainsDistributionPercentageTrafficByTopCountrySection",
  "DomainsTopCountriesDistributionSection",
  "MainLanguagesForDomainsSection",
  "MainTableSection",
  "NumOfPagesByGiForRefDomainsSection",
  "SummaryOfThePageSection",
  "SwCategoryForNicheSection",
  "TopSwCategoryForUrlSection",
  "TrThemeForNicheSection",
  "UrlsAh2ndLevelSection",
  "UrlsAhTrafficSection",
  "UrlsAnchorLinksTypesSection",
  "UrlsAnchorTypesSection",
  "UrlsDrSection",
  "UrlsLinkTypesDrSection",
  "UrlsLinkTypesSection",
  "UrlsLinkTypesUrSection",
  "UrlsUrSection",
  "WebArchiveRefDomainsSection",
  "ByPageTypeUrlSection",
];

export const AD_FIXED_HEADERS = [
  { name: "ph", tooltip: "Phrase" },
  { name: "p", tooltip: "Position" },
  { name: "i", tooltip: "Impression" },
  { name: "vol", tooltip: "Volume" },
  { name: "t", tooltip: "Total Links" },
  { name: "ut", tooltip: "Total Unique Domains" },
  { name: "cl", tooltip: "Clicks" },
  { name: "rl", tooltip: "Real Links" },
];

export const MANAGERS_GPM_FLOW_ITEMS = [
  {
    text: "WR",
    value: "wm_response_at",
  },
  {
    text: "TPA",
    value: "theme_approve_at",
  },
  {
    text: "TA",
    value: "tech_task_appear",
  },
  {
    text: "CNT",
    value: "content_appear_at",
  },
  {
    text: "PBL",
    value: "on_site_appear",
  },
  {
    text: "MNG",
    value: "outreach_manager_approve",
  },
];
export const LINK_DYNAMIC_DASHBOARD_SECTIONS_IDS = {
  BY_PAGE_URL: {
    name: "by Type URL",
    id: "ByPageTypeUrl",
  },
  WA_FOR_RD: {
    name: "WebArchive for Ref Domains",
    id: "WebArchiveForRefDomains",
  },
  TR_THEME_FOR_NICHES: {
    name: "TR Theme for current Niche",
    id: "tr_theme_for_niche",
  },
  NUM_OF_PAGES_BY_GI_RD: {
    name: "Num of pages by GI for Ref Domains",
    id: "NumOfPagesByGiForRefDomains",
  },
  MAIN_LANG_FOR_DOMAINS: {
    name: "Language of Main Page for Domains",
    id: "MainLanguagesForDomains",
  },
  TR_THEME_F_NICHE: {
    name: "TR Theme for current Niche",
    id: "TRThemeForCurrentNiche",
  },
  SW_CAT_F_CURR_NICHE: {
    name: "SW Category for current Niche",
    id: "sw_cateogory_for_niche",
  },
  DIST_OF_T_PERC_TRAFF_B_T_COUNTRY_SW_PRO: {
    name: "Distribution of the percentage traffic by Top Country - SW Pro",
    id: "domains_distribution_percentage_traffic_top_country",
  },
  TOP_SW_CAT_F_CURR_URLS: {
    name: "Top SW Category",
    id: "top_sw_category_for_url",
  },
  URLS_SUM_OF_T_PAGE: {
    name: "URLs Summary",
    id: "urls_summary_of_the_page",
  },
  AH_TRAFF_F_REF_DMNS: {
    name: "AH Traffic for Ref Domains",
    id: "domains_ah_traffic_ref_domains",
  },
  GT_SW_RANK_DIST: {
    name: "GT & SW Rank distribution",
    id: "domains_common_trust_ref_domains",
  },
  URLS_LINK_TYPES_UR: {
    name: "Urls * Link Types * UR",
    id: "urls_link_types_ur",
  },
  URLS_UR: {
    name: "Urls * UR",
    id: "urls_ur",
  },
  URLS_LINK_TYLES_DR: {
    name: "Urls * Link Types * DR",
    id: "urls_link_types_dr",
  },
  URLS_DR: {
    name: "Urls * DR",
    id: "urls_dr",
  },
  URLS_AH_URL_TRAFFIC: {
    name: "URLs * AH URL Traffic",
    id: "urls_ah_2nd_level",
  },
  URLS_AH_2ND_LEVEL: {
    name: "URLs * AH 2nd Level",
    id: "urls_ah_traffic",
  },
  URLS_ANCHOR_TYPES_LINK_TYPES: {
    name: "Urls * Anchor Types * Link Types",
    id: "urls_anchor_links_types",
  },
  URLS_LINK_TYPES_ANCHRO_TYPES: {
    name: "Urls * Link Types * Anchor Types",
    id: "urls_link_anchors_types",
  },
  ULRS_ANCHOR_TYPES: {
    name: "Urls * Anchor Types",
    id: "urls_anchor_types",
  },
  URLS_LINK_TYPES: {
    name: "Urls * Link Types",
    id: "urls_link_types",
  },
  TOP_CNTRY_DISTRIBUTION: {
    name: "TOP Country Distribution - SW Pro",
    id: "domains_top_countries_distribution",
  },
};

export const LINKS_DYNAMIC_DASHBOARD_SECTIONS_DATA = {
  main_lang_for_domains: {
    name: "Language of Main Page for Domains",
    type: "default",
    priority: 19,
    id: "MainLanguagesForDomains",
    sectionName: "MainLanguagesForDomainsSection",
  },
  domains_distribution_percentage_traffic_top_country: {
    name: "Distribution of the percentage traffic by Top Country - SW Pro",
    type: "simple",
    priority: 17,
    id: "domains_distribution_percentage_traffic_top_country",
    sectionName: "DomainsDistributionPercentageTrafficByTopCountrySection",
  },
  domains_common_trust_ref_domains: {
    name: "GT & SW Rank distribution",
    type: "simple",
    priority: 12,
    id: "domains_common_trust_ref_domains",
    sectionName: "DomainsCommonTrustRefDomainsSection",
  },
  domains_ah_traffic_ref_domains: {
    name: "AH Traffic for Ref Domains",
    type: "default",
    priority: 13,
    id: "domains_ah_traffic_ref_domains",
    sectionName: "DomainsAhTrafficRefDomainsSection",
  },
  sw_cateogory_for_niche: {
    name: "SW Category for current Niche",
    type: "vertical",
    priority: 15,
    id: "sw_cateogory_for_niche",
    sectionName: "SwCategoryForNicheSection",
  },
  tr_theme_for_niche: {
    name: "TR Theme for current Niche",
    type: "default",
    priority: 16,
    id: "tr_theme_for_niche",
    sectionName: "TrThemeForNicheSection",
  },
  domains_top_countries_distribution: {
    name: "TOP Country Distribution - SW Pro",
    type: "simple",
    priority: 18,
    id: "domains_top_countries_distribution",
    sectionName: "DomainsTopCountriesDistributionSection",
  },
  top_sw_category_for_url: {
    name: "Top SW Category",
    type: "vertical",
    priority: 14,
    id: "top_sw_category_for_url",
    sectionName: "TopSwCategoryForUrlSection",
  },
  urls_ah_2nd_level: {
    name: "URLs * AH 2nd Level",
    type: "default",
    priority: 6,
    id: "urls_ah_2nd_level",
    sectionName: "UrlsAh2ndLevelSection",
  },
  urls_ah_traffic: {
    name: "URLs * AH URL Traffic",
    type: "default",
    priority: 7,
    id: "urls_ah_traffic",
    sectionName: "UrlsAhTrafficSection",
  },
  urls_anchor_types: {
    name: "Urls * Anchor Types",
    type: "default",
    priority: 3,
    id: "urls_anchor_types",
    sectionName: "UrlsAnchorTypesSection",
  },
  urls_dr: {
    name: "Urls * DR",
    type: "default",
    priority: 10,
    id: "urls_dr",
    sectionName: "UrlsDrSection",
  },
  num_of_pages_by_gi_rd: {
    name: "Num of pages by GI for Ref Domains",
    type: "default",
    priority: 20,
    id: "NumOfPagesByGiForRefDomains",
    sectionName: "NumOfPagesByGiForRefDomainsSection",
  },
  urls_summary_of_the_page: {
    name: "URLs Summary",
    type: "simple",
    priority: 4,
    id: "urls_summary_of_the_page",
    sectionName: "SummaryOfThePageSection",
  },
  all_links: {
    name: "All Links",
    type: "all_links",
    priority: 1,
    id: "all_links",
    sectionName: "MainTableSection",
  },
  urls_link_types: {
    name: "Urls * Link Types",
    type: "default",
    priority: 2,
    id: "urls_link_types",
    sectionName: "UrlsLinkTypesSection",
  },
  urls_link_types_dr: {
    name: "Urls * Link Types * DR",
    type: "simple",
    priority: 11,
    id: "urls_link_types_dr",
    sectionName: "UrlsLinkTypesDrSection",
  },
  urls_link_types_ur: {
    name: "Urls * Link Types * UR",
    type: "simple",
    priority: 9,
    id: "urls_link_types_ur",
    sectionName: "UrlsLinkTypesUrSection",
  },
  urls_ur: {
    name: "Urls * UR",
    type: "default",
    priority: 5,
    id: "urls_ur",
    sectionName: "UrlsUrSection",
  },
  urls_anchor_links_types: {
    name: "Urls * Anchor Types * Link Types",
    type: "simple",
    priority: 8,
    id: "urls_anchor_links_types",
    sectionName: "UrlsAnchorTypesSection",
  },
  urls_link_anchors_types: {
    name: "Urls * Link Types * Anchor Types",
    type: "simple",
    priority: 22,
    id: "urls_link_anchors_types",
    sectionName: "UrlsAnchorLinksTypesSection",
  },
  wa_for_rd: {
    name: "WebArchive for Ref Domains",
    type: "default",
    priority: 21,
    id: "WebArchiveForRefDomains",
    sectionName: "WebArchiveRefDomainsSection",
  },
};

export const DAILY_REPORT_V3_HEADERS = [
  {
    text: "Task Name",
    value: "task_name",
    width: 450,
  },
  {
    text: "Pr",
    value: "priority",
    align: "center",
  },
  {
    text: "T, day",
    value: "now",
    align: "center",
  },
  {
    text: "T, all",
    value: "time_all",
    align: "center",
  },
  {
    text: "T, last 14d",
    value: "last_10_d",
  },
  {
    text: "E",
    value: "max_time",
    align: "center",
  },
  {
    text: "Weekly",
    value: "weekly",
    align: "center",
  },
  {
    text: "Metrics",
    value: "labels",
    width: "175px",
  },
  {
    text: "Deadline",
    value: "date_end",
  },
  {
    text: "Executive",
    value: "executive",
  },
  {
    text: "Project",
    value: "project",
  },
  {
    text: "",
    value: "_json",
  },
  // {
  //   text: "Pr_pr",
  //   value: "priority_project",
  //   align: "center",
  // },
];

export const MOCK_CURRENT = {
  totalTime: 33120000,
  meetingTime: 11520000,
  regularTime: 21600000,
  items: [
    {
      task_name: {
        text: "TDH: Виправити помилки та перенести на сайт",
        value: "https://ra.worksection.com/project/248054/10940266/",
        cd: 3,
      },
      pr: 7,
      abc: "A",
      time: 1380000,
      estimate: 1800000,
      labels: ["meeting", "content", "links"],
      last_10_d: [12, 32, 41, 23, 64, 12, 32, 23, 12, 5],
      executive: {
        text: "Andrii",
        value:
          "https://ra.worksection.com/report/?order=sum_time&desc=0&ctm=0&ctm_=05.09.2022_05.09.2022&sel_user=397832&flt=1",
      },
      pr_pr: 144,
    },
    {
      task_name: {
        text: "Content Planning: зробити щоб при натисканні прокидувалося в SiteWorkplace",
        value: "https://ra.worksection.com/project/248054/10940266/",
        cd: 3,
      },
      pr: 7,
      abc: "A",
      time: 1380000,
      estimate: 1800000,
      labels: ["meeting", "content", "links"],
      last_10_d: [12, 32, 41, 23, 64, 12, 32, 23, 12, 5],
      executive: {
        text: "Andrii",
        value:
          "https://ra.worksection.com/report/?order=sum_time&desc=0&ctm=0&ctm_=05.09.2022_05.09.2022&sel_user=397832&flt=1",
      },
      pr_pr: 144,
    },
    {
      task_name: {
        text: "SiteWorkplace / Content Planning",
        value: "https://ra.worksection.com/project/248054/10940266/",
        cd: 2,
      },
      pr: 13,
      abc: "B",
      time: 1880000,
      estimate: 2300000,
      labels: ["meeting", "links"],
      last_10_d: [12, 12, 11, 65, 34, 32, 22, 11, 0, 45],
      executive: {
        text: "Vitaly",
        value:
          "https://ra.worksection.com/report/?order=sum_time&desc=0&ctm=0&ctm_=05.09.2022_05.09.2022&sel_user=397832&flt=1",
      },
      pr_pr: 144,
    },
  ],
};

export const LINK_PLANNING_BY_URL_HEADERS = [
  {
    text: "Url",
    value: "url",
  },
  {
    text: "GP Jun",
    value: "$gp_jun",
    align: "center",
  },
  {
    text: "GP Jul",
    value: "$gp_jul",
    align: "center",
  },
  {
    text: "GP Aug",
    value: "$gp_aug",
    align: "center",
  },
  {
    text: "NT",
    value: "need_top",
    align: "center",
  },
  {
    text: "Url P",
    value: "url_priority",
    align: "center",
  },
  {
    text: "Pr pr",
    value: "pr_pr",
    align: "center",
  },
];

export const MOCK_LINK_PLANNING_BY_URLS = [
  {
    url: "https://aussiebestcasinos.com/reviews/spin-samurai-casino/",
    $gp_jun: {
      on_page: rand(0, 30),
      noindex: rand(0, 30),
      plan: rand(0, 30),
      plan_status: 0,
    },
    $gp_jul: {
      on_page: rand(0, 30),
      noindex: rand(0, 30),
      plan: rand(0, 30),
      plan_status: -1,
    },
    $gp_aug: {
      on_page: rand(0, 30),
      noindex: rand(0, 30),
      plan: rand(0, 30),
      plan_status: 1,
    },
    need_top: 3,
    url_priority: 10,
    pr_pr: 42,
  },
  {
    url: "https://aussiebestcasinos.com/",
    $gp_jun: {
      on_page: rand(0, 30),
      noindex: rand(0, 30),
      plan: rand(0, 30),
      plan_status: 1,
    },
    $gp_jul: {
      on_page: rand(0, 30),
      noindex: rand(0, 30),
      plan: rand(0, 30),
      plan_status: 1,
    },
    $gp_aug: {
      on_page: rand(0, 30),
      noindex: rand(0, 30),
      plan: rand(0, 30),
      plan_status: 0,
    },
    need_top: 5,
    url_priority: 2,
    pr_pr: 32,
  },
  {
    url: "https://aussiebestcasinos.com/safe-online-casino/",
    $gp_jun: {
      on_page: rand(0, 30),
      noindex: rand(0, 30),
      plan: rand(0, 30),
      plan_status: 1,
    },
    $gp_jul: {
      on_page: rand(0, 30),
      noindex: rand(0, 30),
      plan: rand(0, 30),
      plan_status: 1,
    },
    $gp_aug: {
      on_page: rand(0, 30),
      noindex: rand(0, 30),
      plan: rand(0, 30),
      plan_status: 1,
    },
    need_top: 1,
    url_priority: 6,
    pr_pr: 22,
  },
];

export const O_Y_MOCK = {
  positions: {
    items: [
      {
        name: {
          text: "visibility, %",
        },
        t: 11,
        $1w: 13,
        $2w: 14,
        $3w: 13,
        $1m: 13,
        $2m: 13,
      },
      {
        name: {
          text: "traffic",
        },
        t: 9135,
        $1w: 15022,
        $2w: 15192,
        $3w: 14330,
        $1m: 14212,
        $2m: 15609,
      },
      {
        name: {
          text: "Num of ...",
          divider: 1,
        },
        t: null,
        $1w: null,
        $2w: null,
        $3w: null,
        $1m: null,
        $2m: null,
      },
      {
        name: {
          text: "keys",
        },
        t: 2131,
        $1w: 2444,
        $2w: 2493,
        $3w: 2410,
        $1m: 2347,
        $2m: 2744,
      },
      {
        name: {
          text: "Num of keys in top...",
          divider: 1,
        },
        t: null,
        $1w: null,
        $2w: null,
        $3w: null,
        $1m: null,
        $2m: null,
      },
      {
        name: {
          text: "1",
          align: "right",
        },
        t: 5,
        $1w: 7,
        $2w: 14,
        $3w: 11,
        $1m: 18,
        $2m: 7,
      },
      {
        name: { text: "3", align: "right" },
        t: 41,
        $1w: 75,
        $2w: 80,
        $3w: 72,
        $1m: 68,
        $2m: 82,
      },
      {
        name: {
          text: "10",
          align: "right",
        },
        t: 642,
        $1w: 672,
        $2w: 694,
        $3w: 679,
        $1m: 635,
        $2m: 707,
      },
      {
        name: {
          text: "20",
          align: "right",
        },
        t: 331,
        $1w: 363,
        $2w: 411,
        $3w: 392,
        $1m: 377,
        $2m: 451,
      },
      {
        name: { text: "50", align: "right" },
        t: 600,
        $1w: 737,
        $2w: 721,
        $3w: 667,
        $1m: 722,
        $2m: 849,
      },
      {
        name: {
          text: "100",
          align: "right",
        },
        t: 514,
        $1w: 590,
        $2w: 574,
        $3w: 592,
        $1m: 532,
        $2m: 652,
      },
    ],
  },
  conversionDynamics: {
    items: [
      {
        name: {
          text: "Clicks GSC",
        },
        t: rand(0, 500),
        $1w: rand(0, 500),
        $2w: rand(0, 500),
        $3w: rand(0, 500),
        $1m: rand(0, 500),
        $2m: rand(0, 500),
      },
      {
        name: {
          text: "Clicks Keitaro",
        },
        t: rand(0, 500),
        $1w: rand(0, 500),
        $2w: rand(0, 500),
        $3w: rand(0, 500),
        $1m: rand(0, 500),
        $2m: rand(0, 500),
      },
      {
        name: {
          text: "Clicks/GSC,%",
          divider: 2,
        },
        t: rand(0, 500),
        $1w: rand(0, 500),
        $2w: rand(0, 500),
        $3w: rand(0, 500),
        $1m: rand(0, 500),
        $2m: rand(0, 500),
      },
      {
        name: {
          text: "uCl",
        },
        t: rand(0, 500),
        $1w: rand(0, 500),
        $2w: rand(0, 500),
        $3w: rand(0, 500),
        $1m: rand(0, 500),
        $2m: rand(0, 500),
      },
      {
        name: {
          text: "uCl/GSC,%",
          divider: 2,
        },
        t: rand(0, 500),
        $1w: rand(0, 500),
        $2w: rand(0, 500),
        $3w: rand(0, 500),
        $1m: rand(0, 500),
        $2m: rand(0, 500),
      },
      {
        name: {
          text: "Reg",
        },
        t: rand(0, 500),
        $1w: rand(0, 500),
        $2w: rand(0, 500),
        $3w: rand(0, 500),
        $1m: rand(0, 500),
        $2m: rand(0, 500),
      },
      {
        name: {
          text: "Reg/Keitaro,%",
        },
        t: rand(0, 500),
        $1w: rand(0, 500),
        $2w: rand(0, 500),
        $3w: rand(0, 500),
        $1m: rand(0, 500),
        $2m: rand(0, 500),
      },
      {
        name: {
          text: "Reg/GSC,%",
          divider: 2,
        },
        t: rand(0, 500),
        $1w: rand(0, 500),
        $2w: rand(0, 500),
        $3w: rand(0, 500),
        $1m: rand(0, 500),
        $2m: rand(0, 500),
      },
      {
        name: {
          text: "NDC",
        },
        t: rand(0, 500),
        $1w: rand(0, 500),
        $2w: rand(0, 500),
        $3w: rand(0, 500),
        $1m: rand(0, 500),
        $2m: rand(0, 500),
      },
      {
        name: {
          text: "NDC/Keitaro,%",
        },
        t: rand(0, 500),
        $1w: rand(0, 500),
        $2w: rand(0, 500),
        $3w: rand(0, 500),
        $1m: rand(0, 500),
        $2m: rand(0, 500),
      },
      {
        name: {
          text: "NDC/GSC,%",
        },
        t: rand(0, 500),
        $1w: rand(0, 500),
        $2w: rand(0, 500),
        $3w: rand(0, 500),
        $1m: rand(0, 500),
        $2m: rand(0, 500),
      },
    ],
  },
  conversionByPageTypes: {
    items: [
      {
        name: {
          text: "Num of pages",
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
      {
        name: {
          text: "Clicks GSC",
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
      {
        name: {
          text: "Clicks Keitaro",
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
      {
        name: {
          text: "Clicks/GSC,%",
          divider: 2,
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
      {
        name: {
          text: "uCl",
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
      {
        name: {
          text: "uCl/GSC,%",
          divider: 2,
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
      {
        name: {
          text: "Reg",
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
      {
        name: {
          text: "Reg/Keitaro,%",
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
      {
        name: {
          text: "Reg/GSC,%",
          divider: 2,
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
      {
        name: {
          text: "NDC",
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
      {
        name: {
          text: "NDC/Keitaro,%",
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
      {
        name: {
          text: "NDC/GSC,%",
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
    ],
  },
  linksByProject: {
    items: [
      {
        name: {
          text: "Links Type",
          divider: 1,
        },
        all: {
          value: null,
          comp: null,
        },
        review: {
          value: null,
          comp: null,
        },
        payment: {
          value: null,
          comp: null,
        },
        $w5: {
          value: null,
          comp: null,
        },
        $w4: {
          value: null,
          comp: null,
        },
        $w3: {
          value: null,
          comp: null,
        },
        $w2: {
          value: null,
          comp: null,
        },
        $w1: {
          value: null,
          comp: null,
        },
      },
      {
        name: {
          text: "Guest post",
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
      {
        name: {
          text: "Main Page",
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
      {
        name: {
          text: "Main Page",
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
      {
        name: {
          text: "Anchor Type",
          divider: 1,
        },
        all: {
          value: null,
          comp: null,
        },
        review: {
          value: null,
          comp: null,
        },
        payment: {
          value: null,
          comp: null,
        },
        $w5: {
          value: null,
          comp: null,
        },
        $w4: {
          value: null,
          comp: null,
        },
        $w3: {
          value: null,
          comp: null,
        },
        $w2: {
          value: null,
          comp: null,
        },
        $w1: {
          value: null,
          comp: null,
        },
      },
      {
        name: {
          text: "Commercial",
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
      {
        name: {
          text: "Commercial Broad",
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
      {
        name: {
          text: "Brand",
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
      {
        name: {
          text: "Domain Url",
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
      {
        name: {
          text: "Generic",
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
      {
        name: {
          text: "Other",
        },
        all: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        review: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        payment: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w5: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w4: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w3: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w2: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
        $w1: {
          value: rand(0, 1000),
          comp: rand(-100, 100),
        },
      },
    ],
  },
};

export const OYR_POSITION_TABLE_HEADERS = [
  {
    text: "Name",
    value: "name",
    width: "175px",
  },
  {
    text: "Dynamic",
    value: "_dynamic",
    width: "125px",
  },
  {
    text: "t",
    value: "t",
    align: "center",
  },
  {
    text: "y",
    value: "$y",
    align: "center",
  },
  {
    text: "1w",
    value: "$1w",
    align: "center",
  },
  {
    text: "2w",
    value: "$2w",
    align: "center",
  },
  {
    text: "3w",
    value: "$3w",
    align: "center",
  },
  {
    text: "1m",
    value: "$1m",
    align: "center",
  },
  {
    text: "2m",
    value: "$2m",
    align: "center",
  },
  {
    text: "3m",
    value: "$3m",
    align: "center",
  },
  {
    text: "6m",
    value: "$6m",
    align: "center",
  },
  {
    text: "9m",
    value: "$9m",
    align: "center",
  },
  {
    text: "12m",
    value: "$12m",
    align: "center",
  },
  {
    text: "18m",
    value: "$18m",
    align: "center",
  },
  {
    text: "24m",
    value: "$24m",
    align: "center",
  },
];

export const ONE_YEAR_REPORT_POSITIONS_TABLE_HEADERS = [
  {
    text: "Name",
    value: "name",
    width: "175px",
  },
  {
    text: "Bonus",
    value: "$bonus",
    align: "center",
  },
  {
    text: "Casino",
    value: "$casino",
    align: "center",
  },
  {
    text: "Games",
    value: "$games",
    align: "center",
  },
  {
    text: "Payment",
    value: "$payment",
    align: "center",
  },
  {
    text: "Review",
    value: "$review",
    align: "center",
  },
];
export const ONE_YEAR_REPORT_CONVERSION_TABLE_HEADERS = [
  {
    text: "Name",
    value: "name",
    width: "175px",
  },
  {
    text: "All",
    value: "all",
    align: "center",
  },
  {
    text: "Review",
    value: "review",
    align: "center",
  },
  {
    text: "Payment",
    value: "payment",
    align: "center",
  },
  {
    text: "1w",
    value: "$1w",
    align: "center",
  },
  {
    text: "2w",
    value: "$2w",
    align: "center",
  },
  {
    text: "3w",
    value: "$3w",
    align: "center",
  },
  {
    text: "1m",
    value: "$1m",
    align: "center",
  },
  {
    text: "2m",
    value: "$2m",
    align: "center",
  },
  {
    text: "3m",
    value: "$3m",
    align: "center",
  },
  {
    text: "6m",
    value: "$6m",
    align: "center",
  },
  {
    text: "9m",
    value: "$9m",
    align: "center",
  },
  {
    text: "12m",
    value: "$12m",
    align: "center",
  },
  {
    text: "18m",
    value: "$18m",
    align: "center",
  },
];
export const ONE_YEAR_REPORT_LINKS_BY_PROJECT_TABLE_HEADERS = [
  {
    text: "Name",
    value: "name",
    width: "175px",
  },
  {
    text: "t",
    value: "$t",
    align: "center",
  },
  {
    text: "y",
    value: "$y",
    align: "center",
  },
  {
    text: "1w",
    value: "$1w",
    align: "center",
  },
  {
    text: "2w",
    value: "$2w",
    align: "center",
  },
  {
    text: "3w",
    value: "$3w",
    align: "center",
  },
  {
    text: "1m",
    value: "$1m",
    align: "center",
  },
  {
    text: "2m",
    value: "$2m",
    align: "center",
  },
  {
    text: "3m",
    value: "$3m",
    align: "center",
  },
  {
    text: "6m",
    value: "$6m",
    align: "center",
  },
  {
    text: "9m",
    value: "$9m",
    align: "center",
  },
  {
    text: "12m",
    value: "$12m",
    align: "center",
  },
  {
    text: "18m",
    value: "$18m",
    align: "center",
  },
  {
    text: "24m",
    value: "$24m",
    align: "center",
  },
];

export const NDC_MANAGE_TABLE_HEADERS = [
  {
    text: "",
    value: "_actions",
    align: "center",
    type: "none",
    sortable: false,
  },
  {
    text: "Partnership",
    value: "partnership",
    type: "text",
  },
  {
    text: "FIN Account",
    value: "fin_account",
    type: "text",
  },
  {
    text: "Login",
    value: "login",
    type: "text",
  },
  {
    text: "Casino Brand",
    value: "casino_brand",
    type: "text",
  },
  {
    text: "Site/Subprojects",
    value: "site",
    type: "text",
  },
  {
    text: "Fin project",
    value: "fin_project",
    type: "none",
  },
  {
    text: "Seo",
    value: "seo",
    type: "text",
  },
  {
    text: "Month",
    value: "month",
    type: "date",
  },
  {
    text: "$",
    value: "money",
    align: "right",
    type: "number",
  },
  {
    text: "NDC",
    value: "ndc",
    align: "right",
    type: "number",
  },
  {
    text: "Last update",
    value: "updated_at",
    align: "right",
    type: "none",
  },
];

export const MOCJ123123 = [
  {
    name: "Total and Plan",
    categories: ["Oct 2022", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    series: [
      {
        name: "SUM of Money",
        data: [
          rand(0, 100),
          rand(0, 300),
          rand(0, 500),
          rand(0, 700),
          rand(0, 900),
          rand(0, 1100),
          rand(0, 1300),
          rand(0, 3000),
          rand(0, 4000),
          rand(0, 5000),
        ],
      },
      {
        name: "SUM of Plan",
        data: [
          rand(0, 100),
          rand(0, 300),
          rand(0, 500),
          rand(0, 700),
          rand(0, 900),
          rand(0, 1100),
          rand(0, 1300),
          rand(0, 3000),
          rand(0, 4000),
          rand(0, 5000),
        ],
      },
    ],
  },
  {
    name: "Total and Plan Ndc",
    categories: ["Oct 2022", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    series: [
      {
        name: "SUM of Money",
        type: "column",
        data: [
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
        ],
      },
      {
        name: "SUM of Plan",
        type: "column",
        data: [
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
        ],
      },
    ],
  },
  {
    name: "papersowl.com - money",
    categories: ["Oct 2022", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    series: [
      {
        name: "SUM of Money",
        type: "column",
        data: [
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
        ],
      },
      {
        name: "SUM of Plan",
        type: "column",
        data: [
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
        ],
      },
    ],
  },
  {
    name: "papersowl.com - ndc",
    categories: ["Oct 2022", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    series: [
      {
        name: "SUM of Ndc",
        type: "column",
        data: [
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
        ],
      },
      {
        name: "SUM of Plan",
        type: "column",
        data: [
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
          rand(0, 1000),
        ],
      },
    ],
  },
];

export const CB_ANALYTICS_HEADERS = [
  {
    text: "Url",
    value: "url",
  },
  {
    text: "Text size",
    value: "text_size",
  },
  {
    text: "Main Phrases by Vision",
    value: "main_phrases_by_vision",
    align: "center",
  },
  {
    text: "Snippet",
    value: "snippet",
    align: "center",
  },
  {
    text: "Related",
    value: "related",
    align: "center",
  },
  {
    text: "Suggest",
    value: "suggest",
    align: "center",
  },
  {
    text: "ONLY 1 Stop Word",
    value: "stop_word_1",
    align: "center",
  },
  {
    text: "By words 2 others",
    value: "by_words_2_others",
    align: "center",
  },
  {
    text: "ONLY 2 Stop Word",
    value: "stop_word_2",
    align: "center",
  },
  {
    text: "By words 3 others",
    value: "by_words_3_others",
    align: "center",
  },
  {
    text: "ONLY 3 Stop Word",
    value: "stop_word_3",
    align: "center",
  },
  {
    text: "Words 6-10",
    value: "words_6_10",
    align: "center",
  },
  {
    text: "Words 5 Without Stop Words",
    value: "without_stop_word_5",
    align: "center",
  },
  {
    text: "Words 4 Without Stop Words",
    value: "without_stop_word_4",
    align: "center",
  },
  {
    text: "Words 3 Without Stop Words",
    value: "without_stop_word_3",
    align: "center",
  },
  {
    text: "Words 2 Without Stop Words",
    value: "without_stop_word_2",
    align: "center",
  },
];

export const CB_ANALYTICS_MOCK_ITEMS = [
  {
    url: "https://casinotop.co.nz/fastest-payout-casino/",
    text_size: null,
    main_phrases_by_vision: 15,
    snippet: 15,
    related: 10,
    suggest: 10,
    stop_word_1: "all",
    by_words_2_others: "all",
    stop_word_2: "all",
    by_words_3_others: "all",
    stop_word_3: "all",
    words_6_10: "all",
    without_stop_word_5: "all",
    without_stop_word_4: "all",
    without_stop_word_3: "all",
    without_stop_word_2: "all",
  },
  {
    url: "https://casinotop.co.nz/fastest-payout-casino/",
    text_size: null,
    main_phrases_by_vision: 80,
    snippet: 80,
    related: 15,
    suggest: 15,
    stop_word_1: 80,
    by_words_2_others: 80,
    stop_word_2: 80,
    by_words_3_others: 80,
    stop_word_3: 80,
    words_6_10: 40,
    without_stop_word_5: 40,
    without_stop_word_4: 50,
    without_stop_word_3: 60,
    without_stop_word_2: 80,
  },
  {
    url: "https://casinotop.co.nz/fastest-payout-casino/",
    text_size: null,
    main_phrases_by_vision: null,
    snippet: null,
    related: null,
    suggest: null,
    stop_word_1: null,
    by_words_2_others: null,
    stop_word_2: null,
    by_words_3_others: null,
    stop_word_3: null,
    words_6_10: null,
    without_stop_word_5: null,
    without_stop_word_4: null,
    without_stop_word_3: null,
    without_stop_word_2: null,
  },
];

export const TOOLS_MENU_ITEMS = [
  {
    link: "dashboard-dev.detailed",
    icon: "mdi-dev-to",
    title: "Dev Dashboard Detailed",
  },
  {
    link: "niche.analyzer.index",
    icon: "mdi-cloud-search",
    title: "Niche Analyzer",
    separator: true,
  },
  {
    link: "semantic.tool.index",
    icon: "mdi-table-key",
    title: "Semantic Tool",
  },
  {
    link: "view.info.by.site.index",
    icon: "mdi-information-outline",
    title: "View Info By Site",
  },
  {
    link: "site-analysis",
    icon: "mdi-information-outline",
    title: "Site Analysis",
    separator: true,
  },
  {
    link: "mailer.index",
    icon: "mdi-email-outline",
    title: "Mailer",
    separator: true,
  },
  {
    link: "aparser.task.index",
    icon: "mdi-link-box",
    title: "Aparser Tasks",
    separator: true,
  },
  {
    link: "aparser.back-links.index",
    icon: "mdi-link-box",
    title: "Aparser BackLinks",
  },
  {
    link: "textrazor.index",
    icon: "mdi-book-open-page-variant-outline",
    title: "Aparser TextRazor",
    separator: true,
  },
  {
    link: "similar-task-creating.choose-type",
    icon: "mdi-hammer-wrench",
    title: "Create Tasks",
  },
];

export const DETAIL_TABLE_HEADERS = [
  {
    text: "p",
    value: "p",
    align: "start",
  },
  {
    text: "ph",
    value: "ph",
    align: "start",
  },
  {
    text: "op",
    value: "op",
    align: "center",
  },
  {
    text: "mp",
    value: "mp",
    align: "center",
  },
  {
    text: "o",
    value: "o",
    align: "center",
  },
  {
    text: "m",
    value: "m",
    align: "center",
  },
  {
    text: "c",
    value: "c",
    align: "center",
  },
];

export const DAILY_OVERALL_REPORT_TABLE_HEADERS = [
  {
    text: "Team",
    value: "team",
  },
  {
    text: "Role",
    value: "role",
  },
  {
    text: "Executive",
    value: "executive",
  },
  {
    text: "Date Time",
    value: "date_time",
    align: "center",
  },
  {
    text: "Last 14d",
    value: "last_14d",
    align: "center",
    width: "100px",
  },
  {
    text: "WeekA",
    value: "week_a",
    align: "center",
  },
  {
    text: "L 7d",
    value: "l_a_7d",
    align: "center",
    width: "100px",
  },
  {
    text: "WeekB",
    value: "week_b",
    align: "center",
  },
  {
    text: "L 7d",
    value: "l_b_7d",
    align: "center",
    width: "100px",
  },
  {
    text: "WeekC",
    value: "week_c",
    align: "center",
  },
  {
    text: "L 7d",
    value: "l_c_7d",
    align: "center",
    width: "100px",
  },
  {
    text: "TT",
    value: "total_time",
    align: "center",
  },
  {
    text: "wABC",
    value: "wABC",
    align: "center",
  },
  {
    text: "! C",
    title: "! Critical",
    value: "critical",
    align: "center",
  },
  {
    text: "W MS",
    title: "Weekly MS",
    value: "weekly_ms",
    align: "center",
  },
  {
    text: "LB O",
    title: "LB Overview",
    value: "lb_overview",
    align: "center",
  },
  {
    text: "1 to 1",
    value: "1-to-1",
    align: "center",
  },
  {
    text: "Overdue",
    value: "overdue",
    align: "center",
  },
  {
    text: "Today's plan",
    value: "today_plan",
    align: "center",
  },
  {
    text: "KPI",
    value: "kpi",
    align: "center",
  },
];

export const GP_DONORS_FINDER_TABLE_HEADERS = [
  {
    text: "Name",
    value: "name",
  },
  {
    text: "Status",
    value: "status",
    width: "300px",
  },
  {
    text: "Link Type",
    value: "link_type",
  },
  {
    text: "Niche",
    value: "niche",
  },
  {
    text: "Domains",
    value: "domains",
  },
  {
    text: "Date",
    value: "date",
  },
  {
    text: "Actions",
    value: "_actions",
  },
];

export const MONTHLY_REPORT_TABLE_MOCK_ITEMS = [
  {
    name: "Casinotop.co.nz",
    items: [
      {
        name: "test1",
        pr: 24,
        stage: ["! Critical"],
        deadline: "30-04-2022",
        time: "421412",
        estimate: "421424",
        executive: "hordiienko.rodion@boosta.co",
        label: "1. Strategic",
      },
      {
        name: "test1",
        pr: 24,
        stage: ["! Critical"],
        deadline: "30-04-2022",
        time: "421412",
        estimate: "421424",
        executive: "hordiienko.rodion@boosta.co",
        label: "2. Conversion",
      },
      {
        name: "test1",
        pr: 24,
        stage: ["! Critical"],
        deadline: "30-04-2022",
        time: "421412",
        estimate: "421424",
        executive: "hordiienko.rodion@boosta.co",
        label: "3. Links",
      },
      {
        name: "test1",
        pr: 24,
        stage: ["! Critical"],
        deadline: "30-04-2022",
        time: "421412",
        estimate: "421424",
        executive: "hordiienko.rodion@boosta.co",
        label: "4. Content",
      },
    ],
  },
];
export const MONTHLY_REPORT_TABLE_HEADERS = [
  {
    text: "Name",
    value: "name",
  },
  {
    text: "Statuses",
    value: "statuses",
    width: "220px",
  },
  {
    text: "Dynamic",
    value: "dynamic",
    width: "140px",
  },
  {
    text: "Time",
    value: "time",
    align: "center",
    width: "75px",
  },
  {
    text: "Pr",
    value: "priority",
    align: "center",
    width: "75px",
  },
  {
    text: "D",
    value: "deadline",
    width: "90px",
    align: "center",
  },
  {
    text: "E",
    value: "estimate",
    align: "center",
    width: "75px",
  },
  {
    text: "Sprint",
    value: "sprint",
    width: "300px",
    align: "center",
  },
  {
    text: "Stage",
    value: "stage",
    width: "120px",
  },
  {
    text: "Ex",
    value: "executive",
    align: "center",
    width: "90px",
  },
  {
    text: "Done",
    value: "status",
    width: "75px",
  },
];

export const FLEX_FINDER_TABLE_HEADERS = [
  {
    text: "URL Donor",
    value: "url_donor",
  },
  {
    text: "URL Acceptor",
    value: "url_acceptor",
  },
  {
    text: "Anchor",
    value: "anchor",
  },
  {
    text: "h1 (Acceptor)",
    value: "h1",
  },
  {
    text: "Link Type",
    value: "link_type",
  },
  {
    text: "Do Follow",
    value: "do_follow",
  },
  {
    text: "Date",
    value: "date",
  },
  {
    text: "Niche",
    value: "niche",
  },
  {
    text: "SW Category",
    value: "sw_category",
  },
  {
    text: "Positions Growth",
    value: "positions_growth",
  },
  {
    text: "Start Positions",
    value: "start_positions",
  },
];

export const TDH1_OLD_COLLECTED_DATA_HEADERS = [
  {
    text: "Date",
    value: "date",
    width: "110px",
  },
  {
    text: "Vision",
    value: "vision",
    align: "center",
    width: "90px",
  },
  {
    text: "Url",
    value: "url",
  },
];

export const TDH1_OLD_COLLECTED_DATA_MOCK = [
  {
    date: "10.09.2022",
    vision: 0.24,
    url: "http://url1",
  },
  {
    date: "11.09.2022",
    vision: 0.2,
    url: "http://url2",
  },
  {
    date: "10.09.2022",
    vision: 0.12,
    url: "http://url3",
  },
  {
    date: "12.09.2022",
    vision: 0.06,
    url: "http://url4",
  },
  {
    date: "12.09.2022",
    vision: 0.05,
    url: "http://url5",
  },
];

export const OPTIMIZATION_TABLE_HEADERS = [
  {
    text: "Phrase",
    value: "phrase",
  },
  {
    text: "Used",
    value: "entry",
  },
  {
    text: "Sum of use",
    value: "sum_of_use",
  },
  {
    text: "0.24",
    value: "$v1",
  },
  {
    text: "0.2",
    value: "$v2",
  },
  {
    text: "0.12",
    value: "$v3",
  },
  {
    text: "0.8",
    value: "$v4",
  },
  {
    text: "0.4",
    value: "$v5",
  },
  {
    text: "0.6",
    value: "$v6",
  },
  {
    text: "0.02",
    value: "$v7",
  },
  {
    text: "0.02",
    value: "$v8",
  },
  {
    text: "0.01",
    value: "$v9",
  },
  {
    text: "0.01",
    value: "$v10",
  },
  {
    text: "Pr",
    value: "pr",
  },
  {
    text: "Volume",
    value: "volume",
    align: "center",
  },
  {
    text: "Cl(28d)",
    value: "cl_28d",
    align: "center",
  },
  {
    text: "Imo(28d)",
    value: "imp_28d",
    align: "center",
  },
];
export const OPTIMIZATION_TABLE_MOCK = [
  {
    phrase: "1$ deposit casino",
    entry: 1,
    sum_of_use: rand(0, 255),
    $v1: rand(0, 1),
    $v2: rand(0, 1),
    $v3: rand(0, 1),
    $v4: rand(0, 1),
    $v5: rand(0, 1),
    $v6: rand(0, 1),
    $v7: rand(0, 1),
    $v8: rand(0, 1),
    $v9: rand(0, 1),
    $v10: rand(0, 1),
    pr: "f",
    volume: rand(0, 5000),
    cl_28d: rand(0, 200),
    imp_28d: rand(0, 200),
  },
  {
    phrase: "1 dollar casino",
    entry: 1,
    sum_of_use: rand(0, 255),
    $v1: rand(0, 1),
    $v2: rand(0, 1),
    $v3: rand(0, 1),
    $v4: rand(0, 1),
    $v5: rand(0, 1),
    $v6: rand(0, 1),
    $v7: rand(0, 1),
    $v8: rand(0, 1),
    $v9: rand(0, 1),
    $v10: rand(0, 1),
    pr: "f",
    volume: rand(0, 5000),
    cl_28d: rand(0, 200),
    imp_28d: rand(0, 200),
  },
  {
    phrase: "1 dollar deposit casinos",
    entry: 1,
    sum_of_use: rand(0, 255),
    $v1: rand(0, 1),
    $v2: rand(0, 1),
    $v3: rand(0, 1),
    $v4: rand(0, 1),
    $v5: rand(0, 1),
    $v6: rand(0, 1),
    $v7: rand(0, 1),
    $v8: rand(0, 1),
    $v9: rand(0, 1),
    $v10: rand(0, 1),
    pr: "f",
    volume: rand(0, 5000),
    cl_28d: rand(0, 200),
    imp_28d: rand(0, 200),
  },
];

export const GP_CURRENT_RESULT_HEADERS = [
  {
    text: "Project",
    value: "p_name",
  },
  {
    text: "Tasks",
    value: "t_name",
  },
  {
    text: "Plan",
    value: "plan",
    align: "center",
  },
  {
    text: "In Progress",
    value: "in_progress",
    align: "center",
  },
  {
    text: "Done",
    value: "done",
    align: "center",
  },
  // {
  //   text: "Active&Done",
  //   value: "active_done",
  //   align: "center",
  // },
  {
    text: "Topic approve",
    value: "topic_approve",
    align: "center",
  },
  {
    text: "Waiting for content",
    value: "waiting_content",
    align: "center",
  },
  {
    text: "Publication Process",
    value: "publication_proccess",
    align: "center",
  },
  {
    text: "Approving placement by SEO",
    value: "aprove_seo",
    align: "center",
  },
  {
    text: "Donors",
    value: "active_donors",
    align: "center",
  },
  {
    text: "Send Letter",
    value: "send_letter",
    align: "center",
  },
  {
    text: "Canceled",
    value: "canceled",
    align: "center",
  },
  {
    text: "Reserved",
    value: "reserved",
    align: "center",
  },
  {
    text: "Pause",
    value: "pause",
    align: "center",
  },
  {
    text: "Seo",
    value: "seo",
  },
  {
    text: "Executive",
    value: "executive",
  },
  {
    text: "Priority",
    value: "priority",
    align: "end",
  },
];

export const TDH1_SECTION_INTERFACE = {
  competitors: {
    items: [],
    status: false,
  },
  semantic: {
    headers: [],
    items: [],
  },
  byWord: {
    headers: [],
    items: [],
  },
  topByCompetitors: {
    headers: [],
    items: [],
  },
  byTop10: {
    headers: [],
    items: [],
  },
};

export const ACTIVITY_TABLE_HEADERS = [
  { text: "", value: "_actions" },
  { text: "Url", value: "url" },
  { text: "Amount", value: "amount" },
  { text: "Content Score", value: "content_score" },
  { text: "Total Words", value: "total_words" },
  { text: "Total Sentences", value: "total_sentences" },
  {
    text: "Average sentence length",
    value: "average_sentence_length",
  },
  { text: "Semantic Vocabulary", value: "semantic_vocabulary" },
  { text: "Vocabulary", value: "vocabulary" },
  { text: "Water Index", value: "water_index" },
  { text: "Naturalness", value: "zipfs_law" },
  {
    text: "Classical Nausea Index",
    value: "classical_nausea_index",
  },
  { text: "Dale–Chall Readability", value: "dale_chall_score" },
  { text: "Spache Readability", value: "spache" },
  { text: "Flesch Readability", value: "flesch" },
  {
    text: "Automated readability index Readabilit",
    value: "automated_index",
  },
];

export const GUEST_POST_UPLOAD_TABLE_HEADERS = [
  {
    text: "Donor",
    value: "donor",
  },
  {
    text: "Used in my Tasks",
    value: "used_in_my_tasks",
  },
  {
    text: "Bad Status",
    value: "bad_status",
  },
  {
    text: "Decline Reasons",
    value: "decline_reasons",
  },
  {
    text: "Used in LTT",
    value: "used_in_ltt",
  },
  {
    text: "Removed",
    value: "removed",
  },
  {
    text: "Ltt on page",
    value: "ltt_on_page",
  },
  {
    text: "Ltt last posting",
    value: "ltt_last_posting",
  },
];

export const PAGE_TYPES = [
  { value: 0, text: "default" },
  { value: 1, text: "casino" },
  { value: 2, text: "review" },
  { value: 3, text: "payment" },
  { value: 4, text: "bonus" },
  { value: 5, text: "games" },
  { value: 6, text: "commercial" },
  { value: 7, text: "blog" },
  { value: 8, text: "tools" },
  { value: 9, text: "sample" },
  { value: 10, text: "sampleCategory" },
  { value: 11, text: "reviewBrand" },
  { value: 12, text: "reviewBestOf" },
  { value: 13, text: "info" },
  { value: 14, text: "betting" },
  { value: 15, text: "mindep" },
];

export const COMP_MOCK = [
  {
    value: null,
    url: "https://vinsonar.com/blog/best-spark-plugs-for-5-7-hemi/",
    vision: "OUR",
    date: "1970-01-01",
  },
  {
    value: null,
    url: "https://www.youtube.com/watch?v=gJj6yNO8X4E",
    vision: 0.43,
    date: "1970-01-01",
  },
  {
    value: null,
    url: "https://southseascycles.com/best-spark-plugs-for-5-7ll-hemi/",
    vision: 0.29,
    date: "1970-01-01",
  },
  {
    value: null,
    url: "https://www.replicarclub.com/best-spark-plugs-for-5-7-hemi/",
    vision: 0.14,
    date: "1970-01-01",
  },
  {
    value: null,
    url: "https://www.jeepzine.com/best-spark-plugs-for-dodge-ram-1500/",
    vision: 0.14,
    date: "1970-01-01",
  },
  {
    value: null,
    url: "https://internal.unitedsoybean.org/contextual?dataid=12754&FileName=2004%20neon%20owners%20manual.pdf",
    vision: 0,
    date: "1970-01-01",
  },
  {
    value: null,
    url: "https://www.forbes.com/wheels/accessories/best-spark-plugs/",
    vision: 0,
    date: "1970-01-01",
  },
  {
    value: null,
    url: "https://www.dodgegarage.com/news/article/how-to/2019/03/sparks-and-recreation.html",
    vision: 0,
    date: "1970-01-01",
  },
  {
    value: null,
    url: "https://www.rockauto.com/en/catalog/ram,2015,1500,5.7l+v8,3310234,ignition,spark+plug,7212",
    vision: 0,
    date: "1970-01-01",
  },
  {
    value: null,
    url: "https://www.dodgetalk.com/threads/this-is-easy-best-spark-plugs-for-the-hemi.298765/",
    vision: 0,
    date: "1970-01-01",
  },
  {
    value: null,
    url: "https://www.justanswer.com/dodge/58kb8-dodge-ram-1500-4x4-proper-spark-plug-gap.html",
    vision: 0,
    date: "1970-01-01",
  },
];

export const ACCEPTOR_DOMAINS_HEADERS = [
  {
    text: "Domain",
    value: "domain",
  },
  {
    text: "Niche",
    value: "niche",
  },
  {
    text: "Country",
    value: "country",
  },
  {
    text: "Country Lang",
    value: "country_lang",
  },
  {
    text: "Hide",
    value: "hide",
    sortable: false,
  },
];

export const GPM_UNIVERSAL_DASHBOARD_TABLE_HEADERS = [
  { text: "Domain", value: "domain" },
  { text: "Price", value: "cost_task", align: "center" },
  { text: "Casino Price", value: "cost_casino", align: "center" },
  { text: "Status", value: "status", align: "center" },
  { text: "Cancel reason", value: "cancel_reason", align: "center" },
  { text: "Created At", value: "add_datetime", align: "center" },
  { text: "Stage Time", value: "time_in_work_stage", align: "center" },
  { text: "Responsible", value: "responsible", align: "center" },
  // tags
  // id
];

export const MAINTAINERS = {
  talasymov: {
    email: "vladyslav.talasimov@boosta.co",
    name: "Vladyslav Talasimov",
    bio: "Tech support. Back-end developer.",
    avatar: "https://ca.slack-edge.com/T04B6PEDQ-UCG9RRJ7M-7458fbd25a49-512",
    slack: "slack://channel?team=T04B6PEDQ&id=C02PPT8PQSH",
  },
  hordiienko: {
    email: "rodion.hordiienko@boosta.co",
    name: "Rodion Hordiienko",
    bio: "Tech support. Front-end developer.",
    avatar: "https://ca.slack-edge.com/T04B6PEDQ-U039MJZTE5S-f77baa12df66-512",
    slack: "slack://channel?team=T04B6PEDQ&id=U039MJZTE5S",
  },
  klih: {
    email: "vitaliy.klih@boosta.co",
    name: "Vitaliy Klih",
    bio: "Tech support. Back-end developer. Manager.",
    avatar: "https://ca.slack-edge.com/T04B6PEDQ-U63KETF8A-b8b7f23149f5-512",
    slack: "slack://user?team=T04B6PEDQ&id=U63KETF8A",
  },
};

export const SITE_WORKPLACE_URL_HISTORY_HEADERS = [
  {
    text: "Date",
    value: "date",
  },
  {
    text: "User",
    value: "email",
  },
  {
    text: "Status",
    value: "status",
  },
  {
    text: "Deadline",
    value: "deadline",
  },
];

export const TASK_PRIORITY_TABLE_HEADERS = [
  {
    text: "Project",
    value: "project",
    width: "140px",
  },
  {
    text: "Labels",
    value: "labels",
    width: "220px",
  },
  {
    text: "Task",
    value: "task",
    width: "480px",
  },
  {
    text: "W",
    value: "week",
  },
  {
    text: "Pr",
    title: "Priority",
    value: "priority",
  },
  {
    text: "R",
    title: "Rating",
    value: "rating",
  },
  {
    text: "Imp",
    title: "Impact",
    value: "impact",
  },
  {
    text: "Urg",
    title: "Urgency",
    value: "urgency",
  },
  {
    text: "P",
    title: "Peoples",
    value: "peoples",
  },
  {
    text: "Est",
    title: "Estimate",
    value: "estimate",
  },
  {
    text: "C",
    title: "Created at",
    value: "created_at",
  },
  {
    text: "D",
    title: "Deadline",
    value: "deadline",
  },
  {
    text: "User from",
    title: "User From",
    value: "user_from",
  },
];

export const CONTENTBALL_ANALYTICS_HEADERS = [
  {
    text: "Url",
    value: "url",
  },
  { text: "M Score", value: "m_score" },
  { text: "M, Sum", value: "m_sum" },
  { text: "M1", value: "m1" },
  { text: "M2", value: "m2" },
  { text: "M3", value: "m3" },
  { text: "M4", value: "m4" },
  { text: "M5", value: "m5" },
  {
    text: "TW",
    title: "Total words",
    value: "total_words",
  },
  {
    text: "TS",
    title: "Total sentences",
    value: "total_sentences",
  },
  {
    text: "ASL",
    title: "Average sentence length",
    value: "average_sentence_length",
  },
  {
    text: "SV",
    title: "Semantic Vocabulary",
    value: "semantic_vocabulary",
  },
  {
    text: "Vc",
    title: "Vocabulary",
    value: "vocabulary",
  },
  {
    text: "Wi",
    title: "Water Index",
    value: "water_index",
  },
  {
    text: "Nat",
    title: "Naturalness",
    value: "zipfs_law",
  },
  {
    text: "Cl NI",
    title: "Classical Nausea Index",
    value: "classical_nausea_index",
  },
  {
    text: "D-ch R",
    title: "Dale–Chall Readability",
    value: "dale_chall_score",
  },
  {
    text: "Spch R",
    title: "Spache Readability",
    value: "spache",
  },
  {
    text: "Flch R",
    title: "Flesch Readability",
    value: "flesch",
  },
  {
    text: "ARI",
    title: "Automated readability index",
    value: "automated_index",
  },
  {
    text: "1SW",
    value: "only_stop_words_1",
    width: "60px",
    align: "center",
  },
  {
    text: "2O",
    value: "by_words_2_others",
    width: "60px",
    align: "center",
  },
  {
    text: "2SW",
    value: "only_stop_words_2",
    width: "60px",
    align: "center",
  },
  {
    text: "MP By V",
    title: "Main Phrases By Vision",
    value: "main_phrases_by_vision",
    align: "center",
  },
  {
    text: "MW By V",
    title: "Main Words By Vision",
    value: "main_words_by_vision",
    align: "center",
  },
  {
    text: "Snippet",
    value: "snippet",
    align: "center",
  },
  {
    text: "Related",
    value: "related",
    align: "center",
  },
  {
    text: "Suggest",
    value: "suggest",
    align: "center",
  },
  {
    text: "Size, %",
    value: "text_size_p",
    align: "center",
  },
  {
    text: "Size, O",
    value: "text_size_our",
    align: "center",
  },
  {
    text: "Size, M",
    value: "text_size_med",
    align: "center",
  },
  {
    text: "NT",
    value: "nt",
  },
  {
    text: "Pr",
    value: "pr",
  },
  {
    text: "Pr Pr",
    value: "pr_pr",
  },
  {
    text: "Date",
    value: "date",
  },
  { text: "SW, D", title: "SW, Deadline", value: "sw_deadline" },
  { text: "SW, St", title: "SW, Status", value: "sw_status" },
  { text: "Pr, St", title: "Pr, Status", value: "pr_status" },
  { text: "Cd, St", title: "Code, Status", value: "code" },
  { text: "St Cll", title: "Stat Collect", value: "stat_collect" },
];

export const LANGUAGES = [
  {
    id: 1,
    country: "Abkhazian",
    language: "аҧсуа бызшәа, аҧсшәа",
    iso: "ab",
    priority: 10,
  },
  {
    id: 2,
    country: "Afar",
    language: "Afaraf",
    iso: "aa",
    priority: 10,
  },
  {
    id: 3,
    country: "Afrikaans",
    language: "Afrikaans",
    iso: "af",
    priority: 10,
  },
  {
    id: 4,
    country: "Akan",
    language: "Akan",
    iso: "ak",
    priority: 10,
  },
  {
    id: 5,
    country: "Albanian",
    language: "Shqip",
    iso: "sq",
    priority: 10,
  },
  {
    id: 6,
    country: "Amharic",
    language: "አማርኛ",
    iso: "am",
    priority: 10,
  },
  {
    id: 7,
    country: "Arabic",
    language: "العربية",
    iso: "ar",
    priority: 10,
  },
  {
    id: 8,
    country: "Aragonese",
    language: "aragonés",
    iso: "an",
    priority: 10,
  },
  {
    id: 9,
    country: "Armenian",
    language: "Հայերեն",
    iso: "hy",
    priority: 10,
  },
  {
    id: 10,
    country: "Assamese",
    language: "অসমীয়া",
    iso: "as",
    priority: 10,
  },
  {
    id: 11,
    country: "Avaric",
    language: "авар мацӀ, магӀарул мацӀ",
    iso: "av",
    priority: 10,
  },
  {
    id: 12,
    country: "Avestan",
    language: "avesta",
    iso: "ae",
    priority: 10,
  },
  {
    id: 13,
    country: "Aymara",
    language: "aymar aru",
    iso: "ay",
    priority: 10,
  },
  {
    id: 14,
    country: "Azerbaijani",
    language: "azərbaycan dili",
    iso: "az",
    priority: 10,
  },
  {
    id: 15,
    country: "Bambara",
    language: "bamanankan",
    iso: "bm",
    priority: 10,
  },
  {
    id: 16,
    country: "Bashkir",
    language: "башҡорт теле",
    iso: "ba",
    priority: 10,
  },
  {
    id: 17,
    country: "Basque",
    language: "euskara, euskera",
    iso: "eu",
    priority: 10,
  },
  {
    id: 18,
    country: "Belarusian",
    language: "беларуская мова",
    iso: "be",
    priority: 10,
  },
  {
    id: 19,
    country: "Bengali",
    language: "বাংলা",
    iso: "bn",
    priority: 10,
  },
  {
    id: 20,
    country: "Bihari languages",
    language: "भोजपुरी",
    iso: "bh",
    priority: 10,
  },
  {
    id: 21,
    country: "Bislama",
    language: "Bislama",
    iso: "bi",
    priority: 10,
  },
  {
    id: 22,
    country: "Bosnian",
    language: "bosanski jezik",
    iso: "bs",
    priority: 10,
  },
  {
    id: 23,
    country: "Breton",
    language: "brezhoneg",
    iso: "br",
    priority: 10,
  },
  {
    id: 24,
    country: "Bulgarian",
    language: "български език",
    iso: "bg",
    priority: 10,
  },
  {
    id: 25,
    country: "Burmese",
    language: "ဗမာစာ",
    iso: "my",
    priority: 10,
  },
  {
    id: 26,
    country: "Catalan, Valencian",
    language: "català, valencià",
    iso: "ca",
    priority: 10,
  },
  {
    id: 27,
    country: "Chamorro",
    language: "Chamoru",
    iso: "ch",
    priority: 10,
  },
  {
    id: 28,
    country: "Chechen",
    language: "нохчийн мотт",
    iso: "ce",
    priority: 10,
  },
  {
    id: 29,
    country: "Chichewa, Chewa, Nyanja",
    language: "chiCheŵa, chinyanja",
    iso: "ny",
    priority: 10,
  },
  {
    id: 30,
    country: "Chinese",
    language: "中文(Zhōngwén), 汉语, 漢語",
    iso: "zh",
    priority: 10,
  },
  {
    id: 31,
    country: "Chuvash",
    language: "чӑваш чӗлхи",
    iso: "cv",
    priority: 10,
  },
  {
    id: 32,
    country: "Cornish",
    language: "Kernewek",
    iso: "kw",
    priority: 10,
  },
  {
    id: 33,
    country: "Corsican",
    language: "corsu, lingua corsa",
    iso: "co",
    priority: 10,
  },
  {
    id: 34,
    country: "Cree",
    language: "ᓀᐦᐃᔭᐍᐏᐣ",
    iso: "cr",
    priority: 10,
  },
  {
    id: 35,
    country: "Croatian",
    language: "hrvatski jezik",
    iso: "hr",
    priority: 10,
  },
  {
    id: 36,
    country: "Czech",
    language: "čeština, český jazyk",
    iso: "cs",
    priority: 80,
  },
  {
    id: 37,
    country: "Danish",
    language: "dansk",
    iso: "da",
    priority: 10,
  },
  {
    id: 38,
    country: "Divehi, Dhivehi, Maldivian",
    language: "ދިވެހި",
    iso: "dv",
    priority: 10,
  },
  {
    id: 39,
    country: "Dutch, Flemish",
    language: "Nederlands, Vlaams",
    iso: "nl",
    priority: 10,
  },
  {
    id: 40,
    country: "Dzongkha",
    language: "རྫོང་ཁ",
    iso: "dz",
    priority: 10,
  },
  {
    id: 41,
    country: "English",
    language: "English",
    iso: "en",
    priority: 100,
  },
  {
    id: 42,
    country: "Esperanto",
    language: "Esperanto",
    iso: "eo",
    priority: 10,
  },
  {
    id: 43,
    country: "Estonian",
    language: "eesti, eesti keel",
    iso: "et",
    priority: 10,
  },
  {
    id: 44,
    country: "Ewe",
    language: "Eʋegbe",
    iso: "ee",
    priority: 10,
  },
  {
    id: 45,
    country: "Faroese",
    language: "føroyskt",
    iso: "fo",
    priority: 10,
  },
  {
    id: 46,
    country: "Fijian",
    language: "vosa Vakaviti",
    iso: "fj",
    priority: 10,
  },
  {
    id: 47,
    country: "Finnish",
    language: "suomi, suomen kieli",
    iso: "fi",
    priority: 10,
  },
  {
    id: 48,
    country: "French",
    language: "français, langue française",
    iso: "fr",
    priority: 95,
  },
  {
    id: 49,
    country: "Fulah",
    language: "Fulfulde, Pulaar, Pular",
    iso: "ff",
    priority: 10,
  },
  {
    id: 50,
    country: "Galician",
    language: "Galego",
    iso: "gl",
    priority: 10,
  },
  {
    id: 51,
    country: "Georgian",
    language: "ქართული",
    iso: "ka",
    priority: 10,
  },
  {
    id: 52,
    country: "German",
    language: "Deutsch",
    iso: "de",
    priority: 90,
  },
  {
    id: 53,
    country: "Greek (modern)",
    language: "ελληνικά",
    iso: "el",
    priority: 10,
  },
  {
    id: 54,
    country: "Guaraní",
    language: "Avañe'ẽ",
    iso: "gn",
    priority: 10,
  },
  {
    id: 55,
    country: "Gujarati",
    language: "ગુજરાતી",
    iso: "gu",
    priority: 10,
  },
  {
    id: 56,
    country: "Haitian, Haitian Creole",
    language: "Kreyòl ayisyen",
    iso: "ht",
    priority: 10,
  },
  {
    id: 57,
    country: "Hausa",
    language: "(Hausa) هَوُسَ",
    iso: "ha",
    priority: 10,
  },
  {
    id: 58,
    country: "Hebrew (modern)",
    language: "עברית",
    iso: "he",
    priority: 10,
  },
  {
    id: 59,
    country: "Herero",
    language: "Otjiherero",
    iso: "hz",
    priority: 10,
  },
  {
    id: 60,
    country: "Hindi",
    language: "हिन्दी, हिंदी",
    iso: "hi",
    priority: 10,
  },
  {
    id: 61,
    country: "Hiri Motu",
    language: "Hiri Motu",
    iso: "ho",
    priority: 10,
  },
  {
    id: 62,
    country: "Hungarian",
    language: "magyar",
    iso: "hu",
    priority: 10,
  },
  {
    id: 63,
    country: "Interlingua",
    language: "Interlingua",
    iso: "ia",
    priority: 10,
  },
  {
    id: 64,
    country: "Indonesian",
    language: "Bahasa Indonesia",
    iso: "id",
    priority: 10,
  },
  {
    id: 65,
    country: "Interlingue",
    language: "Originally called Occidental; then Interlingue aft",
    iso: "ie",
    priority: 10,
  },
  {
    id: 66,
    country: "Irish",
    language: "Gaeilge",
    iso: "ga",
    priority: 10,
  },
  {
    id: 67,
    country: "Igbo",
    language: "Asụsụ Igbo",
    iso: "ig",
    priority: 10,
  },
  {
    id: 68,
    country: "Inupiaq",
    language: "Iñupiaq, Iñupiatun",
    iso: "ik",
    priority: 10,
  },
  {
    id: 69,
    country: "Ido",
    language: "Ido",
    iso: "io",
    priority: 10,
  },
  {
    id: 70,
    country: "Icelandic",
    language: "Íslenska",
    iso: "is",
    priority: 10,
  },
  {
    id: 71,
    country: "Italian",
    language: "Italiano",
    iso: "it",
    priority: 85,
  },
  {
    id: 72,
    country: "Inuktitut",
    language: "ᐃᓄᒃᑎᑐᑦ",
    iso: "iu",
    priority: 10,
  },
  {
    id: 73,
    country: "Japanese",
    language: "日本語 (にほんご)",
    iso: "ja",
    priority: 10,
  },
  {
    id: 74,
    country: "Javanese",
    language: "ꦧꦱꦗꦮ, Basa Jawa",
    iso: "jv",
    priority: 10,
  },
  {
    id: 75,
    country: "Kalaallisut, Greenlandic",
    language: "kalaallisut, kalaallit oqaasii",
    iso: "kl",
    priority: 10,
  },
  {
    id: 76,
    country: "Kannada",
    language: "ಕನ್ನಡ",
    iso: "kn",
    priority: 10,
  },
  {
    id: 77,
    country: "Kanuri",
    language: "Kanuri",
    iso: "kr",
    priority: 10,
  },
  {
    id: 78,
    country: "Kashmiri",
    language: "कश्मीरी, كشميري\u200E",
    iso: "ks",
    priority: 10,
  },
  {
    id: 79,
    country: "Kazakh",
    language: "қазақ тілі",
    iso: "kk",
    priority: 10,
  },
  {
    id: 80,
    country: "Central Khmer",
    language: "ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ",
    iso: "km",
    priority: 10,
  },
  {
    id: 81,
    country: "Kikuyu, Gikuyu",
    language: "Gĩkũyũ",
    iso: "ki",
    priority: 10,
  },
  {
    id: 82,
    country: "Kinyarwanda",
    language: "Ikinyarwanda",
    iso: "rw",
    priority: 10,
  },
  {
    id: 83,
    country: "Kirghiz, Kyrgyz",
    language: "Кыргызча, Кыргыз тили",
    iso: "ky",
    priority: 10,
  },
  {
    id: 84,
    country: "Komi",
    language: "коми кыв",
    iso: "kv",
    priority: 10,
  },
  {
    id: 85,
    country: "Kongo",
    language: "Kikongo",
    iso: "kg",
    priority: 10,
  },
  {
    id: 86,
    country: "Korean",
    language: "한국어",
    iso: "ko",
    priority: 10,
  },
  {
    id: 87,
    country: "Kurdish",
    language: "Kurdî, کوردی\u200E",
    iso: "ku",
    priority: 10,
  },
  {
    id: 88,
    country: "Kuanyama, Kwanyama",
    language: "Kuanyama",
    iso: "kj",
    priority: 10,
  },
  {
    id: 89,
    country: "Latin",
    language: "latine, lingua latina",
    iso: "la",
    priority: 10,
  },
  {
    id: 90,
    country: "Luxembourgish, Letzeburgesch",
    language: "Lëtzebuergesch",
    iso: "lb",
    priority: 10,
  },
  {
    id: 91,
    country: "Ganda",
    language: "Luganda",
    iso: "lg",
    priority: 10,
  },
  {
    id: 92,
    country: "Limburgan, Limburger, Limburgish",
    language: "Limburgs",
    iso: "li",
    priority: 10,
  },
  {
    id: 93,
    country: "Lingala",
    language: "Lingála",
    iso: "ln",
    priority: 10,
  },
  {
    id: 94,
    country: "Lao",
    language: "ພາສາລາວ",
    iso: "lo",
    priority: 10,
  },
  {
    id: 95,
    country: "Lithuanian",
    language: "lietuvių kalba",
    iso: "lt",
    priority: 10,
  },
  {
    id: 96,
    country: "Luba-Katanga",
    language: "Kiluba",
    iso: "lu",
    priority: 10,
  },
  {
    id: 97,
    country: "Latvian",
    language: "latviešu valoda",
    iso: "lv",
    priority: 10,
  },
  {
    id: 98,
    country: "Manx",
    language: "Gaelg, Gailck",
    iso: "gv",
    priority: 10,
  },
  {
    id: 99,
    country: "Macedonian",
    language: "македонски јазик",
    iso: "mk",
    priority: 10,
  },
  {
    id: 100,
    country: "Malagasy",
    language: "fiteny malagasy",
    iso: "mg",
    priority: 10,
  },
  {
    id: 101,
    country: "Malay",
    language: "Bahasa Melayu, بهاس ملايو\u200E",
    iso: "ms",
    priority: 10,
  },
  {
    id: 102,
    country: "Malayalam",
    language: "മലയാളം",
    iso: "ml",
    priority: 10,
  },
  {
    id: 103,
    country: "Maltese",
    language: "Malti",
    iso: "mt",
    priority: 10,
  },
  {
    id: 104,
    country: "Maori",
    language: "te reo Māori",
    iso: "mi",
    priority: 10,
  },
  {
    id: 105,
    country: "Marathi",
    language: "मराठी",
    iso: "mr",
    priority: 10,
  },
  {
    id: 106,
    country: "Marshallese",
    language: "Kajin M̧ajeļ",
    iso: "mh",
    priority: 10,
  },
  {
    id: 107,
    country: "Mongolian",
    language: "Монгол хэл",
    iso: "mn",
    priority: 10,
  },
  {
    id: 108,
    country: "Nauru",
    language: "Dorerin Naoero",
    iso: "na",
    priority: 10,
  },
  {
    id: 109,
    country: "Navajo, Navaho",
    language: "Diné bizaad",
    iso: "nv",
    priority: 10,
  },
  {
    id: 110,
    country: "North Ndebele",
    language: "isiNdebele",
    iso: "nd",
    priority: 10,
  },
  {
    id: 111,
    country: "Nepali",
    language: "नेपाली",
    iso: "ne",
    priority: 10,
  },
  {
    id: 112,
    country: "Ndonga",
    language: "Owambo",
    iso: "ng",
    priority: 10,
  },
  {
    id: 113,
    country: "Norwegian Bokmål",
    language: "Norsk Bokmål",
    iso: "nb",
    priority: 10,
  },
  {
    id: 114,
    country: "Norwegian Nynorsk",
    language: "Norsk Nynorsk",
    iso: "nn",
    priority: 10,
  },
  {
    id: 115,
    country: "Norwegian",
    language: "Norsk",
    iso: "no",
    priority: 10,
  },
  {
    id: 116,
    country: "Sichuan Yi, Nuosu",
    language: "ꆈꌠ꒿ Nuosuhxop",
    iso: "ii",
    priority: 10,
  },
  {
    id: 117,
    country: "South Ndebele",
    language: "isiNdebele",
    iso: "nr",
    priority: 10,
  },
  {
    id: 118,
    country: "Occitan",
    language: "occitan, lenga d'òc",
    iso: "oc",
    priority: 10,
  },
  {
    id: 119,
    country: "Ojibwa",
    language: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
    iso: "oj",
    priority: 10,
  },
  {
    id: 120,
    country: "Church Slavic, Church Slavonic, Old Church Slavoni",
    language: "ѩзыкъ словѣньскъ",
    iso: "cu",
    priority: 10,
  },
  {
    id: 121,
    country: "Oromo",
    language: "Afaan Oromoo",
    iso: "om",
    priority: 10,
  },
  {
    id: 122,
    country: "Oriya",
    language: "ଓଡ଼ିଆ",
    iso: "or",
    priority: 10,
  },
  {
    id: 123,
    country: "Ossetian, Ossetic",
    language: "ирон æвзаг",
    iso: "os",
    priority: 10,
  },
  {
    id: 124,
    country: "Panjabi, Punjabi",
    language: "ਪੰਜਾਬੀ",
    iso: "pa",
    priority: 10,
  },
  {
    id: 125,
    country: "Pali",
    language: "पाऴि",
    iso: "pi",
    priority: 10,
  },
  {
    id: 126,
    country: "Persian",
    language: "فارسی",
    iso: "fa",
    priority: 10,
  },
  {
    id: 127,
    country: "Polish",
    language: "język polski, polszczyzna",
    iso: "pl",
    priority: 10,
  },
  {
    id: 128,
    country: "Pashto, Pushto",
    language: "پښتو",
    iso: "ps",
    priority: 10,
  },
  {
    id: 129,
    country: "Portuguese",
    language: "Português",
    iso: "pt",
    priority: 10,
  },
  {
    id: 130,
    country: "Quechua",
    language: "Runa Simi, Kichwa",
    iso: "qu",
    priority: 10,
  },
  {
    id: 131,
    country: "Romansh",
    language: "Rumantsch Grischun",
    iso: "rm",
    priority: 10,
  },
  {
    id: 132,
    country: "Rundi",
    language: "Ikirundi",
    iso: "rn",
    priority: 10,
  },
  {
    id: 133,
    country: "Romanian, Moldavian, Moldovan",
    language: "Română",
    iso: "ro",
    priority: 10,
  },
  {
    id: 134,
    country: "Russian",
    language: "русский",
    iso: "ru",
    priority: 0,
  },
  {
    id: 135,
    country: "Sanskrit",
    language: "संस्कृतम्",
    iso: "sa",
    priority: 10,
  },
  {
    id: 136,
    country: "Sardinian",
    language: "sardu",
    iso: "sc",
    priority: 10,
  },
  {
    id: 137,
    country: "Sindhi",
    language: "सिन्धी, سنڌي، سندھی\u200E",
    iso: "sd",
    priority: 10,
  },
  {
    id: 138,
    country: "Northern Sami",
    language: "Davvisámegiella",
    iso: "se",
    priority: 10,
  },
  {
    id: 139,
    country: "Samoan",
    language: "gagana fa'a Samoa",
    iso: "sm",
    priority: 10,
  },
  {
    id: 140,
    country: "Sango",
    language: "yângâ tî sängö",
    iso: "sg",
    priority: 10,
  },
  {
    id: 141,
    country: "Serbian",
    language: "српски језик",
    iso: "sr",
    priority: 10,
  },
  {
    id: 142,
    country: "Gaelic, Scottish Gaelic",
    language: "Gàidhlig",
    iso: "gd",
    priority: 10,
  },
  {
    id: 143,
    country: "Shona",
    language: "chiShona",
    iso: "sn",
    priority: 10,
  },
  {
    id: 144,
    country: "Sinhala, Sinhalese",
    language: "සිංහල",
    iso: "si",
    priority: 10,
  },
  {
    id: 145,
    country: "Slovak",
    language: "Slovenčina, Slovenský Jazyk",
    iso: "sk",
    priority: 10,
  },
  {
    id: 146,
    country: "Slovene",
    language: "Slovenski Jezik, Slovenščina",
    iso: "sl",
    priority: 10,
  },
  {
    id: 147,
    country: "Somali",
    language: "Soomaaliga, af Soomaali",
    iso: "so",
    priority: 10,
  },
  {
    id: 148,
    country: "Southern Sotho",
    language: "Sesotho",
    iso: "st",
    priority: 10,
  },
  {
    id: 149,
    country: "Spanish, Castilian",
    language: "Español",
    iso: "es",
    priority: 10,
  },
  {
    id: 150,
    country: "Sundanese",
    language: "Basa Sunda",
    iso: "su",
    priority: 10,
  },
  {
    id: 151,
    country: "Swahili",
    language: "Kiswahili",
    iso: "sw",
    priority: 10,
  },
  {
    id: 152,
    country: "Swati",
    language: "SiSwati",
    iso: "ss",
    priority: 10,
  },
  {
    id: 153,
    country: "Swedish",
    language: "Svenska",
    iso: "sv",
    priority: 10,
  },
  {
    id: 154,
    country: "Tamil",
    language: "தமிழ்",
    iso: "ta",
    priority: 10,
  },
  {
    id: 155,
    country: "Telugu",
    language: "తెలుగు",
    iso: "te",
    priority: 10,
  },
  {
    id: 156,
    country: "Tajik",
    language: "тоҷикӣ, toçikī, تاجیکی\u200E",
    iso: "tg",
    priority: 10,
  },
  {
    id: 157,
    country: "Thai",
    language: "ไทย",
    iso: "th",
    priority: 10,
  },
  {
    id: 158,
    country: "Tigrinya",
    language: "ትግርኛ",
    iso: "ti",
    priority: 10,
  },
  {
    id: 159,
    country: "Tibetan",
    language: "བོད་ཡིག",
    iso: "bo",
    priority: 10,
  },
  {
    id: 160,
    country: "Turkmen",
    language: "Türkmen, Түркмен",
    iso: "tk",
    priority: 10,
  },
  {
    id: 161,
    country: "Tagalog",
    language: "Wikang Tagalog",
    iso: "tl",
    priority: 10,
  },
  {
    id: 162,
    country: "Tswana",
    language: "Setswana",
    iso: "tn",
    priority: 10,
  },
  {
    id: 163,
    country: "Tongan (Tonga Islands)",
    language: "Faka Tonga",
    iso: "to",
    priority: 10,
  },
  {
    id: 164,
    country: "Turkish",
    language: "Türkçe",
    iso: "tr",
    priority: 10,
  },
  {
    id: 165,
    country: "Tsonga",
    language: "Xitsonga",
    iso: "ts",
    priority: 10,
  },
  {
    id: 166,
    country: "Tatar",
    language: "татар теле, tatar tele",
    iso: "tt",
    priority: 10,
  },
  {
    id: 167,
    country: "Twi",
    language: "Twi",
    iso: "tw",
    priority: 10,
  },
  {
    id: 168,
    country: "Tahitian",
    language: "Reo Tahiti",
    iso: "ty",
    priority: 10,
  },
  {
    id: 169,
    country: "Uighur, Uyghur",
    language: "ئۇيغۇرچە\u200E, Uyghurche",
    iso: "ug",
    priority: 10,
  },
  {
    id: 170,
    country: "Ukrainian",
    language: "Українська",
    iso: "uk",
    priority: 75,
  },
  {
    id: 171,
    country: "Urdu",
    language: "اردو",
    iso: "ur",
    priority: 10,
  },
  {
    id: 172,
    country: "Uzbek",
    language: "Oʻzbek, Ўзбек, أۇزبېك\u200E",
    iso: "uz",
    priority: 10,
  },
  {
    id: 173,
    country: "Venda",
    language: "Tshivenḓa",
    iso: "ve",
    priority: 10,
  },
  {
    id: 174,
    country: "Vietnamese",
    language: "Tiếng Việt",
    iso: "vi",
    priority: 10,
  },
  {
    id: 175,
    country: "Volapük",
    language: "Volapük",
    iso: "vo",
    priority: 10,
  },
  {
    id: 176,
    country: "Walloon",
    language: "Walon",
    iso: "wa",
    priority: 10,
  },
  {
    id: 177,
    country: "Welsh",
    language: "Cymraeg",
    iso: "cy",
    priority: 10,
  },
  {
    id: 178,
    country: "Wolof",
    language: "Wollof",
    iso: "wo",
    priority: 10,
  },
  {
    id: 179,
    country: "Western Frisian",
    language: "Frysk",
    iso: "fy",
    priority: 10,
  },
  {
    id: 180,
    country: "Xhosa",
    language: "isiXhosa",
    iso: "xh",
    priority: 10,
  },
  {
    id: 181,
    country: "Yiddish",
    language: "ייִדיש",
    iso: "yi",
    priority: 10,
  },
  {
    id: 182,
    country: "Yoruba",
    language: "Yorùbá",
    iso: "yo",
    priority: 10,
  },
  {
    id: 183,
    country: "Zhuang, Chuang",
    language: "Saɯ cueŋƅ, Saw cuengh",
    iso: "za",
    priority: 10,
  },
  {
    id: 184,
    country: "Zulu",
    language: "isiZulu",
    iso: "zu",
    priority: 10,
  },
];
