<template>
  <v-dialog v-model="dialog" content-class="my-shadow--e2" width="460">
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2">
        Add domains
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-form ref="formRef">
          <v-row>
            <template v-if="false">
              <v-col cols="12">
                <v-text-field
                  v-model="form.name"
                  label="Task name"
                  hide-details
                  dense
                  outlined
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="form.source"
                  hide-details
                  dense
                  outlined
                  label="Source"
                  :items="filtersData?.sources"
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="form.post_type"
                  hide-details
                  dense
                  outlined
                  label="Post type"
                  :items="filtersData?.post_types"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="form.urls"
                  label="URL"
                  hide-details
                  dense
                  outlined
                  :placeholder="`https://example.com\nhttps://example.com\n...`"
                />
              </v-col>
            </template>
            <v-col cols="12">
              <v-textarea
                v-model="form.domains"
                label="Domains"
                hide-details
                dense
                outlined
                :placeholder="`domain.com\ndomain.com\n...`"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn large class="px-6 text-normal">Back</v-btn>
        <v-btn
          large
          class="px-6 text-normal"
          color="success"
          @click="handleEdit"
          >Add domains</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "@/mixins/Dialog";
import { AutopostingAlpApiService } from "@/views/autoposting/services/AutopostingAlpApiService";

const EMPTY_FORM_STATE = {
  task_id: null,
  domains: null,
};

export default {
  mixins: [Dialog],

  props: ["data", "filtersData"],

  watch: {
    dialog(isVisible) {
      if (!isVisible) this.resetForm();
    },
    data: {
      handler(newData) {
        console.log(newData);
        if (newData) this.form = this.dataAdapter(newData) || {};

        console.log(this.form);
      },
      deep: true,
    },
  },

  data: () => ({
    form: { ...EMPTY_FORM_STATE },
  }),

  methods: {
    resetForm() {
      this.form = { ...EMPTY_FORM_STATE };
    },
    dataAdapter(data) {
      const result = {};

      result.task_id = data?.task?.id;
      result.domains = data?.postDomains
        ?.map((domain) => domain.domain)
        ?.join("\n");

      return result;
    },
    async handleEdit() {
      try {
        await AutopostingAlpApiService().updateTask(
          {
            domains: this.form.domains.split("\n"),
          },
          this.form.task_id
        );
        this.resetForm();
        this.dialog = false;
      } catch (e) {
        console.error("Error while editing task.", e);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
