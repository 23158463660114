export const queueUrlsHeaders = [
  {
    text: "ID",
    value: "id",
  },
  {
    text: "Task id",
    value: "task_id",
  },
  {
    text: "URL",
    value: "url",
  },
  {
    text: "URL Gen",
    value: "url_gen",
  },
  {
    text: "Link text",
    value: "link_text",
  },
  {
    text: "Length",
    value: "length",
  },
  {
    text: "Post date",
    value: "post_date",
  },
];
