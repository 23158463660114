<template>
  <v-container fluid style="padding-bottom: 200px">
    <v-row>
      <v-col cols="12">
        <page-header
          title="Link Dynamics Report"
          :breadcrumbs="[
            { text: 'Home', to: '/' },
            { text: 'Link Dynamics Report', disabled: true },
          ]"
        />
      </v-col>
      <v-col cols="12">
        <div class="d-flex" style="gap: 8px">
          <download-csv
            v-if="export_data_status"
            :data="export_data.items"
            :name="export_data.dataFile"
            :labels="export_data.labels"
            :fields="export_data.fields"
            :delimiter="'\t'"
            v-on:export-finished="exported_csv"
          >
            <v-btn class="text-normal" @click="() => {}">
              <v-icon small left>mdi-cloud-download-outline</v-icon>
              Download CSV
            </v-btn>
          </download-csv>
          <v-btn
            v-else
            :disabled="table.items?.length === 0"
            class="text-normal"
            @click="export_csv"
          >
            <v-icon small left>mdi-cloud-download-outline</v-icon>
            Download CSV
          </v-btn>
          <v-menu
            transition="slide-y-transition"
            :close-on-content-click="false"
            nudge-bottom="40"
            v-model="modal.shown"
            :value="modal.shown"
            max-width="620"
            eager
            content-class="my-shadow--e2 rounded-lg"
          >
            <template v-slot:activator="{ on }">
              <v-btn class="text-normal" color="primary" v-on="on">
                <v-icon small left>mdi-filter</v-icon> Filters
                <v-icon small right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card flat rounded="lg" outlined>
              <v-card-title class="text-body-2"> Filters </v-card-title>
              <v-divider />
              <v-card-text>
                <v-form ref="formRef">
                  <v-row>
                    <v-col cols="6">
                      <smart-date-filter
                        :model-value="filter.date_range"
                        @update:modelValue="filter.date_range = $event"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        dense
                        hide-details
                        v-model="filter.interval"
                        :items="intervals"
                        outlined
                        item-value="id"
                        item-text="name"
                        label="Interval"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        dense
                        hide-details
                        v-model="filter.niche"
                        :items="niches"
                        item-value="id"
                        outlined
                        multiple
                        item-text="name"
                        label="Niche"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        dense
                        hide-details
                        v-model="filter.department"
                        :items="departments"
                        item-value="id"
                        multiple
                        outlined
                        item-text="name"
                        label="Department"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="filter.project"
                        :items="filtersData.projects"
                        outlined
                        label="Project"
                        placeholder="Select project"
                        hide-details="auto"
                        dense
                        clearable
                        multiple
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="filter.subprojects"
                        :items="filtersData.subprojects"
                        outlined
                        label="Subproject"
                        placeholder="Select project"
                        hide-details="auto"
                        dense
                        multiple
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="filter.seo"
                        :items="seoItems"
                        :loading="loadings.seo"
                        outlined
                        multiple
                        label="Responsible seo"
                        placeholder="Select user"
                        item-text="email"
                        item-value="id"
                        dense
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="filter.link_type"
                        dense
                        outlined
                        multiple
                        hide-details
                        item-text="text"
                        item-value="value"
                        :items="linkTypeItems"
                        label="Link type"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="filter.team"
                        dense
                        outlined
                        multiple
                        hide-details
                        :items="filtersData.team"
                        :loading="loadings.filters"
                        label="Team"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="filter.project_categories"
                        :items="filtersData.project_categories"
                        :loading="filtersDataPendingKeys?.project_categories"
                        multiple
                        hide-details
                        dense
                        outlined
                        label="Project categories"
                        placeholder="Type to search"
                      />
                    </v-col>
                    <v-col cols="6">
                      <smart-autocomplete
                        :model-value="filter.sections"
                        @update:modelValue="filter.sections = $event"
                        :items="filtersData.link_dynamics_report_sections || []"
                        placeholder="Type to search"
                        label="Sections"
                      />
                    </v-col>
                    <v-col cols="6"></v-col>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="filter.last_month"
                        hide-details
                        dense
                        class="ma-0"
                        label="Last month"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="filter.target_urls"
                        hide-details
                        dense
                        class="ma-0"
                        label="Target URL's"
                      ></v-checkbox
                    ></v-col>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="filter.action"
                        hide-details
                        dense
                        class="ma-0"
                        label="Action"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  @click="fetch_data"
                  large
                  color="primary"
                  class="px-6 text-normal"
                  :loading="loadings.fetch_data"
                >
                  Get data
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-btn
            height="40"
            style="min-width: 40px"
            class="px-2"
            @click="fetch_data"
            :loading="loadings.fetch_data"
            title="Update tables"
          >
            <v-icon small>mdi-refresh</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="12">
        <v-card
          flat
          outlined
          rounded="lg"
          v-if="loadings.fetch_data && firstEnter"
        >
          <v-skeleton-loader type="table" />
        </v-card>

        <v-card flat outlined rounded="lg">
          <v-card-text
            style="gap: 0.5rem; background: var(--card-darken-body-color)"
            class="d-flex flex-column"
          >
            <template v-for="(section, idx) in sections">
              <v-card
                v-if="section"
                :key="`section.${idx}`"
                flat
                outlined
                rounded="lg"
                :loading="loadings.fetch_data"
              >
                <v-card-title class="text-body-2 font-weight-bold">
                  {{ section.title }}
                </v-card-title>
                <v-card-title class="text-body-2 pa-3 pt-0">
                  <v-text-field
                    v-model="section._search"
                    hide-details
                    dense
                    outlined
                    clearable
                    append-icon="mdi-magnify"
                    placeholder="Type to search"
                  />
                </v-card-title>
                <v-divider />
                <v-card-text class="pa-0 pb-4">
                  <template v-if="section.type === 'links-by-project'">
                    <LinkDynamicsReportLinksByProjectTable
                      :items="section.data.items || []"
                      :headers="section.data.headers || []"
                      :search="section._search"
                      :filter="filter"
                    />
                  </template>
                  <template v-else-if="section.type === 'links-by-type'">
                    <LinkDynamicsReportLinksByTypeTable
                      :items="section.data.items || []"
                      :headers="section.data.headers || []"
                      :search="section._search"
                      :filter="filter"
                    />
                  </template>
                  <template v-else-if="section.type === 'all-links'">
                    <LinkDynamicsReposrtAllLinksTable
                      :items="section.data.items || []"
                      :headers="section.data.headers || []"
                      :search="section._search"
                      :filter="filter"
                    />
                  </template>
                  <template v-else>
                    <div class="error--text px-6 py-3">
                      Unsupported table type <u>{{ section.type }}</u>
                    </div>
                  </template>
                </v-card-text>
              </v-card>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import JsonCSV from "vue-json-csv";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import Project from "@/mixins/Project";
import service from "@/plugins/service";
import SmartDateFilter from "@/components/UI/SmartDateFilter";
import Location from "../../../mixins/Location";
import Service from "@/plugins/service";
import PageHeader from "@/components/UI/PageHeader.vue";
import UploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";
import SmartAutocomplete from "../../UI/SmartAutocomplete.vue";
import LinkDynamicsReportLinksByProjectTable from "./components/LinkDynamicsReportLinksByProjectTable.vue";
import LinkDynamicsReportLinksByTypeTable from "./components/LinkDynamicsReportLinksByTypeTable.vue";
import LinkDynamicsReposrtAllLinksTable from "./components/LinkDynamicsReposrtAllLinksTable.vue";
import GlobalProjectBinderMixin from "@/mixins/utils/GlobalProjectBinderMixin";

let dateFormat = require("dateformat");
let date = new Date();

export default {
  name: "LinkDynamicsReportIndex",
  metaInfo: {
    title: "Link Dynamics Report - LTT - SI",
  },
  components: {
    LinkDynamicsReposrtAllLinksTable,
    LinkDynamicsReportLinksByTypeTable,
    LinkDynamicsReportLinksByProjectTable,
    SmartAutocomplete,
    PageHeader,
    SmartDateFilter,
    "download-csv": JsonCSV,
  },
  mixins: [
    FiltersHandling,
    Project,
    Location,
    UploadFiltersDataMixin,
    GlobalProjectBinderMixin,
  ],
  data() {
    return {
      sections: [],
      firstEnter: true,
      linkTypeItems: [],
      seoItems: [],
      date_modal: false,
      search: "",
      modal: {
        shown: false,
      },
      filtersData: {
        team: [],
        project_categories: [],
      },
      loadings: {
        fetch_data: false,
        seo: false,
        filters: false,
      },
      filter: {
        sections: [],
        project_categories: [],
        link_type: [],
        date_range: [
          dateFormat(
            new Date(date.getFullYear(), date.getMonth(), 1),
            "yyyy-mm-dd"
          ),
          dateFormat(
            new Date(date.getFullYear(), date.getMonth() + 1, 0),
            "yyyy-mm-dd"
          ),
        ],
        team: [],
        niche: [],
        last_month: false,
        department: [],
        action: false,
        target_urls: false,
        interval: "day",
        seo: [],
        project: [],
        subprojects: [],
      },
      departments: [
        { id: "la", name: "la" },
        { id: "ha", name: "ha" },
        { id: "fl", name: "fl" },
      ],
      intervals: [
        { id: "day", name: "day" },
        { id: "week", name: "week" },
      ],
      table: {
        headers: [],
        items: [],
      },
      export_data_status: false,
      export_data: {
        items: [],
        dataFile: "serp_export.csv",
        labels: {},
        fields: [],
      },
    };
  },
  async mounted() {
    await this.parseQuery("filter");

    // if (this.filter.project.length === 0)
    //   this.filter.project = [this.gpbmComputedGlobalProject];

    this.fetchUsers();
    this.getItems();
    this.fetchFilters();
    await this.uploadFiltersData(
      [
        "project_categories",
        "projects",
        "subprojects",
        "link_dynamics_report_sections",
      ],
      {
        projects: this.filter.project,
      }
    );

    // TODO Возможен баг
    if (!this.filter.sections?.length)
      this.filter.sections =
        this.filtersData?.link_dynamics_report_sections?.map(
          (section) => section.value
        ) || [];

    this.fetch_data();

    this.$watch("filter.project", () => {
      this.filter.subprojects = [];

      this.uploadFiltersData(["subprojects"], {
        projects: this.filter.project,
      });
    });

    if (this.niches.length === 0) {
      this.$store.dispatch("fetch_niches_list");
    }
  },
  computed: {
    niches() {
      return this.$store.state.reuse.niches;
    },
  },
  methods: {
    gpbmHandleChangeGlobalProject(projectId) {
      this.filter.project = [projectId];
    },
    sectionsAdapter(items) {
      return items.map((item) => {
        item._search = "";

        return item;
      });
    },
    async fetchFilters() {
      const url = "/static/get-link-types";

      const resp = await service.get(url, {});

      if (!resp) return;

      this.linkTypeItems = resp.data.items;
    },
    async getItems() {
      try {
        const payload = {
          type: "/ltt/link-dynamics-report",
          take: ["team"],
        };

        this.loadings.filters = true;

        const resp = await this.$store.dispatch("global/getFilters", payload);

        if (resp) this.filtersData = { ...this.filtersData, ...resp };
      } catch (e) {
        console.error(
          "Error while getting filter items for Link Dynamic Report.",
          e
        );
      } finally {
        this.loadings.filters = false;
      }
    },
    async fetchUsers() {
      this.loadings.seo = true;
      const projectId = this.$store.state.project.active_project;
      const resp = await this.$store.dispatch("global/getUsers", { projectId });

      if (resp) {
        this.seoItems = resp;
      }

      this.loadings.seo = false;
    },
    exported_csv() {
      this.export_data_status = false;
    },
    export_csv() {
      let self = this;

      self.export_data.items = self.table.items.map((v) => {
        let res = {
          name: v.project,
          type: v.type,
        };

        self.export_data.labels["name"] = "name";
        self.export_data.labels["type"] = "type";
        let dates = Object.keys(v.dates).reverse();
        for (let date in dates) {
          let newDate = new Date(dates[date]);
          let d = newDate.getDate() + "-" + (newDate.getMonth() + 1);
          //     console.log(d, dates[date], newDate.getMonth())
          self.export_data.labels[dates[date]] = d;
          res[dates[date]] = v.dates[dates[date]].all.all;
        }

        return res;
      });

      self.export_data.fields = Object.keys(self.export_data.items[0]);
      self.export_data_status = true;
    },
    async fetch_data() {
      if (!this.$refs.formRef.validate()) return (this.modal.shown = true);

      this.table.headers = [];
      this.table.items = [];
      this.modal.shown = false;

      try {
        this.loadings.fetch_data = true;

        const url = `/ltt/link-dynamics-report/0`;

        const payload = { filter: this.filter };

        const { data } = await Service.post(url, payload);

        this.firstEnter = false;

        this.sections = this.sectionsAdapter(data);
      } catch (e) {
        console.error("Error while loading table data.", e);
      } finally {
        this.loadings.fetch_data = false;
      }
    },
  },
};
</script>

<style scoped></style>
