<template>
  <v-dialog v-model="dialog" content-class="my-shadow--e2" width="1280">
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2 font-weight-bold">
        Partners
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close </v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4 px-0">
        <v-data-table :items="data.items" :headers="data.headers" dense>
          <template #[`item.final_url`]="{ value }">
            <div style="max-width: 420px">
              {{ value }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="text-normal px-6"
          color="error"
          large
          @click="handleStopParsing(tableItem)"
          :loading="loadings.stop"
        >
          Stop parsing <v-icon small right> mdi-stop</v-icon>
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../../../mixins/Dialog";
import Service from "../../../../../plugins/service";
import Notification from "@/mixins/Notification";

export default {
  props: ["data", "tableItem"],

  mixins: [Dialog, Notification],

  data: () => ({
    loadings: {
      add: false,
      stop: false,
    },
  }),

  methods: {
    async handleStopParsing(tableItem) {
      try {
        this.loadings.stop = true;
        await Service.post(`/url-control/${tableItem.url_id}/stop-parsing`);
        this.mSuccess({
          title: "Stopped",
          text: "Parsing stopped",
        });
      } catch (e) {
        console.error("Error while stopping parsing.", e);
      } finally {
        this.loadings.stop = false;
      }
    },
  },
};
</script>
