export default {
  data: () => ({
    globalProjectHandlingConfig: {
      bindPath: "", // Path to value for binding
    },
    gpbmIsProjectInitialized: false,
  }),

  created() {},

  watch: {
    gpbmComputedGlobalProject: {
      handler(newProjectId) {
        this.gpbmHandleChangeGlobalProject(newProjectId);

        this.gpbmIsProjectInitialized = true;
      },
      // immediate: true,
    },
  },

  computed: {
    gpbmComputedGlobalProject() {
      return Number(this.$store.getters["project/active_project"]);
    },
  },

  methods: {
    gpbmSetProjectValueIfItsNotExist(target) {
      if (!target) target = Number(this.gpbmComputedGlobalProject);
    },
    gpbmBindProjectTo(target, key) {
      this.$set(target, key, this.gpbmComputedGlobalProject);
    },
    gpbmHandleChangeGlobalProject() {},
  },
};
