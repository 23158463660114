<template>
  <v-container fluid style="padding-bottom: 200px">
    <v-row dense>
      <v-col cols="12" class="d-flex align-center justify-space-between">
        <div class="d-flex align-baseline" style="gap: 8px">
          <h2>Expances Manage</h2>
          <small>( NDC / Money )</small>
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-autocomplete
          dense
          hide-details
          filled
          label="Projects"
          item-value="id"
          item-text="name"
          clearable
          :items="_$getProjects"
          v-model="filters.project"
          @change="handleChangeProject"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="filters.subprojects"
          label="Subproject"
          :items="_$getSubprojects"
          item-value="id"
          item-text="name"
          multiple
          filled
          dense
          clearable
          hide-details
        >
        </v-autocomplete>
      </v-col>
      <v-col>
        <smart-date-filter
          :model-value="filters.month"
          @update:modelValue="filters.month = $event"
          filled
        />
      </v-col>
      <v-col>
        <v-btn
          :loading="loadings.table"
          large
          style="height: 52px"
          @click="fetchData"
          color="primary"
          class="text-normal"
        >
          Get data
        </v-btn>
      </v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
    </v-row>
    <v-row dense class="mt-6">
      <v-col cols="12" class="d-flex" style="gap: 8px">
        <table-search
          :model-value="search"
          @update:modelValue="search = $event"
          placeholder="Search in table"
          style="max-width: 250px"
        />
        <v-chip
          label
          @click="handleDeleteSelected"
          :disabled="selectedRows.length === 0 || loadings.deleteSelected"
        >
          Delete selected
          <my-badge
            v-if="selectedRows?.length > 0"
            class="error white--text ml-1"
            title="Deselect rows"
          >
            {{ selectedRows?.length }}
          </my-badge>
          <v-icon v-if="!loadings.deleteSelected" right small
            >mdi-delete-outline
          </v-icon>
          <v-progress-circular
            v-else
            class="ml-2"
            indeterminate
            size="18"
            width="2"
            color="error"
          />
        </v-chip>
        <vue-json-to-csv
          :json-data="tableData.items"
          csv-title="ndc_manage"
          :separator="'\t'"
        >
          <v-chip
            label
            @click="() => {}"
            :disabled="tableData?.items?.length === 0"
          >
            Export CSV
            <v-icon small right>mdi-export</v-icon>
          </v-chip>
        </vue-json-to-csv>
        <v-spacer />
        <v-menu
          bottom
          content-class="shadow-e1-bordered"
          nudge-bottom="42px"
          rounded="lg"
        >
          <template #activator="{ on }">
            <v-chip v-on="on" label outlined>
              Upload
              <v-icon small right>mdi-tray-arrow-up</v-icon>
            </v-chip>
          </template>
          <v-list dense>
            <v-list-item @click="importFactDialogModel = true">
              Fact
            </v-list-item>
            <v-list-item @click="importPlanDialogModel = true">
              Plan
            </v-list-item>
            <v-list-item @click="importExpenseDialogModel = true">
              Expense
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-card class="styled-card--default pa-0">
          <v-progress-linear indeterminate :active="loadings.table" />
          <v-data-table
            v-model="selectedRows"
            show-select
            :headers="headers"
            :search="search"
            :items="tableData.items"
            multi-sort
            :custom-sort="_$smartCustomTableSort"
            :items-per-page="50"
            :footer-props="{ itemsPerPageOptions: [50, 150, 300, -1] }"
          >
            <template #body="{ items }">
              <tbody>
                <tr
                  v-for="(row, index) in items"
                  :key="index"
                  :class="{ first: index === 0 }"
                >
                  <td>
                    <v-checkbox
                      v-model="selectedRows"
                      :value="row"
                    ></v-checkbox>
                  </td>
                  <td title="Edit row">
                    <v-btn
                      icon
                      @click="setEditableContent(row)"
                      :loading="loadings?.saving?.includes(row.id)"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td>{{ row.partnership }}</td>
                  <td>{{ row.fin_account }}</td>
                  <td>
                    {{ row.login }}
                  </td>
                  <td>
                    {{ row.casino_brand }}
                  </td>
                  <td>
                    {{ row.site }}
                  </td>
                  <td>
                    {{ row.fin_project }}
                  </td>
                  <td>
                    {{ row.seo }}
                  </td>
                  <td>
                    <span v-if="row.month" style="white-space: nowrap">
                      {{ $moment(row.month).format("MMM DD YYYY") }}
                    </span>
                  </td>
                  <td class="text-end">
                    {{ fixNumber(row.money) }}
                  </td>
                  <td class="text-end">
                    {{ row.ndc }}
                  </td>
                  <td>
                    <span style="white-space: nowrap">
                      {{
                        row.updated_at
                          ? $moment(row.updated_at).format("MM-DD-YYYY")
                          : null
                      }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="editDialogModel"
      content-class="remove-dialog-shadow"
      max-width="650px"
      scrollable
    >
      <v-card class="styled-card--default">
        <v-card-title>
          Edit row
          <v-spacer />
          <v-btn @click="editDialogModel = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row dense class="d-flex flex-column" style="gap: 8px">
            <v-col
              cols="12"
              v-for="itemKey in Object.keys(contentOnEdit)"
              :key="itemKey"
            >
              <edit-field-manager
                :model-value="contentOnEdit[itemKey]"
                @update:modelValue="contentOnEdit[itemKey] = $event"
                :headers="headers"
                :data="{ key: itemKey, content: contentOnEdit }"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-chip
            large
            label
            class="px-6"
            color="error"
            @click="deleteRow"
            :outlined="!confirm"
          >
            {{ confirm ? "Are you sure?" : "Delete" }}
          </v-chip>
          <v-chip
            large
            label
            class="px-6"
            color="success"
            @click="saveEditedRow"
            >Save
          </v-chip>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <import-fact-dialog
      :model-value="importFactDialogModel"
      @update:modelValue="importFactDialogModel = $event"
    />
    <import-plan-dialog
      :model-value="importPlanDialogModel"
      @update:modelValue="importPlanDialogModel = $event"
    />
    <import-expense-dialog
      :model-value="importExpenseDialogModel"
      @update:modelValue="importExpenseDialogModel = $event"
    />
  </v-container>
</template>

<script>
import { NDC_MANAGE_TABLE_HEADERS } from "@/utils/defaultData";
import EditFieldManager from "@/components/NDCManage/UI/EditFieldManager";
import ImportFactDialog from "@/components/NDCManage/UI/ImportFactDialog";
import ImportPlanDialog from "@/components/NDCManage/UI/ImportPlanDialog";
import ImportExpenseDialog from "@/components/NDCManage/UI/ImportExpenseDialog";
import TableSorting from "@/mixins/TableSorting";
import { deepClone } from "@/utils/functions";
import TableSearch from "@/components/UI/TableSearch.vue";
import MyBadge from "@/components/UI/MyBadge.vue";
import VueJsonToCsv from "vue-json-to-csv";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import Project from "@/mixins/Project";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import eventPipe from "@/events/eventPipe";
import GlobalProjectBinderMixin from "@/mixins/utils/GlobalProjectBinderMixin";

export default {
  components: {
    SmartDateFilter,
    VueJsonToCsv,
    MyBadge,
    TableSearch,
    ImportExpenseDialog,
    ImportPlanDialog,
    ImportFactDialog,
    EditFieldManager,
  },
  mixins: [TableSorting, FiltersHandling, Project, GlobalProjectBinderMixin],
  data: () => ({
    selectedRows: [],
    breadcrumbs: [
      {
        text: "NDC",
        disabled: true,
      },
      {
        text: "Money",
        disabled: true,
      },
      {
        text: "Expances Manage",
        disabled: true,
      },
    ],
    search: "",
    confirm: false,
    editDialogModel: false,
    importFactDialogModel: false,
    importPlanDialogModel: false,
    importExpenseDialogModel: false,
    contentOnEdit: {},
    uploadFact: {
      headers: "",
      rows: "",
    },
    headers: NDC_MANAGE_TABLE_HEADERS,
    tableData: {
      items: [],
    },
    loadings: {
      table: false,
      deleteSelected: false,
      saving: [],
    },
    filters: {
      subprojects: [],
      project: null,
      month: [null, null],
    },
  }),
  async mounted() {
    await this.parseQuery("filters");

    if (!this.filters.project)
      this.filters.project = this.gpbmComputedGlobalProject;

    if (Object.keys(this.$route.params).length > 0) {
      this.fetchData();
    }
  },
  watch: {
    editDialogModel(value) {
      if (!value) this.confirm = false;
    },
  },
  methods: {
    gpbmHandleChangeGlobalProject(projectId) {
      this.filters.project = projectId;
    },
    handleChangeProject(projectId) {
      eventPipe.$emit("replace-project", projectId);
    },
    async handleDeleteSelected() {
      const idOfRowsOnDelete = this.selectedRows.map((v) => v.id);

      const promises = [];

      this.loadings.deleteSelected = true;

      idOfRowsOnDelete.forEach((id) => {
        promises.push(this.sendIdOnDelete(id));
      });

      const results = await Promise.all(promises);

      results.forEach(({ status: deleted, id }) => {
        if (!deleted) return;

        this.tableData.items = this.tableData.items.filter((v) => v.id !== id);
        this.selectedRows = this.selectedRows.filter((v) => v.id !== id);
      });

      this.$forceUpdate();

      this.loadings.deleteSelected = false;
    },
    async sendIdOnDelete(id) {
      try {
        const resp = await this.$store.dispatch("ndc-manage/deleteRow", {
          id,
        });

        return {
          status: resp.success,
          id,
        };
      } catch (e) {
        console.error(e);
      }
    },
    fixNumber(data) {
      if (!data) return null;
      return Number(data).toFixed(0);
    },
    async deleteRow() {
      setTimeout(() => {
        this.confirm = false;
      }, 5000);

      if (!this.confirm) return (this.confirm = true);
      this.confirm = false;

      const resp = await this.$store.dispatch("ndc-manage/deleteRow", {
        id: this.contentOnEdit.id,
      });

      if (resp && resp.success) {
        const clone = JSON.parse(JSON.stringify(this.tableData));
        const idx = clone.items.findIndex(
          (v) => v.id === this.contentOnEdit.id
        );

        clone.items.splice(idx, 1);

        this.tableData = clone;

        this.contentOnEdit = {};

        this.$forceUpdate();

        this.editDialogModel = false;

        this.$message.notification({
          title: "Deleted",
          text: "Data successfully deleted 👍",
          type: "success",
          duration: 3500,
        });

        this.selectedRows = this.selectedRows.filter(
          (v) => v.id !== this.contentOnEdit.id
        );

        this.loadings.saving = this.loadings.saving.filter(
          (v) => v !== this.contentOnEdit.id
        );
      }
    },
    setEditableContent(data) {
      this.contentOnEdit = JSON.parse(JSON.stringify(data));
      this.editDialogModel = true;
    },
    async saveEditedRow() {
      const itemIdOnSave = this.contentOnEdit.id;
      try {
        this.loadings.saving.push(itemIdOnSave);

        const resp = await this.$store.dispatch("ndc-manage/updateFact", {
          ...this.contentOnEdit,
        });
        this.loadings.saving = this.loadings.saving.filter(
          (v) => v !== itemIdOnSave
        );

        if (resp.success) {
          const clone = deepClone(this.tableData);

          const idx = clone.items.findIndex(
            (v) => v.id === this.contentOnEdit.id
          );

          clone.items[idx] = this.contentOnEdit;

          this.tableData = clone;

          this.$forceUpdate();

          this.editDialogModel = false;

          this.$message.notification({
            title: "Saved",
            text: "Data successfully saved 👍",
            type: "success",
            duration: 3500,
          });
        }
      } catch (e) {
        console.error(e);
        this.loadings.saving = this.loadings.saving.filter(
          (v) => v !== itemIdOnSave
        );
      }
    },
    currencyFormat(data) {
      return Number(data).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    fixItems(data) {
      data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (typeof item[key] === "number" && item[key] < 1) item[key] = null;
          if (String(item[key]) === "0") item[key] = null;
        });
      });
      return [...data];
    },
    async fetchData() {
      const payload = {
        ...this.filters,
      };

      this.loadings.table = true;

      const resp = await this.$store.dispatch("ndc-manage/fetchData", payload);

      if (resp) this.tableData.items = this.fixItems(resp.data);

      this.loadings.table = false;
    },
  },
};
</script>
