<template>
  <v-container class="positions" fluid>
    <v-row>
      <v-col>
        <h2>Positions</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
      <v-col cols="auto" class="d-flex align-end">
        <v-btn
          style="height: 40px"
          block
          class="px-3 text-normal"
          color="primary"
          :loading="loadings.dashboard"
          @click="fetchDashboardData"
        >
          Get data
          <v-icon small right>mdi-refresh</v-icon>
        </v-btn>
        <v-chip v-if="false" outlined label @click="handleCopyUrl">
          {{ copied ? "Copied" : "Copy URL" }}
          <v-icon small right
            >{{ copied ? "mdi-check" : "mdi-content-copy" }}
          </v-icon>
        </v-chip>
      </v-col>
    </v-row>
    <v-card flat outlined rounded="lg" class="px-5 pt-3 pb-5 mt-4 mb-3">
      <v-form ref="formRef">
        <v-row dense>
          <v-col cols="12">
            <v-row dense>
              <v-col>
                <filter-label> Project</filter-label>
                <v-autocomplete
                  v-model="filters.project"
                  :items="filtersData.projects"
                  hide-details="auto"
                  clearable
                  dense
                  outlined
                  :loading="loadings.projects"
                  placeholder="Type to search"
                  append-icon="mdi-chevron-down"
                  @change="handleChangeProject"
                />
              </v-col>
              <v-col>
                <filter-label> Subproject</filter-label>
                <v-autocomplete
                  v-model="filters.subproject"
                  :items="filtersData.subprojects"
                  hide-details="auto"
                  clearable
                  dense
                  multiple
                  placeholder="Type to search"
                  outlined
                  :loading="loadings.subprojects"
                  append-icon="mdi-chevron-down"
                  @change="handleChangeSubproject"
                />
              </v-col>
              <v-col>
                <filter-label> Url</filter-label>
                <v-autocomplete
                  v-model="filters.url"
                  :items="filtersData.urls"
                  hide-details="auto"
                  clearable
                  dense
                  placeholder="Type to search"
                  outlined
                  :loading="loadings.projects"
                  append-icon="mdi-chevron-down"
                />
                <small style="line-height: 110%" class="warning--text">
                  {{
                    computedSelectProjectOrSubprojectError
                      ? SELECT_PROJECT_OR_SUBPROJECT_ERROR_MESSAGE
                      : ""
                  }}
                </small>
              </v-col>
              <v-col>
                <filter-label> Cluster</filter-label>
                <v-autocomplete
                  v-model="filters.cluster"
                  :items="filtersData.clusters"
                  hide-details="auto"
                  clearable
                  dense
                  placeholder="Type to search"
                  outlined
                  :loading="loadings.projects"
                  append-icon="mdi-chevron-down"
                />
                <small style="line-height: 110%" class="warning--text">
                  {{
                    computedSelectProjectOrSubprojectError
                      ? SELECT_PROJECT_OR_SUBPROJECT_ERROR_MESSAGE
                      : ""
                  }}
                </small>
              </v-col>
              <v-col>
                <filter-label> Priority</filter-label>
                <v-autocomplete
                  v-model="filters.priority"
                  chips
                  deletable-chips
                  multiple
                  :items="['F', 'S', 'T']"
                  hide-details="auto"
                  clearable
                  dense
                  placeholder="Type to search"
                  outlined
                  append-icon="mdi-chevron-down"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row dense>
              <v-col>
                <filter-label>
                  Dropped keywords

                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon small v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <template #default>
                      <div style="width: 360px">
                        When enabled, it only shows the keys that had a position
                        of 1-100 yesterday and are in positions 101+ today
                      </div>
                    </template>
                  </v-tooltip>
                </filter-label>
                <v-checkbox
                  v-model="filters.dropped_keywords"
                  hide-details
                  dense
                  :true-value="1"
                  :false-value="0"
                  label="Dropped keywords"
                />
              </v-col>
              <v-col>
                <filter-label>
                  New keywords

                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon small v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <template #default>
                      <div style="width: 360px">
                        When enabled, it only shows the keys that had a position
                        of 101+ yesterday and are in positions 1-100 today
                      </div>
                    </template>
                  </v-tooltip>
                </filter-label>
                <v-checkbox
                  v-model="filters.new_keywords"
                  hide-details
                  dense
                  :true-value="1"
                  :false-value="0"
                  label="New keywords"
                />
              </v-col>
              <v-col>
                <filter-label>
                  Wrong target URL
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon small v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <template #default>
                      <div style="width: 360px">
                        When enabled, it only shows the keys that are ranked by
                        an irrelevant page from the Keywords control
                      </div>
                    </template>
                  </v-tooltip>
                </filter-label>
                <v-checkbox
                  v-model="filters.wrong_target_url"
                  hide-details
                  dense
                  :true-value="1"
                  :false-value="0"
                  label="Wrong target URL"
                />
              </v-col>
              <v-col>
                <filter-label> By top</filter-label>
                <v-autocomplete
                  v-model="filters.by_top"
                  multiple
                  chips
                  deletable-chips
                  :items="['1', '2-3', '4-10', '11-20', '21-50', '51-100']"
                  hide-details="auto"
                  clearable
                  dense
                  placeholder="Type to search"
                  outlined
                  append-icon="mdi-chevron-down"
                />
              </v-col>
              <v-col>
                <filter-label> Date</filter-label>
                <smart-date-filter
                  outlined
                  label=""
                  :disabled="loadings.projects"
                  :model-value="filters.date"
                  @update:modelValue="filters.date = $event"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-autocomplete
                  v-model="filters.project_categories"
                  :items="filtersData.project_categories"
                  :loading="filtersDataPendingKeys?.project_categories"
                  multiple
                  hide-details="auto"
                  clearable
                  dense
                  outlined
                  label="Project categories"
                  placeholder="Type to search"
                />
              </v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <div
      v-if="firstLoading && loadings.trends"
      class="d-flex py-2"
      style="gap: 0.5rem"
    >
      <div
        v-for="i in [0, 1, 2, 3, 4]"
        class="skeleton"
        style="width: 33.33%; height: 142px"
        :key="`trends-sekeleton-${i}`"
      ></div>
    </div>
    <v-row v-else-if="dashboard?.trends && dashboard?.trends?.length > 0">
      <v-col cols="12">
        <div class="d-flex" style="gap: 0.5rem">
          <trends-card
            v-for="trend in dashboard?.trends || []"
            :key="trend.id"
            :trend="trend"
            :loading="loadings.trends"
          />
        </div>
      </v-col>
    </v-row>
    <div v-if="firstLoading && loadings.positions_chart" class="py-2">
      <div class="skeleton" style="width: 100%; height: 470px"></div>
    </div>
    <v-row
      v-else-if="
        dashboard?.positions_chart &&
        Object.keys(dashboard.positions_chart).length > 0
      "
    >
      <v-col cols="12" class="pt-0">
        <positions-chart
          :positions="dashboard?.positions_chart"
          :loading="loadings.positions_chart"
        />
      </v-col>
    </v-row>

    <div v-if="firstLoading && loadings.positions_table" class="py-2">
      <div class="skeleton" style="width: 100%; height: 470px"></div>
    </div>

    <v-row
      v-else-if="
        dashboard?.positions && Object.keys(dashboard.positions)?.length > 0
      "
    >
      <v-col cols="12">
        <v-card
          class="styled-card--default"
          rounded="lg"
          :loading="loadings.positions_table"
        >
          <v-card-title> Positions data table</v-card-title>
          <template v-if="false">
            <v-divider />
            <v-card-text class="pa-3">
              <v-menu
                transition="slide-x-transition"
                :close-on-content-click="false"
                :nudge-right="200"
                content-class="remove-dialog-shadow pa-1"
                max-width="600"
              >
                <template v-slot:activator="{ on }">
                  <v-btn class="text-normal" color="primary" v-on="on">
                    Settings <v-icon small right>mdi-tune</v-icon>
                  </v-btn>
                </template>
                <v-card flat outlined rounded="lg">
                  <v-card-title class="text-body-2">
                    <b>Real-time table setting</b>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          label="Url"
                          v-model="positionsDataTableFilters.urls"
                          :items="filtersData.urls"
                          clearable
                          multiple
                          filled
                          return-object
                          :loading="filters.project"
                          placeholder="Type to search"
                          hide-details
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          label="Cluster"
                          v-model="positionsDataTableFilters.clusters"
                          :items="filtersData.clusters"
                          clearable
                          filled
                          :loading="filters.project"
                          placeholder="Type to search"
                          return-object
                          hide-details
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          label="Subproject"
                          v-model="positionsDataTableFilters.subprojects"
                          :items="filtersData.subprojects"
                          clearable
                          :loading="filtersDataPendingKeys?.subprojects"
                          filled
                          placeholder="Type to search"
                          return-object
                          hide-details
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          label="By Top"
                          v-model="positionsDataTableFilters.by_top"
                          :items="filtersData.tops"
                          multiple
                          clearable
                          small-chips
                          deletable-chips
                          return-object
                          placeholder="Type to search"
                          filled
                          hide-details
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-card-text>
          </template>
          <v-divider />
          <v-card-text class="pa-0">
            <positions-data-table
              :data="computedPositionsDashboardData"
              :filters="filters"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div v-if="firstLoading && loadings.page_types" class="py-2">
      <div class="skeleton" style="width: 100%; height: 470px"></div>
    </div>

    <v-row
      v-else-if="
        dashboard?.page_types && Object.keys(dashboard.page_types)?.length > 0
      "
    >
      <v-col cols="12">
        <v-card
          class="styled-card--default"
          rounded="lg"
          :loading="loadings.page_types"
        >
          <v-card-title>Page type</v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <page-types-table :data="dashboard?.page_types" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div v-if="firstLoading && loadings.growth_fell" class="py-2">
      <div class="skeleton" style="width: 100%; height: 470px"></div>
    </div>

    <v-row
      v-else-if="dashboard?.growth_fell && dashboard.growth_fell?.length > 0"
      dense
    >
      <v-col
        v-for="section in dashboard?.growth_fell"
        :key="section.name"
        cols="12"
      >
        <v-card class="styled-card--light" :loading="loadings.growth_fell">
          <v-card-title>
            {{ section.name }}
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col v-for="table in section.tables" :key="table.name">
                <v-card class="styled-card--default" rounded="lg">
                  <v-card-title>
                    {{ table.name }}
                  </v-card-title>
                  <v-divider />
                  <v-card-text class="pa-0">
                    <growth-fallen-data-table :data="table" />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { BREADCRUMBS } from "./defaultData";
import GrowthFallenDataTable from "@/components/PositionsV2/GrowthFallenDataTable/index.vue";
import FilterLabel from "@/components/Filter/Label/index.vue";
import Project from "../../../mixins/Project";
import PositionsChart from "../PositionsChart/index.vue";
import TrendsCard from "../TrendsCard/index.vue";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import SmartDateFilter from "../../UI/SmartDateFilter.vue";
import PositionsDataTable from "../PositionsDataTable/index.vue";
import PageTypesTable from "../PageTypesTable/index.vue";
import { DEFAULT_MOMENT_DATE_FORMAT } from "../../../utils/defaultData";
import PositionsApiService from "../../../services/positions";
import QueryBuilder from "../../../mixins/QueryBuilder";
import copy from "copy-to-clipboard";
import header from "../../Gpm/ViewEntity/Header.vue";
import UploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";
import GlobalProjectBinderMixin from "../../../mixins/utils/GlobalProjectBinderMixin";

/**
 * @typedef {object} PositionsV2DashboardSections
 * @property {object[]} growth_fell
 * @property {object[]} trends
 * @property {{header: any[], items: any[]}} page_types
 * @property {{header: any[], items: any[]}} positions
 * @property {{categories: any[], series: any[]}} positions_chart
 */

export default {
  components: {
    GrowthFallenDataTable,
    PositionsDataTable,
    FilterLabel,
    SmartDateFilter,
    TrendsCard,
    PositionsChart,
    PageTypesTable,
  },
  mixins: [
    Project,
    FiltersHandling,
    QueryBuilder,
    UploadFiltersDataMixin,
    GlobalProjectBinderMixin,
  ],
  data: () => ({
    firstLoading: true,
    positionsDataTableFilters: {
      urls: [],
      clusters: [],
      subprojects: [],
      by_top: [],
      cell_style: null,
    },
    apiService: null,
    isFiltersBlocked: false,
    copied: false,
    filtersData: {
      project_categories: [],
      subprojects: [],
      projects: [],
      urls: [],
      clusters: [],
      tops: [],
      priority: [],
    },
    SELECT_PROJECT_OR_SUBPROJECT_ERROR_MESSAGE:
      "To see the list, select a project or subproject.",
    breadcrumbs: BREADCRUMBS,
    trends: null, // MOCK_TRENDS_DATA,
    positions: null, //MOCK_POSITIONS,
    positionsTable: null,
    growthFellSections: null,
    pageType: null,
    filtersAlreadyInitialized: false,
    loadings: {
      positions_chart: false,
      growth_fell: false,
      page_types: false,
      positions_table: false,
      trends: false,
      projects: false,
      dashboard: false,
      subprojects: false,
    },

    /** @type PositionsV2DashboardSections */
    dashboard: {
      growth_fell: [],
      positions_chart: {},
      positions: {},
      page_types: {},
      trends: [],
    },
    filters: {
      project_categories: [],
      project: null,
      by_top: [],
      priority: [],
      dropped_keywords: false,
      new_keywords: false,
      wrong_target_url: false,
      url: null,
      cluster: null,
      subproject: [],
      date: [null, null],
    },
  }),
  watch: {
    "filters.project"(value) {
      if (!value) {
        this.filters.subproject = null;
        this.filtersData.subprojects = [];
      }
    },
  },
  created() {
    this.apiService = new PositionsApiService();
  },
  async mounted() {
    await this.parseQuery("filters");

    if (!this.filters.project)
      this.filters.project = this.gpbmComputedGlobalProject;

    await this.uploadFiltersData(
      ["project_categories", "projects", "subprojects"],
      {
        project: this.filters.project,
      }
    );

    await this.$nextTick();

    if (this.$route.query.project) this.handleChangeProject();

    if (!this.$route.query.date) this.initializeDefaultDateFilter();
  },
  computed: {
    computedSelectProjectOrSubprojectError() {
      return !this.filters.project && !this.filters.subproject;
    },
    computedPositionsDashboardData() {
      const result = {
        ...this.dashboard.positions,
        items: this.dashboard.positions.items.filter(this.filterPositionsTable),
      };

      return result;
    },
    header() {
      return header;
    },
  },
  methods: {
    gpbmHandleChangeGlobalProject(projectId) {
      this.filters.project = projectId;
    },
    filterPositionsTable(item) {
      return !!item;
    },
    handleCopyUrl() {
      this.copied = true;

      copy(window.location.href + this._$buildQuery(this.filters));

      setTimeout(() => (this.copied = false), 1000);
    },
    async getFilters(take, filter) {
      try {
        this.loadings.projects = true;
        const payload = {
          type: "/serp/positions",
          take,
          filter,
        };
        return await this.$store.dispatch("global/getFilters", payload);
      } catch {
        console.error("Error while loading projects");
      } finally {
        this.loadings.projects = false;
      }
    },
    async handleChangeProject() {
      const response = await this.getFilters(
        ["urls", "clusters", "tops", "priority"],
        {
          projectID: this.filters.project,
          subproject: this.filters.subproject,
        }
      );
      this.filtersData = {
        ...this.filtersData,
        ...(response || {}),
      };

      this.uploadFiltersData([
        "subprojects",
        {
          project: this.filters.project,
        },
      ]);

      // Pre-select first subproject
      // if (this.filtersData?.subprojects?.length) {
      //   this.filters.subproject = this.filtersData.subprojects[0]?.value;
      // }
    },
    async handleChangeSubproject() {
      const response = await this.getFilters(
        ["urls", "clusters", "tops", "priority"],
        {
          projectID: this.filters.project,
          subproject: this.filters.subproject,
        }
      );
      this.filtersData = {
        ...this.filtersData,
        ...(response || {}),
      };
    },
    initializeDefaultDateFilter() {
      if (!this.filters.date.every((date) => date)) {
        const start28DaysAgo = this.$moment()
          .subtract(28, "days")
          .startOf("day")
          .format(DEFAULT_MOMENT_DATE_FORMAT);
        const today = this.$moment()
          .endOf("day")
          .format(DEFAULT_MOMENT_DATE_FORMAT);

        this.filters.date = [start28DaysAgo, today];
      }
    },
    /**
     * @param data
     * @param data.growth_fell{object[]}
     * @param data.trends{object[]}
     * @param data.page_types{{header: any[], items: any[]}}
     * @param data.positions{{header: any[], items: any[]}}
     * @param data.positions_chart{{categories: any[], series: any[]}}
     */
    initializeDashboard(data) {
      this.dashboard = data;
    },
    async initializeTrends() {
      this.loadings.trends = true;

      const { trends } = await this.apiService.getTrends({
        filters: this.filters,
      });

      this.$set(this.dashboard, "trends", trends);

      this.loadings.trends = false;
    },
    async initializePositionsChart() {
      this.loadings.positions_chart = true;

      const { items } = await this.apiService.getPositionsChart({
        filters: this.filters,
      });

      this.$set(this.dashboard, "positions_chart", items);

      this.loadings.positions_chart = false;
    },
    async initializePositionsTable() {
      this.loadings.positions_table = true;

      const { items } = await this.apiService.getPositionsTable({
        filters: this.filters,
      });

      this.$set(this.dashboard, "positions", items);

      this.loadings.positions_table = false;
    },
    async initializePageTypes() {
      this.loadings.page_types = true;

      const { items } = await this.apiService.getPageTypes({
        filters: this.filters,
      });

      this.$set(this.dashboard, "page_types", items);

      this.loadings.page_types = false;
    },
    async initializeGrowthFell() {
      this.loadings.growth_fell = true;

      const { growth_fell } = await this.apiService.getGrowthFell({
        filters: this.filters,
      });

      this.$set(this.dashboard, "growth_fell", growth_fell);

      this.loadings.growth_fell = false;
    },
    async fetchDashboardData() {
      if (!this.$refs.formRef?.validate()) return;

      await this.initializeTrends();

      const promises = [
        this.initializePositionsChart(),
        this.initializePositionsTable(),
        this.initializePageTypes(),
        this.initializeGrowthFell(),
      ];

      Promise.allSettled(promises).then(() => {
        this.firstLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
.positions {
  //
}
</style>
