import NicheAnalyzerHomePage from "@/components/NicheAnalyzer/Home";
import NicheAnalyzerAddNewProjectPage from "@/components/NicheAnalyzer/Add/index.vue";
import NicheAnalyzerIndexPage from "@/components/NicheAnalyzer/Index";
import NicheAnalyzerShowPage from "@/components/NicheAnalyzer/Show";
import DetailedStaticPage from "@/components/NicheAnalyzer/DetailedStatic/Index";
import NicheAnalyzerProjectDashboardPage from "@/components/NicheAnalyzer/ProjectDashboard/Index";
import GlobalSettingsPage from "@/components/GlobalSettings/index.vue";
import AccessDeniedPage from "@/components/Main/AccessDeniedIndex";
import UserActivityPage from "@/components/UserActivity/Index";
import UserActivityStaticPage from "@/components/UserActivityStatic/index.vue";
import StatusHomePage from "@/components/Status/Home";
import AparserBackLinksPage from "@/components/Aparser/BackLinks/BackLinksIndex";
import SpiderLinkStatusPage from "@/components/Status/SpiderLink/SpiderLinkIndex";
import AparserTaskPage from "@/components/Aparser/Task/Index";
import SiteAnalysisPage from "@/components/SiteAnalysis/index.vue";
import UctPage from "../components/UCT/Index";
import GttPage from "@/components/GpTopicTool/Home";
import ContentCheckerShowPage from "@/components/ContentChecker/Show";
import ContentCheckerCompareBlocks from "@/components/ContentChecker/CompareBlocks";
import GpTopicToolPage from "@/components/GpTopicTool/Show";
import EssayHubPage from "@/components/EssayHub/Home";
import EssayHubIndexPage from "@/components/EssayHub/Index";
import EssayHubShowPage from "@/components/EssayHub/Show";
import HelpPage from "@/components/Help/Index";
import LinksPlanPage from "@/components/LinksPlan/Index";
import TextRazorPage from "@/components/Textrazor/TaskManager/Index";
import DevDashboardDetailedPage from "@/components/DashboardDev/Detailed";
import DevDashboardDetailedV2Page from "@/components/DashboardDevV2/Detailed.vue";
import AnchorsInfoByPages from "@/components/Dashboards/AnchorsInfoByPages/index.vue";
import WorkSectionThirdDailyReportPage from "@/components/Ltt/Dashboards/WorksectionDailyReportV3/index.vue";
import SamplesPerPagePage from "@/components/Analytics/AllSite/SamplesPerPage";
import LinkUrlControlByPhrasePage from "@/components/Ltt/Dashboards/LinkUrlControlPhrase";
import GpmViewEntityPage from "../components/Gpm/GpmEntityId.vue";
import SemanticToolPage from "../components/SemanticTool/Index";
import SemanticToolKeywordsPage from "../components/SemanticTool/Keywords";
import SemanticToolKeywordsDetailedPage from "@/components/SemanticTool/KeywordsDetailed";
import SemanticToolDashboardPage from "../components/SemanticTool/Dashboard";
import AnchorPlanLbHelperPage from "@/components/AnchorPlan/AnchorPlanLbHelper";
import AnchorPlansPlacementLinksPage from "@/components/AnchorPlan/PlacementLinks/Other/PlacementLinksIndex";
import AnchorPlanTierTwoPlacementLinksPage from "@/components/AnchorPlan/PlacementLinks/Other/PlacementLinksTier2Index";
import AnchorPlanTaskEditPage from "@/components/AnchorPlan/Edit/AnchorPlanEdit";
import AnchorPlanPlacementLinksXPage from "@/components/AnchorPlan/PlacementLinks/X/PlacementLinksIndex";
import AnchorPlanPlacementLinksXTierTwoIndexPage from "@/components/AnchorPlan/PlacementLinks/X/PlacementLinksTier2Index";
import SearchConsoleUrlPage from "@/components/SearchConsole/Url/SearchConsoleUrlIndex";
import MailerPage from "@/components/Mailer/Home";
import MailerIndexPage from "@/components/Mailer/Index";
import MailerShowPage from "@/components/Mailer/Show";
import NewLinksDynamicDataPage from "@/components/OnePageAnalyzer/NewLinksDynamicDashboard/index.vue";
import PositionsDashboardPage from "@/components/PositionsDashboard/index.vue";
import NdcManagePage from "@/components/NDCManage/index.vue";
import MoneyPlanPage from "@/components/NDCManage/MoneyPlan.vue";
import NdcManageChartsPage from "@/components/NDCManage/Charts/index.vue";
import GpDonorsFinderCreatePage from "@/components/GpDonorsFinder/Create";
import FlexDonorsFinderAcceptorDomainsPage from "@/components/FlexDonorsFinder/AcceptorDomains/index.vue";
import TdhOnePage from "@/components/TDH1/index.vue";
import SimilarTaskCreatingTaskInSubtaskPage from "@/components/SimilarTaskCreating/TaskInSubtask/index.vue";
import SimilarTaskCreatingChooseTypePage from "@/components/SimilarTaskCreating/ChooseType/index.vue";
import SimilarTaskCreatingToThoseResponsiblePage from "@/components/SimilarTaskCreating/ToThoseResponsible/index.vue";
import SimilarTaskCreatingByFilePage from "@/components/SimilarTaskCreating/ByFile/index.vue";
import SimilarTaskCreatingSeriesPage from "@/components/SimilarTaskCreating/Series/index.vue";
import PageNotFoundPage from "@/components/NotFound/PageNotFound";
import Dashboard4DX from "@/components/Dashboard4DX/index.vue";
import Dashboard4DXLb from "@/components/Dashboard4DXLb/index.vue";
import Tribe4DX from "@/components/Tribe4DX/index.vue";
import WebsiteLinksStatusDashboard from "@/components/WebsiteLinksStatusDashboard/index.vue";
import auth from "@/middleware/auth";
import RoleMiddleware from "@/middleware/roles/RoleMiddleware";
import HomePage from "@/components/Home/Index";
import TextGeneratePage from "@/components/TextGenerate/Index";
import DashboardsHomePage from "@/components/Dashboards/Home";
import LoginPage from "@/components/LoginForm/Index";
import SearchConsolePage from "@/components/SearchConsole/Home";
import LttHomePage from "../components/Ltt/Index";
import DevDashboardPage from "@/components/DashboardDev";
import SummaryDashboardPage from "@/components/Dashboards/Summary/SummaryIndex";
import BriefLowImpressionsPagesAnalyzeIndex from "@/components/Dashboards/BriefLowImpressionsPagesAnalyze/Index";
import SnippetOptimizationPage from "@/components/Dashboards/Summary/Top5PageOptimization";
import UrlsOverviewPage from "@/components/Dashboards/UrlsOverview/index.vue";
import LinksPlanningByUrlsPage from "@/components/Dashboards/LinkPlanningByUrls/index";
import LinksPlanningBySitePage from "@/components/Dashboards/LinkPlanningBySite/index";
import SiteOverviewDashboardPage from "../components/Dashboards/SiteOverview/index.vue";
import SurvivalDashboardPage from "@/components/Dashboards/SurvivalDashboard/index.vue";
import ContentDashboardPage from "@/components/Dashboards/Content/ContentIndex";
import ContentPlanningPage from "@/components/Dashboards/ContentPlaning/index.vue";
import PagesInfoDashboardPage from "@/components/Dashboards/ContentChecker/index.vue";
import TechnicalDashboardPage from "@/components/Dashboards/Technical/TechnicalIndex";
import AnalyticsByPageTypeDashboardPage from "@/components/Analytics/byPageType/index.vue";
import CommercialPagesDashboardPage from "@/components/Analytics/CommercialPages/CommercialPagesIndex";
import AllSitePage from "@/components/Analytics/AllSite/AllSiteIndex";
import ConversionFirstReportPage from "@/components/Analytics/Conversion/ConversionReport1Index";
import TopFivePagesConversionPage from "@/components/Analytics/Conversion/Top5PagesConversion/index.vue";
import AnalyticsConversionReportPage from "@/components/Analytics/Conversion/ConversionIndex";
import AnalyticsConversionSecondReportPage from "@/components/Analytics/Conversion/Conversion2Index";
import ConversionThirdPage from "@/components/Analytics/Conversion/Conversion3Index";
import ConversionFourthPage from "@/components/Analytics/Conversion/Conversion4Index";
import ConversionFifthPage from "@/components/Analytics/Conversion/Conversion5Index";
import ConversionSixthPage from "@/components/Analytics/Conversion/Conversion6Index";
import ConversionSeventhPage from "@/components/Analytics/Conversion/Conversion7Index";
import ConversionEightPage from "@/components/Analytics/Conversion/Conversion8.vue";
import WorkSectionWeekReportPage from "@/components/Ltt/Dashboards/WorksectionWeekReportIndex";
import LttSecondLevelPage from "@/components/Ltt/2Level/Index";
import LttContinuePayment from "@/components/Ltt/ContinuePayment/index.vue";
import LttIndexAllPage from "@/components/Ltt/IndexAll";
import LttMainReportPage from "../components/Ltt/Report/Index";
import LinksDynamicReportPage from "@/components/Ltt/Dashboards/LinkDynamicsReportIndex";
import LinkUrlControlPage from "@/components/Ltt/Dashboards/LinkUrlControl";
import DeletedLinksPage from "@/components/Ltt/DeletedLinks/Index";
import LinksProgressDashboardPage from "@/components/Ltt/Dashboards/ProgressLinksDashboardIndex";
import LttMonitorPage from "@/components/Ltt/LinksMonitor/Index";
import LttDynamicDomainPage from "@/components/Ltt/Dashboards/LinkDynamicsDomainReportIndex";
import GpmGuestPostPage from "@/components/Gpm/GpmGuestPost";
import OutreachLeadConversionPage from "@/components/Gpm/OutreachLeadConversion/index.vue";
import GpTrackerPage from "@/components/Gpm/GPTracker/index.vue";
import GpmThirdBlogPage from "@/components/Gpm/Dashboard/Gpm3rdBlog";
import GpmReservedDomainPage from "@/components/Gpm/GpmReservedDomains";
import GpmMainDashboardPage from "@/components/Gpm/Dashboard/index.vue";
import AlexDashboardPage from "@/components/Gpm/Dashboard/Alex";
import ResearchHubPage from "@/components/Gpm/Dashboard/ResearchHub";
import ManagerDashboardPage from "../components/Gpm/Dashboard/Manager";
import TimeSpentDashboardPage from "@/components/Gpm/Dashboard/TimeSpent";
import GpmAdminFieldsPage from "@/components/Gpm/Admin/FieldManager";
import GpmAdminStatusPage from "@/components/Gpm/Admin/StatusManger";
import GpmGpTotalPage from "@/components/Gpm/GpmGPTotal";
import ManagersGpmDashboardPage from "@/components/Gpm/Managers/index.vue";
import OutreachProblemsPage from "@/components/Gpm/OutreachProblems/index.vue";
import ContentCheckerIndexPage from "../components/ContentChecker/Index";
import ContentCheckerChangesPage from "@/components/ContentChecker/Changes";
import GttIndexPage from "@/components/GpTopicTool/Index";
import KeywordControlIndexPage from "@/components/KeywordControl/Index";
import UrlControlIndexPage from "../components/UrlControl/Index";
import SiteWorkplaceDashboardPage from "@/components/SiteWorkplace/Dashboard";
import SiteWorkplaceIndexPage from "@/components/SiteWorkplace/SiteWorksheet/Index";
import FieldManagerPage from "@/components/SiteWorkplace/Admin/FieldManager";
import AnchorPlanTasksIndexPage from "../components/AnchorPlan/AnchorPlanIndex.vue";
import AnchorPlanTierTwoTasksPage from "@/components/AnchorPlan/AnchorPlanTier2Index";
import AnchorPlansLinkBuilderTasksPage from "@/components/AnchorPlan/Linkbuilder/LinkbuilderAnchorPlanIndex";
import AnchorPlansTierTwoLinkBuilderTasksPage from "@/components/AnchorPlan/Linkbuilder/LinkbuilderAnchorPlanTier2Index";
import AnchorPlanDashboardPage from "@/components/AnchorPlan/Dashboard/AnchorPlanDashboardIndex.vue";
import SearchConsoleIndexPage from "@/components/SearchConsole/SearchConsoleIndex";
import PositionsIndexPage from "@/components/Serp/PositionsIndex";
import PositionsV2Page from "../components/PositionsV2/Page/index.vue";
import PositionsV3Page from "../components/PositionsV3/Page/index.vue";
import DonorBaseCleanIndexPage from "../components/DonorBase/Clean/Index";
import DonorBaseXIndexPage from "../components/DonorBase/X/Index";
import DonorBaseXResearchIndexPage from "@/components/DonorBase/ResearchBaseX/Index";
import ShellLinksPage from "@/components/DonorBase/ShellLinks/index.vue";
import OnePageAnalyzerHomePage from "@/components/OnePageAnalyzer/Home";
import AnchorDistributionPage from "@/components/OnePageAnalyzer/AnchorDistribution/index.vue";
import OnePageAnalyzerShowPage from "../components/OnePageAnalyzer/Show";
import LinksDynamicDataPage from "@/components/OnePageAnalyzer/LinksDynamicDashboard/index.vue";
import CompetitorUrlDynamicPage from "@/components/OnePageAnalyzer/CompetitorUrlsDynamic/index.vue";
import ExportQueuePage from "@/components/ExportQueue/index";
import OneYearReportPage from "@/components/OneYearReport/index.vue";
import GscOneYearReportPage from "@/components/GoogleSearchConsoleOneYearReport/index";
import DbcDashboardPage from "@/components/Dashboards/DBC/index";
import SerpDynamicDashboardPage from "@/components/SERPDynamic/index.vue";
import LttDeletedLinksPage from "@/components/Ltt/LTTDeletedLinks/index.vue";
import ContentBallAnalyticsPage from "@/components/ContentballAnalytics/index.vue";
import DailyOverallReportPage from "@/components/DailyOverallReport/index.vue";
import GpDonorsFinderPage from "@/components/GpDonorsFinder";
import MonthlyReportPage from "@/components/MonthlyReport/index.vue";
import FlexDonorsFinderPage from "@/components/FlexDonorsFinder/index.vue";
import GpCurrentResultDashboardPage from "@/components/GPCurrentResultDashboard/index.vue";
import CheckUniquePage from "@/components/CheckUnique/index.vue";
import CompetitorsPositionsPage from "@/components/CompetitorsPositions/index.vue";
import AccountManagePage from "@/components/Accounts/index.vue";
import RolesManagePage from "@/components/Roles/index.vue";
import TaskPriorityPage from "@/components/TaskPriority/index.vue";
import PositionHomePage from "@/components/Serp/PositionIndexRoute.vue";
import AutopostingPage from "@/views/autoposting/index.vue";
import AutopostingViewJobsPage from "@/views/autoposting/view-jobs/index.vue";
import AutopostingTasksPage from "@/views/autoposting/tasks/index.vue";
import PageRouterView from "@/components/UI/PageRouterView.vue";
import Pages from "@/components/Pages/index.vue";
import HonorBoard from "@/components/HonorBoard/Index.vue";
import BlogAdmin from "@/components/BlogAdmin/index.vue";
import ArticleDetails from "@/components/BlogAdmin/ArticleDetails.vue";
import CreateArticle from "@/components/BlogAdmin/CreateArticle.vue";
import RelevantPhrases from "@/components/RelevantPhrases/index.vue";
import PageTypeChecker from "@/components/PageTypeChecker/index.vue";
import ManageDomainOwner from "@/components/NicheAnalyzer/ManageDomainOwner/index.vue";
import InsertHtml from "@/components/InsertHtml/index.vue";
import MonthlyMovementByTaskPage from "@/components/MonthlyMovementByTask/Page/index.vue";
import MonthlyMovementByTaskStatisticsPage from "@/components/MonthlyMovementByTaskStatistics/Page/index.vue";
import OauthPage from "../components/Google/OauthPage.vue";
import PerformanceMonitorPage from "@/views/performance/index.vue";
import SeoStrategyPage from "@/views/seo-strategy/index.vue";
import RedirectsIndex from "@/views/redirects/index.vue";
import RedirectsResults from "@/views/redirects/results/index.vue";
import RedirectsQueue from "@/views/redirects/queue/index.vue";
import GpmTagsControlPage from "@/views/gpm/tags/index.vue";
import ContentballIndexPage from "@/views/contentball/index.vue";
import ContentballPromptsPage from "@/views/contentball/prompts/index.vue";
import RoutesManagePage from "@/views/access/route/index.vue";
import ContentTeamPerformancePage from "../components/SiteWorkplace/ContentTeamPerformance/ContentTeamPerformancePage.vue";
import BackgroundTasksPage from "../components/BackgroundTasks/BackgroundTasksPage.vue";
import ContentCurrentResult from "../components/ContentCurrentResult/ContentCurrentResult.vue";
import StatusManager from "../components/SiteWorkplace/StatusManager/index.vue";
import FieldsManager from "../components/SiteWorkplace/FieldsManager/index.vue";
import BaseOfFreelance from "../components/BaseOfFreelance/index.vue";
import DomainSubfolderList from "../views/domain-subfolder/components/list/index.vue";

export default [
  // Pages existed in navigation menu
  {
    path: "/",
    name: "home",
    forced: true,
    component: HomePage,
    icon: "mdi-home",
    title: "Home",
    meta: {
      maintainer: "talasymov",
      middleware: [auth],
      title: "Home | Serpinsider",
    },
  },
  {
    path: "/autoposting",
    name: "autoposting",
    component: PageRouterView,
    title: "Autoposting",
    icon: "mdi-circle-small",
    children: [
      {
        path: "dashboard",
        name: "autoposting.dashboard",
        component: AutopostingPage,
        title: "Dashboard",
        icon: "mdi-circle-small",
        meta: {
          title: "Autoposting dashboard",
        },
      },
      {
        path: "view-task",
        name: "autoposting.view-task",
        component: AutopostingViewJobsPage,
        title: "View task",
        icon: "mdi-circle-small",
        hidden: true,
        meta: {
          title: "Autoposting | View task",
        },
      },
      {
        path: "tasks",
        name: "autoposting.tasks",
        component: AutopostingTasksPage,
        title: "Tasks",
        icon: "mdi-circle-small",
        meta: {
          title: "Autoposting | Tasks list",
        },
      },
    ],
    meta: {
      workSectionData: 241753,
      maintainer: "talasymov",
      middleware: [auth],
      title: "Autoposting | Serpinsider",
    },
  },
  {
    path: "/freelance",
    name: "freelance",
    component: BaseOfFreelance,
    title: "Base of freelance",
    icon: "mdi-circle-small",
    meta: {
      workSectionData: 241753,
      maintainer: "talasymov",
      middleware: [auth],
      section: 30,
      domain: "Freelance",
      title: "Base of freelance | Serpinsider",
    },
  },
  {
    path: "/domain-subfolder",
    name: "domain-subfolder",
    component: PageRouterView,
    title: "Domain subfolder",
    icon: "mdi-circle-small",
    children: [
      {
        path: "list",
        name: "domain-subfolder.list",
        component: DomainSubfolderList,
        title: "List",
        icon: "mdi-menu",
        meta: {
          workSectionData: 241753,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 30,
          domain: "Domain subfolder",
          title: "Domain subfolder list | Serpinsider",
        },
      },
    ],
    meta: {
      workSectionData: 241753,
      maintainer: "talasymov",
      middleware: [auth],
      section: 30,
      domain: "Freelance",
      title: "Base of freelance | Serpinsider",
    },
  },
  {
    path: "/positions",
    name: "positions",
    component: PositionHomePage,
    title: "Positions",
    icon: "mdi-chart-timeline-variant",
    meta: {
      workSectionData: 241753,
      maintainer: "talasymov",
      middleware: [auth],
      section: 30,
      domain: "Positions",
      title: "Positions Home Page | Serpinsider",
    },
    children: [
      {
        path: "v3",
        name: "positions.dashboard.v3",
        component: PositionsV3Page,
        title: "Positions Dashboard v3",
        meta: {
          new: true,
          workSectionData: 241753,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 30,
          domain: "Positions",
          title: "Positions Dashboard v3 | Serpinsider",
        },
      },
      {
        path: "one-year-report",
        name: "one-year-report",
        component: OneYearReportPage,
        icon: "mdi-chart-timeline-variant",
        title: "One year report",
        meta: {
          workSectionData: 241753,
          moduleId: 171238,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 29,
          domain: "Positions",
          title: "One year report | Serpinsider",
        },
      },
      {
        path: "gsc-one-year-report",
        name: "gsc-one-year-report",
        icon: "mdi-chart-timeline-variant",
        title: "GSG one year report",
        component: GscOneYearReportPage,
        meta: {
          workSectionData: 228161,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 12,
          domain: "GP",
          title: "GSC One Year Report | Serpinsider",
        },
      },
      {
        path: "competitors-positions",
        name: "competitors-positions",
        icon: "mdi-account-question-outline",
        title: "Competitors",
        component: CompetitorsPositionsPage,
        meta: {
          workSectionData: 228161,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 12,
          domain: "None",
          title: "Competitors Positions | Serpinsider",
        },
      },
    ],
  },
  {
    path: "/redirects",
    name: "redirects",
    component: RedirectsIndex,
    title: "Redirects",
    icon: "mdi-arrow-decision-outline",
    meta: {
      workSectionData: 241753,
      maintainer: "talasymov",
      middleware: [auth],
      section: 30,
      domain: "Redirects",
      title: "Redirects | Serpinsider",
    },
    children: [
      {
        path: "queue",
        name: "redirects.queue",
        component: RedirectsQueue,
        title: "Queue",
        meta: {
          workSectionData: 241753,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 30,
          domain: "Redirects",
          title: "Redirects queue | Serpinsider",
        },
      },
      {
        path: "results",
        name: "redirects.results",
        component: RedirectsResults,
        title: "Results",
        meta: {
          workSectionData: 241753,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 30,
          domain: "Redirects",
          title: "Redirects results | Serpinsider",
        },
      },
    ],
  },
  {
    path: "/keyword-control/index",
    name: "keyword.control.index",
    icon: "mdi-key-chain",
    title: "Keyword control",
    component: KeywordControlIndexPage,
    meta: {
      workSectionData: 240854,
      maintainer: "talasymov",
      middleware: [auth, RoleMiddleware],
      section: 8,
      domain: "Keyword Control",
      title: "Keyword Control | Serpinsider",
    },
  },
  {
    path: "/performance",
    name: "performance",
    icon: "mdi-chart",
    title: "Performance",
    component: PerformanceMonitorPage,
    meta: {
      workSectionData: 240854,
      maintainer: "klih",
      middleware: [auth, RoleMiddleware],
      section: 8,
      domain: "Performance",
      title: "Performance | Serpinsider",
    },
  },
  {
    path: "/seo-strategy",
    name: "seo-strategy",
    icon: "mdi-chart",
    title: "Seo strategy",
    component: SeoStrategyPage,
    meta: {
      workSectionData: 240854,
      maintainer: "klih",
      middleware: [auth, RoleMiddleware],
      section: 8,
      domain: "Seo Strategy",
      title: "Seo Strategy | Serpinsider",
    },
  },
  {
    path: "/site-workplace",
    name: "site-workplace",
    icon: "mdi-desk-lamp",
    title: "Site workplace",
    component: PageRouterView,
    meta: {
      workSectionData: 284639,
      maintainer: "talasymov",
      middleware: [auth],
      section: 8,
      domain: "Site Workplace",
      title: "Site Workplace | Serpinsider",
    },
    children: [
      {
        path: "dashboard",
        name: "site-workplace.dashboard",
        icon: "mdi-link-box",
        title: "Dashboard",
        component: SiteWorkplaceDashboardPage,
        meta: {
          workSectionData: 284639,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 8,
          domain: "Site Workplace Dashboard",
          title: "Site Workplace Dashboard | Serpinsider",
        },
      },
      {
        path: "content-team-performance",
        name: "site-workplace.content-team-performance",
        icon: "mdi-account-group-outline",
        title: "Content team performance",
        component: ContentTeamPerformancePage,
        meta: {
          workSectionData: 284639,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],

          section: 8,
          domain: "Site Workplace Dashboard",
          title: "Content team performance | Serpinsider",
        },
      },
      {
        name: "site-workplace.site-worksheet",
        path: "site-worksheet",
        icon: "mdi-link-box",
        title: "Site Worksheet by elements",
        component: SiteWorkplaceIndexPage,
        meta: {
          workSectionData: 284639,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 8,
          domain: "Site Workplace Dashboard",
          title: "Site Worksheet by elements | Serpinsider",
        },
      },
      {
        path: "url-control",
        name: "url.control.index",
        icon: "mdi-ghost",
        title: "Url control",
        component: UrlControlIndexPage,
        meta: {
          workSectionData: 284639,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 8,
          domain: "Url Control",
          title: "Url Control | Serpinsider",
        },
      },
      {
        name: "site-workplace.admin.status-manager",
        path: "admin/status-manager",
        icon: "mdi-cog",
        title: "Status manager",
        component: StatusManager,
        meta: {
          workSectionData: 284639,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 8,
          domain: "Site Workplace",
          title: "Status manager | Serpinsider",
        },
      },
      {
        name: "site-workplace.admin.fields-manager",
        path: "admin/fields-manager",
        icon: "mdi-cog",
        title: "Fields manager",
        component: FieldsManager,
        meta: {
          workSectionData: 284639,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 8,
          domain: "Site Workplace",
          title: "Fields manager | Serpinsider",
        },
      },
    ],
  },
  {
    name: "content.checker",
    path: "/content-checker",
    icon: "mdi-clipboard-check-outline",
    title: "Content checker",
    component: PageRouterView,
    meta: {
      workSectionData: 224096,
      maintainer: "talasymov",
      middleware: [auth],
      section: 36,
      domain: "Content Checker",
      title: "Content Checker | Serpinsider",
    },
    children: [
      {
        name: "content.checker.show",
        path: "show/:id",
        hidden: true,
        component: ContentCheckerShowPage,
        meta: {
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 36,
          domain: "Content Checker",
          title: "Content Checker Show | Serpinsider",
        },
      },
      {
        name: "content.checker.compare-blocks",
        path: "compare-blocks/:id?",
        hidden: true,
        component: ContentCheckerCompareBlocks,
        meta: {
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 36,
          domain: "Content Checker",
          title: "Compare Blocks - Content Checker | Serpinsider",
        },
      },
      {
        name: "content-checker.dashboard",
        path: "dashboard",
        icon: "mdi-clipboard-check-outline",
        title: "Dashboard",
        component: ContentCheckerIndexPage,
        meta: {
          workSectionData: 224096,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 36,
          domain: "Content Checker",
          title: "Content Checker Dashboard | Serpinsider",
        },
      },
      {
        name: "content-checker.changes",
        path: "changes/:id?",
        icon: "mdi-calendar-month",
        title: "Changes",
        component: ContentCheckerChangesPage,
        meta: {
          workSectionData: 224096,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 36,
          domain: "Content Checker",
          title: "Content Checker Changes | Serpinsider",
        },
      },
    ],
  },
  {
    name: "anchors-plan",
    path: "/anchors-plan",
    icon: "mdi-anchor",
    title: "Anchors plan",
    component: PageRouterView,
    meta: {
      workSectionData: 228161,
      maintainer: "klih",
      middleware: [auth],
      section: 32,
      domain: "Anchor Plan",
      title: "Anchor Plan | Serpinsider",
    },
    children: [
      {
        name: "anchors-plan.dashboard",
        path: "dashboard/v1",
        icon: "mdi-chart-bar-stacked",
        title: "Anchor plan dashboard",
        component: AnchorPlanDashboardPage,
        meta: {
          workSectionData: 228161,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 34,
          domain: "Anchor Plan",
          title: "Anchor Plan Dashboard | Serpinsider",
        },
      },
      {
        name: "anchors-plan.tasks",
        path: "tasks",
        icon: "mdi-clipboard-list",
        title: "Tier 1 (Tasks)",
        component: AnchorPlanTasksIndexPage,
        meta: {
          workSectionData: 228161,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 32,
          domain: "Anchor Plan",
          title: "Anchor Plan | Serpinsider",
        },
      },
      {
        name: "anchors-plan.linkbuilder.tasks",
        path: "linkbuilder/tasks",
        icon: "mdi-calendar-plus",
        title: "Tier 1 (LB)",
        component: AnchorPlansLinkBuilderTasksPage,
        meta: {
          workSectionData: 228161,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 33,
          domain: "Anchor Plan",
          title: "LinkBuilder Tasks - Anchor Plan | Serpinsider",
        },
      },
      {
        name: "anchors-plan.tier2.tasks",
        path: "tier2/tasks",
        icon: "mdi-clipboard-list",
        title: "Tier 2 (Tasks)",
        component: AnchorPlanTierTwoTasksPage,
        meta: {
          workSectionData: 228161,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 32,
          domain: "Anchor Plan",
          title: "Anchor Plan Tier2 | Serpinsider",
        },
      },
      {
        name: "anchors-plan.tier2.linkbuilder.tasks",
        path: "tier2/linkbuilder/tasks",
        icon: "mdi-calendar-plus",
        title: "Tier 2 (LB)",
        component: AnchorPlansTierTwoLinkBuilderTasksPage,
        meta: {
          workSectionData: 228161,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 33,
          domain: "Anchor Plan",
          title: "LinkBuilder Tasks2 - Anchor Plan | Serpinsider",
        },
      },
      {
        name: "anchors-plan.lb-helper",
        path: "lb-helper",
        hidden: true,
        component: AnchorPlanLbHelperPage,
        meta: {
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 32,
          domain: "Anchor Plan",
          title: "Anchor Plan LB Helper | Serpinsider",
        },
      },
      {
        name: "anchors-plan.placement-links",
        path: "placement-links/:id",
        hidden: true,
        component: AnchorPlansPlacementLinksPage,
        meta: {
          workSectionData: 228161,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 34,
          domain: "Anchor Plan",
          title: "Placement Links - Anchor Plan | Serpinsider",
        },
      },
      {
        name: "anchors-plan.tier2.placement-links",
        path: "tier2/placement-links/:id",
        hidden: true,
        component: AnchorPlanTierTwoPlacementLinksPage,
        meta: {
          workSectionData: 228161,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 34,
          domain: "Anchor Plan",
          title: "Placement Links - Anchor Plan | Serpinsider",
        },
      },
      {
        name: "anchors-plan.task.edit",
        path: "task/edit/:id/:level",
        hidden: true,
        component: AnchorPlanTaskEditPage,
        meta: {
          workSectionData: 228161,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 34,
          domain: "Anchor Plan Edit",
          title: "Anchor Plan Edit - Anchor Plan | Serpinsider",
        },
      },
      {
        name: "anchors-plan.placement-links.x",
        path: "placement-links/x/:id",
        hidden: true,
        component: AnchorPlanPlacementLinksXPage,
        meta: {
          workSectionData: {
            id: 228161,
            postfix: "?sel_tag=470853",
          },
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 34,
          domain: "Anchor Plan",
          title: "Placement Links - Anchor Plan | Serpinsider",
        },
      },
      {
        name: "anchors-plan.tier2.placement-links.x",
        path: "tier2/placement-links/x/:id",
        hidden: true,
        component: AnchorPlanPlacementLinksXTierTwoIndexPage,
        meta: {
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 34,
          domain: "Anchor Plan",
          title: "Placement Links - Anchor Plan | Serpinsider",
        },
      },
      {
        path: "relevant-phrases",
        name: "relevant-phrases",
        icon: "mdi-star-box-multiple-outline",
        title: "Relevant phrases",
        component: RelevantPhrases,
        meta: {
          workSectionData: 240854,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 8,
          domain: "Relevant phrases",
          title: "Relevant phrases | Serpinsider",
        },
      },
    ],
  },
  {
    path: "/donor-base",
    name: "donor-base",
    icon: "mdi-folder-account-outline",
    title: "Donor base",
    component: PageRouterView,
    meta: {
      maintainer: "klih",
      middleware: [auth],
      section: 61,
      domain: "Donor Base Clean",
      title: "Donor Base Clean | Serpinsider",
    },
    children: [
      {
        path: "clean",
        name: "donor-base.clean",
        icon: "mdi-link-box",
        title: "Clean",
        component: DonorBaseCleanIndexPage,
        meta: {
          workSectionData: 247934,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 28,
          domain: "Donor Base Clean",
          title: "Donor Base Clean | Serpinsider",
        },
      },
      {
        path: "x/clean",
        name: "donor-base.x.index",
        icon: "mdi-link-box",
        title: "X",
        component: DonorBaseXIndexPage,
        meta: {
          workSectionData: 247934,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 60,
          domain: "X Base",
          title: "X Base | Serpinsider",
        },
      },
      {
        path: "x/research",
        name: "donor-base.x.research.index",
        icon: "mdi-link-box",
        title: "X research",
        component: DonorBaseXResearchIndexPage,
        meta: {
          workSectionData: 247934,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 61,
          domain: "X Base",
          title: "X Base Research | Serpinsider",
        },
      },
      {
        path: "shell-links",
        name: "donor-base.shell-links",
        icon: "mdi-link-box",
        title: "Shell links",
        component: ShellLinksPage,
        meta: {
          workSectionData: 247934,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 61,
          domain: "Shell Links",
          title: "Shell Links | Serpinsider",
        },
      },
      {
        path: "text-generate",
        name: "text-generate",
        icon: "mdi-cloud-search",
        title: "Text generate",
        component: TextGeneratePage,
        meta: {
          maintainer: "klih",
          middleware: [auth],
          section: 42,
          title: "Text Generate | Serpinsider",
        },
      },
    ],
  },
  {
    path: "/gpm",
    name: "gpm.index",
    icon: "mdi-post-outline",
    title: "GPM",
    component: PageRouterView,
    meta: {
      maintainer: "talasymov",
      middleware: [auth],
      section: 13,
      title: "GPM | Serpinsider",
    },
    children: [
      {
        name: "gpm.main-dashboard",
        path: "gpm-main-dashboard",
        icon: "mdi-apps-box ",
        title: "Dashboard",
        component: GpmMainDashboardPage,
        meta: {
          workSectionData: 242572,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 13,
          domain: "GPM",
          title: "GPM Dashboard | Serpinsider",
        },
      },
      {
        name: "gpm.outreach-lead-conversion",
        path: "outreach-lead-conversion",
        icon: "mdi-account-convert-outline ",
        title: "Outreach lead conversion",
        component: OutreachLeadConversionPage,
        meta: {
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 13,
          domain: "GPM",
          title: "GPM Outreach Lead Conversion | Serpinsider",
        },
      },
      {
        name: "gpm.guest-post",
        path: "guest-post",
        redirect: { name: "gpm.main-dashboard" },
        icon: "mdi-apps-box ",
        title: "Main GP",
        hidden: true,
        component: GpmGuestPostPage,
        meta: {
          workSectionData: {
            id: 242572,
            postfix: "?sel_tag=470850",
          },
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 13,
          domain: "GPM",
          title: "GPM Work | Serpinsider",
        },
      },
      {
        name: "gpm.3rd-blogs",
        path: "3rd-blogs",
        icon: "mdi-apps-box ",
        title: "Main 3rd blogs",
        component: GpmThirdBlogPage,
        meta: {
          workSectionData: 242572,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 13,
          domain: "GPM",
          title: "GPM Work | Serpinsider",
        },
      },
      {
        name: "gpm.reserved-domains",
        path: "reserved-domains",
        icon: "mdi-basket-outline",
        title: "Reserved domains",
        component: GpmReservedDomainPage,
        meta: {
          workSectionData: 242572,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 13,
          domain: "GPM",
          title: "GPM Work | Serpinsider",
        },
      },
      {
        path: "gp-current-result",
        name: "gp-current-result",
        icon: "mdi-apps-box",
        title: "GP current result",
        component: GpCurrentResultDashboardPage,
        meta: {
          workSectionData: 228161,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 12,
          domain: "GP",
          title: "GP Current Result | Serpinsider",
        },
      },
      {
        path: "content-current-result",
        name: "content-current-result",
        icon: "mdi-apps-box",
        title: "Content current result",
        component: ContentCurrentResult,
        meta: {
          workSectionData: 228161,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 12,
          domain: "GP",
          title: "Content Current Result | Serpinsider",
        },
      },
      {
        name: "gpm.alex-dashboard",
        redirect: { name: "gpm.main-dashboard" },
        path: "alex-dashbaord",
        icon: "mdi-book-outline",
        title: "Status GP",
        hidden: true,
        component: AlexDashboardPage,
        meta: {
          workSectionData: 935,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 13,
          domain: "GPM",
          title: "Alex Dashboard | Serpinsider",
        },
      },
      {
        name: "gpm.time-spent-dashboard",
        path: "time-spent-dashboard",
        icon: "mdi-timer-outline",
        title: "Time spent",
        component: TimeSpentDashboardPage,
        meta: {
          workSectionData: 242572,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 13,
          domain: "GPM",
          title: "GPM Time Spent Dashboard | Serpinsider",
        },
      },
      {
        name: "gpm.research-hub",
        path: "research-hub",
        icon: "mdi-timer-outline",
        title: "Research hub",
        component: ResearchHubPage,
        meta: {
          workSectionData: 242572,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 13,
          domain: "GPM",
          title: "GPM Research Hub | Serpinsider",
        },
      },
      {
        name: "gpm.manager-dashboard",
        path: "manager-dashboard",
        icon: "mdi-progress-upload",
        title: "Progress GP",
        component: ManagerDashboardPage,
        meta: {
          workSectionData: 242572,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 13,
          domain: "GPM",
          title: "GPM Managers Dashboard | Serpinsider",
        },
      },
      {
        name: "gpm.admin.fields",
        path: "admin/fields",
        icon: "mdi-link-box",
        title: "Fields",
        component: GpmAdminFieldsPage,
        meta: {
          workSectionData: 242572,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 13,
          domain: "GPM",
          title: "GPM admin fields | Serpinsider",
        },
      },
      {
        name: "gpm.admin.status",
        path: "admin/status",
        icon: "mdi-link-box",
        title: "Status",
        component: GpmAdminStatusPage,
        meta: {
          workSectionData: 242572,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 13,
          domain: "GPM",
          title: "GPM admin status | Serpinsider",
        },
      },
      {
        name: "gpm.admin.gp-total",
        path: "admin/gp-total",
        icon: "mdi-apps-box ",
        title: "GP total",
        component: GpmGpTotalPage,
        meta: {
          workSectionData: 242572,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 13,
          domain: "GPM",
          title: "GPM Admin GP Total | Serpinsider",
        },
      },
      {
        name: "gpm.managers-gpm",
        path: "managers-gpm",
        icon: "mdi-apps-box ",
        title: "Managers GPM",
        component: ManagersGpmDashboardPage,
        meta: {
          workSectionData: 242572,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          moduleId: 171238,
          section: 13,
          domain: "GPM",
          title: "Managers GPM | Serpinsider",
        },
      },
      {
        name: "gpm.outreach-problems",
        path: "outreach-problems",
        icon: "mdi-account-alert-outline",
        title: "Outreach problems",
        component: OutreachProblemsPage,
        meta: {
          workSectionData: 242572,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          moduleId: 171238,
          section: 13,
          domain: "GPM",
          title: "Managers Outreach Problems | Serpinsider",
        },
      },
      {
        name: "gpm.gp-tracker",
        path: "gp-tracker",
        icon: "mdi-link-box",
        title: "GP tracker",
        component: GpTrackerPage,
        meta: {
          workSectionData: 242572,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 13,
          moduleId: 171238,
          domain: "GPM",
          title: "GP Tracker | Serpinsider",
        },
      },
      {
        name: "gtt.index",
        path: "index",
        icon: "mdi-eye",
        title: "GP topic tool",
        component: GttIndexPage,
        meta: {
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 58,
          domain: "GTT",
          title: "GpTopicTool | Serpinsider",
        },
      },
      {
        name: "gpm.view-entity",
        path: "view-entity/:id",
        hidden: true,
        component: GpmViewEntityPage,
        meta: {
          workSectionData: {
            id: 242572,
            postfix: "?sel_tag=470849",
          },
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 13,
          domain: "GPM",
          title: "GPM view entity | Serpinsider",
        },
      },
      {
        name: "gpm.tags",
        path: "tags",
        icon: "mdi-account-convert-outline ",
        title: "Tags control",
        component: GpmTagsControlPage,
        meta: {
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 13,
          domain: "GPM",
          title: "GPM tags control | Serpinsider",
        },
      },
    ],
  },
  {
    path: "/ltt",
    name: "ltt",
    icon: "mdi-link-box",
    title: "LTT pro",
    component: PageRouterView,
    meta: {
      maintainer: "klih",
      middleware: [auth],
      section: 48,
      title: "Ltt | Serpinsider",
    },
    children: [
      {
        name: "ltt.index",
        path: "index",
        icon: "mdi-link-box",
        title: "Links",
        component: LttHomePage,
        meta: {
          workSectionData: 244951,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 38,
          domain: "LTT",
          title: "Ltt project | Serpinsider",
        },
      },
      {
        name: "ltt.continue-payment",
        path: "continue-payment",
        icon: "mdi-currency-usd",
        title: "Continue payment",
        component: LttContinuePayment,
        meta: {
          workSectionData: 244951,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 38,
          domain: "LTT",
          title: "Ltt: Continue payment | Serpinsider",
        },
      },
      {
        name: "ltt.2Level.index",
        path: "2level/index",
        icon: "mdi-link-box",
        title: "Tier2 links",
        component: LttSecondLevelPage,
        meta: {
          workSectionData: 244951,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 55,
          domain: "LTT",
          title: "Ltt level | Serpinsider",
        },
      },
      {
        name: "ltt.index-all",
        path: "all/index",
        icon: "mdi-link-box",
        title: "Links all",
        component: LttIndexAllPage,
        meta: {
          workSectionData: 244951,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 39,
          domain: "LTT",
          title: "Ltt all | Serpinsider",
        },
      },
      {
        name: "ltt.report",
        path: "all/report",
        icon: "mdi-link-box",
        title: "Report",
        component: LttMainReportPage,
        meta: {
          workSectionData: 244951,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 39,
          domain: "LTT",
          title: "Ltt report | Serpinsider",
        },
      },
      {
        name: "ltt.link-url-control",
        path: "link-url-control",
        icon: "mdi-link-box",
        title: "Link url control",
        component: LinkUrlControlPage,
        meta: {
          workSectionData: 244951,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 40,
          domain: "LTT",
          title: "Link Url Control - LTT | Serpinsider",
        },
      },
      {
        name: "ltt.link-dynamics-report",
        path: "link-dynamics-report",
        icon: "mdi-calendar-text",
        title: "Link dynamics report",
        component: LinksDynamicReportPage,
        meta: {
          workSectionData: 244951,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 40,
          domain: "LTT",
          title: "Link Dynamics Report - LTT | Serpinsider",
        },
      },
      {
        name: "ltt.progress-links-dashboard",
        path: "progress-links-dashboard",
        icon: "mdi-calendar-text",
        title: "Progress links dashboard",
        component: LinksProgressDashboardPage,
        meta: {
          workSectionData: 244951,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 41,
          domain: "LTT",
          title: "Progress Links Dashboard - LTT | Serpinsider",
        },
      },
      {
        name: "ltt.deleted-links",
        path: "link-deleted-links",
        hidden: true,
        redirect: { name: "ltt.ltt-deleted-links" },
        icon: "mdi-link-box",
        title: "Deleted links",
        component: DeletedLinksPage,
        meta: {
          workSectionData: 244951,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 40,
          domain: "LTT",
          title: "Deleted Links - LTT | Serpinsider",
        },
      },
      {
        name: "ltt.monitor",
        path: "ltt-monitor",
        icon: "mdi-link-box",
        title: "Monitor",
        component: LttMonitorPage,
        meta: {
          workSectionData: 244951,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 41,
          domain: "LTT",
          title: "Monitor - LTT | Serpinsider",
        },
      },
      {
        name: "ltt.ltt-deleted-links",
        path: "ltt-deleted-links",
        icon: "mdi-link-box",
        title: "LTT deleted links",
        component: LttDeletedLinksPage,
        meta: {
          workSectionData: 244951,
          moduleId: 171238,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 29,
          domain: "Positions",
          title: "LTT Deleted Links | Serpinsider",
        },
      },
      {
        name: "dashboards.survival-dashboard",
        path: "survival-dashboard",
        icon: "mdi-link-box",
        title: "Survival dashboard",
        component: SurvivalDashboardPage,
        meta: {
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 44,
          title: "Survival Dashboard | Serpinsider",
        },
      },
      {
        name: "ltt.link-url-control-phrase",
        path: "link-url-control/:id",
        hidden: true,
        component: LinkUrlControlByPhrasePage,
        meta: {
          workSectionData: 244951,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 40,
          domain: "LTT",
          title: "Link Url Control - LTT | Serpinsider",
        },
      },
    ],
  },
  // Fix routes before
  {
    path: "/one-page-analyzer",
    name: "one-page-analyzer",
    icon: "mdi-book-open-page-variant-outline",
    title: "One page analyzer",
    component: OnePageAnalyzerHomePage,
    meta: {
      maintainer: "talasymov",
      middleware: [auth],
      section: 54,
      title: "One Page Analyze | Serpinsider",
    },
    children: [
      {
        name: "one-page-analyzer-show",
        path: "show",
        icon: "mdi-view-dashboard-outline",
        title: "Dashboard",
        component: OnePageAnalyzerShowPage,
        meta: {
          workSectionData: 276020,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 29,
          domain: "OPA",
          title: "One Page Analyze Show | Serpinsider",
        },
      },
      {
        name: "links-dynamic-data",
        path: "links-dynamic-data",
        icon: "mdi-chart-areaspline-variant",
        title: "Links dynamic data",
        component: LinksDynamicDataPage,
        meta: {
          workSectionData: 276020,
          moduleId: 276020,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 29,
          domain: "OPA",
          title: "Links Dynamic Dashboard | Serpinsider",
        },
      },
      {
        name: "competitor-url-dynamic",
        path: "competitor-url-dynamic",
        icon: "mdi-chart-areaspline-variant",
        title: "Competitor urls dynamic",
        component: CompetitorUrlDynamicPage,
        meta: {
          workSectionData: 276020,
          moduleId: 276020,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 29,
          domain: "OPA",
          title: "Competitor Url's Dynamic | Serpinsider",
        },
      },
      {
        path: "serp-dynamics",
        name: "serp-dynamics",
        icon: "mdi-chart-areaspline",
        title: "Links SERP dynamic",
        component: SerpDynamicDashboardPage,
        meta: {
          workSectionData: 276020,
          moduleId: 171238,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 29,
          domain: "Positions",
          title: "URLs vision & keywords SERP DYNAMICS | Serpinsider",
        },
      },
      {
        name: "one-page-analyzer.anchors-distribution",
        path: "anchor-distribution",
        icon: "mdi-chart-areaspline",
        title: "Anchors distribution",
        component: AnchorDistributionPage,
        meta: {
          workSectionData: 228161,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 29,
          domain: "OPA",
          title: "Anchors Distribution | Serpinsider",
        },
      },
    ],
  },
  {
    path: "/dashboards",
    name: "dashboards",
    icon: "mdi-view-dashboard",
    title: "Dashboards",
    component: DashboardsHomePage,
    meta: {
      maintainer: "klih",
      middleware: [auth],
      section: 70,
      title: "Dashboards | Serpinsider",
    },
    children: [
      {
        name: "dashboards.summary",
        path: "summary",
        icon: "mdi-view-dashboard",
        title: "Summary",
        component: SummaryDashboardPage,
        meta: {
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 44,
          title: "Dashboards Summary | Serpinsider",
        },
      },
      {
        name: "dashboards.snippet-optimization",
        path: "snippet-optimization",
        icon: "mdi-view-dashboard",
        title: "Snippet optimization",
        component: SnippetOptimizationPage,
        meta: {
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 44,
          title: "Top 5 Page Optimization - 4DX | Serpinsider",
        },
      },
      {
        name: "dashboards.content-planing",
        path: "content-planing",
        icon: "mdi-view-dashboard",
        title: "Content Planning",
        component: ContentPlanningPage,
        meta: {
          moduleId: 171238,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 44,
          title: "Content Planning | Serpinsider",
        },
      },
      {
        name: "dashboards.pages-info",
        path: "pages-info",
        icon: "mdi-view-dashboard",
        title: "Pages info - 4DX",
        component: PagesInfoDashboardPage,
        meta: {
          moduleId: 171238,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 44,
          title: "Pages info - 4DX | Serpinsider",
        },
      },
      {
        name: "dashboards.urls-overview",
        path: "urls-overview",
        icon: "mdi-view-dashboard",
        title: "Urls Overview - 4DX",
        component: UrlsOverviewPage,
        meta: {
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 44,
          title: "Urls Overview - 4DX | Serpinsider",
        },
      },
      {
        name: "dashboards.site-overview",
        path: "site-overview",
        icon: "mdi-view-dashboard",
        title: "Site Overview - 4DX",
        component: SiteOverviewDashboardPage,
        meta: {
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 44,
          title: "Site Overview - 4DX | Serpinsider",
        },
      },
      {
        name: "dashboards.content",
        path: "content",
        icon: "mdi-view-dashboard",
        title: "Content",
        component: ContentDashboardPage,
        meta: {
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 44,
          title: "Dashboards Content | Serpinsider",
        },
      },
      {
        name: "dashboards.technical",
        path: "technical",
        icon: "mdi-view-dashboard",
        title: "Technical",
        component: TechnicalDashboardPage,
        meta: {
          workSectionData: 295679,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 67,
          title: "Dashboards Technical | Serpinsider",
        },
      },
      {
        path: "/monthly-report",
        name: "monthly-report",
        icon: "mdi-link-box",
        title: "Worksection month",
        component: MonthlyReportPage,
        meta: {
          workSectionData: 228161,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 12,
          domain: "GP",
          title: "Monthly Report | Serpinsider",
        },
      },
      {
        path: "/monthly-movements-by-tasks",
        name: "monthly-movements-by-tasks",
        icon: "mdi-link-box",
        title: "Monthly movement by tasks",
        component: MonthlyMovementByTaskPage,
        meta: {
          workSectionData: 228161,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 12,
          domain: "GP",
          title: "Monthly movement by tasks | Serpinsider",
        },
      },
      {
        path: "/monthly-statistic",
        name: "monthly-statistic",
        icon: "mdi-link-box",
        title: "Monthly statistics",
        component: MonthlyMovementByTaskStatisticsPage,
        meta: {
          workSectionData: 228161,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 12,
          domain: "GP",
          title: "Monthly statistics | Serpinsider",
        },
      },
      {
        name: "worksection.week.report",
        path: "report/week",
        icon: "mdi-link-box",
        title: "Worksection week",
        component: WorkSectionWeekReportPage,
        meta: {
          workSectionData: 246407,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 38,
          domain: "Worksection",
          title: "Worksection week | Serpinsider",
        },
      },
      {
        name: "worksection.daily3.report",
        path: "/worksection/report/daily3",
        hidden: true,
        component: WorkSectionThirdDailyReportPage,
        meta: {
          workSectionData: 246407,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 38,
          domain: "Worksection",
          title: "Worksection daily v3 | Serpinsider",
        },
      },
      {
        path: "/dbc-dashboard",
        name: "dbc-dashboard",
        icon: "mdi-link-box",
        title: "DBC",
        component: DbcDashboardPage,
        meta: {
          workSectionData: 247934,
          moduleId: 171238,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 29,
          domain: "Positions",
          title: "DBC | Serpinsider",
        },
      },
      {
        path: "/dashboard-dev",
        name: "dashboard-dev",
        icon: "mdi-view-dashboard",
        title: "Dashboard dev",
        component: DevDashboardPage,
        meta: {
          workSectionData: 246407,
          moduleId: 171238,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 29,
          domain: "Dashboard dev",
          title: "Dashboard dev | Serpinsider",
        },
      },
      {
        name: "ltt.dynamics.domain",
        path: "ltt-dynamics-domain",
        icon: "mdi-link-box",
        title: "Link dynamics domain",
        component: LttDynamicDomainPage,
        meta: {
          workSectionData: 244951,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 41,
          domain: "LTT",
          title: "Link Dynamics Domain - LTT | Serpinsider",
        },
      },
      {
        name: "dashboards.link-planning-by-urls",
        path: "link-planning-by-urls",
        icon: "mdi-link-box",
        title: "Links planning by urls",
        component: LinksPlanningByUrlsPage,
        meta: {
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 44,
          title: "Link Planning by Urls | Serpinsider",
        },
      },
      {
        name: "dashboards.link-planning-by-site",
        path: "link-planning-by-site",
        icon: "mdi-link-box",
        title: "Links planning by site",
        component: LinksPlanningBySitePage,
        meta: {
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 44,
          title: "Link Planning by Urls | Serpinsider",
        },
      },
      {
        name: "dashboards.survival-dashboard",
        path: "survival-dashboard",
        icon: "mdi-progress-clock",
        title: "Survival dashboard",
        component: SurvivalDashboardPage,
        meta: {
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 44,
          title: "Survival Dashboard | Serpinsider",
        },
      },
      {
        path: "/task-priority",
        name: "task-priority",
        icon: "mdi-list-box-outline",
        title: "Task priority",
        component: TaskPriorityPage,
        meta: {
          workSectionData: 228161,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 12,
          domain: "None",
          title: "Task Priority | Serpinsider",
        },
      },
      {
        name: "dashboards.anchors-info-by-pages",
        path: "anchors-info-by-pages",
        hidden: true,
        component: AnchorsInfoByPages,
        meta: {
          moduleId: 171238,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 44,
          title: "Anchor info by Pages | Serpinsider",
        },
      },
      {
        name: "dashboards.brief-low-impressions-pages-analyze",
        path: "brief-low-impressions-pages-analyze",
        icon: "mdi-view-dashboard",
        title: "Brief Low Impressions Pages Analyze",
        component: BriefLowImpressionsPagesAnalyzeIndex,
        meta: {
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 44,
          title: "Dashboards Brief Low Impressions Pages Analyze | Serpinsider",
        },
      },
    ],
  },
  {
    name: "4dx",
    path: "/4dx",
    icon: "mdi-view-dashboard",
    title: "4DX",
    component: PageRouterView,
    meta: {
      maintainer: "klih",
      middleware: [auth],
      section: 70,
      title: "Dashboards | Serpinsider",
    },
    children: [
      {
        path: "/lb-main-dashboard-4dx",
        name: "lb-main-dashboard-4dx",
        icon: "mdi-view-dashboard",
        title: "LB Main",
        component: Dashboard4DXLb,
        meta: {
          workSectionData: 228161,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 12,
          domain: "None",
          title: "4DX LB Main Dashboard | Serpinsider",
        },
      },
      {
        path: "/tribe-4dx",
        name: "tribe-4dx",
        icon: "mdi-view-dashboard",
        title: "Tribe",
        component: Tribe4DX,
        meta: {
          workSectionData: 228161,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 12,
          domain: "None",
          title: "Tribe 4DX | Serpinsider",
        },
      },
      {
        name: "main-dashboard-4dx",
        path: "/main-dashboard-4dx",
        icon: "mdi-view-dashboard",
        title: "Main",
        component: Dashboard4DX,
        meta: {
          workSectionData: 228161,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 68,
          domain: "None",
          title: "4DX Main Dashboard | Serpinsider",
        },
      },
      {
        path: "/website-links-status",
        name: "website-links-status",
        icon: "mdi-view-dashboard",
        title: "Website links status",
        component: WebsiteLinksStatusDashboard,
        meta: {
          workSectionData: 228161,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 12,
          domain: "None",
          title: "Website Links Status Dashboard | Serpinsider",
        },
      },
    ],
  },
  {
    path: "/ndc",
    name: "ndc",
    title: "NDC",
    icon: "mdi-table",
    component: PageRouterView,
    meta: {
      maintainer: "talasymov",
      middleware: [auth],
      section: 57,
      title: "NDC | Serpinsider",
    },
    children: [
      {
        path: "manage",
        name: "ndc.manage",
        title: "Expances manage",
        component: NdcManagePage,
        meta: {
          workSectionData: 244951,
          moduleId: 171238,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 64,
          domain: "Positions",
          title: "NDC Expances manage | Serpinsider",
        },
      },
      {
        path: "money-plan",
        name: "ndc.money-plan",
        title: "Money plan",
        component: MoneyPlanPage,
        meta: {
          workSectionData: 244951,
          moduleId: 171238,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 64,
          domain: "Positions",
          title: "NDC Money Plan | Serpinsider",
        },
      },
      {
        path: "manage/charts",
        name: "ndc.manage.charts",
        title: "Charts",
        component: NdcManageChartsPage,
        meta: {
          workSectionData: 244951,
          moduleId: 171238,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 64,
          domain: "Positions",
          title: "NDC / Money / Expances manage - Charts | Serpinsider",
        },
      },
    ],
  },
  {
    path: "/analytics",
    name: "analytics",
    icon: "mdi-poll",
    title: "Analytics",
    component: SearchConsolePage,
    meta: {
      moduleId: 240170,
      maintainer: "klih",
      middleware: [auth],
      section: 47,
      title: "Analytics | Serpinsider",
    },
    children: [
      {
        name: "analytics.commercial-pages",
        path: "commercial-pages",
        icon: "mdi-book-open-page-variant",
        title: "Commercial pages",
        component: CommercialPagesDashboardPage,
        meta: {
          moduleId: 240170,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 44,
          title: "Commercial Pages Analytics | Serpinsider",
        },
      },
      {
        path: "page-type",
        name: "analytics.by-page-type",
        icon: "mdi-book-open-page-variant",
        title: "By page type",
        component: AnalyticsByPageTypeDashboardPage,
        meta: {
          moduleId: 171238,
          maintainer: "talasymov",
          middleware: [auth],
          section: 47,
          title: "Analytics | Serpinsider",
        },
      },
      {
        name: "analytics.all-site",
        path: "all-site",
        icon: "mdi-book-open-page-variant",
        title: "Samples",
        component: AllSitePage,
        meta: {
          moduleId: 240170,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 45,
          domain: "Analytics",
          title: "All Site Analytics | Serpinsider",
        },
      },
      {
        name: "analytics.conversion.top-5-pages-conversion",
        path: "conversion/top-5-pages-conversion",
        icon: "mdi-email",
        title: "Top 5 pages conversion",
        component: TopFivePagesConversionPage,
        meta: {
          workSectionData: 290831,
          moduleId: 240170,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 45,
          domain: "Analytics",
          title: "Top 5 Pages Conversion | Serpinsider",
        },
      },
      {
        name: "analytics.conversion.report",
        path: "conversion/report",
        icon: "mdi-email",
        title: "Conversion",
        component: AnalyticsConversionReportPage,
        meta: {
          workSectionData: 290831,
          moduleId: 240170,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 45,
          domain: "Analytics",
          title: "Conversion Dashboard | Serpinsider",
        },
      },
      {
        name: "analytics.samples-per-page",
        path: "all-site/samples-per-page/:id?",
        hidden: true,
        component: SamplesPerPagePage,
        meta: {
          moduleId: 240170,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 45,
          domain: "Analytics",
          title: "Samples per page analytics | Serpinsider",
        },
      },
    ],
  },
  {
    name: "search-console.index",
    path: "/search-console/index",
    icon: "mdi-console",
    title: "Search console",
    component: SearchConsoleIndexPage,
    meta: {
      workSectionData: 241753,
      maintainer: "klih",
      middleware: [auth, RoleMiddleware],
      section: 53,
      domain: "Search Console",
      title: "Search Console | Serpinsider",
    },
  },
  {
    path: "/contentball-analytic",
    name: "contentball-analytic",
    icon: "mdi-chart-timeline-variant",
    title: "Contentball analytic",
    component: ContentBallAnalyticsPage,
    meta: {
      workSectionData: 244951,
      moduleId: 171238,
      maintainer: "talasymov",
      middleware: [auth, RoleMiddleware],
      section: 12,
      domain: "Contentball",
      title: "ContentBall Analytics | Serpinsider",
    },
  },
  {
    path: "/contentball",
    name: "contentball",
    icon: "mdi-balloon",
    title: "Contentball",
    component: ContentballIndexPage,
    meta: {
      workSectionData: 244951,
      moduleId: 171238,
      maintainer: "talasymov",
      middleware: [auth, RoleMiddleware],
      section: 12,
      domain: "Contentball",
      title: "ContentBall | Serpinsider",
    },
    children: [
      {
        path: "/contentball/prompts",
        name: "contentball.prompts",
        icon: "mdi-check",
        title: "Prompts",
        component: ContentballPromptsPage,
        meta: {
          workSectionData: 228161,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 12,
          domain: "None",
          title: "Prompts | Serpinsider",
        },
      },
    ],
  },
  {
    path: "/daily-overview",
    name: "daily-overview",
    icon: "mdi-calendar-today",
    title: "Daily overview",
    component: DailyOverallReportPage,
    meta: {
      workSectionData: 244951,
      moduleId: 171238,
      maintainer: "talasymov",
      middleware: [auth, RoleMiddleware],
      section: 12,
      domain: "GP",
      title: "Daily Overview | Serpinsider",
    },
  },
  {
    path: "/flex-donors-finder",
    name: "flex-donors-finder",
    icon: "mdi-magnify-scan",
    title: "Flex donors finder",
    component: FlexDonorsFinderPage,
    meta: {
      workSectionData: 228161,
      maintainer: "talasymov",
      middleware: [auth, RoleMiddleware],
      section: 12,
      domain: "GP",
      title: "Flex Donors Finder | Serpinsider",
    },
  },
  {
    path: "/check-unique",
    name: "check-unique",
    icon: "mdi-content-copy",
    title: "Check unique",
    component: CheckUniquePage,
    meta: {
      workSectionData: 228161,
      maintainer: "talasymov",
      middleware: [auth, RoleMiddleware],
      section: 66,
      domain: "None",
      title: "Check Unique | Serpinsider",
    },
  },
  {
    path: "/donors-finder",
    name: "donors-finder",
    icon: "mdi-magnify-scan",
    title: "Donors finder",
    component: GpDonorsFinderPage,
    meta: {
      workSectionData: 244951,
      moduleId: 171238,
      maintainer: "talasymov",
      middleware: [auth, RoleMiddleware],
      section: 12,
      domain: "Contentball",
      title: "Donors Finder | Serpinsider",
    },
  },
  {
    name: "export-queue",
    path: "/export-queue",
    icon: "mdi-archive-clock",
    title: "Export queue",
    component: ExportQueuePage,
    meta: {
      workSectionData: 276020,
      moduleId: 276020,
      maintainer: "talasymov",
      middleware: [auth, RoleMiddleware],
      section: 29,
      domain: "OPA",
      title: "Export Queue | Serpinsider",
    },
  },
  {
    name: "background-tasks",
    path: "/background-tasks",
    icon: "mdi-archive-clock",
    title: "Background tasks queue",
    component: BackgroundTasksPage,
    meta: {
      workSectionData: 276020,
      moduleId: 276020,
      maintainer: "talasymov",
      middleware: [auth, RoleMiddleware],
      section: 29,
      domain: "OPA",
      title: "Background tasks | Serpinsider",
    },
  },
  {
    path: "/access",
    name: "access",
    icon: "mdi-account-key",
    title: "Access",
    component: DashboardsHomePage,
    meta: {
      maintainer: "klih",
      middleware: [auth],
      section: 70,
      title: "Dashboards | Serpinsider",
    },
    children: [
      {
        path: "/accounts/manage",
        name: "accounts.manage",
        icon: "mdi-account-group-outline",
        title: "Users",
        component: AccountManagePage,
        meta: {
          workSectionData: 228161,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 12,
          domain: "None",
          title: "Users | Serpinsider",
        },
      },
      {
        path: "/roles/manage",
        name: "roles.manage",
        icon: "mdi-account-group-outline",
        title: "Roles",
        component: RolesManagePage,
        meta: {
          workSectionData: 228161,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 12,
          domain: "None",
          title: "Roles | Serpinsider",
        },
      },
      {
        path: "/routes",
        name: "access.routes",
        icon: "mdi-check",
        title: "Routes",
        component: RoutesManagePage,
        meta: {
          workSectionData: 228161,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 12,
          domain: "None",
          title: "Routes | Serpinsider",
        },
      },
    ],
  },
  {
    path: "/page-type-checker",
    name: "page-type-checker",
    icon: "mdi-account-key",
    title: "Page type checker",
    component: PageTypeChecker,
    meta: {
      maintainer: "talasymov",
      middleware: [auth],
      section: 70,
      title: "Page type checker | Serpinsider",
    },
  },
  {
    path: "",
    name: "legacy",
    component: PageRouterView,
    title: "Legacy",
    icon: "mdi-circle-small",
    children: [
      {
        path: "/positions/main",
        name: "positions.dashboard",
        component: PositionsIndexPage,
        title: "Positions dashboard v1",
        meta: {
          workSectionData: 241753,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 30,
          domain: "Positions",
          title: "Positions Dashboard | Serpinsider",
        },
      },
      {
        path: "/positions/v2",
        name: "positions.dashboard.v2",
        component: PositionsV2Page,
        title: "Positions dashboard v2",
        meta: {
          workSectionData: 241753,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 30,
          domain: "Positions",
          title: "Positions Dashboard | Serpinsider",
        },
      },
      {
        name: "site-workplace.admin.fields",
        path: "/site-workplace/admin/fields",
        icon: "mdi-ghost",
        title: "SW Admin fields",
        component: FieldManagerPage,
        meta: {
          workSectionData: 284639,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 8,
          domain: "Site Workplace Admin",
          title: "Site Workplace Admin | Serpinsider",
        },
      },

      {
        name: "analytics.conversion.report1",
        path: "analytics/conversion/report-1",
        icon: "mdi-book-open-page-variant",
        title: "Conversion 1",
        component: ConversionFirstReportPage,
        meta: {
          workSectionData: 290831,
          moduleId: 240170,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 45,
          domain: "Analytics",
          title: "Report 1 Conversion | Serpinsider",
        },
      },
      {
        name: "analytics.conversion2.report",
        path: "analytics/conversion2/report",
        icon: "mdi-email",
        title: "Conversion 2",
        component: AnalyticsConversionSecondReportPage,
        meta: {
          workSectionData: 290831,
          moduleId: 240170,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 45,
          domain: "Analytics",
          title: "Conversion2 | Serpinsider",
        },
      },
      {
        name: "analytics.conversion3.report",
        path: "analytics/conversion3/report",
        icon: "mdi-email",
        title: "Conversion 3",
        component: ConversionThirdPage,
        meta: {
          workSectionData: 290831,
          moduleId: 240170,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 45,
          domain: "Analytics",
          title: "Conversion3 | Serpinsider",
        },
      },
      {
        name: "analytics.conversion4.report",
        path: "analytics/conversion4/report",
        icon: "mdi-email",
        title: "Conversion 4",
        component: ConversionFourthPage,
        meta: {
          workSectionData: 290831,
          moduleId: 240170,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 45,
          domain: "Analytics",
          title: "Conversion4 | Serpinsider",
        },
      },
      {
        name: "analytics.conversion5.report",
        path: "analytics/conversion5/report",
        icon: "mdi-email",
        title: "Conversion 5",
        component: ConversionFifthPage,
        meta: {
          workSectionData: 290831,
          moduleId: 240170,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 45,
          domain: "Analytics",
          title: "Conversion5 | Serpinsider",
        },
      },
      {
        name: "analytics.conversion6.report",
        path: "analytics/conversion6/report",
        icon: "mdi-email",
        title: "Conversion 6",
        component: ConversionSixthPage,
        meta: {
          workSectionData: 290831,
          moduleId: 240170,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 45,
          domain: "Analytics",
          title: "Conversion 6 Dashboard | Serpinsider",
        },
      },
      {
        name: "analytics.conversion7.report",
        path: "analytics/conversion7/report",
        icon: "mdi-email",
        title: "Conversion 7",
        component: ConversionSeventhPage,
        meta: {
          workSectionData: 290831,
          moduleId: 240170,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 45,
          domain: "Analytics",
          title: "Conversion 7 Dashboard | Serpinsider",
        },
      },
      {
        name: "analytics.conversion8.report",
        path: "analytics/conversion8/report",
        icon: "mdi-email",
        title: "Conversion 8",
        component: ConversionEightPage,
        meta: {
          workSectionData: 290831,
          moduleId: 240170,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 45,
          domain: "Analytics",
          title: "Conversion 8 Dashboard | Serpinsider",
        },
      },
    ],
    meta: {
      workSectionData: 241753,
      maintainer: "talasymov",
      middleware: [auth],
      title: "Autoposting | Serpinsider",
    },
  },

  // Pages which didn't exist in navigation menu
  {
    path: "/blog",
    name: "blog",
    hidden: true,
    component: PageRouterView,
    meta: "hordiienko",
    title: "Blog",
    children: [
      {
        path: "/blog/admin",
        name: "blog.admin",
        hidden: true,
        component: BlogAdmin,
        meta: "hordiienko",
        title: "Blog Admin | Serpinsider",
      },
      {
        path: "/blog/admin/article/:id",
        name: "blog.article-details",
        hidden: true,
        component: ArticleDetails,
        meta: "hordiienko",
        title: "Article Details | Serpinsider",
      },
      {
        path: "/blog/admin/create-article",
        name: "blog.new-article",
        hidden: true,
        component: CreateArticle,
        meta: "hordiienko",
        title: "Create new article | Serpinsider",
      },
    ],
  },
  {
    path: "/insert-html",
    name: "insert-html",
    hidden: true,
    component: InsertHtml,
    meta: "hordiienko",
    title: "Insert html",
  },
  {
    path: "/login",
    name: "login",
    hidden: true,
    component: LoginPage,
    meta: {
      maintainer: "talasymov",
      title: "Login | Serpinsider",
    },
  },
  {
    path: "/niche-analyzer",
    name: "niche.analyzer",
    hidden: true,
    component: NicheAnalyzerHomePage,
    meta: {
      maintainer: "talasymov",
      middleware: [auth],
      section: 51,
      title: "Niche Analyzer | Serpinsider",
    },
    children: [
      {
        path: "add",
        name: "niche.analyzer.add-new-niche-analyzer-project",
        component: NicheAnalyzerAddNewProjectPage,
        meta: {
          workSectionData: 228161,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 12,
          domain: "None",
          title: "Add New Project | Serpinsider",
        },
      },
      {
        path: "manage-domain-owner",
        name: "niche.analyzer.manage-domain-owner",
        component: ManageDomainOwner,
        meta: {
          workSectionData: 228161,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 12,
          domain: "None",
          title: "Manage domain owner | Serpinsider",
        },
      },
      {
        name: "niche.analyzer.index",
        path: "index",
        component: NicheAnalyzerIndexPage,
        meta: {
          workSectionData: 242317,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 11,
          domain: "Niche Analyzer",
          title: "Niche Analyzer | Serpinsider",
        },
      },
      {
        name: "niche.analyzer.show",
        path: "show/:id",
        component: NicheAnalyzerShowPage,
        meta: {
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 11,
          domain: "Niche Analyzer",
          title: "Niche Analyzer Show | Serpinsider",
        },
      },
      {
        name: "niche.analyzer.detailed-static",
        path: "detailed-static",
        component: DetailedStaticPage,
        meta: {
          workSectionData: 242317,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 11,
          domain: "Niche Analyzer",
          title: "Detailed Static - Niche Analyzer  | Serpinsider",
        },
      },
      {
        name: "niche.analyzer.project-dashboard",
        path: "project-dashboard",
        component: NicheAnalyzerProjectDashboardPage,
        meta: {
          workSectionData: 242317,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 11,
          domain: "Niche Analyzer",
          title: "Project Dashboard - Niche Analyzer  | Serpinsider",
        },
      },
    ],
  },
  {
    path: "/global-settings",
    name: "global-settings",
    hidden: true,
    component: GlobalSettingsPage,
    meta: {
      workSectionData: 284639,
      maintainer: "talasymov",
      middleware: [auth, RoleMiddleware],
      section: 63,
      domain: "Global settings",
      title: "Settings | Serpinsider",
    },
  },
  {
    path: "/access-denied",
    name: "access-denied",
    hidden: true,
    component: AccessDeniedPage,
    meta: {
      maintainer: "talasymov",
      middleware: [auth],
      title: "Access denied | Serpinsider",
    },
  },
  {
    path: "/user-activity",
    name: "user-activity",
    hidden: true,
    component: UserActivityPage,
    meta: {
      maintainer: "talasymov",
      middleware: [auth],
      title: "User Activity | Serpinsider",
    },
  },
  {
    path: "/user-activity-static",
    name: "user-activity-static",
    hidden: true,
    component: UserActivityStaticPage,
    meta: {
      maintainer: "talasymov",
      middleware: [auth],
      title: "User Activity Static | Serpinsider",
    },
  },
  {
    path: "/status",
    name: "status",
    hidden: true,
    component: StatusHomePage,
    meta: {
      maintainer: "klih",
      middleware: [auth],
      section: 47,
      title: "Status | Serpinsider",
    },
    children: [
      {
        name: "status.spiderlink",
        path: "content",
        component: SpiderLinkStatusPage,
        meta: {
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 44,
          title: "Status SpiderLink | Serpinsider",
        },
      },
    ],
  },
  {
    path: "/aparser",
    name: "aparser.backlinks.home",
    component: PageRouterView,
    hidden: true,
    meta: {
      maintainer: "talasymov",
      middleware: [auth],
      section: 49,
      title: "Aparser | Serpinsider",
    },
    children: [
      {
        name: "aparser.back-links.index",
        path: "back-links/index",
        component: AparserBackLinksPage,
        meta: {
          middleware: [auth, RoleMiddleware],
          section: 37,
          domain: "Aparser",
          title: "BackLinks | Serpinsider",
        },
      },
      {
        name: "aparser.task.index",
        path: "task/index",
        component: AparserTaskPage,
        meta: {
          workSectionData: 171238,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 37,
          domain: "Aparser",
          title: "Aparser Tasks | Serpinsider",
        },
      },
    ],
  },
  {
    path: "/site-analysis",
    name: "site-analysis",
    hidden: true,
    component: SiteAnalysisPage,
    meta: {
      maintainer: "klih",
      middleware: [auth],
      section: 59,
      title: "Site Analysis | Serpinsider",
    },
  },
  {
    path: "/uct",
    name: "uct",
    hidden: true,
    component: UctPage,
    meta: {
      maintainer: "klih",
      middleware: [auth],
      section: 59,
      title: "UCT | Serpinsider",
    },
    children: [
      {
        name: "uct.index",
        path: "index",
        component: UctPage,
        meta: {
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 58,
          domain: "UCT",
          title: "UCT | Serpinsider",
        },
      },
    ],
  },
  {
    path: "/gtt",
    name: "gtt",
    hidden: true,
    component: GttPage,
    meta: {
      maintainer: "talasymov",
      middleware: [auth],
      section: 59,
    },
    children: [
      {
        name: "gtt.index",
        path: "index",
        component: GttIndexPage,
        meta: {
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 58,
          domain: "GTT",
          title: "GpTopicTool | Serpinsider",
        },
      },
      {
        name: "gtt.show",
        path: "show/:id",
        component: GpTopicToolPage,
        meta: {
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 58,
          domain: "GTT",
          title: "GpTopicTool | Serpinsider",
        },
      },
    ],
  },
  {
    path: "/essay-hub",
    name: "essay.hub",
    hidden: true,
    component: EssayHubPage,
    meta: {
      maintainer: "talasymov",
      middleware: [auth],
      section: 52,
      title: "Essay Hub Home | Serpinsider",
    },
    children: [
      {
        name: "essay.hub.index",
        path: "index",
        component: EssayHubIndexPage,
        meta: {
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 35,
          domain: "Essay Hub",
          title: "Essay Hub Home | Serpinsider",
        },
      },
      {
        name: "essay.hub.show",
        path: "show/:id",
        component: EssayHubShowPage,
        meta: {
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 35,
          domain: "Essay Hub",
          title: "Essay Hub Show | Serpinsider",
        },
      },
    ],
  },
  {
    path: "/help",
    name: "help",
    hidden: true,
    component: HelpPage,
    meta: {
      maintainer: "talasymov",
      title: "Help | Serpinsider",
    },
  },
  {
    path: "/links-plan/index",
    name: "links.plan.index",
    hidden: true,
    component: LinksPlanPage,
    meta: {
      workSectionData: 228161,
      maintainer: "talasymov",
      middleware: [auth, RoleMiddleware],
      section: 43,
      domain: "Link Plan",
      title: "Link Plan | Serpinsider",
    },
  },
  {
    path: "/textrazor/index",
    name: "textrazor.index",
    hidden: true,
    component: TextRazorPage,
    meta: {
      maintainer: "talasymov",
      middleware: [auth, RoleMiddleware],
      section: 42,
      domain: "TextRazor",
      title: "TextRazor | Serpinsider",
    },
  },
  {
    name: "dashboard-dev.detailed",
    path: "/dashboard-dev/detailed",
    hidden: true,
    component: DevDashboardDetailedPage,
    redirect: { name: "dashboard-dev.detailed-v2" },
    meta: {
      maintainer: "klih",
      middleware: [auth, RoleMiddleware],
      section: 44,
      title: "Dashboard Dev Detailed | Serpinsider",
    },
  },
  {
    name: "dashboard-dev.detailed-outdated",
    path: "/dashboard-dev/detailed-outdated",
    hidden: true,
    component: DevDashboardDetailedPage,
    meta: {
      maintainer: "klih",
      middleware: [auth, RoleMiddleware],
      section: 44,
      title: "Dashboard Dev Detailed outdated | Serpinsider",
    },
  },
  {
    name: "dashboard-dev.detailed-v2",
    path: "/dashboard-dev/detailed-v2",
    hidden: true,
    component: DevDashboardDetailedV2Page,
    meta: {
      maintainer: "klih",
      middleware: [auth, RoleMiddleware],
      section: 44,
      title: "Dashboard Dev Detailed v2 | Serpinsider",
    },
  },
  {
    path: "/semantic-tool",
    name: "semantic.tool",
    hidden: true,
    component: PageRouterView,
    meta: {
      maintainer: "talasymov",
      middleware: [auth],
      section: 51,
      title: "Semantic Tool | Serpinsider",
    },
    children: [
      {
        name: "semantic.tool.index",
        path: "index",
        component: SemanticToolPage,
        meta: {
          workSectionData: 290183,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 11,
          domain: "Semantic Tool",
          title: "Semantic Tool | Serpinsider",
        },
      },
      {
        name: "semantic.tool.keyword",
        path: "keyword/:id",
        component: SemanticToolKeywordsPage,
        meta: {
          workSectionData: 290183,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 11,
          domain: "Semantic Tool",
          title: "Semantic Tool | Serpinsider",
        },
      },
      {
        name: "semantic.tool.keyword-detailed",
        path: "keyword-detailed/:id",
        component: SemanticToolKeywordsDetailedPage,
        meta: {
          workSectionData: 290183,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 11,
          domain: "Semantic Tool",
          title: "Semantic Tool | Serpinsider",
        },
      },
      {
        name: "semantic.tool.dashboard",
        path: "dashboard/:id",
        component: SemanticToolDashboardPage,
        meta: {
          workSectionData: 290183,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 11,
          domain: "Semantic Tool",
          title: "Semantic Tool Dashboard | Serpinsider",
        },
      },
    ],
  },
  {
    path: "/search-console",
    name: "search-console",
    hidden: true,
    component: PageRouterView,
    meta: {
      maintainer: "klih",
      middleware: [auth],
      section: 31,
      title: "Search Console | Serpinsider",
    },
    children: [
      {
        name: "search-console.url",
        path: "url/:id",
        component: SearchConsoleUrlPage,
        meta: {
          workSectionData: 241753,
          maintainer: "klih",
          middleware: [auth, RoleMiddleware],
          section: 31,
          domain: "Search Console",
          title: "Search Console Url | Serpinsider",
        },
      },
    ],
  },
  {
    path: "/mailer",
    name: "mailer",
    hidden: true,
    component: MailerPage,
    meta: {
      maintainer: "talasymov",
      middleware: [auth],
      section: 57,
      title: "Mailer | Serpinsider",
    },
    children: [
      {
        name: "mailer.index",
        path: "index",
        component: MailerIndexPage,
        meta: {
          workSectionData: 246363,
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 56,
          domain: "Mailer",
          title: "Mailer | Serpinsider",
        },
      },
      {
        name: "mailer.show",
        path: "show/:id",
        component: MailerShowPage,
        meta: {
          maintainer: "talasymov",
          middleware: [auth, RoleMiddleware],
          section: 56,
          domain: "Mailer",
          title: "Mailer Show | Serpinsider",
        },
      },
    ],
  },
  {
    name: "new-links-dynamic-data",
    path: "new-links-dynamic-data",
    hidden: true,
    component: NewLinksDynamicDataPage,
    meta: {
      workSectionData: 276020,
      moduleId: 276020,
      maintainer: "talasymov",
      middleware: [auth, RoleMiddleware],
      section: 29,
      domain: "OPA",
      title: "New Links Dynamic Dashboard | Serpinsider",
    },
  },
  {
    path: "/new-positions-dashboard",
    name: "new-positions-dashboard",
    hidden: true,
    component: PositionsDashboardPage,
    meta: {
      workSectionData: 241753,
      moduleId: 171238,
      maintainer: "talasymov",
      middleware: [auth, RoleMiddleware],
      section: 29,
      domain: "Positions",
      title: "Positions | Serpinsider",
    },
  },
  {
    path: "/donors-finder/create",
    name: "donors-finder.create",
    hidden: true,
    component: GpDonorsFinderCreatePage,
    meta: {
      workSectionData: 228161,
      maintainer: "talasymov",
      middleware: [auth, RoleMiddleware],
      section: 12,
      domain: "GP",
      title: "Donors Finder Create | Serpinsider",
    },
  },
  {
    path: "/flex-donors-finder/acceptor-domains",
    name: "flex-donors-finder.acceptor-domains",
    hidden: true,
    component: FlexDonorsFinderAcceptorDomainsPage,
    meta: {
      workSectionData: 246407,
      moduleId: 171238,
      maintainer: "talasymov",
      middleware: [auth, RoleMiddleware],
      section: 29,
      domain: "Flex Donor Finder",
      title: "Acceptor Domains | Serpinsider",
    },
  },
  {
    path: "/tdh1-dashboard",
    name: "tdh1-dashboard",
    hidden: true,
    component: TdhOnePage,
    meta: {
      workSectionData: 228161,
      maintainer: "talasymov",
      middleware: [auth, RoleMiddleware],
      section: 12,
      domain: "GP",
      title: "TDH1 Dashboard | Serpinsider",
    },
  },
  {
    path: "/similar-task-creating/task-in-subtask",
    name: "similar-task-creating.task-in-subtask",
    hidden: true,
    component: SimilarTaskCreatingTaskInSubtaskPage,
    meta: {
      workSectionData: 228161,
      maintainer: "klih",
      middleware: [auth, RoleMiddleware],
      section: 12,
      domain: "None",
      title: "Task in Subtask | Serpinsider",
    },
  },
  {
    path: "/similar-task-creating/choose-type",
    name: "similar-task-creating.choose-type",
    hidden: true,
    component: SimilarTaskCreatingChooseTypePage,
    meta: {
      workSectionData: 228161,
      maintainer: "klih",
      middleware: [auth, RoleMiddleware],
      section: 12,
      domain: "None",
      title: "Choose type | Serpinsider",
    },
  },
  {
    path: "/similar-task-creating/to-those-responsible",
    name: "similar-task-creating.to-those-responsible",
    hidden: true,
    component: SimilarTaskCreatingToThoseResponsiblePage,
    meta: {
      workSectionData: 228161,
      maintainer: "klih",
      middleware: [auth, RoleMiddleware],
      section: 12,
      domain: "None",
      title: "To Those Responsible | Serpinsider",
    },
  },
  {
    path: "/similar-task-creating/by-file",
    name: "similar-task-creating.by-file",
    hidden: true,
    component: SimilarTaskCreatingByFilePage,
    meta: {
      workSectionData: 228161,
      maintainer: "klih",
      middleware: [auth, RoleMiddleware],
      section: 12,
      domain: "None",
      title: "By File | Serpinsider",
    },
  },
  {
    path: "/similar-task-creating/series",
    name: "similar-task-creating.series",
    hidden: true,
    component: SimilarTaskCreatingSeriesPage,
    meta: {
      workSectionData: 228161,
      maintainer: "klih",
      middleware: [auth, RoleMiddleware],
      section: 12,
      domain: "None",
      title: "Task Series | Serpinsider",
    },
  },
  {
    path: "/pages",
    name: "pages",
    hidden: true,
    component: Pages,
    meta: {
      workSectionData: 228161,
      maintainer: "talasymov",
      middleware: [auth, RoleMiddleware],
      section: 12,
      domain: "None",
      title: "Pages | Serpinsider",
    },
  },
  {
    path: "/honor-board",
    name: "honor-board",
    hidden: true,
    component: HonorBoard,
    meta: {
      workSectionData: 228161,
      maintainer: "talasymov",
      middleware: [auth, RoleMiddleware],
      section: 12,
      domain: "None",
      title: "Honor Board | Serpinsider",
    },
  },
  {
    path: "/oauth/login",
    name: "google-oauth-login",
    hidden: true,
    component: OauthPage,
    meta: {
      workSectionData: 228161,
      maintainer: "talasymov",
      section: 12,
      domain: "None",
      title: "Honor Board | Serpinsider",
    },
  },
  // Redirects
  {
    path: "/ndc-manage",
    hidden: true,
    redirect: { name: "ndc.manage" },
  },
  {
    path: "/daily-overall-report",
    redirect: { name: "daily-overview" },
    hidden: true,
  },
  {
    path: "*",
    hidden: true,
    component: PageNotFoundPage,
  },
];
