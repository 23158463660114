<template>
  <v-card flat outlined rounded="lg">
    <v-progress-linear v-show="loading" indeterminate />
    <v-card-title class="pb-3 text-body-2 font-weight-bold px-3">
      {{ trend.title }}
    </v-card-title>
    <v-card-text class="px-3 pb-3">
      <template v-if="trend.type === 'percent'">
        <div class="d-flex" style="gap: 1rem">
          <div class="d-flex justify-center flex-column" style="gap: 0.25rem">
            <template v-if="trend.diff?.value">
              <template v-if="trend.diff.value > 0">
                <span class="success--text">
                  <span>{{ trend.diff.value }}{{ trend.diff.append }}</span>
                  <v-icon size="14" class="success--text">mdi-arrow-up</v-icon>
                </span>
              </template>
              <template v-else-if="trend.diff.value < 0">
                <span class="error--text">
                  <span>
                    {{ String(trend.diff.value).replace("-", "")
                    }}{{ trend.diff.append }}
                  </span>
                  <v-icon size="14" class="error--text">
                    mdi-arrow-down
                  </v-icon>
                </span>
              </template>
              <template v-else>
                <small>
                  <span>{{ trend.diff.value }}{{ trend.diff.append }}</span>
                </small>
              </template>
            </template>
            <strong class="text-h4 font-weight-bold">
              {{ trend.content.value }}{{ trend.content.append }}
            </strong>
          </div>
          <div>
            <v-progress-circular
              :value="trend.content?.value"
              size="64"
              width="8"
            />
          </div>
        </div>
      </template>
      <template v-else-if="trend.type === 'top-diff-list'">
        <div class="positions-v3__trends-list">
          <div v-for="(listItem, idx) in trend?.list" :key="`list-item-${idx}`">
            <div class="d-flex justify-space-between" style="gap: 2rem">
              <div>
                <v-badge
                  v-if="trend?.config?.colored"
                  inline
                  dot
                  :color="ctx?.colors?.[idx]"
                />
                {{ listItem.title }}
              </div>
              <div
                class="d-flex flex-start"
                style="min-width: 84px; overflow-x: hidden"
              >
                <span class="font-weight-bold">{{ listItem.value }}</span>
                <small
                  v-if="listItem.diff"
                  class="d-inline-flex ml-1"
                  style="line-height: 100%"
                  :class="{
                    'success--text': listItem.diff > 0,
                    'error--text': listItem.diff < 0,
                  }"
                >
                  {{ String(listItem.diff).replaceAll("-", "") }}
                  <v-icon
                    size="12"
                    :color="listItem.diff > 0 ? 'success' : 'error'"
                    >{{
                      listItem.diff > 0 ? "mdi-arrow-up" : "mdi-arrow-down"
                    }}</v-icon
                  >
                </small>
              </div>
            </div>
          </div>
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    ctx: Object,
    trend: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getOptions() {
      return {
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
            bottom: 0,
            top: 8,
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        xaxis: {
          type: "numeric",
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },

          labels: {
            show: false,
          },
        },
        yaxis: {
          show: true,
        },
        // yaxis: [
        //   {
        //     y: 0,
        //     offsetX: 0,
        //     offsetY: 0,
        //     padding: {
        //       left: 0,
        //       right: 0,
        //     },
        //   },
        // ],
        tooltip: {
          x: {
            show: false,
            format: "dd/MM/yy HH:mm",
          },
        },
      };
    },
  },
};
</script>
