<template>
  <v-btn-toggle class="grouped-buttons" v-model="modelValue" mandatory>
    <v-btn
      v-for="(variant, idx) in variants"
      :key="idx"
      :value="variant.key"
      class="text-normal"
      height="32"
    >
      {{ variant.title }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  props: ["variants", "value"],

  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>
