<template>
  <apex-charts
    :series="computedSeries"
    :options="computedSettings"
    height="200"
  />
</template>

<script>
import ApexCharts from "vue-apexcharts";

export default {
  props: ["data"],

  components: {
    ApexCharts,
  },

  computed: {
    computedSeries() {
      return this.data?.series;
    },
    computedSettings() {
      return {
        chart: {
          type: "line",
        },
        animations: {
          enabled: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 1,
        },
        title: {
          enabled: false,
        },
        grid: {
          padding: {
            bottom: 10,
            top: 10,
          },
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        xaxis: {
          categories: this.data?.categories || [],
          tooltip: {
            enabled: false,
          },
          labels: {
            show: false,
          },
          line: {
            show: false,
            height: 0,
            color: "transparent",
          },
          axisTicks: {
            show: false,
          },
        },
      };
    },
  },
};
</script>
