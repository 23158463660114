<template>
  <v-dialog
    :retain-focus="false"
    v-model="inputVal"
    max-width="600"
    content-class="my-shadow--e2"
  >
    <template>
      <div v-if="alert.shown">
        <v-alert
          v-for="item in alert.data"
          :key="item.status"
          type="info"
          prominent
          border="left"
          dismissible
        >
          {{ item.status }}: {{ item.domains }}
        </v-alert>
      </div>
    </template>
    <v-card>
      <v-card-title class="text-body-2 font-weight-bold">
        {{ this.name }}
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-3">
        <v-textarea
          v-model="domains"
          outlined
          label="Domains"
          hide-details
          :placeholder="'domain.com [<tab>https://www.domain.com/hosting <tab> notes]\ndomain.com [<tab>https://www.domain.com/hosting <tab> notes]\ndomain.com [<tab>https://www.domain.com/hosting <tab> notes]\n'"
        >
        </v-textarea>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          large
          class="text-normal px-6"
          @click="add_donors(false)"
        >
          Upload domains
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "Chat",
  props: ["value", "link_type", "task", "project", "name", "performer"],
  data() {
    return {
      alert: {
        data: [],
        shown: false,
      },
      domains: [],
      /*  selected_domains: [],
      table: {
        search: '',
        headers: [
          {text: 'id', title: 'id', value: 'id', class: 'text-center'},
          {text: 'domain', title: 'domain', value: 'domain', class: 'text-center'},
          {
            text: 'Last posting',
            title: 'last_posting_in_days',
            value: 'last_posting_in_days',
            class: 'text-center'
          },
          {text: 'DR', title: 'domain_rating', value: 'domain_rating', class: 'text-center'},
          {text: 'Ahrefs traffic', title: 'traffic', value: 'traffic', class: 'text-center'},
          {
            text: 'Regional Traff Ahrefs',
            title: 'country_traffic',
            value: 'country_traffic',
            class: 'text-center',
            icon: true
          },
          {text: 'SW Rank', title: 'global_rank', value: 'global_rank', class: 'text-center'},
          {text: 'Google Index', title: 'google_index', value: 'google_index', class: 'text-center'},
          {text: 'Date Add', title: 'created_at', value: 'created_at', class: 'text-center'},
          {text: 'Spam score', title: 'spam_score', value: 'spam_score', class: 'text-center'},
        ],
        items: []
      }*/
    };
  },
  created() {
    //let self = this
    //self.fetch_donors()
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    userId() {
      return this.$store.state.auth.user.id;
    },
  },
  methods: {
    get_date(last_posting_in_days) {
      let days = last_posting_in_days;
      let months = Math.round(days / 30);
      let years = Math.round(days / 365);

      let time_ago = `${days} d`;
      let color = "green";

      if (years > 0) {
        time_ago = `${years} y`;
        color = "red";
      } else if (months > 0) {
        time_ago = `${months} m`;
        color = "#ffcc00";
      }
      return '<span style="color: ' + color + '">' + time_ago + "</span>";
    },
    /* add_to_selected(item) {
      if (item.selected)
        this.selected_domains.push(item.id)
      else this.selected_domains = this.selected_domains.filter((v) => v !== item.id)
    },*/
    add_donors(fromBase) {
      let self = this;

      this.$store.state.loading = true;

      let url = `${self.$store.state.server_url}/anchor-plan/upload-donors`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {
        items: {
          fromBase,
          domains: this.domains,
          //   selected_domains: this.selected_domains,
          link_type: this.link_type,
          task: this.task,
          project: this.project,
          name: this.name,
          performer: this.performer,
        },
      };

      axios
        .post(url, data, config)
        .then(function (response) {
          self.alert.shown = true;
          self.alert.data = response.data;
          self.$store.state.loading = false;
          self.domains = [];
          self.selected_domains = [];
        })
        .catch(function (error) {
          self.domains = [];
          alert(error);
        });
    },
    /* fetch_donors() {
      let self = this;
      if (self.inputVal !== null && self.inputVal !== undefined) {
        self.$store.state.loading = true
        let url = `${self.$store.state.server_url}/anchor-plan/tasks/list/donors-base`;

        let config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.$store.state.auth.token
          }
        };

        let data = {
          link_type: self.link_type,
          task: self.task,
          project: self.project,
        }

        axios.post(url, data, config)
            .then(function (response) {
              self.table.items = response.data
              self.$store.state.loading = false
            }).catch(function (error) {
          alert(error);
        })
      }
    },*/
  },
};
</script>

<style scoped></style>
