<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <page-header
          title="Autoposting"
          :breadcrumbs="[
            { text: 'Home', to: '/' },
            { text: 'Autoposting', disabled: true },
            { text: 'Dashboard', disabled: true },
          ]"
        />
      </v-col>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="6">
            <v-card flat outlined rounded="lg">
              <v-card-title class="text-body-2 font-weight-bold">
                Amount of donors
                <v-spacer />
                <autoposting-data-toggler
                  v-model="postfix.number_donors_added"
                  :variants="[
                    { title: '7 days', key: '7_days' },
                    { title: '24 hours', key: '24_hours' },
                  ]"
                />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <autoposting-chart
                  :data="
                    dashboard?.[
                      `number_donors_added_${this.postfix.number_donors_added}`
                    ]
                  "
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card flat outlined rounded="lg">
              <v-card-title class="text-body-2 font-weight-bold">
                Number access checked
                <v-spacer />
                <autoposting-data-toggler
                  v-model="postfix.number_running_access_checks"
                  :variants="[
                    { title: '7 days', key: '7_days' },
                    { title: '24 hours', key: '24_hours' },
                  ]"
                />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <autoposting-chart
                  :data="
                    dashboard?.[
                      `number_running_access_checks_${this.postfix.number_running_access_checks}`
                    ]
                  "
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="4">
            <v-card flat outlined rounded="lg">
              <v-card-title class="text-body-2 font-weight-bold">
                Successful authorizations
                <v-spacer />
                <autoposting-data-toggler
                  v-model="postfix.number_success_authorized"
                  :variants="[
                    { title: '7 days', key: '7_days' },
                    { title: '24 hours', key: '24_hours' },
                  ]"
                />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <autoposting-chart
                  :data="
                    dashboard?.[
                      `number_success_authorized_${this.postfix.number_success_authorized}`
                    ]
                  "
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card flat outlined rounded="lg">
              <v-card-title class="text-body-2 font-weight-bold">
                Posts
                <v-spacer />
                <autoposting-data-toggler
                  v-model="postfix.posts"
                  :variants="[
                    { title: '7 days', key: '7_days' },
                    { title: '24 hours', key: '24_hours' },
                  ]"
                />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <autoposting-chart
                  :data="dashboard?.[`${this.postfix.posts}`]"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card flat outlined rounded="lg">
              <v-card-title class="text-body-2 font-weight-bold">
                Not created texts
                <v-spacer />
                <autoposting-data-toggler
                  v-model="postfix.text_not_created_7_days"
                  :variants="[
                    { title: '7 days', key: '7_days' },
                    { title: '24 hours', key: '24_hours' },
                  ]"
                />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <autoposting-chart
                  :data="
                    dashboard?.[
                      `text_not_created_${this.postfix.text_not_created_7_days}`
                    ]
                  "
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row dense> </v-row>
      </v-col>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="text-body-2 font-weight-bold">
            Queue URL's
          </v-card-title>
          <v-card-title>
            <v-text-field
              v-model="search"
              hide-details
              dense
              outlined
              placeholder="Type to search"
              append-icon="mdi-magnify"
            />
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0">
            <v-data-table
              :search="search"
              :headers="queueUrlsHeaders"
              :items="dashboard?.queue_urls || []"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/UI/PageHeader.vue";
import { AutopostingAlpApiService } from "@/views/autoposting/services/AutopostingAlpApiService";
import AutopostingChart from "@/views/autoposting/components/AutopostingChart.vue";
import AutopostingDataToggler from "@/views/autoposting/components/AutopostingDataToggler.vue";
import { queueUrlsHeaders } from "@/views/autoposting/defaults";

export default {
  components: { AutopostingDataToggler, AutopostingChart, PageHeader },

  data: () => ({
    queueUrlsHeaders,
    search: null,
    dashboard: {
      number_donors_added_7_days: null,
      number_donors_added_24_hours: null,
      number_running_access_checks_7_days: null,
      number_running_access_checks_24_hours: null,
      number_success_authorized_7_days: null,
      number_success_authorized_24_hours: null,
      "7_days": null,
      "24_hours": null,
      text_not_created_7_days: null,
      text_not_created_24_hours: null,
      queue_urls: null,
    },
    loadings: {
      dashboard: false,
    },
    postfix: {
      number_donors_added: "7_days",
      number_running_access_checks: "7_days",
      number_success_authorized: "7_days",
      posts: "7_days",
      text_not_created: "7_days",
    },
  }),

  mounted() {
    this.initializeDashboard();
  },

  methods: {
    async initializeDashboard() {
      try {
        this.loadings.dashboard = true;

        const { data } = await AutopostingAlpApiService().getDashboard();

        this.dashboard = data;
      } catch (e) {
        console.error("Error while initializing dashboard.", e);
      } finally {
        this.loadings.dashboard = false;
      }
    },
  },
};
</script>
