<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <page-header
          title="Tasks list"
          :breadcrumbs="[
            { text: 'Home', to: '/' },
            { text: 'Autoposting', disabled: true },
            { text: 'Tasks list', disabled: true },
          ]"
        />
      </v-col>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.status"
              label="Status"
              :items="filtersData?.alp_statuses"
              dense
              hide-details
              outlined
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <smart-date-filter
              :model-value="filters.date"
              @update:modelValue="filters.date = $event"
              label="Date of creating"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.post_type"
              label="Post type"
              dense
              hide-details
              :items="filtersData?.post_types"
              outlined
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-btn
              class="text-normal"
              @click="initializeDashboard"
              :loading="loadings.dashboard"
              height="40"
              color="primary"
              >Get data</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="pb-0">
        <v-row>
          <v-col>
            <v-card flat outlined rounded="lg" :loading="loadings.dashboard">
              <v-card-title class="text-body-2 font-weight-bold">
                Open AI
              </v-card-title>
              <v-card-text class="d-flex flex-column" style="gap: 0.5rem">
                <div>
                  Estimated cost – <b>{{ dashboard.openai.estimated_cost }}</b>
                </div>
                <div>
                  Total tokens – <b>{{ dashboard.openai.total_tokens }}</b>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col></v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title style="gap: 0.25rem">
            <v-text-field
              v-model="search"
              hide-details
              dense
              outlined
              placeholder="Type to search"
              append-icon="mdi-magnify"
            />
            <v-btn class="text-normal" height="40" @click="handleCreate">
              Create task <v-icon small right>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0">
            <v-data-table
              :items="dashboard?.items"
              :headers="[...(dashboard?.headers || []), { value: '_actions' }]"
              :search="search"
            >
              <template #[`item._actions`]="{ item }">
                <div class="d-flex" style="gap: 0.25rem">
                  <v-btn
                    class="text-normal"
                    @click="
                      $router.push({
                        name: 'autoposting.view-task',
                        query: { task_id: item.id },
                      })
                    "
                  >
                    Show
                  </v-btn>
                  <v-tooltip open-delay="150" bottom>
                    <template #activator="{ on }">
                      <v-btn
                        v-on="on"
                        class="text-normal px-2"
                        style="min-width: 36px"
                        @click="handleEdit(item)"
                        :loading="loadings.edit === item.id"
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <div>Edit task</div>
                  </v-tooltip>
                  <v-tooltip open-delay="150" bottom>
                    <template #activator="{ on }">
                      <v-btn
                        v-on="on"
                        class="text-normal px-2 flat-chip flat-chip--error"
                        style="min-width: 36px"
                        :loading="loadings.delete === item.id"
                        @click="handleDelete(item)"
                      >
                        <v-icon small>mdi-trash-can</v-icon>
                      </v-btn>
                    </template>
                    <div>Delete task</div>
                  </v-tooltip>
                </div>
              </template>
              <template #[`item.chart`]="{ value }">
                <horizontal-bar-stacked-mini-chart :series="value" />
              </template>
              <template #[`item.created_at`]="{ value }">
                {{ value ? $moment(value).format("ll") : "" }}
              </template>
              <template #[`item.updated_at`]="{ value }">
                {{ value ? $moment(value).format("ll") : "" }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <create-task-dialog
      v-model="isCreateDialogVisible"
      :filters-data="filtersData"
    />
    <edit-task-dialog
      v-model="isEditDialogVisible"
      :data="editData"
      :filters-data="filtersData"
    />
    <v-dialog
      v-model="aamIsAwaiting['delete']"
      content-class="my-shadow--e2"
      width="320"
    >
      <v-card flat outlined rounded="lg">
        <v-card-title class="text-body-2 font-weight-bold">
          Task deleting
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          Are you sure you want to delete this task?
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="aamResolveAwaiting('reject', 'delete')"
            class="text-normal px-6"
            large
            >Back</v-btn
          >
          <v-btn
            @click="aamResolveAwaiting('resolve', 'delete')"
            class="text-normal px-6"
            large
            color="error"
            >Delete <v-icon small right>mdi-trash-can</v-icon></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/UI/PageHeader.vue";
import {
  AutopostingAlpApiService,
  mockTasksListResponse,
} from "@/views/autoposting/services/AutopostingAlpApiService";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import CreateTaskDialog from "./components/CreateTaskDialog.vue";
import EditTaskDialog from "@/views/autoposting/tasks/components/EditTaskDialog.vue";
import AwaitResolveMixin from "@/mixins/utils/AwaitResolveMixin";
import UploadFiltersDataMixin from "@/mixins/UploadFiltersDataMixin";
import HorizontalBarStackedMiniChart from "@/views/autoposting/tasks/components/HorizontalBarStackedMiniChart.vue";
import Notification from "@/mixins/Notification";

export default {
  components: {
    HorizontalBarStackedMiniChart,
    EditTaskDialog,
    CreateTaskDialog,
    SmartDateFilter,
    PageHeader,
  },

  mixins: [AwaitResolveMixin, UploadFiltersDataMixin, Notification],

  data: () => ({
    search: null,
    dashboard: {
      items: [],
      headers: [],
      openai: {
        estimated_cost: "...",
        total_tokens: "...",
      },
    },
    isCreateDialogVisible: false,
    isEditDialogVisible: false,
    editData: null,
    filters: {
      status: null,
      date: [null, null],
      post_type: null,
    },
    loadings: {
      dashboard: false,
      edit: null,
      delete: null,
    },
  }),

  mounted() {
    this.initializeDashboard();
    this.uploadFiltersData([
      "sources",
      "alp_statuses",
      "post_types",
      "projects",
    ]);
  },

  methods: {
    handleCreate() {
      this.isCreateDialogVisible = true;
    },
    async handleEdit(tableItem) {
      try {
        this.loadings.edit = tableItem.id;
        const { data } = await AutopostingAlpApiService().getTasksDetails(
          tableItem.id
        );
        this.editData = { ...data, _timestamp: new Date().getTime() };
        this.isEditDialogVisible = true;
      } catch (e) {
        console.error("Error while loading edit data for task editing.", e);
      } finally {
        this.loadings.edit = null;
      }
    },
    async handleDelete(tableItem) {
      try {
        this.loadings.delete = tableItem.id;

        await this.aamWait("delete");

        await AutopostingAlpApiService().deleteTask(tableItem.id);

        this.initializeDashboard();

        this.mSuccess({
          title: "Deleted",
          text: "Task successfully deleted.",
        });
      } catch (e) {
        console.error("Error while deleting task.", e);
      } finally {
        this.loadings.delete = null;
      }
    },
    async initializeDashboard() {
      try {
        this.loadings.dashboard = true;

        const { data } = await AutopostingAlpApiService().getTasksList(
          this.filters
        );

        this.dashboard = data;
        this.dashboard.headers = mockTasksListResponse.headers;
      } catch (e) {
        console.error("Error while initializing dashboard.", e);
      } finally {
        this.loadings.dashboard = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
