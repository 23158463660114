<template>
  <v-container fluid style="padding-bottom: 200px">
    <v-row>
      <v-col>
        <h2>Keywords control</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.project_category"
              :items="filtersData.project_categories"
              :loading="filtersDataPendingKeys?.project_categories"
              multiple
              hide-details="auto"
              dense
              outlined
              clearable
              label="Project categories"
              placeholder="Type to search"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.project_id"
              hide-details="auto"
              dense
              outlined
              clearable
              placeholder="Type to search"
              label="Project"
              :items="filtersData?.projects"
              :loading="filtersDataPendingKeys?.projects"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill"></v-col>
          <v-col cols="6" md="4" lg="2" xl="fill"></v-col>
          <v-col cols="6" md="4" lg="2" xl="fill"></v-col>
          <v-col cols="6" md="4" lg="2" xl="fill" class="d-flex justify-end">
            <v-btn
              height="40"
              color="primary"
              @click="load_data"
              :loading="loadings.tableData"
              class="text-normal"
              >Get data <v-icon small right>mdi-refresh</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="
          urlsWithFailedAudit.length > 0 || urls_with_bad_performance.length > 0
        "
        cols="12"
      >
        <v-alert
          border="left"
          type="error"
          v-if="urls_with_bad_performance.length > 0"
        >
          You have {{ urls_with_bad_performance.length }} urls with performance
          less than 50%.
        </v-alert>
        <v-alert
          type="warning"
          text
          icon="mdi-alert"
          class="mb-0"
          border="left"
          v-if="urlsWithFailedAudit.length > 0"
        >
          <v-row dense>
            <v-col cols="12">
              You have {{ urlsWithFailedAudit.length }} failed auditing urls.
            </v-col>
            <v-col cols="12">
              <v-chip
                v-if="!filter.failed_auditing"
                color="warning"
                @click="filter.failed_auditing = true"
                >Show failed urls
                <v-icon small right>mdi-magnify</v-icon>
              </v-chip>
              <v-chip
                v-else
                color="warning"
                @click="filter.failed_auditing = false"
                >Hide failed urls
                <v-icon small right>mdi-magnify-remove-outline</v-icon>
              </v-chip>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
      <v-col cols="12">
        <v-row class="pb-2" dense>
          <v-col>
            <v-menu
              :close-on-content-click="false"
              bottom
              offset-y
              content-class="shadow-e1-bordered"
            >
              <template #activator="{ on }">
                <v-chip v-on="on" outlined label class="px-2">
                  Table actions
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-chip>
              </template>
              <v-list dense>
                <v-list-item link @click="recollectVolume">
                  <v-list-item-icon>
                    <v-icon v-if="!loadings.recollectVolume"
                      >mdi-refresh</v-icon
                    >
                    <v-progress-circular
                      v-else
                      indeterminate
                      size="18"
                      width="2"
                    />
                  </v-list-item-icon>
                  <v-list-item-title>Recollect volume</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="deleteSelected">
                  <v-list-item-icon>
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Delete selected</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="deleteAll">
                  <v-list-item-icon>
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Delete all</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-chip
              v-if="selected.length > 0"
              outlined
              label
              color="transparent"
              class="ml-2 success--text"
            >
              Selected: <b class="ml-1">{{ selected.length }}</b>
            </v-chip>
          </v-col>
          <v-col class="d-flex justify-end">
            <template>
              <div
                v-if="loadings.tableData"
                class="skeleton mr-2"
                style="width: 88px; height: 32px"
              />
              <v-tooltip v-else bottom>
                <template #activator="{ on }">
                  <v-chip
                    v-on="on"
                    label
                    outlined
                    style="border-style: dashed"
                    class="mr-2"
                    @click="statistic_dialog = true"
                  >
                    {{ count_all }}
                    <span class="mx-1" style="opacity: 0.5">/</span>
                    {{ count_entry }}
                    <v-icon style="opacity: 0.5" class="ml-2" small
                      >mdi-information-outline</v-icon
                    >
                  </v-chip>
                </template>
                <div>All / Entry</div>
              </v-tooltip>
            </template>
            <v-menu bottom offset-y content-class="my-shadow--e2">
              <template #activator="{ on }">
                <v-chip outlined label v-on="on">
                  Exports <v-icon small right>mdi-chevron-down</v-icon>
                </v-chip>
              </template>
              <v-card class="styled-card--default">
                <v-list dense>
                  <v-list-item
                    v-show="filteredItems.length !== 0"
                    @click="copyTable(headers, filteredItems)"
                  >
                    <v-list-item-icon>
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content> Copy table </v-list-item-content>
                  </v-list-item>
                  <vue-json-to-csv
                    name="Keywords_control"
                    :json-data="filteredItems"
                    csv-title="Keywords_control"
                    :separator="'\t'"
                  >
                    <v-list-item @click="() => {}">
                      <v-list-item-icon>
                        <v-icon small>mdi-export</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content> export CSV </v-list-item-content>
                    </v-list-item>
                  </vue-json-to-csv>
                  <json-excel
                    :data="filteredItems"
                    filename="keywords_control.xlsx"
                    style="display: inline-block"
                  >
                    <v-list-item @click="() => {}">
                      <v-list-item-icon>
                        <v-icon small>mdi-export</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content> export EXCEL </v-list-item-content>
                    </v-list-item>
                  </json-excel>
                </v-list>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-card elevation="0" class="styled-card--light" :loading="loading">
          <v-card-text>
            <v-row>
              <v-col>
                <v-chip
                  v-if="filter.failed_auditing"
                  close
                  @click:close="filter.failed_auditing = false"
                >
                  Show failed urls
                </v-chip>
              </v-col>
              <v-col> </v-col>
              <v-col class="d-flex justify-end">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  dense
                  hide-details
                  style="max-width: 360px"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="px-0">
            <template v-if="loadings.tableData">
              <v-skeleton-loader type="table"></v-skeleton-loader>
            </template>
            <template v-else>
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="filteredItems"
                :sort-desc.sync="sortDesc"
                :search="search"
                item-key="pk_id"
                multi-sort
                show-select
                :items-per-page="25"
                :footer-props="{
                  itemsPerPageOptions: [10, 25, 50, 100, 250, 500, -1],
                }"
              >
                <template #[`item.priority`]="{ item }">
                  <v-menu bottom offset-y content-class="my-shadow--e2">
                    <template #activator="{ on }">
                      <v-chip
                        v-on="on"
                        label
                        outlined
                        :class="`${
                          item.priority !== null &&
                          item.priority !== undefined &&
                          item.priority !== ''
                            ? ''
                            : 'px-2'
                        }`"
                        :disabled="loadings.itemsOnLoading.includes(item.pk_id)"
                      >
                        <template v-if="item.priority">
                          <template v-if="item.priority === 'NONE'">
                            <span style="opacity: 0.5">none</span>
                          </template>
                          <template v-else>
                            {{ item.priority }}
                          </template>
                        </template>
                        <v-icon
                          v-if="
                            item.priority !== null &&
                            item.priority !== undefined &&
                            item.priority !== ''
                          "
                          small
                          right
                          style="opacity: 0.5"
                          @click="handleChangeItemPriority(item, null)"
                        >
                          mdi-close
                        </v-icon>
                        <v-icon v-else small>mdi-chevron-down</v-icon>
                      </v-chip>
                    </template>
                    <v-card class="styled-card--light">
                      <v-list dense>
                        <v-list-item
                          @click="handleChangeItemPriority(item, 'F')"
                        >
                          F
                        </v-list-item>
                        <v-list-item
                          @click="handleChangeItemPriority(item, 'S')"
                        >
                          S
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </template>
                <template #[`item.phrase`]="{ item }">
                  <span v-if="item.phrase_html">
                    <span v-html="item.phrase_html"></span>
                    <v-icon color="error" title="Contains Russian characters"
                      >mdi-information</v-icon
                    ></span
                  >
                  <span v-else>{{ item.phrase }}</span>
                </template>
                <template #[`item.audit`]="{ item }">
                  <v-chip
                    small
                    v-if="item.code !== 200 && item.code"
                    color="error"
                    >{{ item.code }}
                  </v-chip>
                  <v-chip
                    small
                    v-if="item.canonical !== item.url && item.canonical"
                    color="warning"
                    >{{ item.canonical }}
                  </v-chip>
                  <v-chip small v-if="!isFailedAudit(item)" color="primary"
                    >Good
                  </v-chip>
                </template>
                <template #[`item.invalid_serp_title`]="{ item }">
                  <div
                    v-if="
                      item.invalid_serp_title[2] !== null &&
                      item.invalid_serp_title[2]
                    "
                  >
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-chip small color="warning" v-bind="attrs" v-on="on"
                          >Diff
                        </v-chip>
                      </template>
                      <span
                        >page: {{ item.invalid_serp_title[0] }}<br />serp:
                        {{ item.invalid_serp_title[1] }}</span
                      >
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
              <upload-keys />
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      :retain-focus="false"
      v-model="statistic_dialog"
      max-width="900"
      content-class="remove-dialog-shadow"
    >
      <v-card class="styled-card--default">
        <v-card-title>
          <span>Statistics</span>
          <v-spacer />
          <v-btn icon @click="statistic_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-0">
          <v-data-table
            :headers="statistics_headers"
            :items="statistics"
            :items-per-page="5"
          >
            <template #[`item.md5`]="{ item }">
              <router-link
                target="_blank"
                :to="{ name: 'search-console.url', params: { id: item.md5 } }"
              >
                <v-chip outlined @click="() => {}">
                  add
                  <v-icon small right>mdi-open-in-new</v-icon>
                </v-chip>
              </router-link>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VueJsonToCsv from "vue-json-to-csv";
import axios from "axios";
import JsonExcel from "vue-json-excel";
import copy from "copy-to-clipboard";
import { deepClone } from "@/utils/functions";
import service from "@/plugins/service";
import UploadKeys from "./Dialogs/UploadKeys.vue";
import GlobalProjectBinderMixin from "@/mixins/utils/GlobalProjectBinderMixin";
import Service from "@/plugins/service";
import UploadFiltersDataMixin from "@/mixins/UploadFiltersDataMixin";
import FiltersHandling from "@/mixins/components/FiltersHandling";

export default {
  name: "KeywordsControlIndex",
  components: { UploadKeys, JsonExcel, VueJsonToCsv },
  mixins: [GlobalProjectBinderMixin, UploadFiltersDataMixin, FiltersHandling],
  data() {
    return {
      filters: {
        project_id: null,
        project_category: [1],
      },
      test: null,
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Keywords control",
          disabled: true,
        },
      ],
      isContainerFluid: false,
      search: "",
      loading: false,
      loadings: {
        itemsOnLoading: [],
        tableData: false,
        recollectVolume: false,
      },
      sortDesc: [true],
      selected: [],
      headers: [
        { text: "Phrase", value: "phrase", align: "left" },
        { text: "Path", value: "path", align: "left" },
        { text: "Priority", value: "priority", align: "center" },
        { text: "Audit", value: "audit", align: "center" },
        { text: "Volume", value: "volume", align: "left" },
        { text: "Volume Date", value: "volume_date", align: "left" },
        { text: "Custom Value", value: "custom_value", align: "left" },
        { text: "Target Serp Top", value: "target_serp_top", align: "center" },
        { text: "Category", value: "category_name", align: "left" },
        { text: "Sub Category", value: "sub_category_name", align: "left" },
        { text: "Lang", value: "lang_name", align: "center" },
        {
          text: "Invalid Serp Title",
          value: "invalid_serp_title",
          align: "center",
        },
      ],
      items: [],
      count_all: 0,
      count_entry: 0,
      statistic_dialog: false,
      statistics: [],
      statistics_headers: [
        { text: "Url", value: "url", align: "left" },
        { text: "Ks", value: "ks", align: "left" },
        { text: "Sc", value: "sc", align: "left" },
        { text: "Add", value: "md5", align: "left" },
      ],
      urls_with_bad_performance: [],
      filter: {
        failed_auditing: false,
      },
    };
  },
  methods: {
    gpbmHandleChangeGlobalProject(projectId) {
      this.filters.project_id = projectId;
    },
    handleChangeItemPriority(item, value) {
      item.priority = value;

      this.saveNewPriority(item.pk_id, item.id, value);
    },
    async saveNewPriority(pkId, projectId, priorityString) {
      try {
        const url = `/keyword-control/${projectId}/update-priority`;

        const payload = {
          pk_id: pkId,
          priority: priorityString,
        };

        this.loadings.itemsOnLoading.push(pkId);

        const response = await service.post(url, payload);

        if (response && response.data && response.data.success) {
          this.$message.notification({
            title: "Success",
            text: "Priority successfully changed",
            type: "success",
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.itemsOnLoading = this.loadings.itemsOnLoading.filter(
          (v) => v !== pkId
        );
      }
    },
    copyTable(headers, items) {
      if (items.length > 1000) {
        if (
          !window.confirm(
            "Watch out for a large number of elements in the table! Copying may overload the page. Are you sure you want to start copying the table?"
          )
        )
          return;
      }

      try {
        let tmprStr = "";

        headers.forEach((header) => {
          tmprStr += header.value;
          tmprStr += "\t";
        });

        tmprStr += "\n";

        items.forEach((row) => {
          Object.keys(row).forEach((key) => {
            const val = row[key];

            if (val === null || val === "null") {
              tmprStr += " ";
            } else {
              tmprStr += val;
            }

            tmprStr += "\t";
          });
          tmprStr += "\n";
        });

        copy(tmprStr);
        this.$message.notification({
          title: "Coppied",
          text: `${items.length} rows were copied to clipboard.`,
          type: "success",
        });
      } catch (e) {
        console.error(e);
      }
    },
    deleteSelected() {
      if (this.getProjectId !== null && this.getProjectId !== undefined) {
        let url = `${this.$store.state.server_url}/keyword-control/${this.getProjectId}/delete-selected`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        axios
          .post(
            url,
            {
              selected: this.selected.map((v) => v.pk_id),
            },
            config
          )
          .then(() => {
            this.loading = false;
            this.load_data();
            this.$message.notification({
              type: "success",
              title: "Success",
              text: "Selected items successfully deleted",
              duration: 6000,
            });
          })
          .catch((error) => {
            this.sheet = true;
            this.$message.notification({
              type: "error",
              title: "Something wrong... 🤕",
              text: error.message,
              duration: 6000,
            });
          });
      }
    },
    deleteAll() {
      if (!window.confirm("Are you sure you want delete all?")) return;

      this.loading = true;

      if (this.getProjectId !== null && this.getProjectId !== undefined) {
        let url = `${this.$store.state.server_url}/keyword-control/${this.getProjectId}/delete-all`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        axios
          .post(url, {}, config)
          .then(() => {
            this.loading = false;
            this.load_data();
          })
          .catch(() => (this.sheet = true));
      }
    },
    recollectVolume() {
      if (this.getProjectId !== null && this.getProjectId !== undefined) {
        let url = `${this.$store.state.server_url}/keyword-control/${this.getProjectId}/recollect-volume`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        this.loadings.recollectVolume = true;

        axios
          .post(url, {}, config)
          .then(() => {
            this.$message.notification({
              title: "Success",
              text: "Volumes successfull recollected.",
              type: "success",
            });
          })
          .catch(() => (this.sheet = true))
          .finally(() => {
            this.loadings.recollectVolume = false;
          });
      }
    },
    fixTableData(items) {
      const tmpr = deepClone(items);

      tmpr.forEach((item) => {
        if (item.priority !== undefined && item.priority !== null)
          item.priority = String(item.priority).toUpperCase();
      });

      return tmpr;
    },
    async load_data() {
      if (!this.getProjectId) return;

      try {
        this.loadings.tableData = true;

        const response = await Service.get(
          `/keyword-control/${this.getProjectId}`,
          true,
          { params: { project_category: this.filters.project_category } }
        );

        this.items = this.fixTableData(response.data.ks);
        this.count_all = response.data.count_all;
        this.count_entry = response.data.count_entry;
        this.statistics = response.data.statistics;
      } catch (e) {
        console.error("Error while loading table data.", e);
        this.sheet = true;
        this.$message.notification({
          title: "Error",
          text: `Something wrong. Try again.`,
          type: "error",
        });
      } finally {
        this.loadings.tableData = false;
      }
    },
    isFailedAudit(item) {
      return (
        item.code !== 200 ||
        item.canonical !== item.url ||
        item.invalid_serp_title[2]
      );
    },
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => {
        if (this.filter.failed_auditing) {
          return this.isFailedAudit(item);
        }
        return true;
      });
    },
    isProjectSelected() {
      return (
        this.$store.getters["project/active_project"] !== null &&
        this.$store.getters["project/active_project"] !== undefined &&
        this.$store.getters["project/active_project"] !== "null" &&
        this.$store.getters["project/active_project"] !== "undefined"
      );
    },
    getProjectId() {
      return this.filters.project_id;
    },
    urlsWithFailedAudit() {
      return this.items.filter((v) => this.isFailedAudit(v));
    },
  },
  watch: {
    isContainerFluid(value) {
      localStorage.setItem(
        "keywords-control-dashboard-container-state-523942",
        value ? "1" : "0"
      );
    },
  },
  created() {
    this.isContainerFluid =
      localStorage.getItem(
        "keywords-control-dashboard-container-state-523942"
      ) === "1";

    this.load_data();
  },
  async mounted() {
    await this.parseQuery("filters");

    this.uploadFiltersData(["projects", "project_categories"]);

    if (!this.filters.project_id)
      this.filters.project_id = this.gpbmComputedGlobalProject;
  },
};
</script>

<style scoped></style>
