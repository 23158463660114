<template>
  <div>
    <v-data-table
      :search="search_domains"
      :headers="headers"
      :items="items"
      multi-sort
      :sort-by="['error_manual_priority', 'ltt_all', 'traffic']"
      :sort-desc="[true, true, true]"
      dense
    >
      <template
        v-for="h in headers"
        v-slot:[`header.${h.value}`]="{ header }"
        v-slot:class="{}"
      >
        <v-tooltip bottom :key="h.text">
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ header.text }}</span
            ><span v-if="header.icon"><v-img :src="icon" width="20px" /></span>
          </template>
          <span>{{ header.title }}</span>
        </v-tooltip>
      </template>

      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td
              class="text-center"
              :style="
                'white-space: nowrap;background-color:' +
                (is_dark() ? '#1E1E1E;' : 'white')
              "
            >
              <v-btn
                v-if="
                  block.value === 'new' ||
                  block.value === 'pending' ||
                  block.value === 'in_progress'
                "
                color="error"
                bottom
                x-small
                max-width="20"
                max-height="20"
                right
                fab
                @click="in_trash(item, 2)"
              >
                <v-icon size="14">mdi-delete-empty</v-icon>
              </v-btn>
              <v-btn
                v-if="block.value === 'delete' || block.value === 'pending'"
                color="success"
                bottom
                x-small
                max-width="20"
                max-height="20"
                right
                fab
                @click="in_trash(item, 0)"
              >
                <v-icon size="14">mdi-chevron-double-up</v-icon>
              </v-btn>
              {{ item.id }}
            </td>
            <td
              :style="
                'white-space: nowrap;background-color:' +
                (is_dark() ? '#1E1E1E;' : 'white')
              "
            >
              {{ item.domain }}
              <a :href="'http://' + item.domain" target="_blank">
                <v-icon small>mdi-open-in-new</v-icon>
              </a>
              <v-chip
                label
                small
                :to="{ name: 'site-analysis', query: { domain: item.domain } }"
                class="px-2 ml-1"
                title="Site analysis"
                target="_blank"
                >SA
                <v-icon x-small class="ml-1">mdi-open-in-new</v-icon></v-chip
              >
              <v-chip
                v-if="item.ltt_use"
                color="warning"
                title="Ltt Used"
                class="pa-1 ml-2"
                x-small
                label
                >USED</v-chip
              >
            </td>
            <td class="text-center">
              <span style="white-space: nowrap">
                {{ item.login }}
                <v-icon small @click="copy_for_gen_text(item.login)"
                  >mdi-content-copy
                </v-icon>
              </span>
            </td>
            <td class="text-center">
              <span style="white-space: nowrap">
                {{ item.pass }}
                <v-icon small @click="copy_for_gen_text(item.pass)"
                  >mdi-content-copy
                </v-icon>
              </span>
            </td>
            <td class="text-center">
              <span style="white-space: nowrap">
                {{ item.address }}
                <v-icon small @click="copy_for_gen_text(item.address)"
                  >mdi-content-copy
                </v-icon>
              </span>
            </td>
            <td class="text-center" style="min-width: 110px">
              <v-select
                v-model="item.error_manual_check_id"
                :background-color="item.color_status"
                @change="save_status(item)"
                :style="get_theme()"
                :items="status"
                item-text="name"
                item-value="id"
                small-chips
              >
              </v-select>
            </td>
            <td class="text-center" style="min-width: 110px">
              <v-select
                v-model="item.no_type"
                :items="no_types"
                item-text="name"
                @change="save_no_types(item)"
                item-value="id"
                multiple
                small-chips
              >
              </v-select>
            </td>
            <td
              class="text-center"
              style="white-space: nowrap"
              :style="get_color(item.ltt_on_page, item.ltt_all)"
            >
              <span v-if="item.ltt_all > 0"
                ><span title="all ltt">{{ item.ltt_all }}</span> -
                <span title="is live">{{ item.ltt_on_page }}</span> -
                <span title="can be indexed">{{ item.ltt_is_index }}</span> -
                <span title="indexed">{{ item.ltt_gi }}</span></span
              >
            </td>
            <td class="text-center" style="white-space: nowrap">
              <span
                v-html="get_date(item.last_posting_in_days)"
                :title="item.last_posting"
              ></span>
            </td>
            <td class="text-center">
              {{ item.domain_rating }}
            </td>
            <td class="text-center">
              {{ item.traffic }}
            </td>
            <td class="text-center">
              {{ item.country_traffic }}
            </td>
            <td class="text-center">
              {{ item.global_rank }}
            </td>
            <td class="text-center">
              {{ item.google_index }}
            </td>
            <td class="text-center">
              {{ item.created_at }}
            </td>
            <td class="text-center" style="white-space: nowrap">
              <span :style="item.spam_score < 30 ? 'color:red' : ''">
                {{ item.spam_score }}
              </span>
              <v-icon v-if="block.value === 'delete' && item.is_seo" size="14"
                >mdi-skull-outline</v-icon
              >
              <v-btn
                v-if="block.value === 'new'"
                color="warning"
                bottom
                x-small
                max-width="20"
                max-height="20"
                right
                fab
                @click="in_trash(item, 3)"
              >
                <v-icon v-if="item.is_seo" size="14"
                  >mdi-emoticon-excited-outline</v-icon
                >
                <v-icon v-else size="14">mdi-alert-circle</v-icon>
              </v-btn>
            </td>
            <td class="text-center">
              <v-autocomplete
                style="min-width: 100px"
                v-model="item.lang_id"
                :items="languages_dct"
                item-text="name"
                item-value="id"
                @change="save_data_domains('lang_id', item.lang_id, item)"
              ></v-autocomplete>
            </td>
            <td class="text-center">
              <v-checkbox
                v-model="item.priority"
                hide-details
                @change="save_data_domains('priority', item.priority, item)"
              >
              </v-checkbox>
            </td>
            <td class="text-center" style="min-width: 210px">
              <v-textarea
                :background-color="item.color_workflow"
                hide-details
                @change="save_data_domains('workflow', item.workflow, item)"
                v-model="item.workflow"
                :style="get_theme()"
                solo
                rows="1"
              ></v-textarea>
            </td>
            <td class="text-center" style="min-width: 210px">
              <v-textarea
                :background-color="item.notes"
                hide-details
                @change="save_data_domains('notes', item.notes, item)"
                v-model="item.notes"
                :style="get_theme()"
                solo
                rows="1"
              ></v-textarea>
            </td>
            <td class="text-center">
              {{ item.user }}
            </td>
            <td class="text-center">
              <v-checkbox
                v-model="item.check"
                hide-details
                @change="save_data_domains('check', item.check, item)"
              >
              </v-checkbox>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TableIndex",
  props: [
    "items",
    "headers",
    "block",
    "thematic_threads",
    "icon",
    "status",
    "fetch_data_domains",
  ],
  data() {
    return {
      search_domains: "",
      loadings: {},
      no_types: [
        { name: "mainpage", id: "allowLinkOld" },
        { name: "linkold", id: "allowMainPage" },
        { name: "linksnew", id: "allowLinksNew" },
      ],
    };
  },
  methods: {
    get_tag_object(tags, tag) {
      let val = tags.find((i) => i.id === tag);
      if (val === undefined) {
        return {};
      }
      return val;
    },
    get_date(last_posting_in_days) {
      let days = last_posting_in_days;
      let months = Math.round(days / 30);
      let years = Math.round(days / 365);

      let time_ago = `${days} d`;
      let color = "green";

      if (years > 0) {
        time_ago = `${years} y`;
        color = "red";
      } else if (months > 0) {
        time_ago = `${months} m`;
        color = "#ffcc00";
      }
      return '<span style="color: ' + color + '">' + time_ago + "</span>";
    },
    get_color(item, all) {
      let percent = item / all;

      let color = ""; //= '#9ACD32'
      if (percent < 0.2) {
        color = "rgba(250,126,126,0.88)";
      } else if (percent < 0.5) {
        color = this.is_dark() ? "#d6b701" : "#fff9b4";
      }
      // console.log(item, all, percent, color)

      return "background-color:" + color;
    },
    copy_for_gen_text(text) {
      this.$clipboard(text);
    },
    in_trash(item, type) {
      this.items = this.items.filter((v) => v.id !== item.id);
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("in_trash", true);

        let url = `${self.$store.state.server_url}/anchor-plan/placement-links/donors-base/save-trash/${item.id}`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          type: type,
        };

        axios
          .post(url, data, config)
          .then(function () {
            self.update_loading("in_trash", false);
            self.fetch_data_domains();
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    /* save_action(column, value, id) {
       let self = this;
       self.update_loading('save_action', true)

       let url = `${self.$store.state.server_url}/anchor-plan/placement-links/donors-base/action/x/save`;

       let config = {
         headers: {
           'Content-Type': 'application/json',
           'Authorization': this.$store.state.auth.token
         }
       };

       let data = {
         id: id,
         column: column,
         value: value
       }

       axios.post(url, data, config)
           .then(function () {
             self.update_loading('save_action', false)
           }).catch(function (error) {
         alert(error);
       })
     },*/
    save_no_types(item) {
      let self = this;
      self.update_loading("save_no_types", true);

      let url = `${self.$store.state.server_url}/anchor-plan/placement-links/donors-base/no_types/x/save`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {
        id: item.x_id,
        allowLinkOld: item.no_type.includes("allowLinkOld"),
        allowLinksNew: item.no_type.includes("allowLinksNew"),
        allowMainPage: item.no_type.includes("allowMainPage"),
      };

      axios
        .post(url, data, config)
        .then(function () {
          self.update_loading("save_no_types", false);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    save_status(item) {
      let self = this;
      self.update_loading("save_status", true);

      let url = `${self.$store.state.server_url}/anchor-plan/placement-links/donors-base/status/x/save`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let type = self.status.find((i) => i.id === item.error_manual_check_id)[
        "type"
      ];

      let data = {
        id: item.x_id,
        domain_id: item.domain_id,
        status: item.error_manual_check_id,
        type: type,
      };

      axios
        .post(url, data, config)
        .then(function (response) {
          if (response.data) {
            self.items.find((i) => i.id === item.id)["color_status"] = "blue";

            if (type === "domain") {
              self.items
                .filter((i) => i.domain_id === item.domain_id)
                .forEach((v) => {
                  v.error_manual_check_id = item.error_manual_check_id;
                });
            }
          } else {
            self.items.find((i) => i.id === item.id)["color_status"] = "red";
          }
          self.update_loading("save_status", false);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    save_data_domains(name, value, item) {
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("save_data_domains", true);

        let url = `${self.$store.state.server_url}/anchor-plan/placement-links/donors-base/save`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          name: name,
          value: value,
          domain: item.domain_id,
          type: item.link_type_id,
          task_id: this.$route.params.id,
          project_id: item.project_id,
        };

        axios
          .post(url, data, config)
          .then(function (response) {
            if (response.data) {
              self.items.find((i) => i.id === item.id)["color_" + name] =
                "blue";
            } else {
              self.items.find((i) => i.id === item.id)["color_" + name] = "red";
            }
            self.update_loading("save_data_domains", false);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    is_dark() {
      return this.$vuetify.theme.dark;
    },
    get_theme() {
      return this.is_dark()
        ? "border-color: transparent; border: 1px solid red"
        : "";
    },
    open_url(url) {
      window.open(url, "_blank");
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
    languages_dct() {
      return this.$store.state.reuse.languages_dct;
    },
  },
  created() {
    if (this.languages_dct.length === 0) {
      this.$store.dispatch("fetch_languages_dct_list");
    }
  },
};
</script>

<style scoped>
table > tbody > tr > td:nth-child(1),
table > thead > tr > th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  min-width: 90px;
  z-index: 9998;
}

table > tbody > tr > td:nth-child(2),
table > thead > tr > th:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 90px;
  z-index: 9998;
}
</style>
