import { uid } from "../../../utils/functions";

export const BREADCRUMBS = [
  {
    text: "Home",
    to: "/",
  },
  {
    text: "Positions",
    disabled: true,
  },
  {
    text: "Dashboard v3",
    disabled: true,
  },
];

export const MOCK_TRENDS_DATA = [
  {
    id: uid(),
    title: "Visibility, %",
    content: {
      value: 4.9,
      append: "%",
    },
    diff: {
      value: 0.29,
      append: "%",
    },
    chart: {
      series: [
        {
          name: "Visibility",
          data: [8, 5, 3, 3, 3, 4, 4, 6, 7, 4],
        },
      ],
    },
  },
  {
    id: uid(),
    title: "Average position",
    content: {
      value: 14.9,
      append: null,
    },
    diff: {
      value: 0.28,
    },
    chart: {
      series: [
        {
          name: "Average position",
          data: [3, 2, 4, 5, 4, 5, 7, 8, 3, 2],
        },
      ],
    },
  },
  {
    id: uid(),
    title: "Traffic",
    content: {
      value: 37.1,
      append: "K",
    },
    diff: {
      value: -1.9,
      append: "K",
    },
    chart: {
      series: [
        {
          name: "Traffic",
          data: [31, 40, 28, 51, 42, 109, 100],
        },
      ],
    },
  },
  {
    id: uid(),
    title: "Pages, num",
    content: {
      value: 302,
    },
    diff: {
      value: 15,
    },
    chart: {
      series: [
        {
          name: "Pages",
          data: [14, 23, 22, 11, 35, 54, 32],
        },
      ],
    },
  },
];

export const MOCK_POSITIONS = {
  series: [
    {
      name: "#1 Position",
      data: [44, 55, 41, 67, 22, 43],
    },
    {
      name: "#2-3 Pos",
      data: [13, 23, 20, 8, 13, 27],
    },
    {
      name: "#4-5 Pos",
      data: [23, 33, 30, 18, 23, 37],
    },
    {
      name: "#6-10 Pos",
      data: [33, 43, 40, 28, 33, 47],
    },
    {
      name: "#11-20 Pos",
      data: [43, 53, 50, 38, 43, 57],
    },
    {
      name: "#21-30 Pos",
      data: [53, 63, 60, 48, 53, 67],
    },
    {
      name: "#31-50 Pos",
      data: [63, 73, 70, 58, 63, 77],
    },
    {
      name: "#51-100 Pos",
      data: [73, 83, 80, 68, 73, 87],
    },
  ],
};
