<template>
  <div class="d-flex align-start">
    <div class="d-flex flex-column">
      <span v-if="date.all.all > 0">
        <span v-if="disableLink">{{ date.all.all }}</span>
        <a v-else :href="link" target="_blank"> {{ date.all.all }}</a>
      </span>
      <div v-if="date.wait_fix" title="Wait fix" class="error--text">
        {{ date.wait_fix }}
      </div>
    </div>
    <div style="white-space: nowrap">
      <span v-if="date.on_page.all > 0"
        >({{ date.on_page.all
        }}<span v-if="date.gi.all > 0"> / {{ date.gi.all }}</span
        >)
      </span>
      <span v-if="date.action > 0"> → {{ date.action }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["date", "link", "disableLink"],
};
</script>

<style lang="scss" scoped></style>
