<template>
  <v-data-table
    :search="search"
    :headers="headers"
    :items="items"
    class="anchor-distribution__links-table"
  >
    <template v-for="h in headers" #[`header.${h.value}`]="{ header }">
      <v-tooltip :key="h.value" bottom>
        <template #activator="{ on }">
          <div
            v-on="on"
            :style="{
              background:
                getSelectedUrl === header.text ? 'rgba(28,90,250,0.35)' : '',
            }"
          >
            {{ fixHeader(header.text) }}
          </div>
        </template>
        <div>{{ header.text }}</div>
      </v-tooltip>
    </template>
    <template #body="{ items }">
      <tbody>
        <tr v-for="(row, index) in items" :key="index">
          <td v-for="(key, i) in Object.keys(row)" :key="key + i">
            <div v-if="i !== 0" style="height: 100%">
              <div class="d-flex flex-wrap py-1" style="gap: 0.25rem">
                <template v-for="(i, idx) in row[key]">
                  <v-chip
                    v-if="i.text"
                    label
                    small
                    color="transparent"
                    :key="idx"
                    class="py-1"
                    style="white-space: normal; height: auto"
                  >
                    {{ i.text }}
                    <span
                      v-if="i.value"
                      class="my-badge primary white--text ml-2"
                      style="white-space: nowrap"
                      >{{ i.value }}</span
                    >
                  </v-chip>
                </template>
              </div>
            </div>
            <div v-else>
              {{ row[key] }}
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ["headers", "items", "selectedUrlId", "filters", "search"],
  computed: {
    getSelectedUrl() {
      return (
        this.filters.url.find((i) => i.value === this.selectedUrlId).text || ""
      );
    },
  },
  methods: {
    fixHeader(string) {
      return string
        .replaceAll("https://", "")
        .replaceAll("http://")
        .split("/")[0];
    },
    getValue(data) {
      if (!data) {
        return data;
      }

      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty("value")) {
        return data.value;
      }
      return data;
    },
  },
};
</script>
