<template>
  <v-dialog
    v-model="dialog"
    content-class="my-shadow--e3"
    width="1440"
    scrollable
  >
    <v-card flat class="rounded-lg border">
      <v-card-title class="text-body-2 pa-4">
        Edit role
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text v-if="data?.role" class="pt-5">
        <v-row>
          <v-col cols="6">
            <div class="text-body-2 mb-1">Role name</div>
            <v-text-field
              v-model="data.role.name"
              outlined
              hide-details
              dense
            />
          </v-col>
          <v-col cols="6">
            <div class="text-body-2 mb-1">Priority</div>
            <v-text-field
              v-model="data.role.priority"
              type="number"
              min="0"
              outlined
              hide-details
              dense
            />
          </v-col>
          <v-col cols="12">
            <div class="text-body-2 mb-1">Permissions</div>
            <div class="text-body-2 mb-2 d-flex">
              <v-text-field
                v-model="searchQuery"
                hide-details
                outlined
                dense
                placeholder="Type to search"
                append-icon="mdi-magnify"
                style="max-width: 320px"
              />
              <v-spacer />
              <vue-json-to-csv
                v-if="formattedSections?.length"
                :json-data="getCsvContent()"
                csv-title="roles"
                :separator="'\t'"
              >
                <v-btn height="40" class="text-normal">
                  Export to CSV <v-icon small right>mdi-export</v-icon>
                </v-btn>
              </vue-json-to-csv>
            </div>
            <v-data-table
              :search="searchQuery"
              :headers="headers"
              :items="formattedSections"
              item-value="id_section"
              class="rounded-lg border"
              :items-per-page="15"
              ref="dataTableRef"
              :footer-props="{ itemsPerPageOptions: [15, 50, 150, 300, -1] }"
              dense
            >
              <template
                v-for="h in headers"
                #[`header.${h.value}`]="{ header }"
              >
                <v-tooltip v-if="h.tooltip" bottom :key="h.text">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{ header.text }}
                      <v-icon size="14" style="opacity: 0.5"
                        >mdi-information-outline</v-icon
                      >
                    </span>
                  </template>
                  <span>{{ header.tooltip }}</span>
                </v-tooltip>
                <template v-else>
                  {{ h.text }}
                </template>
              </template>
              <template #[`item.route`]="{ value }">
                {{ value === "none" ? "" : value }}
              </template>
              <template #[`item.name`]="{ item, value }">
                <small>
                  <a :href="item.url" target="_blank">
                    {{ item.url }}
                  </a>
                </small>
                <div>{{ value }}</div>
              </template>
              <template
                v-for="permission in data.permissions"
                #[`item.permission_${permission.id}`]="{ item }"
              >
                <v-icon
                  @click="toggleAccess(item.id_section, permission.id)"
                  style="cursor: pointer"
                  :key="`${item.id_section}-${permission.id}`"
                >
                  {{
                    isChecked(item.id_section, permission.id)
                      ? "mdi-checkbox-marked"
                      : "mdi-checkbox-blank-outline"
                  }}
                </v-icon>
                <v-checkbox
                  v-if="false"
                  :value="isChecked(item.id_section, permission.id)"
                  :key="`${item.id_section}-${permission.id}`"
                  @change="toggleAccess(item.id_section, permission.id)"
                />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions class="pa-4">
        <v-spacer />
        <v-btn large class="px-6 text-normal" @click="dialog = false">
          Back
        </v-btn>
        <v-btn
          large
          class="px-6 text-normal"
          color="success"
          @click="editRole"
          :loading="loadings.edit"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueJsonToCsv from "vue-json-to-csv";
import routes from "@/utils/routes";
import Dialog from "../../../mixins/Dialog";
import { RolesApiService } from "../services/RolesApiService";
import { deepClone } from "@/utils/functions";

export default {
  components: { VueJsonToCsv },

  props: {
    data: {
      type: Object,
      default: () => ({
        permissions: [],
        sections: [],
        sectionsToRole: [],
        role: {},
      }),
    },
  },

  mixins: [Dialog],

  computed: {
    headers() {
      // Первая колонка — название секции, остальные — права
      return [
        { text: "Section Name", value: "name" },
        { text: "Route", value: "route" },
        ...this.data?.permissions.map((permission) => ({
          text: permission.name,
          value: `permission_${permission.id}`,
          align: "center",
          tooltip: this.getTitle(permission.name),
        })),
      ];
    },
    formattedSections() {
      // Преобразуем секции в items
      return this.data?.sections.map((section) => ({
        ...section,
        url: this.getSectionUrlUsingRoutes(section, this.routesData),
      }));
    },
  },

  watch: {
    data(newEditData) {
      this.accessMatrix = newEditData?.sectionsToRole || {};
    },
  },

  data: () => ({
    routesData: deepClone(routes),
    searchQuery: "",
    permissions: {
      items: [],
      headers: [],
    },
    loadings: {
      edit: false,
    },
    accessMatrix: [],
  }),

  methods: {
    getCsvContent() {
      return this.formattedSections.map((row) => {
        const permissions = {};

        this.data?.permissions.forEach((permission) => {
          permissions[this.getTitle(permission.name)] = this.isChecked(
            row.id_section,
            permission.id
          )
            ? "✅"
            : "❌";
        });

        return {
          ...row,
          url: "https://app.serpinsider.com" + row.url,
          ...permissions,
        };
      });
    },
    getTitle(name) {
      const titles = {
        c: "Create",
        r: "Read",
        u: "Update",
        d: "Delete",
        a: "Admin",
        own: "Owner of the task",
        "is access": "Has access to the task or project",
        export: "Has access to export data from reports",
      };

      return titles[name];
    },
    getSectionUrlUsingRoutes(sectionData, routes) {
      try {
        const { route: name } = sectionData;
        if (!name) return "";

        let parentPath = "";
        let childPath = "";

        for (const route of routes) {
          // First case: Route EQUALS parent element
          if (route.name === name) {
            parentPath = route.path;
            break;
          }

          let childFound = false;
          // Second case: Route EQUALS child element
          if (route.children !== undefined) {
            route.children.forEach((child) => {
              if (childFound) return;
              if (child.name === name) {
                parentPath = route.path;
                childPath = child.path;
                childFound = true;
              }
            });
          }
        }

        if (parentPath || childPath) return parentPath + "/" + childPath;

        return "";
      } catch (e) {
        console.error("Cant get URL from section data.", e);
      }
    },
    async editRole() {
      try {
        this.loadings.edit = true;

        const payload = {
          name: this.data?.role?.name,
          priority: this.data?.role?.priority,
          p: this.accessMatrix,
        };

        await RolesApiService().updateRole(this.data?.role?.id, payload);

        this.$emit("afterEdit");
        this.dialog = false;
        this.searchQuery = "";
      } catch (e) {
        console.error("Error while editing role.", e);
      } finally {
        this.loadings.edit = false;
      }
    },

    isChecked(sectionId, permissionId) {
      return this.accessMatrix.includes(`${sectionId}-${permissionId}`);
    },

    toggleAccess(sectionId, permissionId) {
      const key = `${sectionId}-${permissionId}`;
      const index = this.accessMatrix.indexOf(key);

      if (index > -1) {
        this.accessMatrix = this.accessMatrix.filter(
          (access) => access !== key
        );
      } else {
        this.accessMatrix.push(key);
      }

      this.$forceUpdate();
    },
  },
};
</script>
