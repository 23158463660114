<template>
  <div class="anchor-distribution">
    <v-row dense>
      <v-col cols="12">
        <page-header title="Anchors Distribution" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="1">
            <v-autocomplete
              v-model="filters.gi"
              label="GI"
              disabled
              :items="filtersData.gi"
              item-text="text"
              item-value="value"
              outlined
              multiple
              dense
              clearable
              :loading="loadings.filters"
              @change="_$handleFilterChange($event, 'gi')"
              :menu-props="menuProps"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="1">
            <v-autocomplete
              v-model="filters.do_follow"
              label="Do Follow"
              :items="filtersData.dofollow"
              item-text="text"
              item-value="value"
              outlined
              multiple
              dense
              clearable
              :loading="loadings.filters"
              @change="_$handleFilterChange($event, 'do_follow')"
              :menu-props="menuProps"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.link_type"
              label="Links Type"
              :items="filtersData.link_type"
              item-text="text"
              item-value="value"
              outlined
              multiple
              dense
              clearable
              :loading="loadings.filters"
              @change="_$handleFilterChange($event, 'link_type')"
              :menu-props="menuProps"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="filters.anchor_type"
              label="Anchor Type"
              :items="filtersData.anchor_type"
              item-text="text"
              item-value="value"
              outlined
              multiple
              dense
              clearable
              :loading="loadings.filters"
              :menu-props="menuProps"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col>
            <easy-range-input
              label="AH DR"
              :model-value="filters.ah_dr"
              :max="100"
              :presets="presets.ahDrPresets"
              @update:modelValue="filters.ah_dr = $event"
            />
          </v-col>
          <v-col>
            <easy-range-input
              label="AH UR"
              :model-value="filters.ah_ur"
              :max="100"
              :presets="presets.ahDrPresets"
              @update:modelValue="filters.ah_ur = $event"
            />
          </v-col>
          <v-col>
            <easy-range-input
              label="AH Traffic"
              :model-value="filters.ah_traffic"
              :max="1000000"
              @update:modelValue="filters.ah_traffic = $event"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="1">
            <v-autocomplete
              v-model="filters.title_thematic"
              label="Title Thematic"
              :items="['yes', 'no']"
              item-text="text"
              item-value="value"
              outlined
              dense
              clearable
              :menu-props="menuProps"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="1">
            <v-autocomplete
              v-model="filters.gt"
              label="GT"
              :items="[2, 1, 0]"
              item-text="text"
              item-value="value"
              outlined
              dense
              clearable
              :menu-props="menuProps"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col>
            <easy-range-input
              label="AH URL Traffic"
              :model-value="filters.ah_url_traffic"
              :max="10000"
              :presets="presets.ahUrlTrafficPresets"
              @update:modelValue="filters.ah_url_traffic = $event"
            />
          </v-col>
          <v-col>
            <easy-range-input
              label="AH 2nd Level"
              :model-value="filters.ah_2nd_level"
              :max="1000"
              :presets="presets.ah2ndLevelPresets"
              @update:modelValue="filters.ah_2nd_level = $event"
            />
          </v-col>
          <v-col>
            <easy-range-input
              label="SW Rank"
              :model-value="filters.sw_rank"
              :max="3000000"
              :presets="presets.swRankPresets"
              @update:modelValue="filters.sw_rank = $event"
            />
          </v-col>
          <v-col>
            <easy-range-input
              label="Num of Pages"
              :model-value="filters.nums_of_pages"
              :max="10000"
              :presets="presets.numOfPagesPresets"
              @update:modelValue="filters.nums_of_pages = $event"
            />
          </v-col>
          <v-col>
            <easy-range-input
              label="AH Tr RD"
              :model-value="filters.ah_tr_rd"
              :max="10000"
              :presets="presets.numOfPagesPresets"
              @update:modelValue="filters.ah_tr_rd = $event"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <v-autocomplete
              v-model="filters.url"
              label="URL"
              :items="filtersData.url"
              item-text="text"
              item-value="value"
              outlined
              dense
              clearable
              :loading="loadings.filters"
              :menu-props="menuProps"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              v-model="filters.exclude_types"
              multiple
              :items="filtersData.exclude_types"
              dense
              hide-details
              outlined
              label="Exclude types"
              placeholder="Type to search"
            >
            </v-autocomplete>
          </v-col>
          <v-col>
            <div class="fix-button-height">
              <smart-date-filter
                :model-value="filters.date"
                @update:modelValue="handleUpdateDateFilter"
                :custom-presets="presets.customDatePresets"
              />
            </div>
          </v-col>
          <v-col cols="2" class="d-flex justify-end">
            <div class="fix-button-height" style="width: 100%">
              <v-btn
                block
                color="primary"
                @click="handleGetData"
                :loading="loadings.table"
                >Get data
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-card flat outlined rounded="lg" class="px-0">
          <v-card-title
            class="pa-3 d-flex flex-column"
            style="background: var(--card-darken-body-color); gap: 0.5rem"
          >
            <v-card flat outlined rounded="lg" width="100%">
              <v-card-title class="text-body-2 font-weight-bold">
                Main table
              </v-card-title>
              <v-card-title class="pa-3 pt-0">
                <v-text-field
                  v-model="search1"
                  hide-details
                  dense
                  outlined
                  placeholder="Type to search"
                  clearable
                  append-icon="mdi-magnify"
                />
              </v-card-title>
              <v-divider />
              <v-card-text class="px-0">
                <anchors-distribution-main-table
                  :search="search1"
                  :headers="mainTable.headers"
                  :items="mainTable.items"
                  :selected-url-id="filters.url"
                  :filters="filtersData"
                />
              </v-card-text>
            </v-card>
            <v-card flat outlined rounded="lg" width="100%">
              <v-card-title class="text-body-2 font-weight-bold">
                Top Anchors by Link Types
              </v-card-title>
              <v-card-title class="pa-3 pt-0">
                <v-text-field
                  v-model="search2"
                  hide-details
                  dense
                  outlined
                  placeholder="Type to search"
                  clearable
                  append-icon="mdi-magnify"
                />
              </v-card-title>
              <v-divider />
              <v-card-text class="px-0">
                <anchors-distribution-links-table
                  :search="search2"
                  :headers="linksTable.headers"
                  :items="linksTable.items"
                  :selected-url-id="filters.url"
                  :filters="filtersData"
                >
                </anchors-distribution-links-table>
              </v-card-text>
            </v-card>
            <v-card flat outlined rounded="lg" width="100%">
              <v-card-title class="text-body-2 font-weight-bold">
                Top Anchors by Anchor Types
              </v-card-title>
              <v-card-title class="pa-3 pt-0">
                <v-text-field
                  v-model="search3"
                  hide-details
                  dense
                  outlined
                  placeholder="Type to search"
                  clearable
                  append-icon="mdi-magnify"
                />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <anchors-distribution-links-table
                  :search="search3"
                  :headers="anchorsTable.headers"
                  :items="anchorsTable.items"
                  :selected-url-id="filters.url"
                  :filters="filtersData"
                >
                </anchors-distribution-links-table>
              </v-card-text>
            </v-card>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  AD_FIXED_HEADERS,
  DEFAULT_MENU_PROPS,
  LINKS_DYNAMIC_DASHBOARD_PRESETS,
  PROJECT_ID_IDTF,
} from "@/utils/defaultData";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import AnchorsDistributionMainTable from "@/components/OnePageAnalyzer/AnchorDistribution/AnchorsDistributionMainTable";
import AnchorsDistributionLinksTable from "@/components/OnePageAnalyzer/AnchorDistribution/AnchorsDistributionLinksTable";
import Location from "../../../mixins/Location";
import Project from "@/mixins/Project";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import PageHeader from "../../UI/PageHeader.vue";
import EasyRangeInput from "../../UI/EasyRangeInput.vue";

export default {
  components: {
    EasyRangeInput,
    PageHeader,
    SmartDateFilter,
    AnchorsDistributionLinksTable,
    AnchorsDistributionMainTable,
  },
  mixins: [Location, Project, FiltersHandling],
  data: () => ({
    search1: null,
    search2: null,
    search3: null,
    loadings: {
      filters: false,
      table: false,
    },
    selectedUrl: null,
    mainTable: {
      headers: [],
      items: [],
    },
    linksTable: {
      headers: [],
      items: [],
    },
    anchorsTable: {
      headers: [],
      items: [],
    },
    filtersData: {
      gi: [],
      dofollow: [],
      link_type: [],
      exclude_types: [],
      url: [],
    },
    filters: {
      gi: [],
      gt: null,
      date: [],
      do_follow: [],
      link_type: [],
      anchor_type: [],
      ah_dr: [0, 0],
      ah_ur: [0, 0],
      ah_traffic: [0, 0],
      ah_url_traffic: [0, 0],
      ah_2nd_level: [0, 0],
      sw_rank: [0, 0],
      nums_of_pages: [0, 0],
      url: null,
      title_thematic: null,
    },
    firstEnter: true,
    filtersPrefix: "filters",
    presets: LINKS_DYNAMIC_DASHBOARD_PRESETS,
    menuProps: DEFAULT_MENU_PROPS,
    headersFixData: AD_FIXED_HEADERS,
  }),
  async mounted() {
    await this.parseQuery("filters");

    if (this.$route.query[PROJECT_ID_IDTF]) {
      this._$setActiveProject(this.$route.query.project_id, true);
    }

    // eventPipe.$on("update-active-project", () => {
    //   const project_id = this.$store.getters.active_project;
    //   this.fetchFilters();
    // });

    const filtersWithArrType = [
      "gi",
      "do_follow",
      "link_type",
      "anchor_type",
      "ah_dr",
      "ah_ur",
      "ah_traffic",
      "ah_2nd_level",
      "sw_rank",
      "nums_of_pages",
      "date",
    ];
    const haveParams = this._$collectParams(filtersWithArrType, "filters");
    this.fetchFilters();

    if (!this.$route.query.date) {
      this.handleUpdateDateFilter(this.getDefaultDate);
    }

    if (haveParams) {
      this.fetchData();
    }
  },
  computed: {
    getDefaultDate() {
      const start = this.$moment(new Date())
        .subtract(200, "days")
        .startOf("day")
        .format("YYYY-MM-DD");
      const end = this.$moment(new Date()).format("YYYY-MM-DD");
      return [start, end];
    },
  },
  methods: {
    async fetchFilters() {
      this.loadings.filters = true;
      const filters = ["url", "link_type", "gi", "anchor_type", "dofollow"];
      const payload = {
        type: "/one-page-analyzer/links-dynamic/main",
        take: filters,
        filter: {
          projectID: this.$store.getters.active_project,
        },
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      if (resp) {
        const keys = Object.keys(resp);
        keys.forEach((key) => {
          this.filtersData[key] = resp[key];
        });
      }
      this.loadings.filters = false;
    },
    handleGetData() {
      this.fetchData();
    },
    async fetchData() {
      try {
        this.loadings.table = true;
        this.firstEnter = false;

        const payload = {
          filter: {
            ...this.filters,
            project_id: this.project_id || this.$store.getters.active_project,
          },
        };

        await this.fetchMainTable(payload);
        this.fetchLinkTypesTable(payload);
        await this.fetchAnchorTypesTable(payload);
      } catch (e) {
        console.error("Error while loading data.", e);
      } finally {
        this.loadings.table = false;
      }
    },
    addTooltipToHeader(items) {
      const headersOnFixNames = this.headersFixData.map((v) => v.name);

      return items.map((header) => {
        const headerName = header.text.toLowerCase();
        const match = headersOnFixNames.includes(headerName);

        if (match) {
          const tooltip = this.headersFixData.find(
            (v) => v.name === headerName
          ).tooltip;

          header.tooltip = tooltip;
        }

        return header;
      });
    },
    async fetchMainTable(payload) {
      const resp = await this.$store.dispatch(
        "anchor-distribution/mainTable",
        payload
      );

      if (resp) {
        this.mainTable.items = resp.items;
        this.mainTable.headers = this.addTooltipToHeader(resp.headers);
      }
    },
    async fetchLinkTypesTable(payload) {
      const resp = await this.$store.dispatch(
        "anchor-distribution/linksTable",
        payload
      );

      if (resp) {
        this.linksTable.items = resp.items;
        this.linksTable.headers = resp.headers;
      }
    },
    async fetchAnchorTypesTable(payload) {
      const resp = await this.$store.dispatch(
        "anchor-distribution/anchorsTable",
        payload
      );

      if (resp) {
        this.anchorsTable.items = resp.items;
        this.anchorsTable.headers = resp.headers;
      }
    },
    async wait(ms = 1500) {
      return new Promise((a) => {
        setTimeout(() => {
          return a();
        }, ms);
      });
    },
    handleUpdateDateFilter(value) {
      this.filters.date = value;
      this._$handleFilterChange(value, "date");
    },
  },
};
</script>
